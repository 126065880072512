// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CreateStream from './createStream';
import StreamDatatable from './streamDatatable';
import { StreamService } from '../../services/StreamService';
import { checkUserPermission } from '../../checkUserPermission';

const streamService = new StreamService();

const Stream = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [pending, setPending] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = React.useState(0);

    async function getAllStreams() {
        setPending(true)
        const data = await streamService.getStreams(currentPage, perpage,filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setPending(false)
    }
    useEffect(() => {
        getAllStreams()
    }, [refresh])

    const validationSchema = Yup.object().shape({
        course_stream_name: Yup.string()
            .required('Required'),
        // course: Yup.string().required('Required'),
    });
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])



    const formik = useFormik({
        initialValues: {
            id: "",
            course_stream_name: "",
            // course: "",

        },
        validationSchema,

        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("id", values.id ?? "");
            }
            formData.append("course_stream_name", values.course_stream_name);
            // formData.append("course", values.course);

            streamService.addOrUpdateStream(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    setIsloader(false)
                    toast.error(response?.response?.data?.errors?.non_field_errors[0], {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        formik.setValues({
            id: item.id,
            course_stream_name: item.course_stream_name,
            // course: item?.course,
        })

        console.log(item);
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Course Stream</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Course Stream</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Course Stream") && < React.Fragment >
                        <CreateStream image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <StreamDatatable totalRows={totalRows} setFilterText={setFilterText} currentPage={currentPage} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} setCurrentPage={setCurrentPage} setPerpage={setPerpage} pending={pending} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Stream;