import request from "../api/agent";

const API_ROUTE = "enquiry/enquiry";

export class EnquiryService {
    async getEnquiry(perpage, currentPage,filterId,search) {
        const serchValue = search??""
        if (perpage) {
            const response = await request.get(`${API_ROUTE}/enquiry-all-listing?limit=${perpage}&page=${currentPage}&category=${filterId}&search=${serchValue}`);
            return response.data;
        } else {
            const response = await request.get(`${API_ROUTE}/enquiry-all-listing`);
            return response.data;
        }
    }

    async addOrUpdateEnquiry(EnquiryData) {
        let action = `${API_ROUTE}/create-or-update-enquiry`;
        const response = await request.post(action, EnquiryData);
        return response;
    }

    async deleteEnquiry(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-enquiry`, { data: { id: id } });
        return response;
    }

}