import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { BatchService } from '../../services/Batch';



const UpcomingBatchCreate = ({ setImage, formik, isloader, formRef, image }) => {
    const batchService = new BatchService();
    const [batch, setbatch] = useState([]);
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        async function getAllBatch() {
            const data = await batchService.getBatch();
            setbatch(data?.data?.results)
        }
        getAllBatch()
    }, [])

    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom05">
                            <Form.Label>Batch </Form.Label>
                            <Form.Select
                                placeholder="Select an Option"
                                className="select-dropdown"
                                classnameprefix="select"
                                name="batch"
                                value={formik.values?.batch}
                                isValid={formik.touched.batch && !formik.errors.batch}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.batch && formik.errors.batch}
                                onChange={formik.handleChange}
                            >
                                <option value='' >Select Batch</option>
                                {batch?.map((b, index) =>
                                    <option value={String(b?.id)} key={index}>{b?.name}</option>
                                )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.batch}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom05">
                            <Form.Label>Time </Form.Label>
                            <Form.Control
                                type="text"
                                name="time"
                                value={formik.values?.time}
                                onChange={formik.handleChange}
                                isValid={formik.touched.time && !formik.errors.time}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.time && formik.errors.time}
                                placeholder="eg:10.00am-4.00pm"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.time}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Start Date </Form.Label>
                            <Form.Control
                                type="date"
                                name="start_date"
                                value={formik.values?.start_date}
                                onChange={formik.handleChange}
                                isValid={formik.touched.start_date && !formik.errors.start_date}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.start_date && formik.errors.start_date}
                                placeholder="Start Date"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.start_date}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom05">
                            <Form.Label>Amount </Form.Label>
                            <Form.Control
                                type="number"
                                name="amount"
                                value={formik.values?.amount}
                                onChange={formik.handleChange}
                                isValid={formik.touched.amount && !formik.errors.amount}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.amount && formik.errors.amount}
                                placeholder="Amount"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.amount}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        {/* <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Time</Form.Label>
                            <Form.Control
                                type="time"
                                name="time"
                                value={formik.values?.time}
                                onChange={formik.handleChange}
                                isValid={formik.touched.time && !formik.errors.time}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.time && formik.errors.time}
                                placeholder="Time"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.time}</Form.Control.Feedback>
                        </Form.Group> */}

                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Duration </Form.Label>
                            <Form.Control
                                type="text"
                                name="duration"
                                value={formik.values?.duration}
                                onChange={formik.handleChange}
                                isValid={formik.touched.duration && !formik.errors.duration}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.duration && formik.errors.duration}
                                placeholder="Duration"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.duration}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom06">
                            <Form.Label>Taught By </Form.Label>
                            <Form.Control
                                type="text"
                                name="taught_by"
                                value={formik.values?.taught_by}
                                onChange={formik.handleChange}
                                isValid={formik.touched.taught_by && !formik.errors.taught_by}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.taught_by && formik.errors.taught_by}
                                placeholder="Taught By"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.taught_by}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    {/* <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Flag</Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        setImage(e.target.files[0])
                                        formik.setValues({ ...formik.values, image: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) })
                                    }
                                    }
                                    isValid={formik.touched.image && !formik.errors.image}
                                    isInvalid={!!formik.errors.image}
                                />
                                {formik.values?.image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }
                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>

                            </div>

                        </Form.Group> */}

                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Tutor image<span className='required ms-1'>*</span> </Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        setImage(e.target.files[0])
                                        formik.setValues({ ...formik.values, image: e.target.files[0], image_url: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "" })
                                    }
                                    }
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.image && !formik.errors.image}
                                    isInvalid={formik.touched.image && formik.errors.image}
                                />
                                {formik.values?.image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }
                                {/* {!formik.touched.image && !formik.values.image && <div style={{
                                fontSize: "14px",
                                color: "#198754",
                                margin: "4px 0 0 4px",
                                fontWeight: "500"
                            }}>Max size. 2mb</div>} */}
                                {formik.touched.image && formik.errors.image && <div className='errorMessage danger'>{formik.errors.image}</div>}
                                {console.log(formik)}
                            </div>
                            <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 225 * 225 px)</p></div>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Online/Offline</Form.Label>
                            <Form.Check
                                className='isopen_status'
                                aria-label="option 1"
                                type="checkbox"
                                id="isOnlineCheckbox" // Provide a unique ID
                                checked={formik.values.is_online} // Use the formik's is_online value
                                onChange={() => {
                                    formik.setFieldValue('is_online', !formik.values.is_online); // Toggle the value when the checkbox is clicked
                                }}
                            />
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default UpcomingBatchCreate;