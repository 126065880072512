// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CourseTypeService } from '../../services/CourseTypeService';
import CreateWebinarType from './createWebinarType';
import WebinarTypeDatatable from './webinarTypeDatatable';
import { WebinarTypeService } from '../../services/WebinarTypeService';
import { checkUserPermission } from '../../checkUserPermission';

const webinarTypeService = new WebinarTypeService();

const WebinarType = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = useState(true);
    const [scroll, setScroll] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    async function getAllWebinarTypes() {
        setPending(true)
        const data = await webinarTypeService.getWebinarTypes(currentPage, perpage,filterText);
         setData(data.data)
        
        setTotalRows(data.data.count)
        setPending(false)
    }
    useEffect(() => {
        getAllWebinarTypes()
        
    }, [refresh])

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Required'),
        description: Yup.string()
            .required('Required (max. 250 characters)'),
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            title: "",
            description: "",

        },
        validationSchema,

        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("webinar_event_type_id", values.id ?? "");
            }
            formData.append("title", values.title);
            formData.append("description", values.description);


            webinarTypeService.addOrUpdateWebinarType(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        course_type_name: response?.response?.data?.errors?.course_type_name,

                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        formik.setValues({
            id: item.id,
            title: item.title,
            description: item.description,

        })

    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Webinar/ Event Type</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Webinar Event Type</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Webinar Event Type") && <React.Fragment>
                        <CreateWebinarType image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <WebinarTypeDatatable  totalRows={totalRows} setFilterText={setFilterText} currentPage={currentPage} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} setCurrentPage={setCurrentPage} setPerpage={setPerpage} pending={pending} />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default WebinarType;