import { Formik, useFormik } from 'formik';
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import loader from '../../assets/images/loader.gif';
import { ToastContainer, toast } from 'react-toastify';
import { BsFillCCircleFill } from 'react-icons/bs';
import { BsArrowRightShort } from 'react-icons/bs';
import { AuthService } from '../services/AuthService';
import logo from "../../assets/images/afresh-logo.png";
import design from "../../assets/images/design.png";
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import IconButton from '@material-ui/core/IconButton';


const authService = new AuthService();
const useStyles = makeStyles({

    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
    },
});
const Forgot = () => {
    const classes = useStyles();

    const [isloader, setIsloader] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Invalid email format').required('Required'),
        }),
        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            formData.append("user_email", values.email);

            authService.userForgotPassword(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    toast.success('Email Sent Successfully', {
                        position: toast.POSITION.TOP_RIGHT
                    });

                }
                else {
                    setIsloader(false)
                    toast.error(response?.response?.data?.message ? response?.response?.data?.message : "Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    return (
        <div className='row'>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 image">
                <img src={design} alt="Login image" className="img-fluid imgs"

                />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6 col-12 login-forms'>
                <div className='login_left_wrapper'>
                    <div className=''>
                        <img src={logo} className='img-fluid logo-login' />
                    </div>
                    <ToastContainer />
                    <div className='forgo'>
                        <div className='login-form'>
                            <div className='reset_text'>Forgot Password</div>
                            <Form onSubmit={formik.handleSubmit}>
                                <Form.Group controlId="validationCustom04">
                                    <Form.Control
                                        variant="outlined"
                                        type="text"
                                        name="email"
                                        value={formik.values?.email}
                                        className={`usrname ${classes.root}`}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.email && !formik.errors.email}
                                        isInvalid={formik.touched.email && formik.errors.email}
                                        placeholder="Email"
                                        maxLength={100}
                                    />                         
                                    <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                                </Form.Group>

                                <div className="login_btn_wrappers group">
                                    {isloader == true ?
                                        <div className='loader'>
                                            <img src={loader} alt="" />
                                        </div>
                                        :
                                        <Button type="submit" className='login_btnss'>Send Link</Button>
                                    }
                                </div>
                            </Form>
                        </div>
                        <div className='forgot-card'>
                            <Link to='/login'>
                                <div className='back-to-text' role='button'>Back to login <BsArrowRightShort /></div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Forgot;