import request from "../api/agent";

const API_ROUTE = "blogs/blog-category";

export class BlogCategoryService {
    async getBlogcategory(perpage, currentPage,search) {
        const serchValue = search??""
        if(perpage){     
        const response = await request.get(`${API_ROUTE}/blog-category-all-listing?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
       }else{
        const response = await request.get(`${API_ROUTE}/blog-category-all-listing`);
        return response.data;
    }
    }


    async addOrUpdateBlogcategory(blog) {
        let action = `${API_ROUTE}/create-or-update-blog-category`;
        const response = await request.post(action, blog);
        return response;
    }

    async deleteBlogcategory(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-blog-category`, 
        { data: { category: id } });
        return response;
    }
    async getStatus(id){
        const response = await request.put(`${API_ROUTE}/blog-category-status-change-admin`,{id:id})
        return response;
    }
    
}