import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { FaqService } from '../../services/FaqService';


const FaqCreate = ({ formik, isloader, formRef }) => {
    const faqType = [
        { faq_type: 1, faq_type_name: "IELTS" },
        { faq_type: 2, faq_type_name: "Events And Webinar" },
    ]

    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Question</Form.Label>
                            <Form.Control
                                type="text"
                                name="question"
                                value={formik.values?.question}
                                onChange={formik.handleChange}
                                isValid={formik.touched.question && !formik.errors.question}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.question && formik.errors.question}
                                placeholder="Question"
                                maxLength={250}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.question}</Form.Control.Feedback>
                        </Form.Group>                       
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>FAQ Type</Form.Label>
                            <Form.Select
                                // placeholder="Select an Option"
                                className="select-dropdown"
                                classnameprefix="select"
                                name="faq_type"
                                value={formik.values?.faq_type}
                                isValid={formik.touched.faq_type && !formik.errors.faq_type}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.faq_type && formik.errors.faq_type}
                                onChange={formik.handleChange}
                            >
                                <option value='' >Select FAQ type</option>
                                {faqType?.map((faq, index) =>
                                    <option value={faq?.faq_type} key={index}>{faq?.faq_type_name}</option>
                                )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.faq_type}</Form.Control.Feedback>
                        </Form.Group>

                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Answer </Form.Label>
                            <Form.Control
                                as="textarea"
                                name="answer"
                                value={formik.values?.answer}
                                onChange={formik.handleChange}
                                isValid={formik.touched.answer && !formik.errors.answer}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.answer && formik.errors.answer}
                                placeholder="Answer"
                                style={{ height: '100px' }}
                                maxLength={1000}
                                // maxLength={255}
                            />
                             {formik.errors.description ? <Form.Control.Feedback type="invalid" >{formik.errors.answer}</Form.Control.Feedback> :
                                <div className="max-char">{formik.values.answer.length + "/1000"}</div>}
                        </Form.Group>
                            {/* <Form.Control.Feedback type="invalid">{formik.errors.answer}</Form.Control.Feedback>
                        </Form.Group> */}
                    </Row>
                    <div className="group">
                        {isloader === true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default FaqCreate;