import request from "../api/agent";

const API_ROUTE = "/career/career";

export class JobService {

    async getJob(perpage, currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-job-vacancy-listing?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }


    async addOrUpdateJobVacancy(jobVacancy) {
        let action = `${API_ROUTE}/create-or-update-job-vacancy`;
        const response = await request.post(action, jobVacancy);
        return response;
    }

    async deleteJob(id) {
        const response = await request.delete(`${API_ROUTE}/job-vacancy-destroy`, { data: { job_id: id } });
        return response;
    }


    async setJobStatus(id) {
        const response = await request.post(`${API_ROUTE}/jobs-status-change`,  { job_id: id } );
        return response;
    }
}