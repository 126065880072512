import request from "../api/agent";

const API_ROUTE ="/users/roles";

export class RolesService {

    async getRoles(perpage,currentPage,search) {
        const serchValue = search??""
        if(perpage){
            const response = await request.get(`${API_ROUTE}/get-roles?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
            return response.data;
        }else{ 
            const response = await request.get(`${API_ROUTE}/get-roles`);
            return response.data;
        }

    }


    async addOrUpdateRoles(role) {
        let action = `${API_ROUTE}/create-or-update-role`;
        const response = await request.post(action, role);
        return response;
    }

    async deleteRole(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-role`, {data: { role: id }});
        return response;
    }


}