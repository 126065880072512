import request from "../api/agent";
const API_ROUTE = "webinar/webinar";

export class WebinarService {
    async addOrUpdateWebinar(course) {
        let action = `${API_ROUTE}/create-or-update-webinar`;
        const response = await request.post(action, course);
        return response;
    }

    async getWebinars(page, limit,search) {     
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/webinar-all-listing?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }
    async getAllWebinars() {
        const response = await request.get(`${API_ROUTE}/webinar-all-listing`);
        return response.data;
    }


    async deleteWebinar(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-webinar`, {
            data: { id: id }
        });
        return response;
    }
   
}