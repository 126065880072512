import request from "../api/agent";

const API_ROUTE = "scholarships/university-scholarships";

export class ScholarshipService {

    async getScholarship(perpage, currentPage,search,filterId,countryId) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-all-university-scholarships?limit=${perpage}&page=${currentPage}&category_id=${filterId}&search=${serchValue}&country_id=${countryId}`);
        return response.data;
    }


    async addOrUpdateScholarship(scholarshipData) {
        let action = `${API_ROUTE}/create-or-update-university-scholarships`;
        const response = await request.post(action, scholarshipData);
        return response;
    }

    async deleteScholarship(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-scholarships`, { data: { scholarship_id: id  } });
        return response;
    }

    async setSchoplarshipStatus(id) {
        const response = await request.put(`${API_ROUTE}/active-scholarship-status`, { instance_id: id });
        return response;
    }
    async setIspopular(id) {
        const response = await request.put(`${API_ROUTE}/scholarships-popular-status`, { instance_id: id });
        return response;
    }
}