import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';

const CreateSeo = ({ formik, isloader, toaster, setToaster, setImage, flag, formRef, imageError }) => {

    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Meta title</Form.Label>
                            <Form.Control
                                type="text"
                                name="meta_title"
                                value={formik.values?.meta_title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.meta_title && !formik.errors.meta_title}
                                isInvalid={formik.touched.meta_title && formik.errors.meta_title}
                                placeholder="Meta title"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.meta_title}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03" className="mb-3">
                            <Form.Label>Meta image title</Form.Label>
                            <Form.Control
                                type="text"
                                name="meta_image_title"
                                value={formik.values?.meta_image_title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.meta_image_title && !formik.errors.meta_image_title}
                                isInvalid={formik.touched.meta_image_title && formik.errors.meta_image_title}
                                placeholder="Meta image title"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.meta_image_title}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03" className="mb-3">
                            <Form.Label>Meta description </Form.Label>
                            <Form.Control
                                type="text"
                                as="textarea"
                                name="meta_description"
                                style={{ height: '110px' }}
                                value={formik.values?.meta_description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.meta_description && !formik.errors.meta_description}
                                isInvalid={formik.touched.meta_description && formik.errors.meta_description}
                                placeholder="Meta description"
                                maxLength={1000}
                            />
                            {formik.errors.meta_description ? <Form.Control.Feedback type="invalid">{formik.errors.meta_description}</Form.Control.Feedback> :
                                <div className="max-char">{formik.values.meta_description.length + "/1000"}</div>}
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03" className="mb-3">
                            <Form.Label>Meta keyword (seperated by commas)</Form.Label>
                            <Form.Control
                                type="text"
                                as="textarea"
                                name="meta_keyword"

                                value={formik.values?.meta_keyword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.meta_keyword && !formik.errors.meta_keyword}
                                isInvalid={formik.touched.meta_keyword && formik.errors.meta_keyword}
                                placeholder="Meta keyword"

                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.meta_keyword}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Og image<span className='required ms-1'>*</span></Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="og_image"
                                    accept="image/*"
                                    values={formik.values.og_image}
                                    onChange={(e) => {
                                        setImage(e.target.files[0])
                                        formik.setValues({ ...formik.values, og_image: e.target.files[0], image_url: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "" })
                                    }
                                    }
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.og_image && !formik.errors.og_image}
                                    isInvalid={formik.touched.og_image && formik.errors.og_image}
                                />
                                {formik.values?.image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='seo' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }
                                {/* {!formik.touched.og_image && !formik.values.og_image && <div style={{
                                    fontSize: "14px",
                                    color: "#198754",
                                    margin: "4px 0 0 4px",
                                    fontWeight: "500"
                                }}>Max size. 2mb</div>} */}
                                <Form.Control.Feedback type="invalid">{formik.errors.og_image}</Form.Control.Feedback>
                            </div>
                            <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 1200 * 630 px)</p></div>
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateSeo;