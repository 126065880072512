// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import CreateCourseType from './createCourseType';
import CourseTypeDatatable from './courseTypeDatatable';
import { CourseTypeService } from '../../services/CourseTypeService';
import { checkUserPermission } from '../../checkUserPermission';

const courseTypeService = new CourseTypeService();

const CourseType = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [pending, setPending] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = React.useState(0);

    async function getAllCourses() {
        setPending(true)
        const data = await courseTypeService.getCourseTypePagination(currentPage, perpage,filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setPending(false);
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])


    useEffect(() => {
        getAllCourses()
    }, [refresh])

    const validationSchema = Yup.object().shape({
        course_type_name: Yup.string()
            .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            course_type_name: "",

        },
        validationSchema,

        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("name_id", values.id ?? "");
            }
            formData.append("course_type_name", values.course_type_name.toUpperCase());


            courseTypeService.addOrUpdateCourseType(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    setIsloader(false)
                    toast.error(response?.response?.data?.errors?.non_field_errors[0], {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        formik.setValues({
            id: item.id,
            course_type_name: item.course_type_name,

        })

        console.log(item);
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Course Type</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Course Type</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Course Type") && <React.Fragment>
                        <CreateCourseType image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <CourseTypeDatatable currentPage={currentPage} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik}
                            setCurrentPage={setCurrentPage}
                            setPerpage={setPerpage}
                            pending={pending}
                            setFilterText={setFilterText}
                            totalRows={totalRows}
                        />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default CourseType;