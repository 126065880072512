import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
// import moment from 'moment';
import { CourseService } from '../../services/CourseService';
import { UniversityService } from '../../services/universityService';
import BasicSweetCallback from '../../SweetAlertCallback';
import AsyncSelect from 'react-select/async';

const courseService=new CourseService();
const universityService=new UniversityService();

const CreateUniversityCourse=({ intakeValue, markAllFieldsAsTouched, setFormView, formView, formik, isloader, formRef, setIntakeData }) => {
    const [unversities, setUniversities]=useState([]);
    const [courses, setCourses]=useState([]);
    const [scholarErrors, setScholarErrors]=useState(false);


    useEffect(() => {
        getAllCourses()
        getAllUniversities()
    }, [])

    async function getAllCourses() {
        const data=await courseService.getAllCoursess();
        setCourses(data?.data?.results)
        console.log(data);
    }
    async function getAllUniversities() {
        const data=await universityService.getUniversities();
        setUniversities(data?.data?.results)
        console.log(data);
    }

    const deleteBtn={
        padding: "0.49rem 0.75rem",
        margin: "0 0 0 -2px",
        borderRadius: "0.25rem"
    }
    const addBtn={
        padding: "0.49rem 1.2rem",
        margin: "0 0 0 -2px",
        borderRadius: "0.25rem"
    }

    const handleSelectChange=({ name, value }) => {

        formik.setValues({ ...formik.values, [name]: value.map(val => val) })
        setIntakeData(value.map(val => val.label))
    }

    const handleDelete = () => {
        // Reset the form field values related to the selected PDF
        formik.setFieldValue('syllabus_pdf', ''); // Remove the selected file
        formik.setFieldValue('syllabus_pdf_url', ''); // Remove the file URL or any other related data
    
        // You may also want to clear any error messages if applicable
        formik.setFieldError('syllabus_pdf', ''); // Clear any error message related to the file
    
        // Optionally, you can add further logic for handling deletion, such as sending a request to your server to delete the file.
    };

    const addNewFeedDetails=() => {
        let details=formik.values.fee_details;
        details.push({
            term: '',
            fees_type: '',
            amount: ''
        })
        formik.setValues({ ...formik.values, fee_details: details })
    }

    const deleteNewFeedDetails=(index) => {
        let details=formik.values.fee_details;
        details.splice(index, 1)
        formik.setValues({ ...formik.values, fee_details: details })
    }

    const addNewEligibility=() => {
        let intake=formik.values.intake;
        intake.push({
            intake: '',
            is_open: false,
            deadline: ''
        })
        formik.setValues({ ...formik.values, intake: intake })
    }

    const deleteNewEligibility=(index) => {
        let intake=formik.values.intake;
        intake.splice(index, 1)
        formik.setValues({ ...formik.values, intake: intake })
    }
    const addNewEntrance=() => {
        let over_view=formik.values.over_view;
        over_view.push({
            required_details: ''
        })
        formik.setValues({ ...formik.values, over_view: over_view })
    }
    const deleteNewEntrance=(index) => {
        let over_view=formik.values.over_view;
        over_view.splice(index, 1)
        formik.setValues({ ...formik.values, over_view: over_view })
    }

    const addNewSyllabusDetails=(index) => {
        let syllabus=formik.values.syllabus;
        syllabus[index]?.syllabus_details?.push({
            details: ''
        })
        formik.setValues({ ...formik.values, syllabus: syllabus })
    }
    const deleteNewSyllabusDetails=(index, detailIndex) => {
        let syllabus=formik.values.syllabus;
        syllabus[index]?.syllabus_details?.splice(detailIndex, 1)
        formik.setValues({ ...formik.values, syllabus: syllabus })
    }

    const addNewSyllabus=() => {
        let syllabus=formik.values.syllabus;
        syllabus.push({
            title: '',
            days: '',
            number_assignments: '',
            syllabus_details: [{
                details: ''
            }],
        })
        formik.setValues({ ...formik.values, over_view: syllabus })
    }
    const deleteNewSyllabus=(index) => {
        let syllabus=formik.values.syllabus;
        syllabus.splice(index, 1)
        formik.setValues({ ...formik.values, over_view: syllabus })
    }

    const addNewDocuments=() => {
        let required_documents=formik.values.required_documents;
        required_documents?.push({
            required_details: ''
        })
        formik.setValues({ ...formik.values, required_documents: required_documents })
    }
    const deleteNewDocuments=(index) => {
        let required_documents=formik.values.required_documents;
        required_documents.splice(index, 1)
        formik.setValues({ ...formik.values, required_documents: required_documents })
    }

    const handleChangeFile=(e, name, url) => {
        formik.setValues({ ...formik.values, [name]: e.target.files[0], [url]: e.target.files[0]? URL.createObjectURL(e.target.files[0]):"" })
    }

    const handleClick=() => {
        window.open(formik.values.syllabus_pdf_url, '_blank').focus();
    };
    const onClickNext=() => {
        let errorflag=false
        formik.values?.intake?.length&&formik.values?.intake?.map((intake) => {

            if (intake?.intake=='') {
                console.log("data test", errorflag)
                errorflag=true;
                console.log("test data", errorflag)
            }
        })
        if (errorflag) {
            markAllFieldsAsTouched()
            console.log("touched formiks are", formik.touched)
            formik.setTouched({
                ...formik.touched,
                intake: formik.values?.intake?.map((intake) => { return { intake: true } })
            })
        }
        else {
            setFormView('score_listing')
        }

    }

    let requestTime;
    const loadOption = async (value) =>{
        clearTimeout(requestTime);
        return new Promise((resolve,reject)=>{
            requestTime = setTimeout(async()=>{
                if(value.length>=3){
                    try{
                        const data = await courseService.getAllCoursess(value)
                        if(data && data.status){
                            resolve(data?.data)
                        }
                    } catch(err){
                        console.error("Error fetching courses")
                    }
                }else{
                    resolve([])
                }
            },500)
        })
    }

    let requestUniversity;
    const loadUniversityOption = async (value) =>{
        clearTimeout(requestUniversity);
        return new Promise((resolve,reject)=>{
            requestUniversity = setTimeout(async()=>{
                if(value.length>=3){
                    try{
                        const data = await universityService.getUniversities(value)
                        console.log(data,"|||||||DATA")
                        if(data && data.status){
                            resolve(data?.data)
                        }
                    } catch(err){
                        console.error("Error fetching courses")
                    }
                }else{
                    resolve([])
                }
            },500)
        })
    }

    const handleCourseChange = (selectedOption)  =>{
        formik.setValues({ ...formik.values, course_id: selectedOption.value,course:selectedOption })
    }

    const handleUniversityChange = (selectedOption)=>{
        formik.setValues({ ...formik.values, university_id: selectedOption.value,university:selectedOption })
    }

    console.log(formik,"||||||DORMIK")
    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    {formView=='details'&&
                        <Fragment>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>Course</Form.Label>
                                    <AsyncSelect 
                                        placeholder="Type for search"
                                        className="select-dropdown"
                                        classNamePrefix="select"
                                        name="course_id"
                                        value={formik.values?.course}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.course_id&&!formik.errors.course_id}
                                        isInvalid={formik.touched.course_id&&formik.errors.course_id}
                                        onChange={handleCourseChange}
                                        loadOptions={loadOption}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">{formik.errors.course_id}</Form.Control.Feedback> */}
                                    <div className='invalid_feedback' style={{ color: '#DC3544' }}>{formik.touched.course_id && formik.errors.course_id}</div>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>University</Form.Label>

                                    <AsyncSelect 
                                        placeholder="Type to Search University"
                                        className='select-dropdown'
                                        classNamePrefix="select"
                                        name="university_id"
                                        value={formik.values?.university}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.university_id&&!formik.errors.university_id}
                                        isInvalid={formik.touched.university_id&&formik.errors.university_id}
                                        onChange={handleUniversityChange}
                                        loadOptions={loadUniversityOption}

                                    />
                                    {/* <Form.Control.Feedback type="invalid">{formik.errors.university_id}</Form.Control.Feedback> */}
                                    <div className='invalid_feedback' style={{ color: '#DC3544' }}>{formik.touched.university_id && formik.errors.university_id}</div>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Duration</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="duration"
                                        value={formik.values?.duration}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.duration && !formik.errors.duration}
                                        // isInvalid={formik.touched.duration && formik.errors.duration}
                                        placeholder="Eg:6 Months"
                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.duration}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Average Fee</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="total"
                                        value={formik.values?.total}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.total && !formik.errors.total}
                                        // isInvalid={formik.touched.total && formik.errors.total}
                                        placeholder="Average Fee"
                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.total}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Application Fee</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="application_fee"
                                        value={formik.values?.application_fee}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="Application Fee"
                                        maxLength={100}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Website</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="web_url"
                                        value={formik.values?.web_url}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.web_url && !formik.errors.web_url}
                                        // isInvalid={formik.touched.web_url && formik.errors.web_url}
                                        placeholder=" Eg:https://www.cam.ac.uk"
                                        maxLength={100}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">
                                        {formik.errors.web_url}
                                    </Form.Control.Feedback> */}
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                            <Form.Group as={Col} md="6" controlId="formFile" className="mb-3 pos-relative brouchure">
    <Form.Label>Syllabus Brochure<span className='required ms-1'></span></Form.Label>
    <div>
        <Form.Control
            type="file"
            name="syllabus_pdf"
            style={{
                width: formik.values.syllabus_pdf_url ? "100px" : "100%",
                borderRadius: "0.25rem 0 0 0.25rem"
            }}
            accept="application/pdf"
            onChange={(e) => { handleChangeFile(e, 'syllabus_pdf', 'syllabus_pdf_url') }}
            onBlur={formik.handleBlur}
        />
        <Form.Control.Feedback type="invalid">{formik.errors.syllabus_pdf}</Form.Control.Feedback>
    </div>
    
    <div>
        {
            formik.values.syllabus_pdf_url && (
                <div>
                    <Button className='save-btn me-1 view_btn brochure-view-btn' style={addBtn} onClick={handleClick}>View</Button>
                     <Button className='save-btn delete_btn brochure-delete-btn' style={addBtn} onClick={handleDelete}>Delete</Button>
                </div>
            )
        }
    </div>
    <div>
        {formik.values.syllabus_pdf && (
            <p className='selected-file-name'>{formik.values.syllabus_pdf.name}</p>
        )}
        <p className='size'>(Only pdf files are allowed)</p>
    </div>
</Form.Group>
                            </Row>
                            <div className="group">
                                <Button type="button"
                                    //  onClick={() => {  formik.values.country &&  formik.values.website  && formik.values.university_name &&  formik.values.address && formik.values.specialization.length ? setFormView("university_image") : markAllFieldsAsTouched()}}

                                    onClick={() => { formik.values?.course_id&&formik.values?.university_id? setFormView('fee_details'):markAllFieldsAsTouched() }}
                                    className='save-btn me-4'>Next</Button>
                            </div>
                        </Fragment>
                    }
                    {formView==='fee_details'&&
                        <Fragment>
                            {formik.values?.fee_details?.length? formik.values?.fee_details?.map((detail, index) => {
                                if (detail===undefined) {
                                    return;
                                }
                                const touchedTerm=formik.touched?.fee_details&&formik.touched?.fee_details[index]&&formik.touched?.fee_details[index].term;
                                const touchedType=formik.touched?.fee_details&&formik.touched?.fee_details[index]&&formik.touched?.fee_details[index].fees_type;
                                const touchedAmount=formik.touched?.fee_details&&formik.touched?.fee_details[index]&&formik.touched?.fee_details[index].amount;
                                return <Row className="mb-2">
                                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                                        <Form.Label>Term</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`fee_details[${index}].term`}
                                            value={detail?.term}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            // isValid={touchedTerm && !(formik.errors.fee_details && formik.errors.fee_details[index] && formik.errors.fee_details[index]?.term && !!formik.errors.fee_details[index]?.term)}
                                            // isInvalid={touchedTerm && formik.errors.fee_details && formik.errors.fee_details[index] && formik.errors.fee_details[index]?.term && !!formik.errors.fee_details[index]?.term}
                                            placeholder="term"
                                            maxLength={100}
                                        />
                                        <Form.Control.Feedback type="invalid">{touchedTerm&&formik.errors.fee_details&&formik.errors.fee_details[index]&&formik.errors.fee_details[index]?.term&&formik.errors.fee_details[index]?.term}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                                        <Form.Label>Fee Type</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`fee_details[${index}].fees_type`}
                                            value={detail?.fees_type}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            // isValid={touchedType && !(formik.errors.fee_details && formik.errors.fee_details[index] && formik.errors.fee_details[index]?.fees_type && !!formik.errors.fee_details[index]?.fees_type)}
                                            // isInvalid={touchedType && formik.errors.fee_details && formik.errors.fee_details[index] && formik.errors.fee_details[index]?.fees_type && !!formik.errors.fee_details[index]?.fees_type}
                                            placeholder="Fee Type"
                                            maxLength={100}
                                        />
                                        <Form.Control.Feedback type="invalid">{touchedType&&formik.errors.fee_details&&formik.errors.fee_details[index]&&formik.errors.fee_details[index]?.fees_type&&formik.errors.fee_details[index]?.fees_type}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={`fee_details[${index}].amount`}
                                            value={detail?.amount}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            // isValid={touchedAmount && !(formik.errors.fee_details && formik.errors.fee_details[index] && formik.errors.fee_details[index]?.amount && !!formik.errors.fee_details[index]?.amount)}
                                            // isInvalid={touchedAmount && formik.errors.fee_details && formik.errors.fee_details[index] && formik.errors.fee_details[index]?.amount && !!formik.errors.fee_details[index]?.amount}
                                            placeholder="Amount"
                                            maxLength={100}
                                        />
                                        <Form.Control.Feedback type="invalid">{touchedAmount&&formik.errors.fee_details&&formik.errors.fee_details[index]&&formik.errors.fee_details[index]?.amount&&formik.errors.fee_details[index]?.amount}</Form.Control.Feedback>
                                    </Form.Group>
                                    <div>
                                        <div className='hr-divider'>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end' style={{
                                        margin: "-17px 0"
                                    }} >
                                        {index==formik.values.fee_details?.length-1&&
                                            <div>
                                                <Button type="button" onClick={() => addNewFeedDetails()} className='save-btn me-1' style={addBtn}>Add</Button>
                                            </div>
                                        }
                                        <div className='delete_btn'>
                                            {formik.values.fee_details?.length>1&&<Button type="button" onClick={() => deleteNewFeedDetails(index)} className='save-btn' style={deleteBtn}>Delete</Button>}

                                        </div>
                                    </div>
                                </Row>
                            })
                                :
                                <div></div>
                            }
                            <div className="group">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('details')}>Back</Button>
                                <Button type="button" onClick={() => setFormView('intake')} className='save-btn me-4'>Next</Button>
                            </div>
                        </Fragment>
                    }
                    {formView=='intake'&&
                        <Fragment>
                            {formik.values?.intake?.length&&formik.values?.intake?.map((intake, index) => {
                                if (intake===undefined) {
                                    return;
                                }
                                const touchedIntake=formik.touched?.intake&&formik.touched?.intake[index]&&formik.touched?.intake[index].intake;
                                return <div>
                                    <Form.Group as={Col} md="9" controlId="validationCustom04" style={{ display: "flex", alignItems: "baseline", width: "758px", gap: "20px" }}>
                                        <div style={{ width: "250px" }}>
                                            <Form.Label>{index==0&&'Intake'}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={`intake[${index}].intake`}
                                                value={intake?.intake}
                                                onChange={formik.handleChange}
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "0.25rem 0 0 0.25rem"
                                                }}
                                                onBlur={formik.handleBlur}
                                                isValid={touchedIntake&&!(formik.errors.intake&&formik.errors.intake[index]&&formik.errors.intake[index]?.intake&&!!formik.errors.intake[index]?.intake)}
                                                isInvalid={touchedIntake&&formik.errors.intake&&formik.errors.intake[index]&&formik.errors.intake[index]?.intake&&!!formik.errors.intake[index]?.intake}
                                                placeholder="Intake"
                                                maxLength={100}
                                            />
                                            <Form.Control.Feedback type="invalid">{touchedIntake&&formik.errors.intake&&formik.errors.intake[index]&&formik.errors.intake[index]?.intake&&formik.errors.intake[index]?.intake}</Form.Control.Feedback>
                                        </div>
                                        <div>
                                            <div style={{ width: "250px" }}>
                                                <Form.Label>{index==0&&'Deadline'}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name={`intake[${index}].deadline`}
                                                    value={intake?.deadline}
                                                    onChange={formik.handleChange}
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "0.25rem 0 0 0.25rem"
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    // isValid={touchedIntake && !(formik.errors.intake && formik.errors.intake[index] && formik.errors.intake[index]?.intake && !!formik.errors.intake[index]?.intake)}
                                                    // isInvalid={touchedIntake && formik.errors.intake && formik.errors.intake[index] && formik.errors.intake[index]?.intake && !!formik.errors.intake[index]?.intake}
                                                    placeholder="Deadline"
                                                    maxLength={100}
                                                />
                                                {/* <Form.Control.Feedback type="invalid">{touchedIntake && formik.errors.intake && formik.errors.intake[index] && formik.errors.intake[index]?.intake && formik.errors.intake[index]?.intake}</Form.Control.Feedback> */}
                                            </div>
                                        </div>
                                        <div className='isopen_status' style={{ alignSelf: 'center' }}>
                                            <label className="form-check-label" for="flexSwitchCheckDefault">Hide</label>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`flexSwitchCheckDefault-${index}`}
                                                checked={intake.is_open}
                                                onChange={(e) => {
                                                    const is_open=e.target.checked;
                                                    formik.setFieldValue(`intake[${index}].is_open`, is_open);
                                                }}
                                            />
                                        </div>
                                        <div style={{ alignSelf: 'center', gap: "5px" }} className='d-flex' >
                                            <div>
                                                {formik.values.intake?.length>1&&<Button type="button" onClick={() => deleteNewEligibility(index)} className='save-btn' style={deleteBtn}>Delete</Button>}
                                            </div>
                                            {formik.values.intake?.length-1==index&&
                                                <div >
                                                    <Button type="button" onClick={() => addNewEligibility()} className='save-btn me-1' style={addBtn}>Add</Button>
                                                </div>

                                            }
                                        </div>

                                    </Form.Group>
                                </div>
                            }
                            )}
                            <div className="group">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('fee_details')}>Back</Button>
                                <Button type="button" onClick={() => onClickNext()} className='save-btn me-4'>Next</Button>
                            </div>
                        </Fragment>
                    }
                    {
                        formView=='score_listing'&&
                        <Fragment>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>IELTS Score</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="ielts_score"
                                        value={formik.values.ielts_score}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="IELTS Score"
                                        maxLength={100}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>IELTS No Band Less Than</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="ielts_no_band_less"
                                        value={formik.values?.ielts_no_band_less}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="IELTS No Band Less Than"
                                        maxLength={100}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>TOEFL Score</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="toefl_score"
                                        value={formik.values.toefl_score}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="TOEFL Score"
                                        maxLength={100}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>TOEFL No Band Less Than</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="toefl_no_band_less"
                                        value={formik.values?.toefl_no_band_less}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="TOEFL No Band Less Than"
                                        maxLength={100}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>PTE Score</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="pte_score"
                                        value={formik.values?.pte_score}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="PTE Score"
                                        maxLength={100}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>PTE No Band Less Than</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="pte_no_band_less"
                                        value={formik.values?.pte_no_band_less}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="PTE No Band Less Than"
                                        maxLength={100}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>SAT Score</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="sat_score"
                                        value={formik.values?.sat_score}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="SAT Score"
                                        maxLength={100}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>ACT Score</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="act_score"
                                        value={formik.values?.act_score}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="ACT Score"
                                        maxLength={100}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>GRE Score</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="gre_score"
                                        value={formik.values?.gre_score}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="GRE Score"
                                        maxLength={100}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>MAT Score</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="mat_score"
                                        value={formik.values?.mat_score}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="MAT Score"
                                        maxLength={100}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Backlog Range</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="backlog_range"
                                        value={formik.values?.backlog_range}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="Backlog Range"
                                        maxLength={100}
                                    />
                                </Form.Group>
                            </Row>
                            <div className="group mt-3">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('intake')}>Back</Button>
                                <Button type="button" onClick={() => setFormView('over_view')} className='save-btn me-4'>Next</Button>
                            </div>
                        </Fragment>
                    }
                    {
                        formView=='over_view'&&
                        <Fragment>
                            {formik.values?.over_view?.length? formik.values?.over_view?.map((over_view, index) => {
                                if (over_view===undefined) {
                                    return;
                                }
                                const touchedOverview=formik.touched?.over_view&&formik.touched?.over_view[index]&&formik.touched?.over_view[index].required_details;
                                return <div>
                                    <Form.Label>{index==0&&'Overview'}</Form.Label>
                                    <Form.Group as={Col} md="12" controlId="validationCustom04" style={{ display: "flex", width: " 664px;" }}>
                                        <div style={{ width: "720px" }}>
                                            <Form.Control
                                                type="text"
                                                name={`over_view[${index}].required_details`}
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "0.25rem 0 0 0.25rem"
                                                }}
                                                value={over_view?.required_details}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                // isValid={touchedOverview && !(formik.errors.over_view && formik.errors.over_view[index] && formik.errors.over_view[index]?.required_details && !!formik.errors.over_view[index]?.required_details)}
                                                // isInvalid={touchedOverview && formik.errors.over_view && formik.errors.over_view[index] && formik.errors.over_view[index]?.required_details && !!formik.errors.over_view[index]?.required_details}
                                                placeholder="Overview"
                                                maxLength={100}
                                            />
                                            <Form.Control.Feedback type="invalid">{touchedOverview&&formik.errors.over_view&&formik.errors.over_view[index]&&formik.errors.over_view[index]?.required_details}</Form.Control.Feedback>
                                        </div>
                                        <div className='d-flex '>
                                            <div>
                                                {formik.values.over_view?.length>1&&<Button type="button" onClick={() => deleteNewEntrance(index)} className='save-btn btn-del' style={deleteBtn}>Delete</Button>}
                                            </div>
                                            {formik.values.over_view?.length-1==index&&
                                                <div >
                                                    <Button type="button" onClick={() => addNewEntrance()} className='save-btn me-1' style={addBtn}>Add</Button>
                                                </div>
                                            }

                                        </div>
                                    </Form.Group>
                                </div>
                            })
                                :
                                <div></div>
                            }

                            <div className="group mt-3">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('score_listing')}>Back</Button>
                                <Button type="button" onClick={() => setFormView('syllabus')} className='save-btn me-4'>Next</Button>
                            </div>
                        </Fragment>
                    }
                    {
                        formView=='syllabus'&&
                        <Fragment>
                            {formik.values?.syllabus?.length&&formik.values?.syllabus?.map((syllabus, index) => {
                                if (syllabus===undefined) {
                                    return;
                                }
                                const touchedTitle=formik.touched?.syllabus&&formik.touched?.syllabus[index]&&formik.touched?.syllabus[index].title;
                                const touchedDays=formik.touched?.syllabus&&formik.touched?.syllabus[index]&&formik.touched?.syllabus[index].days;
                                const touchedAssignments=formik.touched?.syllabus&&formik.touched?.syllabus[index]&&formik.touched?.syllabus[index].number_assignments;
                                return <Row className="mb-2">
                                    <Form.Group as={Col} md="4" controlId="validationCustom04" className='mb-2'>
                                        <Form.Label>{'Syllabus Title'}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`syllabus[${index}].title`}
                                            value={syllabus?.title}
                                            onChange={formik.handleChange}
                                            // isValid={touchedTitle && !(formik.errors.syllabus && formik.errors.syllabus[index] && formik.errors.syllabus[index]?.title && !!formik.errors.syllabus[index]?.title)}
                                            onBlur={formik.handleBlur}
                                            // isInvalid={touchedTitle && formik.errors.syllabus && formik.errors.syllabus[index] && formik.errors.syllabus[index]?.title && !!formik.errors.syllabus[index]?.title}
                                            placeholder="Syllabus Title"
                                            maxLength={100}
                                        />
                                        <Form.Control.Feedback type="invalid">{touchedTitle&&formik.errors.syllabus&&formik.errors.syllabus[index]&&formik.errors.syllabus[index]?.title&&formik.errors.syllabus[index]?.title}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom04" className='mb-2'>
                                        <Form.Label>{'Days'}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={`syllabus[${index}].days`}
                                            value={syllabus?.days}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            // isValid={touchedDays && !(formik.errors.syllabus && formik.errors.syllabus[index] && formik.errors.syllabus[index]?.days && !!formik.errors.syllabus[index]?.days)}
                                            // isInvalid={touchedDays && formik.errors.syllabus && formik.errors.syllabus[index] && formik.errors.syllabus[index]?.days && !!formik.errors.syllabus[index]?.days}
                                            placeholder="Days"
                                            maxLength={100}
                                        />
                                        <Form.Control.Feedback type="invalid">{touchedDays&&formik.errors.syllabus&&formik.errors.syllabus[index]&&formik.errors.syllabus[index]?.days&&formik.errors.syllabus[index]?.days}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                                        <Form.Label>{index===0&&'Number Assignments'}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={`syllabus[${index}].number_assignments`}
                                            value={syllabus?.number_assignments}
                                            onBlur={formik.handleBlur}
                                            // isValid={touchedAssignments && !(formik.errors.syllabus && formik.errors.syllabus[index] && formik.errors.syllabus[index]?.number_assignments && !!formik.errors.syllabus[index]?.number_assignments)}
                                            // isInvalid={touchedAssignments && formik.errors.syllabus && formik.errors.syllabus[index] && formik.errors.syllabus[index]?.number_assignments && !!formik.errors.syllabus[index]?.number_assignments}
                                            onChange={formik.handleChange}
                                            placeholder="Number Assignments"
                                            maxLength={100}
                                        />
                                        <Form.Control.Feedback type="invalid">{touchedAssignments&&formik.errors.syllabus&&formik.errors.syllabus[index]&&formik.errors.syllabus[index]?.number_assignments&&formik.errors.syllabus[index]?.number_assignments}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Row className="mb-2" styles={{ width: "200px" }} >
                                        {syllabus?.syllabus_details?.length&&syllabus?.syllabus_details?.map((detail, ind) => {
                                            if (detail===undefined) {
                                                return;
                                            }
                                            const touchedDetails=formik.touched?.syllabus&&formik.touched?.syllabus[index]&&formik.touched?.syllabus[index].syllabus_details&&formik.touched?.syllabus[index].syllabus_details[ind]&&formik.touched?.syllabus[index].syllabus_details[ind].details;
                                            return <div>
                                                <Form.Label>{ind===0&&'Syllabus Details'}</Form.Label>
                                                <Form.Group as={Col} md="6" controlId="validationCustom04" style={{ display: "flex", width: "100%" }}>
                                                    <div style={{ width: "720px" }}>
                                                        <Form.Control
                                                            type="text"
                                                            name={`syllabus[${index}].syllabus_details[${ind}].details`}
                                                            value={detail?.details}
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "0.25rem 0 0 0.25rem"
                                                            }}
                                                            onChange={formik.handleChange}
                                                            // isValid={touchedDetails && !(formik.errors.syllabus && formik.errors.syllabus[index] && formik.errors.syllabus[index].syllabus_details && formik.errors.syllabus[index].syllabus_details[ind] && formik.errors.syllabus[index].syllabus_details[ind].details && !!formik.errors.syllabus[index].syllabus_details[ind].details)}
                                                            onBlur={formik.handleBlur}
                                                            // isInvalid={touchedDetails && formik.errors.syllabus && formik.errors.syllabus[index] && formik.errors.syllabus[index].syllabus_details && formik.errors.syllabus[index].syllabus_details[ind] && formik.errors.syllabus[index].syllabus_details[ind]?.details && !!formik.errors.syllabus[index].syllabus_details[ind]?.details}
                                                            placeholder="Syllabus Details"
                                                            maxLength={100}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{touchedDetails&&formik.errors.syllabus&&formik.errors.syllabus[index]&&formik.errors.syllabus[index].syllabus_details&&formik.errors.syllabus[index].syllabus_details[ind]&&formik.errors.syllabus[index].syllabus_details[ind]?.details&&formik.errors.syllabus[index].syllabus_details[ind]?.details}</Form.Control.Feedback>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div>
                                                            {syllabus?.syllabus_details?.length>1&&<Button type="button" onClick={() => deleteNewSyllabusDetails(index, ind)} className='save-btn btn-del' style={deleteBtn}>Delete</Button>}
                                                        </div>
                                                        {syllabus?.syllabus_details?.length-1==ind&&<div>
                                                            <Button type="button" onClick={() => addNewSyllabusDetails(index)} className='save-btn me-1 ' style={addBtn}>Add</Button>
                                                        </div>}

                                                    </div>
                                                </Form.Group>
                                            </div>
                                        }
                                        )}
                                    </Row>
                                    <div>
                                        <div style={{

                                        }} className='hr-divider'>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end ' style={{
                                        margin: "-17px 0"
                                    }} >
                                        {formik.values.syllabus?.length-1===index&&
                                            <div>
                                                <Button type="button" onClick={() => addNewSyllabus()} className='save-btn me-1' style={addBtn}>Add</Button>
                                            </div>}
                                        <div className='delete_btn'>
                                            {/* {formik.values.syllabus?.length > 1 && <Button type="button" onClick={() => deleteNewSyllabus(index)} className='save-btn ' style={deleteBtn}>Delete</Button>} */}
                                            {formik.values.syllabus?.length>1&&<><BasicSweetCallback onDelete={() => deleteNewSyllabus(index)} title="Syllabus" /></>}
                                        </div>
                                    </div>
                                </Row>
                            }
                            )}
                            <div className="group">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('over_view')}>Back</Button>
                                <Button type="button" onClick={() => setFormView('required_documents')} className='save-btn me-4'>Next</Button>
                            </div>
                        </Fragment>
                    }
                    {
                        formView==='required_documents'&&
                        <Fragment>
                            {formik.values?.required_documents?.map((document, index) => {
                                if (document===undefined) {
                                    return;
                                }
                                const touchedRequiredDetails=formik.touched?.required_documents&&formik.touched?.required_documents[index]&&formik.touched?.required_documents[index].required_details;
                                return <div >
                                    <Form.Label>{index===0&&'Required Documents'}</Form.Label>
                                    <Form.Group as={Col} md="6" controlId="validationCustom04" style={{ display: "flex", width: "100%" }}>
                                        <div style={{ width: "720px" }}>
                                            <Form.Control
                                                type="text"
                                                name={`required_documents[${index}].required_details`}
                                                value={document?.required_details}
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "0.25rem 0 0 0.25rem"
                                                }}
                                                onChange={formik.handleChange}
                                                isValid={touchedRequiredDetails&&!(formik.errors.required_documents&&formik.errors.required_documents[index]&&formik.errors.required_documents[index]?.required_details&&!!formik.errors.required_documents[index]?.required_details)}
                                                onBlur={formik.handleBlur}
                                                isInvalid={touchedRequiredDetails&&formik.errors.required_documents&&formik.errors.required_documents[index]&&formik.errors.required_documents[index]?.required_details&&!!formik.errors.required_documents[index]?.required_details}
                                                placeholder="Required Documents"
                                            // maxLength={100}
                                            />
                                            <Form.Control.Feedback type="invalid">{touchedRequiredDetails&&formik.errors.required_documents&&formik.errors.required_documents[index]&&formik.errors.required_documents[index]?.required_details&&formik.errors.required_documents[index]?.required_details}</Form.Control.Feedback>
                                        </div>
                                        <div className='d-flex'>
                                            <div>
                                                {formik.values.required_documents?.length>1&&<Button type="button" onClick={() => deleteNewDocuments(index)} className='save-btn btn-del' style={deleteBtn}>Delete</Button>}
                                            </div>
                                            {formik.values.required_documents?.length-1===index&&
                                                <div>
                                                    <Button type="button" onClick={() => addNewDocuments()} className='save-btn me-1' style={addBtn}>Add</Button>
                                                </div>
                                            }

                                        </div>
                                    </Form.Group>
                                </div>
                            }
                            )}
                            <div className="group">
                                {isloader===true?
                                    <div className='loader'>
                                        <img src={loader} alt="" />
                                    </div>
                                    :<>
                                        <Button type="button" className='save-btn me-4' onClick={() => setFormView('syllabus')}>Back</Button>
                                        <Button type="submit" className='save-btn me-4'>Save</Button>
                                    </>
                                }
                            </div>
                        </Fragment>
                    }
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateUniversityCourse;