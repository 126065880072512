import request from "../api/agent";
const API_ROUTE = "university/university";

export class UniversityService {
    async addOrUpdateUniversity(data) {
        let action = `${API_ROUTE}/create-or-update`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllUniversity(limit,page,search,filterId) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-university?page=${page}&limit=${limit}&country_id=${filterId}&search=${serchValue}`);
        return response.data;
    }

     async getUniversities(values) {
        const searchValue = values ?? "";
        const response = await request.get(`${API_ROUTE}/v2/get-university-dropdown-listing?search=${searchValue}`);
        return response.data;
    }
    async getUniversity() {
        const response = await request.get(`${API_ROUTE}/get-university`);
        return response.data;
    }

    async deleteUniversity(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-university`, {
            data: { id: id }
        });
        return response;
    }

    async setStatus(id) {
        const response = await request.put(`${API_ROUTE}/active-or-inactive`, { university_id	: id });
        return response;
    }

    async setFeatured(id) {
        const response = await request.put(`${API_ROUTE}/university-is-featured-status-change`, { id: id });
        return response;
    }

    async setTopRanking(id) {
        const response = await request.post(`${API_ROUTE}/active-or-inactive-top-ranking`, { id: id });
        return response;
    }

}