import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link, json } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ScholarshipService } from '../../services/ScholarshipService';
import ScholarshipCreate from './ScholarshipCreate';
import ScholarshipDatatable from './ScholarshipDatatable';
import { checkUserPermission } from '../../checkUserPermission';
import moment from 'moment';
import { ScholarshipCategoryService } from '../../services/scholarshipCategoryService';
import { CountryService } from '../../services/CountryServices';


const Scholarship = () => {

    const scholarshipCategoryService = new ScholarshipCategoryService();
    const scholarshipService = new ScholarshipService();

    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [scholarType, setScholarType] = useState([]);
    const [toaster, setToaster] = useState("");
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [formView, setFormView] = useState("page1");
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setpending] = useState(true);
    const [scroll, setScroll] = useState(false);
    const [filterId, setFilterId] = useState("");
    const [active, setActive] = useState(true);
    const [universityData, setUniversityData] = useState([]);
    const [countryFilterId, setCountryFilterId] = useState("");
    const [countries, setCountries] = useState([]);
    const [countrySearch, setCountrySearch] = useState([]);

    const countryService = new CountryService()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage);
        setRefresh(!refresh);
    };

    async function getAllScholarshipDetails() {
        setpending(true)
        const data = await scholarshipService.getScholarship(perpage, currentPage, filterText,filterId,countryFilterId);
        setData(data.data)
        setTotalRows(data.data.count)
        setpending(false)
    }

    useEffect(() => {
        getAllCountries();  
    }, [refresh])

    async function getAllCountries() {
        const data = await countryService.getFilterCountry(countrySearch);
        setCountries(data?.data?.results.map(country=>(
            {
                value:country.id,
                label:country.country_name
            }
        )));
        console.log(data);
    }

    async function getAllCategory() {
        const data = await scholarshipCategoryService.getSchoplarshipCategoryChoice();
        console.log(data)
        const type = [];
        data.data.data.forEach((item) => {
            type.push(item)
        })
        setScholarType(type)
    }

    useEffect(() => {
        getAllScholarshipDetails()
        getAllCategory()
        
    }, [refresh])
    const markAllFieldsAsTouched = () => {
        const fieldKeys = Object.keys(formik.values);
        const touchedFields = fieldKeys.reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {});
        formik.setTouched(touchedFields);
      };
    

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Required'),
        // price: Yup.number().required('Required'),
        about_scholarship: Yup.string().required('Required'),
        // eligibility: Yup.string().required('Required'),
        // scholarship: Yup.string().required('Required'),
        // scholarships_awarded: Yup.string().required("Required"),
        // scholarship_type: Yup.string().required("Required"),
        // renewability: Yup.string().required("Required"),
        // application_deadline: Yup.date().required("Required"),
        // announcement_award_on: Yup.date().required("Required"),
        // application_process: Yup.string().required("Required"),
        // scholarship_amount_details: Yup.string().required("Required"),
    });

    const formik = useFormik({
        initialValues: {
            scholarship_id: "",
            title: "",
            about_scholarship: "",
            scholarship_type: "",
            scholarship: "",
            scholarships_awarded: "",
            renewability: "",
            application_deadline: "",
            announcement_award_on: "",
            scholarship_amount_details: "",
            eligibility: "",
            application_process: "",
        },
        validationSchema,
        onSubmit: (values) => {
            console.log("values")
            console.log(values)
            setIsloader(true)
            let formData = {};
            if (values.scholarship_id) {
                formData.scholarship_id = values.scholarship_id ?? "";
            }
            formData.title = values.title;
            if (values.price) {
                formData.price = values.price ?? "";
            }
            // formData.price = values.price
            if (values.scholarship_type) {
                formData.scholarship_type = values.scholarship_type ?? "";
            }
            formData.about_scholarship = values.about_scholarship;
            // formData.scholarship_type = values.scholarship_type;
            // formData.scholarship = values.scholarship;
            if (values.scholarship) {
                formData.scholarship = values.scholarship ?? "";
            }
            if (values.scholarships_awarded) {
                formData.scholarships_awarded = values.scholarships_awarded ?? "";
            }
            // formData.scholarships_awarded = values.scholarships_awarded;
            if (values.renewability) {
                formData.renewability = values.renewability ?? "";
            }
            // formData.renewability = values.renewability;
            if (values.application_deadline) {
                formData.application_deadline = values.application_deadline ?? "";
            }
            // formData.application_deadline = values.application_deadline;
            if (values.announcement_award_on) {
                formData.announcement_award_on = values.announcement_award_on ?? "";
            }
            // formData.announcement_award_on = values.announcement_award_on;
            if (values.scholarship_amount_details) {
                formData.scholarship_amount_details = values.scholarship_amount_details ?? "";
            }
            // formData.scholarship_amount_details = values.scholarship_amount_details;
            if (values.eligibility) {
                formData.eligibility = values.eligibility ?? "";
            }
            // formData.eligibility = values.eligibility;
            if (values.application_process) {
                formData.application_process = values.application_process ?? "";
            }
            // formData.application_process = values.application_process;



            scholarshipService.addOrUpdateScholarship(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    setFormView("page1")
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                    setUniversityData([])
                }
                else {
                    formik.setErrors({
                        question: response?.response?.data?.errors?.title,
                        category: response?.response?.data?.errors?.category,
                        university_course_id: response?.response?.data?.errors?.university_course_id,
                        price: response?.response?.data?.errors?.price,
                        about: response?.response?.data?.errors?.about,
                        requirements: response?.response?.data?.errors?.requirements,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    const handleEdit = async (item) => {
        console.log("edit click",item)
        const deadline = moment(item.application_deadline).format("YYYY-DD-MM")
        const awardedOn = moment(item.announcement_award_on).format("YYYY-DD-MM")
        setScroll(preVal => !preVal)
        formik.setValues({
            scholarship_id: item.id,
            title: item.title,
            price: item.price,
            about_scholarship: item.about_scholarship,
            scholarship_type: item.scholarship_type,
            scholarships_awarded: item.scholarships_awarded,
            // scholarship: item.university_course_id,
            scholarships_awarded: item.scholarships_awarded,
            renewability: item.renewability,
            application_deadline: item.application_deadline,
            announcement_award_on: item.announcement_award_on,
            scholarship_amount_details: item.scholarship_amount_details,
            eligibility: item.eligibility,
            application_process: item.application_process,
        })
        setUniversityData(item?.university_course_id && [{ value: item?.university_course_id, label: item?.university_course_name + ' | ' + item?.university_name }])
    }
console.log(formik)
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Scholarship</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Scholarship</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Sholarship") && <React.Fragment>
                        <ScholarshipCreate
                            formik={formik}
                            isloader={isloader}
                            toaster={toaster}
                            setToaster={setToaster}
                            formRef={formRef}
                            formView={formView}
                            setFormView={setFormView}
                            scholarType = {scholarType}
                            universityData={universityData}
                            setUniversityData={setUniversityData}
                            markAllFieldsAsTouched={markAllFieldsAsTouched}
                        />
                    </React.Fragment>}
                    <React.Fragment>
                        <ScholarshipDatatable
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            totalRows={totalRows}
                            formik={formik}
                           // loading={loading}
                            currentPage={currentPage}
                            setFilterText={setFilterText}
                            setFilterId={setFilterId}
                            filterId = {filterId}
                            pending={pending}
                            scholarType = {scholarType}
                            countries={countries}
                            countryFilterId={countryFilterId}
                            setCountryFilterId={setCountryFilterId}
                            setCountrySearch={setCountrySearch}
                        />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default Scholarship