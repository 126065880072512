import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';

const CreateInformativeVideo = ({ formik, isloader, formRef}) => {
    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="6" controlId="validationCustom04">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={formik.values?.title}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.title && !formik.errors.title}
                                    isInvalid={formik.touched.title && formik.errors.title}
                                    placeholder="Title"
                                    maxLength={100}
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom04">
                                <Form.Label>Video </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="video"
                                    value={formik.values?.video}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.video && !formik.errors.video}
                                    isInvalid={formik.touched.video && formik.errors.video}
                                    placeholder="video"
                                    maxLength={100}
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.video}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateInformativeVideo;
