import request from "../api/agent";

const API_ROUTE = "testimonials/informative-video";

export class InformativeService {

    async getInformativeVideo(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-videos?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }


    async addOrUpdateInformativeVideo(testimonial) {
        let action = `${API_ROUTE}/video-create-or-update`;
        const response = await request.post(action, testimonial);
        return response;
    }

    async deleteInformativeVideo(id) {
        const response = await request.delete(`${API_ROUTE}/video-delete`, {data: { instance_id: [id] }});
        return response;
    }

    async getStatus(id){
        const response = await request.post(`${API_ROUTE}/video-status`,{instance_id:id})
        return response;
    }
}