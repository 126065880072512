import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link, json } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CourseSyllabusService } from '../../services/courseSyllabusService';
import CreateCoursesyllabus from './createCoursesyllabus';
import CourseSyllabusDatatable from './courseSyllabusDatatable';
import { checkUserPermission } from '../../checkUserPermission';



const CourseSyllabus = () => {

    const coursesyllabusservice = new CourseSyllabusService();

    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [formView, setFormView] = useState("title");
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [loading, setLoading] = useState(true);

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };

    async function getAllScholarshipDetails() {
        const data = await coursesyllabusservice.getCourseSyllabus(perpage, currentPage);
        setData(data.data)
        setTotalRows(data.data.count)
        setLoading(false)
    }

    useEffect(() => {
        getAllScholarshipDetails()
    }, [refresh, perpage, currentPage])

    const validationSchema = Yup.object().shape({
        // course_syllabus_id: Yup.string()
        // .required('Required'),
        // course_details_id: Yup.string()
        // .required('Required'),
        title: Yup.string()
            .required('Required'),
        days: Yup.string()
            .required('Required'),
        number_assignments: Yup.number()
            .required('Required'),

        course_syllabus_points: Yup.array().of(
            Yup.object().shape({
                points: Yup.string().required("Required"),
            }).required("Required")
        )
    });
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])



    const formik = useFormik({
        initialValues: {
            course_syllabus_id: "",
            course_details_id: "",

            title: "",
            days: "",
            number_assignments: "",

            course_syllabus_points: [{
                points: ""
            }]

        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            if (formView === "title") {
                if (values.title == "") {
                    errors.title = "Required"
                }
            }
            return errors
        },
        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            if (values.course_syllabus_id) {
                formData.append("course_syllabus_id", values.course_syllabus_id ?? "");
            }
            formData.append("course_details_id", values.course_details_id);
            formData.append("title", values.title);
            formData.append("days", values.days);
            formData.append("number_assignments", values.number_assignments);

            formData.append("course_syllabus_points", JSON.stringify(values.course_syllabus_points));

            coursesyllabusservice.addOrUpdateCourseSyllabus(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    setFormView("title")
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        course_syllabus_id: response?.response?.data?.errors?.course_syllabus_id,
                        course_details_id: response?.response?.data?.errors?.course_details_id,
                        title: response?.response?.data?.errors?.title,
                        days: response?.response?.data?.errors?.days,
                        number_assignments: response?.response?.data?.errors?.number_assignments,


                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        formik.setValues({
            course_details_id: item.course_details_id,
            title: item.title,
            days: item.days,

            number_assignments: item.number_assignments,

            course_syllabus_points: item.points
        })
    }

    console.log(formik)
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>CourseSyllabus</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">CourseSyllabus</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Course Syllabus") && <React.Fragment>
                        <CreateCoursesyllabus
                            formik={formik}
                            isloader={isloader}
                            toaster={toaster}
                            setToaster={setToaster}
                            formRef={formRef}
                            formView={formView}
                            setFormView={setFormView}
                        />
                    </React.Fragment>}
                    <React.Fragment>
                        <CourseSyllabusDatatable
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            totalRows={totalRows}
                            formik={formik}
                            loading={loading}
                        />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default CourseSyllabus