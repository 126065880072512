import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { RolesService } from '../../services/RolesService';
import RolesDatatble from './RolesDatatable';
import RolesCreate from './RolesCreate';
import { checkUserPermission } from '../../checkUserPermission';


const Roles = () => {

  const rolesService = new RolesService();

  const formRef = useRef(null);
  const [isloader, setIsloader] = useState(false);
  const [toaster, setToaster] = useState("");
  const [image, setImage] = useState();
  const [data, setData] = React.useState([]);
  const [refresh, setRefresh] = useState(false);
  const [totalRows, setTotalRows] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [filterText, setFilterText] = React.useState('');
  const [pending, setPending] = useState(true);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [scroll])

  const handlePerRowsChange = async (newPerPage) => {
    setPerpage(newPerPage)
    setRefresh(!refresh);
  };

  async function getAllRoles() {
    setPending(true)
    const data = await rolesService.getRoles(perpage, currentPage,filterText);
    setData(data.data)
    setTotalRows(data.data.count)
    setPending(false)
  }

  useEffect(() => {
    getAllRoles()
  }, [refresh])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required'),
    permissions: Yup.array().min(1).required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      role: "",
      name: "",
      permissions: []
    },
    validationSchema,
    onSubmit: (values) => {
      setIsloader(true)
      let formData = {};
      if (values.role) {
        formData.role = values.role;
      }
      formData.name = values.name;
      formData.permissions = values.permissions;

      rolesService.addOrUpdateRoles(formData).then((response) => {
        if (response && response.status) {
          setIsloader(false)
          console.log(response?.data?.data)
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT
          });
          setRefresh(!refresh)
          formik.resetForm()
          formRef.current.reset();
        }
        else {
          formik.setErrors({
            question: response?.response?.data?.errors?.title,
            answer: response?.response?.data?.errors?.description,
          });
          console.log(response?.response?.data?.errors);
          setIsloader(false)
          console.log(response?.response)
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    },
  });


  const handleEdit = async (item) => {
    console.log(item.permissions)
    formik.setValues({
      role: item.id,
      name: item.name,
      permissions: item.permissions
    })
    setScroll(preVal=>!preVal)
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Roles</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Roles</li>
              </ol>
            </nav>
          </div>
          {checkUserPermission("Create Roles") && <React.Fragment>
            <RolesCreate image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
          </React.Fragment>}
          <React.Fragment>
            <RolesDatatble pending={pending} setFilterText={setFilterText} currentPage={currentPage} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} totalRows={totalRows} formik={formik}  />
          </React.Fragment>
        </main>
      </>
    </React.Fragment>
  )
}

export default Roles