import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { checkUserPermission } from '../../checkUserPermission';
import { SpeakerService } from '../../services/speakerService';
import SpeakersCreate from './SpeakersCreate';
import SpeakersDatatable from './SpeakersDatatable';
import { dataURLtoFile } from '../../functions';

const Speakers = () => {

    const speakerService = new SpeakerService();

    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [pending, setPending] = React.useState(true)
    const [filterText, setFilterText] = React.useState('');
    const [active, setActive] = useState(true);

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])


    async function getSpeakers() {
       setPending(true)
        const data = await speakerService.getAllSpeaker(perpage, currentPage, filterText);
        setData(data.data)
     setPending(false)
        setTotalRows(data.data.count)
    }

    useEffect(() => {
        getSpeakers()
        
    }, [refresh, currentPage, perpage, filterText])

    const validationSchema = Yup.object().shape({
        speaker_name: Yup.string()
            .required('Required'),
        designation: Yup.string()
            .required('Required'),
        university: Yup.string()
            .required('Required'),
        speaker_image: Yup.string()
            .required('Required'),
        country: Yup.string()
            .required('Required'),
    });



    const formik = useFormik({
        initialValues: {
            speaker_id: "",
            speaker_name: "",
            designation: "",
            university: "",
            speaker_image: "",
            country: ""
        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            if (values.speaker_image == "") {
                errors.speaker_image = "Required"
            }
            else if (values?.speaker_image?.type !== "image/png" && values?.speaker_image?.type !== "image/jpg" && values?.speaker_image?.type !== "image/jpeg") {
                errors.speaker_image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.speaker_image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.speaker_image = "images should be less than 2 MB"
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            if (values.speaker_id) {
                formData.append("speaker_id", values.speaker_id ?? "");
            }
            formData.append("speaker_name", values.speaker_name);
            formData.append("designation", values.designation);
            formData.append("university", values.university);
            formData.append("speaker_image", values.speaker_image);
            formData.append("country", values.country);

            speakerService.addOrUpdateSpeaker(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        speaker_name: response?.response?.data?.errors?.speaker_name,
                        designation: response?.response?.data?.errors?.designation,
                        university: response?.response?.data?.errors?.university_name,
                        country: response?.response?.data?.errors?.country,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        formik.setValues({
            speaker_id: item.id,
            speaker_name: item.speaker_name,
            designation: item.designation,
            speaker_image: item?.speaker_image ? await dataURLtoFile(item.speaker_image) : "",
            speaker_image_url: item.speaker_image,
            university: item.university,
            country: item.country
        })
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Speakers</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Speakers</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Speakers") && <React.Fragment>
                        <SpeakersCreate image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <SpeakersDatatable setFilterText={setFilterText} pending={pending} currentPage={currentPage} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} totalRows={totalRows} formik={formik} />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default Speakers