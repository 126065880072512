import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { GroupService } from '../../services/GroupService';
import GroupDatatble from './GroupDatabale';
import GroupCreate from './GroupCreate';
import { checkUserPermission } from '../../checkUserPermission';


const Group = () => {

  const groupService = new GroupService();

  const formRef = useRef(null);
  const [isloader, setIsloader] = useState(false);
  const [toaster, setToaster] = useState("");
  const [image, setImage] = useState();
  const [data, setData] = React.useState([]);
  const [refresh, setRefresh] = useState(false);
  const [totalRows, setTotalRows] = React.useState(0);
  const [roles, setRoles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [scroll, setScroll] = useState(false);
  const [pending, setpending] = useState(true);
  const [active, setActive] = useState(true);
  const [filterText, setFilterText] = React.useState('');
  



  const handlePerRowsChange = async (newPerPage) => {
    setPerpage(newPerPage)
  };

  async function getFaq() {
    setpending(true)
    const data = await groupService.getGroups(perpage, currentPage,filterText);
    setData(data.data)
    setTotalRows(data.data.count)
   setpending(false)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
}, [scroll])


  useEffect(() => {
    getFaq()
    setActive(false)
  }, [refresh])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required'),
    roles: Yup.array().min(1).required('Required')
  });


  const formik = useFormik({
    initialValues: {
      group: "",
      name: "",
      roles: ""
    },
    validationSchema,
    onSubmit: (values) => {
      setIsloader(true)
      let formData = {};
      if (values.group) {
        formData.group = values.group;
      }
      formData.name = values.name;
      formData.roles = roles;

      groupService.addOrUpdateGroup(formData).then((response) => {
        console.log("formData")
        console.log(formData)

        if (response && response.status) {
          setIsloader(false)
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT
          });
          setRefresh(!refresh)
          formik.resetForm()
          formRef.current.reset();
        }
        else {
          formik.setErrors({
            question: response?.response?.data?.errors?.title,
            answer: response?.response?.data?.errors?.description,
          });
          console.log(response?.response?.data?.errors);
          setIsloader(false)
          toast.error(response?.response?.data?.errors?.name[0] ? response?.response?.data?.errors?.name[0]:"Something went wrong", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    },
  });


  console.log("roles")
  console.log(roles)

  const handleEdit = async (item) => {
    console.log("dfgjksfgfghdjhghh")
    console.log(item)
    setScroll(preVal => !preVal)
    setRoles(item.roles.map((role)=>role.value))
    formik.setValues({
      group: item.pk,
      name: item.name,
      roles: item.roles
    })
  }
  console.log(roles)
  return (
    <React.Fragment>
      <ToastContainer />
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Group</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Group</li>
              </ol>
            </nav>
          </div>
          {checkUserPermission("Create Group") && <React.Fragment>
            <GroupCreate image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} setRoles={setRoles} />
          </React.Fragment>}
          <React.Fragment>
            <GroupDatatble pending={pending} setFilterText={setFilterText} currentPage={currentPage} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} totalRows={totalRows} formik={formik}  />
          </React.Fragment>
        </main>
      </>
    </React.Fragment>
  )
}

export default Group
