import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'


import { JobListService } from '../../services/jobListServices';
import JobDatatable from './jobDatatable';

const JobList = () => {
    const joblistservice = new JobListService();

    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
   // const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = React.useState(true)
    const [active, setActive] = useState(true);
    const [filterId, setFilterId] = useState("");
    

    const handlePerRowsChange = async (newPerPage) => {
      setPerpage(newPerPage);
      setRefresh(!refresh);

    };
  
    async function getAllEnquires() {
      setPending(true)
      const data = await joblistservice.getJobList(perpage, currentPage,filterId,filterText);
      setPending(false)
      setData(data.data.results)
      console.log("dattaaa",data.data);
      setTotalRows(data.data.count)
    }


    useEffect(() => {
      getAllEnquires()
    }, [refresh])


  return (
    <React.Fragment>
      <ToastContainer />
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Job Applications</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active"> Job Applications</li>
              </ol>
            </nav>
          </div>
          <React.Fragment>
            <JobDatatable  setFilterId={setFilterId} currentPage={currentPage} pending={pending} filterText={filterText} setFilterText={setFilterText}  data={data}  refresh={refresh} setRefresh={setRefresh} handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} totalRows={totalRows} />
          </React.Fragment>
        </main>
      </>
    </React.Fragment>
  )
}

export default JobList;