import request from "../api/agent";

const API_ROUTE = "services/upcomingbatch";

export class UpcomingBatchService {
    async getUpcomingBatch(perpage, currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-all-upcomingbatch?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }

    async addOrUpdateUpcomingBatch(scholarshipData) {
        let action = `${API_ROUTE}/create-or-update-upcomingbatch`;
        const response = await request.post(action, scholarshipData);
        return response;
    }

    async deleteUpcomingBatch(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-upcomingbatch`, { data: { instance_id: [id] } });
        return response;
    }

    async setUpcomingBatchStatus(id) {
        const response = await request.post(`${API_ROUTE}/upcomingbatch-status`, {instance_id: id });
        return response;
    }
}