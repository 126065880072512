import request from "../api/agent";
const API_ROUTE = "courses/specialization";

export class SpecializationService {
    async addOrUpdateSpecialization(specialization) {
        let action = `${API_ROUTE}/specialization-creation`;
        const response = await request.post(action, specialization);
        return response;
    }

    async getAllSpecializations(search,perpage,currentPage) {
        if(perpage){
            const serchValue = search??""
            const response = await request.get(`${API_ROUTE}/specialization-listing?search=${serchValue}&limit=${perpage}&page=${currentPage}`);
            return response.data;
        }else{
            const response = await request.get(`${API_ROUTE}/specialization-listing`);
            return response.data;
        }
    }
    async getSpecializations(value) {
        const searchValue = value ?? "";
        const response = await request.get(`${API_ROUTE}/v2/get-course-specialization-dropdown-listing?search=${searchValue}`);
        return response.data;
    }
    async setStatus(id) {
        const response = await request.post(`${API_ROUTE}/specialization-status`, { instance_id: id });
        return response;
    }

    async deleteSpecialization(id) {
        const response = await request.delete(`${API_ROUTE}/specialization-delete`, {
            data: { instance_id: [id] }
        });
        return response;
      }
}