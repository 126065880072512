import request from "../api/agent";
const API_ROUTE = "testimonials/testimonial";



export class EmployeeTestimonialVideoservice {
    async addOrUpdateEmployeeVideo(data) {
        let action = `${API_ROUTE}/employee-testimonial-create-or-update`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllEmployeeVideo(page, limit,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-employee-testimonial?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }
    async deleteEmployeeVideo(id) {
        const response = await request.delete(`${API_ROUTE}/employee-testimonial-delete`, {
            data: { instance_id: [id]}
        });
        return response;

   
      }
      async employeeVideoStatus(id){
        const response = await request.post(`${API_ROUTE}/employee-testimonial-status`,{instance_id:id})
        return response;
    }

    //   async EmployeeVideoStatus(data) {
    //     let action = `${API_ROUTE}/employee-testimonial-status`;
    //     const response = await request.post(action, data);
    //     return response;
    // }
   
}