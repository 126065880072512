import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { CourseService } from '../../services/CourseService';

const courseService=new CourseService()


const CreateStream = ({ formik, isloader, toaster, setToaster, setImage, image, formRef, imageError }) => {
    const [courses, setCourses] = useState([]);
    
      
    useEffect(() => {
        getAllCourses()
    }, [])

    async function getAllCourses() {
        const data = await courseService.getAllCoursess();
        setCourses(data?.data?.results)
    }
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="course_stream_name"
                                value={formik.values?.course_stream_name}
                                onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.course_stream_name && !formik.errors.course_stream_name}
                           isInvalid={formik.touched.course_stream_name && formik.errors.course_stream_name}
                                placeholder="Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.course_stream_name}</Form.Control.Feedback>
                        </Form.Group>
                         {/* <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>Course</Form.Label>
                                    <Form.Select
                                        placeholder="Course"
                                        className="select-dropdown"
                                        classNamePrefix="select"
                                        name="course"
                                        value={formik.values?.course}                                        
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.course && !formik.errors.course}
                                        isInvalid={formik.touched.course && formik.errors.course}
                                        >
                                        <option value='' >Select an Option</option>
                                        {courses?.map(type =>
                                            <option value={type?.id}>{type?.course_name}</option>
                                        )}


                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{formik.errors.course}</Form.Control.Feedback>
                                </Form.Group> */}
                        
                    </Row>
                
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateStream;