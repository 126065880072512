import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile,dataURLtoPDF} from '../../functions';


import { BrochureServices } from '../../services/brochureServices';

import BrochureCreate from './createSettings';




const brochureservices = new BrochureServices();

const BrochureSettings = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);

    const [loading, setLoading] = useState(true);
    const [isToastOpen, setToastOpen]=useState(true);

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)

    };

    async function getAllBrochure() {
        const response = await brochureservices.getallBrochure();
        setData(response.data.results)
        formik.setValues({...formik.values,
            id:response.data.results[0].id,
            title:response.data.results[0].title,
            brochure_pdf:response.data.results[0].brochure_pdf ? await dataURLtoPDF(response.data.results[0].brochure_pdf) : "",

        brochure_url: response.data.results[0]?.brochure_pdf})
        console.log("response.data.results")
        console.log(response.data.results)
    }
    
    useEffect(() => {
        getAllBrochure()
        
    
    }, [refresh, perpage, currentPage])

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Required'),
        
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            title: "",
            brochure_pdf: "",
             brochure_url: "",
        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
           
            // if(values.brochure_pdf === ""){
                
            // }else if(values.brochure_pdf.type !== "application/pdf" ) {
            //         errors.brochure_pdf = "Only PDFs are valid"
            //     }
            //     else {
            //         let kb = values.brochure_pdf.size / 1024; // convert the file size into byte to kb
            //         let mb = kb / 1024; // convert kb to mb
            //         if (mb > 5) {
            //           errors.brochure_pdf ="brochure should be less than 5 MB"
            //         }
            //       }
            //     //   if(values.website !== "URL"){
            //     //     errors.website = "Please enter a valid url"
            //     //     console.log(errors.website);
            //     //   }

            // return errors;
            
        },
        onSubmit: (values) => {
            console.log("val", values);
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("id", values.id ?? "");
            }
            formData.append("title", values.title);
            formData.append("brochure_pdf", values.brochure_pdf);
            formData.append("brochure_url", values.brochure_url);
            

            brochureservices.addOrUpdateBrochure(formData).then((response) => {
                // })
                // CreateOrUpdateCourse({ formData })((response) => {
                console.log("response", response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        toastId: "sucess1",
                        onOpen: () => {
                            setToastOpen(false)
                        },
                        onClose:() => {
                            setToastOpen(true)
                        },
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        title: response?.response?.data?.errors?.title,
                        brochure_pdf: response?.response?.data?.errors?.brochure_pdf,
                        // image: response?.response?.data?.errors?.image,
                    });
                    console.log("res", response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        toastId: "sucess1",
                        onOpen: () => {
                            setToastOpen(false)
                        },
                        onClose:() => {
                            setToastOpen(true)
                        },
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    const handleEdit = async (response) => {
    // console.log("data")
    // console.log(data)
    data[0] &&
        formik.setValues({
            id:response.data.results[0].id,
            title: response.data.results[0].title,
            //image: item.county_flag ? await dataURLtoFile(item.county_flag) : '',
            brochure_pdf:response.data.results[0].brochure_pdf ? await dataURLtoPDF(response.data.results[0].brochure_pdf) : "",
            brochure_url: response.data.results[0]?.brochure_pdf,
        })
        
    }
 console.log(formik.values)
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Brochure </h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Brochure </li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        <BrochureCreate handleEdit={handleEdit} image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>
                    {/* <React.Fragment>
                        <BrochureDatatable data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} loading={loading} />
                    </React.Fragment> */}

                </main>
            </>
        </React.Fragment>
    )
}

export default BrochureSettings;