// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import { CountryService } from '../../services/CountryServices';
import CountryDatatable from './countryDatatable';
import CreateCountry from './createCountry';
import { checkUserPermission } from '../../checkUserPermission';

const countryService = new CountryService();

const Countries = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [toggleCleared, setToggleCleared] = React.useState(false);
    const [pending, setPending] = React.useState(true)
    const [scroll, setScroll] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [active, setActive] = useState(true);
    const [formView, setFormView] = useState("country");

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
        setRefresh(!refresh)

    };
    const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {

        console.log(`${row.name} was clicked!`);
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    const markAllFieldsAsTouched = () => {
        const fieldKeys = Object.keys(formik.values);
        const touchedFields = fieldKeys.reduce((acc, key) => {
            acc[key] = true;
            return acc;
        }, {});
        formik.setTouched(touchedFields);
        console.log(touchedFields);
    };


    async function getCountries() {
        setPending(true)
        const data = await countryService.getCountry(perpage, currentPage, filterText);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)

    }
    useEffect(() => {
        getCountries()

    }, [refresh])

    const validationSchema = Yup.object().shape({
        country_name: Yup.string()
            .required('Required'),
        // country_code: Yup.string()
        //     .required('Required'),
        // image: Yup.string()
        //     .required('Required'),
        other_benefits: Yup.string()
            .required('Required'),


        top_industries: Yup.array().of(
            Yup.object().shape({
                title: Yup.string().required('Title is required.'),
                description: Yup.string().required('Description is required.'),
            })
        ),
        why_this_country: Yup.array().of(
            Yup.object().shape({
                title: Yup.string().required('Title is required.'),
                description: Yup.string().required('Description is required.'),
            })
        ),
        country_benefits: Yup.array().of(
            Yup.object().shape({
                title: Yup.string().required('Title is required.'),
                description: Yup.string().required('Description is required.'),
            })
        ),


    });


    const formik = useFormik({
        initialValues: {
            country_id: "",
            country_name: "",
            country_code: "",
            image: "",
            image_url: "",
            currency_code: "",
            description: "",
            why_this_country: [{
                title: "",
                description: ""
            }],
            country_benefits: [{
                title: "",
                description: ""
            }],
            top_industries: [{
                title: "",
                description: ""
            }],
            visa_process: [{
                title: "",
                description: ""
            }],
            other_benefits: "",
            // og_image: "",
            // og_image_url: "",
            meta_image_title: "",
            meta_title: "",
            meta_description: "",
            meta_keyword: "",

        },
        validationSchema,



        validate: async (values) => {
            const errors = {}

            if (!values?.image) {
                errors.image = "Image is required!"
            } else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            } else {
                let kb = values.image.size / 1024;
                let mb = kb / 1024;
                if (mb > 2) {
                    errors.image = "Images should be less than 2 MB"
                }


            }


            return errors;

        },



        onSubmit: (values) => {
            // console.log("val", values?.image);
            // console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.country_id) {
                formData.append("country_id", values.country_id ?? "");
            }
            formData.append("country_name", values.country_name);
            formData.append("country_code", values.country_code);
            formData.append("county_flag", values?.image);
            formData.append("currency_code", values.currency_code);
            formData.append("description", values.description);
            // formData.append("og_image", values.og_image);
            formData.append("meta_title", values.meta_title);
            formData.append("meta_description", values.meta_description);
            formData.append("meta_keyword", values.meta_keyword);
            formData.append("meta_image_title", values.meta_image_title);

            formData.append("why_this_country", JSON.stringify(values.why_this_country));
            formData.append("country_benefits", JSON.stringify(values.country_benefits));
            formData.append("top_industries", JSON.stringify(values.top_industries));
            formData.append("visa_process", JSON.stringify(values.visa_process));
            formData.append("other_benefits", values.other_benefits);
            // console.log("valu",  values?.image);
            countryService.addOrUpdateCountry(formData).then((response) => {
                // })
                // CreateOrUpdateCourse({ formData })((response) => {
                console.log("response", response);
                if (response && response.status) {
                    setIsloader(false)
                    setFormView('country')
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        country_name: response?.response?.data?.errors?.country_name,
                        country_code: response?.response?.data?.errors?.description,
                        image: response?.response?.data?.errors?.image,
                        why_this_country: response?.response?.data?.errors?.why_this_country,
                        // og_image: response?.response?.data?.errors?.og_image,
                        meta_title: response?.response?.data?.errors?.meta_title,
                        meta_description: response?.response?.data?.errors?.meta_description,
                        meta_keyword: response?.response?.data?.errors?.meta_keyword,
                        meta_image_title: response?.response?.data?.errors?.meta_image_title,
                        // image: response?.response?.data?.errors?.image,
                    });
                    // console.log("res", response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error(response?.response?.data?.errors?.country_name ? response?.response?.data?.errors?.country_name[0] : "Something went wrong",


                        {
                            position: toast.POSITION.TOP_RIGHT
                        });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        console.log("it", item);

        setScroll(preVal => !preVal)
        // setImage(await dataURLtoFile(item.image))
        formik.setValues({
            country_id: item.id,
            country_name: item.country_name,
            image: item.county_flag ? await dataURLtoFile(item.county_flag) : '',
            image_url: item?.county_flag,
            country_code: item.country_code,
            currency_code: item.currency_code,
            description: item.description,
            // og_image: await dataURLtoFile(item.og_image),
            // og_image_url: item?.og_image,
            meta_title: item.meta_title,
            meta_description: item.meta_description,
            meta_keyword: item.meta_keyword,
            meta_image_title: item.meta_image_title,
            other_benefits: item.other_benefits,
            why_this_country: item?.why_this_country?.length ? item?.why_this_country?.map(document => { return { title: document?.title, description: document?.description } }) : [{ title: "" }],
            country_benefits: item?.country_benefits?.length ? item?.country_benefits?.map(document => { return { title: document?.title, description: document?.description } }) : [{ title: "" }],
            top_industries: item?.top_industries?.length ? item?.top_industries?.map(document => { return { title: document?.title, description: document?.description } }) : [{ title: "" }],
            visa_process: item?.visa_process?.length ? item?.visa_process?.map(document => { return { title: document?.title, description: document?.description } }) : [{ title: "" }],

        })
        // console.log("item", item.county_flag);
    }
    // console.log(formik.errors)
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Country</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Country</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Country") && <React.Fragment>
                        <CreateCountry
                            markAllFieldsAsTouched={markAllFieldsAsTouched}
                            setFormView={setFormView}
                            image={image}
                            formik={formik}
                            isloader={isloader}
                            toaster={toaster}
                            formView={formView}
                            setToaster={setToaster}
                            setImage={setImage}
                            formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <CountryDatatable pending={pending} totalRows={totalRows} setFilterText={setFilterText} setCurrentPage={setCurrentPage} currentPage={currentPage} handleRowClicked={handleRowClicked} handleChange={handleChange} handlePerRowsChange={handlePerRowsChange} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Countries;