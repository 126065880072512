import request from "../api/agent";
const API_ROUTE = "/articles/article";

export class Articleservice {
    async addOrUpdatearticle(article) {
        let action = `${API_ROUTE}/create-or-update-article`;
        const response = await request.post(action, article);
        return response;
    }

    async getAllarticle(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/listing-articles?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }


    async deletearticle(id) {
        const response = await request.delete(`${API_ROUTE}/article-delete`, {
            data: { instance_id: [id] }
        });
        return response;
      }
      async getActivatedarticle(id) {
        const response = await request.post(`${API_ROUTE}/article-status`,  { "instance_id":id } );
        return response;
    }

}