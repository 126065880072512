import request from "../api/agent";
const API_ROUTE = "blogs/blog-management";

export class BlogService {
    async addOrUpdateBlog(blog) {
        let action = `${API_ROUTE}/create-or-update-blog`;
        const response = await request.post(action, blog);
        return response;
    }

    async getAllBlog(limit,page,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/blog-listing-with-filtration?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }
    async getAllBlogdropdown() {
        
        const response = await request.get(`blogs/blog-category/blog-category-dropdown`);
        return response.data;
    }


    async deleteBlog(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-blog`, {
            data: { blog_id: id }
        });
        return response;
      }
      async setStatus(id) {
        const response = await request.put(`${API_ROUTE}/blog-status-change-admin`, { id: id });
        return response;
    }

    async setFeatured(id) {
        const response = await request.put(`${API_ROUTE}/blog-is-featured-status-change-admin`, { id: id });
        return response;
    }

}
