import request from "../api/agent";

const API_ROUTE = "career/benefits-of-work-with-us";

export class BenefitService {
    async getBenefit(perpage, currentPage,search) {
        const serchValue = search??""
        if(perpage){
            const response = await request.get(`${API_ROUTE}/admin-listing-benefits-of-work-with-us?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
            return response.data;
        }else{
            const response = await request.get(`${API_ROUTE}/get-all-batch`);
            return response.data;
        }
    }

    async addOrUpdateBenefit(benefit) {
        let action = `${API_ROUTE}/create-or-update-benefits-of-working-with-us`;
        const response = await request.post(action, benefit);
        return response;
    }

    async deleteBenefit(id) {
        const response = await request.delete(`${API_ROUTE}/delete-benefits-of-work-with-us`, { data: { id: id } });
        return response;
    }

    async setBenefitStatus(id) {
        const response = await request.put(`${API_ROUTE}/admin-benefits-of-work-with-us-status-change`, { id: id  });
        return response;
    }
}