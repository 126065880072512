import request from "../api/agent";
const API_ROUTE = "services/happycustomers";




export class HappyCostumerService {
    async addOrUpdateHappyCostumer(costumer) {
        let action = `${API_ROUTE}/create-or-update-happy-customers`;
        const response = await request.post(action, costumer);
        return response;
    }

    async getAllHappyCostumer(perpage, currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-all-happy-customers?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }


    async deleteHappyCostumer(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-happy-customers`, {
            data: { id: id }
        });
        return response;
      }
}