import React, { useState } from 'react';
import logo from '../../assets/images/afresh-logo.png';
import avatar from '../../assets/images/AvatarImg.png';
import { BsBoxArrowInRight } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { CgPassword } from 'react-icons/cg';
import { AuthService } from '../services/AuthService';
import { toast } from 'react-toastify';

const authService = new AuthService();

const Header = (props) => {
    const navigate = useNavigate();
    const [rotate, setRotate] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const rotateTransform = rotate ? "rotate(180deg)" : "rotate(0)"
    const user = JSON.parse(localStorage.getItem("user"));

    const handleClick = () => {
        setRotate(!rotate);

        setIsActive(current => !current);
        if (isActive) {
            const toggleRemovee = document.body.classList.remove('toggle-sidebar')
            const toggleRemove = document.getElementById('sidebar-nav').classList.remove('expander')
        }
        else {
            const toggleOpenn = document.body.classList.add('toggle-sidebar')
            const toggleOpen = document.getElementById('sidebar-nav').classList.add('expander')
            const toggleActiveRemove = document.getElementsByClassName('nav-link');
            console.log(toggleActiveRemove)
            Array.from(toggleActiveRemove).forEach(element => {
                element.classList.add('collapsed');

            });
            const collapse = document.getElementsByClassName('nav-content');
            Array.from(collapse).forEach(element => {

                element.classList.remove('show');

            });
        }
    }

    const logoutUser = () => {
        authService.userLogout({ refresh: localStorage.getItem('refresh') }).then((response) => {
            if (response && response.status) {
                localStorage.clear()
                navigate('/login')
            }
            else {
                toast.error(response?.response?.data?.message ? response?.response?.data?.message : "Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
    }

    return (
        <React.Fragment>
            <header id="header" className="header fixed-top d-flex align-items-center">

                <div className="d-flex align-items-center justify-content-between">
                    <Link to="/" className="logo d-flex align-items-center">
                        <img src={logo} alt="IRM-Logo" />
                    </Link>

                </div>

                <div className="dashboard_togglebtn" onClick={handleClick} style={{ transform: rotateTransform, transition: "all 0.2s linear" }} >
                    <span className="svg-icon svg-icon-2 rotate-180">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="currentColor"></path>
                            <path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="currentColor"></path>
                        </svg>
                    </span>
                </div>



                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">

                        <li className="nav-item d-block d-lg-none">
                            <a className="nav-link nav-icon search-bar-toggle " href="#">
                                <i className="bi bi-search"></i>
                            </a>
                        </li>

                        <li className="nav-item dropdown pe-3">

                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <img src={avatar} alt="Profile" className="rounded-circle" />
                                <span className="d-none d-md-block dropdown-toggle ps-2">
                                    {user && user?.username}
                                </span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li className="dropdown-header boder-bottom">
                                    <h6>
                                        {user && user?.username}
                                    </h6>
                                </li>




                                <li>
                                    <div className="dropdown-item d-flex align-items-center ">

                                        <span role='button'>
                                            <Link to="/resetpassword">
                                                
                                                <div className="chngpw">
                                                <CgPassword />
                                                    Change Password
                                                </div>
                                            </Link>
                                        </span>
                                    </div>
                                    <div className="dropdown-item d-flex align-items-center" onClick={() => logoutUser()}>
                                        <BsBoxArrowInRight />
                                        <span role='button'>Log Out</span>
                                    </div>
                                </li>

                            </ul>
                        </li>

                    </ul>
                </nav>

            </header>
        </React.Fragment>
    )
}

export default Header;