import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
 
const CreateEmployeeTestimonialVideo = ({ formik, isloader, setImage,formRef }) => {
    // const onChangeImage = (e) => {
    //     if (e.target.files[0]) {
    //         setImage(e.target.files[0])
    //         formik.setValues({ ...formik.values, thumbnail: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) })
    //     }
    // }

    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03" className='mb-2'>
                            <Form.Label>Employer</Form.Label>
                            <Form.Control
                               type="text"
                                name="user"
                                value={formik.values?.user}
                                onChange={formik.handleChange}
                                isValid={formik.touched.user && !formik.errors.user}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.user && formik.errors.user}
                                placeholder="User"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.user}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04"  className='mb-2'> 
                            <Form.Label>Designation </Form.Label>
                            <Form.Control
                                type="text"
                                name="designation"
                                value={formik.values?.designation}
                                onChange={formik.handleChange}
                                isValid={formik.touched.designation && !formik.errors.designation}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.designation && formik.errors.designation}
                                placeholder="Designation"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.designation}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                                <Form.Label>Video URL </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="video"
                                    value={formik.values?.video}
                                    onChange={formik.handleChange}
                                    isValid={formik.touched.video && !formik.errors.video}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.video && formik.errors.video}
                                    placeholder="video"
                                    maxLength={100}
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.video}</Form.Control.Feedback>
                            </Form.Group>
                            {/* <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Thumbnail</Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="thumbnail"
                                    accept="image/*"
                                    values={formik.values.thumbnail}
                                    onChange={(e) => {
                                        onChangeImage(e)
                                    }
                                    }
                                    isValid={formik.touched.thumbnail && !formik.errors.thumbnail}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.thumbnail && formik.errors.thumbnail}
                                />
                                 <Form.Control.Feedback type="invalid">{formik.errors.thumbnail}</Form.Control.Feedback>
                                {formik.values?.image_url && 
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }
                            </div>
                        </Form.Group> */}
                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateEmployeeTestimonialVideo;