import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { CountryService } from '../../services/CountryServices';


const RecentIntakeCreate = ({ formik, isloader, formRef, setImage }) => {
    const [countries, setCountries] = useState([]);
    const countryService = new CountryService();

    useEffect(() => {
        getCountries()
    }, [])

    async function getCountries() {
        const countriesData = await countryService.getAllCountry();
        setCountries(countriesData.data.results)
    }
    const onChangeImage = (e) => {
        setImage(e.target.files[0])
        formik.setValues({
            ...formik.values,
            image: e.target.files[0],
            image_url: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "",
        })
    }
    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="full_name"
                                value={formik.values?.full_name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.full_name && !formik.errors.full_name}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.full_name && formik.errors.full_name}
                                placeholder="Full Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.full_name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Image<span className='required ms-1'>*</span></Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        onChangeImage(e)
                                    }
                                    }
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.image && !formik.errors.image}
                                    isInvalid={formik.touched.image && formik.errors.image}
                                />
                                {formik.values?.image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }

                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                                {/* { !formik.touched.image && !formik.values.image && <div style={{
                                        fontSize: "14px",
                                        color: "#198754",
                                        margin: "4px 0 0 4px",
                                        fontWeight:"500"
                                    }}>Max size. 2mb</div>} */}
                            </div>
                            <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 600 * 600 px)</p></div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Course Data</Form.Label>
                            <Form.Control
                                type="text"
                                name="course_data"
                                value={formik.values?.course_data}
                                onChange={formik.handleChange}
                                isValid={formik.touched.course_data && !formik.errors.course_data}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.course_data && formik.errors.course_data}
                                placeholder="Course Data"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.course_data}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom03">
                            <Form.Label>Exam</Form.Label>
                            <Form.Control
                                type="text"
                                name="exam"
                                value={formik.values?.exam}
                                onChange={formik.handleChange}
                                isValid={formik.touched.exam && !formik.errors.exam}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.exam && formik.errors.exam}
                                placeholder="Exam"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.exam}</Form.Control.Feedback>
                        </Form.Group>
                        {/* </Row>
                    <Row className="mb-2"> */}
                        <Form.Group as={Col} md="2" controlId="validationCustom03">
                            <Form.Label>Score</Form.Label>
                            <Form.Control
                                type="text"
                                name="score"
                                value={formik.values?.score}
                                onChange={formik.handleChange}
                                isValid={formik.touched.score && !formik.errors.score}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.score && formik.errors.score}
                                placeholder="Eg:IELTS-7.0"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.score}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>University Data</Form.Label>
                            <Form.Control
                                type="text"
                                name="university_data"
                                value={formik.values?.university_data}
                                onChange={formik.handleChange}
                                isValid={formik.touched.university_data && !formik.errors.university_data}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.university_data && formik.errors.university_data}
                                placeholder="University data"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.university_data}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="6" controlId="validationCustom010">
                            <Form.Label>Country</Form.Label>
                            <Form.Select
                                placeholder="Country"
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="country"
                                value={formik.values?.country}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.country && !formik.errors.country}
                                isInvalid={formik.touched.country && formik.errors.country}
                                onChange={formik.handleChange}
                            >
                                <option value="">Select a Country</option>
                                {countries?.map((country) => (
                                    <option value={country?.id}>{country?.country_name}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.country}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader === true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default RecentIntakeCreate;