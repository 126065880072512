import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CounsellorProfileService } from '../../services/CounsellorProfileService';
import { dataURLtoFile } from '../../functions';
import CounsellorProfileCreate from './CounsellorProfileCreate';
import CounsellorProfileDatable from './CounsellorProfileDatatable';
import { checkUserPermission } from '../../checkUserPermission';
import { min } from 'moment';

const CounsellorProfile = () => {

    const counsellorProfileService = new CounsellorProfileService();

    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [experts, setExperts] = useState([]);
    const [image, setImage] = useState();
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [pending, setPending] = React.useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [active, setActive] = useState(true);

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage);
        setRefresh(!refresh);
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    async function getAllExpertise() {
        setPending(true)
        const data = await counsellorProfileService.getCounsellorProfile(perpage, currentPage, filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setPending(false)
    }

    useEffect(() => {
        getAllExpertise()
    }, [refresh])

    
    // const imageValidation = Yup
    // .mixed()
    // .required("Required (Max size. 2mb)")
    // .test("size", "Image should be less than 2 MB", (value) => {
    //     return value && value.size <= 2000000;
    // })
    // .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
    //     return value && (
    //         value.type === "image/jpeg" ||
    //         value.type === "image/jpg" ||
    //         value.type === "image/png"
    //     );
    // })



    const validationSchema = Yup.object().shape({
        full_name: Yup.string().required('Required'),
        // email: Yup.string().email('Must be a valid email').required('Email is required'),
        // mobile_number: Yup.string().min(10, "Enter valid number").max(10, "Enter valid number").required("Required"),
        image: Yup.string().required('Required'),
        expert_in: Yup.array().min(1).required('Required'),
        // image:imageValidation
    });

    const formik = useFormik({
        initialValues: {
            counsellor_profile_id: "",
            full_name: "",
            email: "",
            mobile_number: "",
            image: "",
            expert_in: [],

        },
        validationSchema,
        validate: async (values) => {
            const errors ={}
        if(values?.image ==""){
            errors.image = "Required"
        }
        else if (values?.image !== "" &&values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
            errors.image = "Only jpg/jpeg and png files are allowed!"
        }
        else {
            let kb = values.image.size / 1024; // convert the file size into byte to kb
            let mb = kb / 1024; // convert kb to mb
            if (mb > 2) {
                errors.image = "images should be less than 2 MB"
            }
        } if (values?.image !== "" &&values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            setIsloader(true)
            console.log("image")
            console.log(values.image)

            let formData = new FormData();
            if (values.counsellor_profile_id) {
                formData.append("counsellor_profile_id", values.counsellor_profile_id ?? "");
            }
            formData.append("full_name", values.full_name);
            formData.append("email", values.email);
            formData.append("mobile_number", values.mobile_number);
            formData.append("image", values.image);
            formData.append("expert_in", JSON.stringify(experts));


            counsellorProfileService.addOrUpdateCounsellorProfile(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        question: response?.response?.data?.errors?.title,
                        category: response?.response?.data?.errors?.category,
                        university_course: response?.response?.data?.errors?.university_course,
                        price: response?.response?.data?.errors?.price,
                        about: response?.response?.data?.errors?.about,
                        requirements: response?.response?.data?.errors?.requirements,
                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        formik.setValues({
            counsellor_profile_id: item.id,
            full_name: item.full_name,
            email: item.email,
            mobile_number: item.mobile_number,
            image: await dataURLtoFile(item.images),
            image_url: item.images,
            expert_in: item.expertise.map(item => ({ value: item.counsellor_expertise, label: item.counsellor_expertise_name }))
        })
    }
    console.log(formik)
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Counsellor Profile</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Counsellor Profile</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Counsellor Profile") && <React.Fragment>
                        <CounsellorProfileCreate
                            formik={formik}
                            isloader={isloader}
                            toaster={toaster}
                            setToaster={setToaster}
                            formRef={formRef}
                            image={image}
                            setImage={setImage}
                            setExperts={setExperts}
                        />
                    </React.Fragment>}
                    <React.Fragment>
                        <CounsellorProfileDatable
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            totalRows={totalRows}
                            formik={formik}
                            pending={pending}
                            currentPage={currentPage}
                            setFilterText={setFilterText}
                        />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default CounsellorProfile;