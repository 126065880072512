import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import { AboutTeamService } from '../../services/aboutTeamService';
import CreateAboutteam from './CreateAboutteam';
import AboutDatatable from './aboutDatatable';
import { checkUserPermission } from '../../checkUserPermission';
const aboutteamservice = new AboutTeamService();
const AboutTeam = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [pending, setPending] = React.useState(true)
    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };
    async function getTeam() {
        setPending(true)
        const data = await aboutteamservice.getAllTeam(perpage, currentPage);
        setData(data.data)
        setPending(false)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])
    useEffect(() => {
        getTeam()
    }, [refresh, perpage, currentPage])
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    // const imageValidation = Yup
    //     .mixed()
    //     .required("Required (Max size. 2mb)")
    //     .test("size", "Image should be less than 2 MB", (value) => {
    //         return value && value.size <= 2000000;
    //     })
    //     .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
    //         return value && (
    //             value.type === "image/jpeg" ||
    //             value.type === "image/jpg" ||
    //             value.type === "image/png"
    //         );
    //     })

    const validationSchema = Yup.object().shape({
        // email: Yup.string()
        //     .required('Required'),
        full_name: Yup.string()
            .required('Required'),
        // mobile_number: Yup.string()
        //     .min(10, "Please enter a valid number")
        //     .max(10, "Please enter a valid number")
        //     .required("This field is required"),
        // image: imageValidation
    });

    const formik = useFormik({
        initialValues: {
            team_id: "",
            // email: "",
            full_name: "",
            // mobile_number: "",
            image: "",
            image_url: ""

        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            if (values?.image == "") {
                errors.image = "Required"
            }
            else if (values?.image !== "" && values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            } if (values?.image !== "" && values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            console.log(values);

            setIsloader(true)
            let formData = new FormData();
            if (values.team_id) {
                console.log("valuessss", values);
                formData.append("team_id", values.team_id ?? "");
            }
            // formData.append("email", values.email);
            formData.append("full_name", values.full_name);
            // formData.append("mobile_number", values.mobile_number);
            formData.append("image", values.image);


            aboutteamservice.addOrUpdateTeam(formData).then((response) => {
                // })
                // CountryCreateOrUpdate({ formData })((response) => {
                console.log(response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        // email: response?.response?.data?.errors?.email,
                        // mobile_number: response?.response?.data?.errors?.mobile_number,
                        full_name: response?.response?.data?.errors?.full_name,
                        image: response?.response?.data?.errors?.image,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        formik.setValues({

            team_id: item.id,
            // email: item.email,
            full_name: item.full_name,
            // mobile_number: item.mobile_number,
            image: await dataURLtoFile(item.image),
            image_url: item?.image
        })
        console.log("item", item);
    }

    console.log(formik)
    return (
        <div>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>About Team</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">About Team</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create About Team") && <React.Fragment>
                        <CreateAboutteam formik={formik} setImage={setImage} isloader={isloader} toaster={toaster} setToaster={setToaster} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <AboutDatatable pending={pending} data={data} currentPage={currentPage} setCurrentPage={setCurrentPage} totalRows={totalRows} handlePerRowsChange={handlePerRowsChange} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} />
                    </React.Fragment>

                </main>
            </>

        </div>


    )
}


export default AboutTeam


