import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { WebinarTypeService } from '../../services/WebinarTypeService';
import { CountryService } from '../../services/CountryServices';

const webinarTypeService=new WebinarTypeService();
const countryService=new CountryService();
const CreateWebinar=({ formik, isloader, formRef, imageError, setImage }) => {
    const [types, setTypes]=useState([])
    const [countries, setCountries]=useState([])
    useEffect(() => {
        webinarTypeService.getAllWebinarTypes().then((response) => {
            setTypes(response?.data?.results)
        })
        countryService.getAllCountry().then((response) => {
            setCountries(response?.data?.results)
        })
    }, [])
    const onChangeImage=(e) => {
        setImage(e.target.files[0])
        formik.setValues({
            ...formik.values,
            image: e.target.files[0],
            image_url: e.target.files[0]? URL.createObjectURL(e.target.files[0]):"",
        })
    }
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03" className='mb-2'>
                            <Form.Label>Webinar / Event Type</Form.Label>
                            <Form.Select
                                placeholder="Select an Option"
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="webinar_event_type"
                                value={formik.values?.webinar_event_type}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.webinar_event_type&&!formik.errors.webinar_event_type}
                                isInvalid={formik.touched.webinar_event_type&&formik.errors.webinar_event_type}
                                onChange={formik.handleChange}
                            >
                                <option value='' >Select Type</option>
                                {types?.map(type =>
                                    <option value={type?.id}>{type?.title}</option>
                                )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.webinar_event_type}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03" className='mb-2'>
                            <Form.Label>Type</Form.Label>
                            <Form.Select
                                placeholder="type"
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="type"
                                value={formik.values?.type}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.type&&!formik.errors.type}
                                isInvalid={formik.touched.type&&formik.errors.type}
                                onChange={formik.handleChange}
                            >
                                <option value=""> Webinar or Event</option>
                                <option value={true}>Webinar</option>
                                <option value={false}>Event</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.type}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={formik.values?.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.title&&!formik.errors.title}
                                isInvalid={formik.touched.title&&formik.errors.title}
                                placeholder="Title"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-2" controlId="validationCustom04">
                            <Form.Label>Meeting Link</Form.Label>
                            <Form.Control
                                type="text"
                                name="website"
                                value={formik.values?.website}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.website&&!formik.errors.website}
                                isInvalid={formik.touched.website&&formik.errors.website}
                                placeholder=" Eg:https://afresh.aventusinformatics.com"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.website}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Speaker</Form.Label>
                            <Form.Control
                                type="text"
                                name="speaker"
                                value={formik.values?.speaker}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.speaker&&!formik.errors.speaker}
                                isInvalid={formik.touched.speaker&&formik.errors.speaker}
                                placeholder="Speaker"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.speaker}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-2">
                            <Form.Label>Image<span className='required ms-1'>*</span></Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        onChangeImage(e)
                                    }
                                    }
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.image&&!formik.errors.image}
                                    isInvalid={formik.touched.image&&formik.errors.image}
                                />
                                {formik.values?.image_url&&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }

                                {/* { !formik.touched.image &&  !formik.values.image && <div style={{
                                        fontSize: "14px",
                                        color: "#198754",
                                        margin: "4px 0 0 4px",
                                        fontWeight:"500"
                                    }}>Max size. 2mb</div>} */}
                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                            </div>
                            <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 800 * 600 px)</p></div>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04" className='mb-2'>
                            <Form.Label>Designation</Form.Label>
                            <Form.Control
                                type="text"
                                name="designation"
                                value={formik.values?.designation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.designation&&!formik.errors.designation}
                                isInvalid={formik.touched.designation&&formik.errors.designation}
                                placeholder="Designation"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.designation}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03" className='mb-2'>
                            <Form.Label>Country</Form.Label>
                            <Form.Select
                                placeholder="Select an Option"
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="country"
                                value={formik.values?.country}
                                onBlur={formik.handleBlur}
                                // isValid={formik.touched.country && !formik.errors.country}
                                // isInvalid={formik.touched.country && formik.errors.country}
                                onChange={formik.handleChange}
                            >
                                <option value='' >Select a Country</option>
                                {countries?.map(country =>
                                    <option value={country?.id}>{country?.country_name}</option>
                                )}
                            </Form.Select>
                            {/* <Form.Control.Feedback type="invalid">{formik.errors.country}</Form.Control.Feedback> */}
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="start_date"
                                value={formik.values?.start_date}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.start_date&&!formik.errors.start_date}
                                isInvalid={formik.touched.start_date&&formik.errors.start_date}
                                placeholder="Start Date"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.start_date}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control
                                type="time"
                                name="time"
                                value={formik.values?.time}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.time&&!formik.errors.time}
                                isInvalid={formik.touched.time&&formik.errors.time}
                                placeholder="Time"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.time}</Form.Control.Feedback>
                        </Form.Group>

                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="end_date"
                                value={formik.values?.end_date}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.end_date&&!formik.errors.end_date}
                                isInvalid={formik.touched.end_date&&formik.errors.end_date}
                                placeholder="End Date"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.end_date}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>End Time</Form.Label>
                            <Form.Control
                                type="time"
                                name="end_time"
                                value={formik.values?.end_time}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.end_time&&!formik.errors.end_time}
                                isInvalid={formik.touched.end_time&&formik.errors.end_time}
                                placeholder="End ime"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.end_time}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                style={{ height: '110px' }}
                                name="description"
                                value={formik.values?.description}
                                onChange={(e) => {
                                    const inputValue=e.target.value;
                                    const charCount=inputValue.length;
                                    console.log(inputValue.split(/\s+/).length)
                                    // debugger
                                    if (inputValue.split(/\s+/).length<=1000) {
                                        formik.handleChange(e);
                                    } else {
                                        alert("Word Limit Exceeded")
                                    }

                                }}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.description&&!formik.errors.description}
                                isInvalid={formik.touched.description&&formik.errors.description}
                                placeholder="Description"
                            // maxLength={1000}

                            />
                            {formik.errors.description? <Form.Control.Feedback type="invalid" >{formik.errors.description}</Form.Control.Feedback>:
                                <div className="max-char">{formik.values.description.split(/\s+/).length+"/1000"}</div>

                            }
                        </Form.Group>
                    </Row>

                    <div className="group">
                        {isloader==true?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}
export default CreateWebinar;