import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { CounsellorExpertiseService } from '../../services/CounsellorExpertiseService';
import Select from 'react-select';


let options = [];
const CounsellorProfileCreate = ({ formik, isloader, formRef, setExperts }) => {
    const [update, setUpdate] = useState(false)
    const counsellorExpertiseService = new CounsellorExpertiseService();

    useEffect(() => {
        getExperts()
    }, [])

    async function getExperts() {
        const expertsData = await counsellorExpertiseService.getCounsellorExpertise();
        options = []
        expertsData.data?.results.forEach((item) => {
            options.push({ value: item.id, label: item.expert_in })
        })
        setUpdate(!update)
    }

    const handleOnSelectChange = ({ name, value }) => {
        console.log("value.value")
        console.log(value)
        formik.setValues({ ...formik.values, [name]: value })
        setExperts(value.map((val) => val.value))
    }

    const style = {
        control: (styles) => ({
            ...styles,
            borderColor: formik.errors.specialization ? "#dc3545" : "white",
            fontSize: "14px",
            height: "10px",

            "&:focus": {
                boxShadow: "#f5b7bd 0px 0px 0px 3px",
                borderColor: "#dc3545",
            },
        }),
    };

    console.log(options)

    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label>Full Name </Form.Label>
                            <Form.Control
                                type="text"
                                name="full_name"
                                value={formik.values?.full_name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.full_name && !formik.errors.full_name}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.full_name && formik.errors.full_name}
                                placeholder="Full Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.full_name}</Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Email *</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formik.values?.email}
                                onChange={formik.handleChange}
                                isValid={formik.touched.email && !formik.errors.email}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.email && formik.errors.email}
                                placeholder="Email"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                        </Form.Group> */}
                        {/* </Row>
                    <Row className="mb-2"> */}
                        {/* <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Phone Number *</Form.Label>
                            <Form.Control
                                type="text"
                                name="mobile_number"
                                value={formik.values?.mobile_number}
                                onChange={formik.handleChange}
                                isValid={formik.touched.mobile_number && !formik.errors.mobile_number}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.mobile_number && formik.errors.mobile_number}
                                placeholder="Phone Number"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.mobile_number}</Form.Control.Feedback>
                        </Form.Group> */}
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Image<span className='required ms-1'>*</span> </Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        formik.setValues({ ...formik.values, image: e.target.files[0], image_url: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "" })
                                    }
                                    }
                                    isValid={formik.touched.image && !formik.errors.image}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.image && formik.errors.image}
                                />
                                {/* {!formik.errors.image && !formik.values.image && <div style={{
                                    fontSize: "14px",
                                    color: "#198754",
                                    margin: "4px 0 0 4px",
                                    fontWeight: "500"
                                }}>Max size. 2mb</div>} */}
                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                                {formik.values?.image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }
                            </div>
                            <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 600 * 600 px)</p></div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Expert In </Form.Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isMulti
                                value={formik.values.expert_in}
                                styles={style}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.expert_in && !formik.errors.expert_in}
                                isInvalid={formik.touched.expert_in && formik.errors.expert_in}
                                name="expert_in"
                                options={options}
                                onChange={(e) => { handleOnSelectChange({ name: 'expert_in', value: e }) }}
                                placeholder="Select Expert Type..."
                            />
                            <div className="errorMessage text-danger mt-1">
                                {formik.errors.expert_in}
                            </div>
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader === true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CounsellorProfileCreate;