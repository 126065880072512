// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import CreateIntake from './createIntake';
import IntakeDatatable from './intakeDatatable';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
// import { CourseService } from '../../services/CourseService';
import { IntakeService } from '../../services/intakeService';
// import { StreamService } from '../../services/StreamService';

const intakeService = new IntakeService()


const Intake = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [pending, setPending] = React.useState(true)
    const [scroll, setScroll] = useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [active, setActive] = useState(true);

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [scroll])
  
    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };
        const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {

        console.log(`${row.name} was clicked!`);
    };



    async function getAllIntakes() {
        setPending(true)
        const data = await intakeService.getAllIntake(perpage,currentPage);
         setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        getAllIntakes()
     
    }, [refresh,perpage,currentPage])
    const validationSchema = Yup.object().shape({
        full_name: Yup.string()
            .required('Required'),
        course_data: Yup.string()
            .required('Required'),

        image: Yup.string()
            .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            instance_id: "",
            full_name: "",
            course_data: "",
            image: "",
            image_url: ""
        },
        validationSchema,
        validate: async (values) => {
            const errors ={}
            if(values?.image == ""){
                errors.image = "Required"
            }
            else if (values?.image !== "" &&values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            } if (values?.image !== "" &&values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                    errors.image = "Only jpg/jpeg and png files are allowed!"
                }
                else {
                    let kb = values.image.size / 1024; // convert the file size into byte to kb
                    let mb = kb / 1024; // convert kb to mb
                    if (mb > 2) {
                        errors.image = "images should be less than 2 MB"
                    }
                }
                return errors;
        },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.instance_id) {
                formData.append("instance_id", values.instance_id ?? "");
            }
            formData.append("full_name", values.full_name);
            formData.append("course_data", values.course_data);
            formData.append("image", values?.image);

            intakeService.addOrUpdateIntake(formData).then((response) => {
                // })
                // CreateOrUpdateCourse({ formData })((response) => {
                console.log(response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        full_name: response?.response?.data?.errors?.title,
                        course_data: response?.response?.data?.errors?.course_type,
                        image: response?.response?.data?.errors?.image,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });






    const handleEdit = async (item) => {
        setScroll(preVal=>!preVal)
        // setImage(await dataURLtoFile(item.image))
        formik.setValues({
            instance_id: item.id,
            full_name: item.full_name,
            image: await dataURLtoFile(item?.image),
            image_url: item?.image,
            course_data: item.course_data
        })

        console.log("item", item);
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Banner Intake</h1>
                         <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Intake</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        <CreateIntake image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>
                    <React.Fragment>
                        <IntakeDatatable
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            totalRows={totalRows}
                            formik={formik}
                            pending={pending}
                            setPerpage={setPerpage}
                            handleChange={handleChange}
                            handleRowClicked={handleRowClicked}
                         />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Intake;