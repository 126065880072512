import request from "../api/agent";

const API_ROUTE ="/users/groups";

export class GroupService {

    async getGroups(perpage,currentPage,search) {
        const serchValue = search??""
        if(perpage){
            const response = await request.get(`${API_ROUTE}/get-groups?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
            return response.data;
        }else {
            const response = await request.get(`${API_ROUTE}/get-groups`);
            return response.data;
        }
    }
    async getGroupRoles() {
            const response = await request.get(`users/groups/get-roles-for-group-creation`);
            return response.data;
    }


    async addOrUpdateGroup(group) {
        let action = `${API_ROUTE}/create-or-update-group`;
        const response = await request.post(action, group);
        return response;
    }


    async addOrUpdateGroup(group) {
        let action = `${API_ROUTE}/create-or-update-group`;
        const response = await request.post(action, group);
        return response;
    }

    // async addOrUpdateGroup(group) {
    //     let action = `${API_ROUTE}/get-roles-for-group-creation`;
    //     const response = await request.post(action, group);
    //     return response;
    // }

    async deleteGroup(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-group`, {data: { group: id }});
        return response;
    }


}