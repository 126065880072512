import request from "../api/agent";
const API_ROUTE = "courses/Courses";

export class CourseService {
    async addOrUpdateCourse(course) {
        let action = `${API_ROUTE}/create-or-update-courses`;
        const response = await request.post(action, course);
        return response;
    }

    async getCourses(page,limit,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-all-courses?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }
    async getAllCourses() {
        const response = await request.get(`${API_ROUTE}/get-all-courses`);
        return response.data;
    }
    async getAllCoursess(value) {
        console.log(value,"||||SEARCH")
        const searchValue = value ?? "";
        const response = await request.get(`${API_ROUTE}/v2/get-courses-dropdown-listing?search=${searchValue}`);
        return response.data;
    }

    async deleteCourse(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-courses`, {
            data: { id: id }
        });
        return response;
      }
      async setStatus(id) {
        const response = await request.post(`${API_ROUTE}/courses-status-change`, { id: id });
        return response;
    }

}