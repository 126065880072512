import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import loader from "../../../assets/images/loader.gif";
import { SpecializationService } from "../../services/SpecializationService";
import { UniversityTypeService } from "../../services/universityTypeService";
import AsyncSelect from "react-select/async";
import { CountryService } from "../../services/CountryServices";


const specializationService=new SpecializationService();
const universityTypeService=new UniversityTypeService();
const countryService=new CountryService();
const CreateUniversity=({
  formik,
  isloader,
  formRef,
  formView,
  setFormView,
  markAllFieldsAsTouched,
}) => {



 

  const deleteBtn={
    padding: "0.49rem 0.75rem",
    margin: "0 0 0 -2px",
    borderRadius: "0.25rem"
  }
  const addBtn={
    padding: "0.49rem 1.2rem",
    margin: "0 0 0 -2px",
    borderRadius: "0.25rem"
  }

  // async function getAllUniversityTypes() {
  //   const data = await universityTypeService.getAllUniversityType();
  //   setTypes(data?.data?.results);
  // }

  // async function getAllUniversityTypes() {
  //   const data=await universityTypeService.getUniversityTypes();
  //   setTypes(data?.data?.data);
  //   // console.log(data);
  // }
  // // console.log(types);

  // async function getAllSpecialization() {
  //   console.log("console data")
  //   // let list = [];
  //   const data=await specializationService.getSpecializations();
  //   // data?.data?.data?.map((specialization) => {
  //   //   list.push({ value: specialization?.id, label: specialization?.name });
  //   // });
  //   setSpecializations(data?.data?.data);
  //   console.log("data in specialization", data);
  // }


  // const addNewImage = () => {
  //   let university_image = formik.values?.university_images;
  //   university_image?.push({
  //     image: "",
  //     image_url: "",
  //   });
  //   formik.setValues({ ...formik.values, university_images: university_image });
  // };

  // const deleteImage = (index) => {
  //   let university_image = formik.values?.university_images;
  //   university_image?.splice(index, 1);
  //   formik.setValues({ ...formik.values, university_images: university_image });
  // };

  // const handleUniversityImage = (e, index) => {
  //   console.log("1234")
  //   let files = formik.values.university_images;
  //   files[index].image = e.target.files[0] ?  e.target.files[0] : "";
  //   files[index].image_url = e.target.files[0] !== undefined ? URL.createObjectURL(e.target.files[0]) : "";
  //   formik.setValues({
  //     ...formik.values,
  //     university_images: files,
  //   });
  // };

  const handleOnSelectChange=({ name, value }) => {
    formik.setValues({ ...formik.values, [name]: value.map((val) => val) });
  };

  const handleChangeFile=(e, name, url) => {
    formik.setValues({
      ...formik.values,
      [name]: e.target.files[0]? e.target.files[0]:"",
      [url]: e.target.files[0]!==undefined? URL.createObjectURL(e.target.files[0]):"",
    });
  };

  const style={
    control: (styles) => ({
      ...styles,
      borderColor: formik.errors.specialization? "#dc3545":"white",
      fontSize: "14px",
      height: "10px",
      "&:focus": {
        boxShadow: "#f5b7bd 0px 0px 0px 3px",
        borderColor: "#dc3545",
      },
    }),
  };
  const onchangeRanking=(e) => {
    if (e.target.value!=='') {
      if (e.target.value<=5&&e.target.value>0) {
        formik.handleChange(e)
      }
    } else {
      formik.handleChange(e)
    }
  }

  let requestTime;
    const requestOptions = async (values) => {
        clearTimeout(requestTime);
        return new Promise((resolve, reject) => {
          requestTime = setTimeout(async () => {
              try {
                const data = await countryService.getCountries(values);
                console.log(data,"|||||||")
                    if (data && data.status) {
                      resolve(data?.data);
                    }
                
              } catch (error) {
                console.error("Error fetching courses:", error);
                resolve([]);
              }
          }, 500);
        });
      };

      const handleCountryChange = (selectedOption) => {
        formik.setFieldValue('country', selectedOption.value);
        formik.setFieldValue('selectedCountry',selectedOption)
      };

      let requestUniversity;
      const requestUniversityOptions = async (values) => {
          clearTimeout(requestUniversity);
          return new Promise((resolve, reject) => {
            requestUniversity = setTimeout(async () => {
                try {
                  const data = await universityTypeService.getUniversityTypes(values);
                      if (data && data.status) {
                        resolve(data?.data?.data);
                      }
                  
                } catch (error) {
                  console.error("Error fetching courses:", error);
                  resolve([]);
                }
            }, 500);
          });
        };
  
        const handleuniversityChange = (selectedOption) => {
          formik.setFieldValue('universityType', selectedOption);
          formik.setFieldValue('type', selectedOption.value)
        };

        const handleSpecializionChange = (selectedOptions) => {
          const selectedValues = selectedOptions.map(option => option.value);
          const commaSeparatedValues = selectedValues.join(',');
          
          formik.setFieldValue('specializationValue', selectedOptions);
          formik.setFieldValue('specialization', commaSeparatedValues);
        };
        

      let requestSpecialization;
      const requestSpecializationOptions = async (values) => {
          clearTimeout(requestSpecialization);
          return new Promise((resolve, reject) => {
            requestSpecialization = setTimeout(async () => {
                try {
                  const data = await specializationService.getSpecializations(values);
                      if (data && data.status) {
                        resolve(data?.data);
                      }
                  
                } catch (error) {
                  console.error("Error fetching courses:", error);
                  resolve([]);
                }
            }, 500);
          });
        };

  console.log(formik,"|||||||||||FORMIK")
  return (
    <React.Fragment>
      <div className="form_sec">
        <Form ref={formRef} onSubmit={formik.handleSubmit}>
          {formView=="page1"&&(
            <Fragment>
              <Row className="mb-2">
                <Form.Group as={Col} md="6" controlId="validationCustom03" className='mb-2'>
                  <Form.Label>Country</Form.Label>

                  <AsyncSelect
                    placeholder="Search for Country"
                    className="select-dropdown"
                    classNamePrefix="select"
                    name="country"
                    value={formik.values?.selectedCountry}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.country&&!formik.errors.country}
                    isInvalid={formik.touched.country&&formik.errors.country}
                    onChange={handleCountryChange}
                    loadOptions={requestOptions}
                  />
                  {formik.touched.country&&<div className="feedback_errors">
                    {formik.errors.country}
                  </div>}
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03" className='mb-2'>
                  <Form.Label>Type</Form.Label>
                  <AsyncSelect 
                    placeholder="Search for University Type"
                    className="select-dropdown"
                    classNamePrefix="select"
                    name="type"
                    value={formik.values?.universityType}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.type&&!formik.errors.type}
                    isInvalid={formik.touched.type&&formik.errors.type}
                    onChange={handleuniversityChange}
                    loadOptions={requestUniversityOptions}
                  />
                  {formik.touched.type&&<div className="feedback_errors">
                    {formik.errors.type}
                  </div>}
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>University Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="university_name"
                    value={formik.values?.university_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.university_name&&!formik.errors.university_name}
                    isInvalid={formik.touched.university_name&&formik.errors.university_name}
                    placeholder="University Name"
                    maxLength={100}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.university_name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={formik.values?.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.address&&!formik.errors.address}
                    isInvalid={formik.touched.address&&formik.errors.address}
                    placeholder="Eg:Cambridge,England"
                    maxLength={100}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Specialization</Form.Label>
                  {/* <Select
                    // className="select_class"
                    classNamePrefix="select"
                    isMulti
                    value={formik.values?.specialization}
                    // styles={style}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.specialization&&!formik.errors.specialization}
                    isInvalid={formik.touched.specialization&&formik.errors.specialization}
                    // onChange={formik.handleChange}
                    name="courses"
                    options={specializations}
                    onChange={(e) => {
                      handleOnSelectChange({
                        name: "specialization",
                        value: e,
                      });
                    }}
                    placeholder="Select a Course..."
                  /> */}
                  <AsyncSelect
                    placeholder="Search for Course"
                    className="select-dropdown"
                    classNamePrefix="select"
                    name="specialization"
                    isMulti
                    value={formik.values?.specializationValue}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.specialization&&!formik.errors.specialization}
                    isInvalid={formik.touched.specialization&&formik.errors.specialization}
                    onChange={handleSpecializionChange}
                    loadOptions={requestSpecializationOptions}
                  />
                  {formik.touched.specialization&&<div className="feedback_errors">
                    {formik.errors.specialization}
                  </div>}
                </Form.Group>
                {/* <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    name="website"
                    value={formik.values?.website}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // isValid={formik.touched.website && !formik.errors.website}
                    isValid={formik.touched.website && !formik.errors.website}
                    isInvalid={formik.touched.website && formik.errors.website}
                    // isInvalid={formik.touched.specialization && formik.errors.specialization}
                    // isInvalid={formik.errors.website}
                    placeholder=" Eg:https://www.cam.ac.uk"
                    maxLength={100}
                  /> 
                  {/* <Form.Control.Feedback type="invalid">
                    {formik.errors.website}
                  </Form.Control.Feedback>
                </Form.Group> */}
                <Form.Group as={Col} md="3" controlId="validationCustom04">
                  <Form.Label>ESTD</Form.Label>
                  <Form.Control
                    type="number"
                    name="established_on"
                    value={formik.values?.established_on}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // isValid={formik.touched.established_on && !formik.errors.established_on}
                    // isInvalid={ formik.errors.established_on}
                    placeholder="ESTD"
                    maxLength={100}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.established_on}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom04">
                  <Form.Label>Rating</Form.Label>
                  <Form.Control
                    type="number"
                    name="university_ranking"
                    value={formik.values?.university_ranking}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      onchangeRanking(e)
                    }}
                    onBlur={formik.handleBlur}
                    // isValid={formik.touched.university_ranking && !formik.errors.university_ranking}
                    // isInvalid={formik.touched.university_ranking && formik.errors.university_ranking}
                    placeholder=" Rating (Out of 5)"
                    maxLength={100}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.university_ranking}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-2 d-flex">
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>University Video Url</Form.Label>
                  <Form.Control
                    type="text"
                    name="university_video_url"
                    value={formik.values?.university_video_url}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // isValid={formik.touched.university_video_url && !formik.errors.university_video_url}
                    isInvalid={formik.touched.university_video_url&&formik.errors.university_video_url}
                    placeholder="Youtube video url"
                    maxLength={100}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.university_video_url}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>University Video Details</Form.Label>
                  <Form.Control
                    type="text"
                    name="university_video_details"
                    value={formik.values?.university_video_details}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // isValid={formik.touched.university_video_details && !formik.errors.university_video_details}
                    // isInvalid={formik.touched.university_video_details && formik.errors.university_video_details}
                    placeholder="University Video Details"
                    maxLength={100}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.university_video_details}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>About</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="about"
                    value={formik.values?.about}
                    onChange={(e) => {
                      const inputValue=e.target.value;
                      const charCount=inputValue.length;
                      console.log(inputValue.split(/\s+/).length)
                      // debugger
                      if (inputValue.split(/\s+/).length<=1000) {
                        formik.handleChange(e);
                      } else {
                        alert("Word Limit Exceeded")
                      }

                    }}
                    // onBlur={formik.handleBlur}
                    // isValid={formik.touched.about && !formik.errors.about}
                    // isInvalid={formik.touched.about && formik.errors.about}
                    placeholder="About"
                    style={{ height: '100px' }}
                  // maxLength={1000}
                  />
                  {/* {formik.errors.about ? <Form.Control.Feedback type="invalid">{formik.errors.about}</Form.Control.Feedback> : <div className="max-char">{formik.values.about.length + "/1000"}</div>} */}
                  {formik.errors.about? <Form.Control.Feedback type="invalid" >{formik.errors.about}</Form.Control.Feedback>:
                    <div className="max-char">{formik.values.about.split(/\s+/).length+"/1000"}</div>

                  }
                </Form.Group>
              </Row>
              <div className="group">
                <Button
                  type="button"
                  onClick={() => { formik.values.country&&formik.values.university_name&&formik.values.address? setFormView("university_image"):markAllFieldsAsTouched() }}
                  className="save-btn me-4"
                >
                  Next
                </Button>
              </div>
            </Fragment>
          )}



          {formView=="university_image"&&(
            <Fragment>
              <Row className="mb-2">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="formFile"
                  className="mb-3"
                >
                  <Form.Label>Logo<span className='required ms-1'>*</span></Form.Label>
                  <div className="file_wrapper">
                    <Form.Control
                      type="file"
                      name="logo"
                      accept="image/*"
                      onChange={(e) => {
                        handleChangeFile(e, "logo", "logo_url");
                      }}
                      onBlur={formik.handleBlur}
                      // isValid={formik.touched.logo && !formik.errors.logo}
                      isInvalid={formik.touched.logo&&formik.errors.logo}
                    />
                    {formik.values?.logo_url&&(
                      <div className="img-wrapper ms-2">
                        <img
                          src={formik.values.logo_url}
                          alt="course"
                          style={{ height: "100px", width: "150px" }}
                          className=" mt-2"
                        />
                      </div>
                    )}
                    <Form.Control.Feedback type="invalid">{formik.errors.logo} </Form.Control.Feedback>
                    {/* {!formik.touched.logo && <div style={{
                      fontSize: "14px",
                      color: "#198754",
                      margin: "4px 0 0 4px",
                      fontWeight: "500"
                    }}>Max size. 2mb</div>} */}
                  </div>
                  <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 300 * 170 px)</p></div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="formFile"
                  className="mb-3"
                >
                  <Form.Label>Cover Image<span className='required ms-1'>*</span></Form.Label>
                  <div className="file_wrapper">
                    <Form.Control
                      type="file"
                      name="cover_image"
                      accept="image/*"
                      onChange={(e) => {
                        handleChangeFile(e, "cover_image", "cover_image_url");
                      }}
                      onBlur={formik.handleBlur}
                      // isValid={formik.touched.cover_image && !formik.errors.cover_image}
                      isInvalid={formik.touched.cover_image&&formik.errors.cover_image}
                    />
                    {formik.values?.cover_image_url&&(
                      <div className="img-wrapper ms-2">
                        <img
                          src={formik.values.cover_image_url}
                          alt="course"
                          style={{ height: "100px", width: "150px" }}
                          className=" mt-2"
                        />
                      </div>
                    )}
                    <Form.Control.Feedback type="invalid">{formik.errors.cover_image}</Form.Control.Feedback>
                    {/* {!formik.touched.cover_image && <div style={{
                      fontSize: "14px",
                      color: "#198754",
                      margin: "4px 0 0 4px",
                      fontWeight: "500"
                    }}>Max size. 2mb</div>} */}
                  </div>
                  <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 800 * 600 px)</p></div>
                </Form.Group>
              </Row>
              {/* <Row className="mb-2">
                {formik.values?.university_images?.map((img, index) => {
                  if (img === undefined) {
                    return;
                  }
                  const touched = formik.touched?.university_images && formik.touched?.university_images[index] && formik.touched?.university_images[index].image;
                  return <div>
                    <Form.Label>{index == 0 && "University images"}</Form.Label>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="formFile"
                      className="mb-1"
                      style={{ display: "flex", width: "600px" }}
                    >
                      <div style={{ width: "250px" }}>
                        <Form.Control
                          type="file"
                          name={`university_images[${index}].image`}
                          style={{
                            width: "100%",
                            borderRadius: "0.25rem 0 0 0.25rem"
                          }}
                          multiple
                          accept="image/*"
                          onChange={(e) => {
                            handleUniversityImage(e, index);
                          }}
                          isValid={touched && !(formik.errors.university_images && formik.errors.university_images[index] && formik.errors.university_images[index]?.image && !!formik.errors.university_images[index]?.image)}
                          onBlur={formik.handleBlur}
                          isInvalid={touched && formik.errors.university_images && formik.errors.university_images[index] && formik.errors.university_images[index]?.image && !!formik.errors.university_images[index]?.image}
                        />
                        {formik.values?.university_images[index].image_url && (
                          <div className="img-wrapper ms-2">
                            <img
                              src={formik.values.university_images[index].image_url}
                              alt="course"
                              style={{ height: "100px", width: "150px" }}
                              className=" mt-2"
                            />
                          </div>
                        )}
                        <div class="text-danger mt-2">
                          {touched && formik.errors.university_images && formik.errors.university_images[index] && formik.errors.university_images[index]?.image && formik.errors.university_images[index]?.image}
                        </div>
                        {/* {!touched && <div style={{
                          fontSize: "14px",
                          color: "#198754",
                          margin: "4px 0 0 4px",
                          fontWeight: "500"
                        }}>Max size. 2mb</div>} */}
              {/* </div>
                      <div className="d-flex">
                        {index ===
                          formik.values?.university_images?.length - 1 &&
                          <div>
                            <Button type="button" onClick={() => addNewImage()} style={addBtn} className="save-btn me-1" >Add</Button>
                          </div>}
                        <div>
                          {formik.values?.university_images?.length > 1 && <Button type="button" onClick={() => deleteImage(index)} style={deleteBtn} className='save-btn'>Delete</Button>}
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                })}
              </Row>  */}
              <div className="group">
                {isloader===true? (
                  <div className="loader">
                    <img src={loader} alt="" />
                  </div>
                ):<>
                  <Button type="button" className='save-btn me-4' onClick={() => setFormView('page1')}>Back</Button>
                  <Button type="submit" className="save-btn me-4" >
                    Save
                  </Button>
                </>
                }
              </div>
            </Fragment>
          )}
        </Form>
      </div>
    </React.Fragment >
  );
};

export default CreateUniversity;
