import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { Link } from 'react-router-dom';
import BasicSweetCallback from '../../SweetAlertCallback';
import { WebinarRegisterService } from '../../services/WebinarRegisterService';
import { checkUserPermission } from '../../checkUserPermission';

const webinarRegisterService = new WebinarRegisterService();;

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };






const WebinarRegisterDatatable = ({ data,totalRows,setTotalRows,setFilterText,refresh,currentPage, setRefresh, setPerpage, setCurrentPage, pending }) => {

    const columns = [
        {
            name: "Sl.No",
            key: "index",
            cell: (row, index) => ((currentPage*10)+ (index + 1))-10,
          },
        {
            name: 'Webinar',
            selector: row => row.webinar,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            name: 'Name',
            selector: row => row.full_name
            ,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            name: 'Email',
            selector: row => row.email
            ,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            name: 'Phone',
            selector: row => row.mobile_number
            ,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        // {
        //     name: 'Status',
        //     selector: row => <div class="form-check form-switch"  >
        //         <input onClick={() => {
        //             courseTypeService.updateUserStatus(row.id).then((response)=>{
        //                 console.log(response)
        //                 setRefresh(!refresh)
        //             })
        //         }} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_active} />
        //         <label class="form-check-label" for="flexSwitchCheckDefault"></label>
        //     </div>
        //     ,
        //     sortable: true,
        //     reorder: true,
        // },


        checkUserPermission("Edit Webinar Register") && {
            id: 'action',
            name: 'Actions',
            cell: (row) =>
                <div className='d-flex align-items-center'>
                    <BasicSweetCallback onDelete={webinarRegisterService.deleteWebinarRegister} deleteId={row.id} title="Webinar Register" setRefresh={setRefresh} refresh={refresh} />
                </div>,
           
        },
    ];

    const [selectedRows, setSelectedRows] = React.useState([]);
    const [toggleCleared, setToggleCleared] = React.useState(false);
    const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {

        console.log(`${row.name} was clicked!`);
    };


    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage);
        setRefresh(!refresh);
    };

    return (
        <React.Fragment>
            <div className='mt-5'>
                <Card style={{ height: '100%' }}>
                <div className="input-group">
                        <div className="form-outline searchEnquiry">
                            <input
                                type="search"
                                id="form1"
                                style={{border:"1px solid #000"}}
                                onChange={(e) => {
                                    setFilterText(e.target.value); setCurrentPage(1);
                                    setRefresh(!refresh);
                                }}
                                className="form-control "
                                placeholder="Search"
                            />
                        </div>
                    </div>
                    <DataTable
                        title="Webinar Register"
                        columns={columns}
                        data={data?.results}
                        defaultSortFieldId={1}
                        // selectableRows={selectableRows}
                        highlightOnHover
                        defaultSortField="name"
                        // actions={actions}
                        // contextActions={contextActions(deleteAll)}
                        sortIcon={sortIcon}
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                        onSelectedRowsChange={handleChange}
                        clearSelectedRows={toggleCleared}
                        onRowClicked={handleRowClicked}
                        onChangePage={(page) => {setCurrentPage(page);setRefresh(!refresh);}}
                        onChangeRowsPerPage={handlePerRowsChange}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        progressPending={pending}
                    // expandableRows={expandableRows}
                    />
                </Card>
            </div>
        </React.Fragment>
    )
}

export default WebinarRegisterDatatable;