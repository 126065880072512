import request from "../api/agent";

const API_ROUTE = "services/batch";

export class BatchService {
    async getBatch(perpage, currentPage,search) {
        const serchValue = search??""
        if(perpage){
            const response = await request.get(`${API_ROUTE}/get-all-batch?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
            return response.data;
        }else{
            const response = await request.get(`${API_ROUTE}/get-all-batch`);
            return response.data;
        }
    }

    async addOrUpdateBatch(scholarshipData) {
        let action = `${API_ROUTE}/create-or-update-batch`;
        const response = await request.post(action, scholarshipData);
        return response;
    }

    async deleteBatch(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-batch`, { data: { instance_id: [id] } });
        return response;
    }

    async setBatchStatus(id) {
        const response = await request.post(`${API_ROUTE}/batch-status`, { instance_id: id });
        return response;
    }
}