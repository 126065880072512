import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import BasicSweetCallback from '../../SweetAlertCallback';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
// import { checkUserPermission } from '../../checkPermission';
import { BlogEnquiryService } from '../../services/blogEnquiryservice';
import { checkUserPermission } from '../../checkUserPermission';



const blogenquiryService = new BlogEnquiryService();

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

const BlogEnquiryDatatable = ({ tableloader, pending,currentPage, setFilterText, filterText, data, handleEdit, refresh, setRefresh, handlePerRowsChange, setCurrentPage, totalRows, formik }) => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Edit
        </Tooltip>
    );
    const onChangeStatus = (id) => {

        blogenquiryService.enquiryStatus({ contact_id: id }).then((response) => {
            setRefresh(!refresh)
        })
    }
    const columns = [
        {
            name: "Sl.No",
            key: "index",
            cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10,
            grow: 2,
        },
        {
            name: 'blog',
            selector: row => <div className='text-truncate'>{row.blog_name}</div>,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            name: 'Email',
            selector: row => <div className='text-truncate'>{row.email}</div>,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            name: 'Full Name',
            selector: row => <div className='text-truncate'>{row.full_name}</div>,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            name: 'Phone',
            selector: row => <div className='text-truncate'>{row.mobile_number}</div>,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        // {
        //     name: 'Enquiry Date ',
        //     selector: row => <div className='text-truncate'>{row.created_date}</div>,
        //     sortable: true,
        //     grow: 2,
        //     reorder: true,
        // },


        // {
        //     name: 'Contacted',
        //     selector: row => <div class="form-check form-switch"  >
        //     <input onClick={() => onChangeStatus(row?.id)} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_contacted} />
        //     <label class="form-check-label" for="flexSwitchCheckDefault"></label>
        // </div>
        // ,
        //     sortable: true,
        //     grow: 2,
        //     reorder: true,
        // },
        checkUserPermission("Edit Blog Enquiry") && {
            id: 'action',
            name: 'Actions',
            cell: (row) =>
                <div className='d-flex align-items-center'>
                    <BasicSweetCallback onDelete={blogenquiryService.deleteEnquiry} deleteId={row.id} title="FAQ" setRefresh={setRefresh} refresh={refresh} formik={formik} />
                </div>,
            
            grow: 2,
        }

    ];

    return (
        <React.Fragment>
            <div className='mt-5'>
                <Card style={{ height: '100%' }}>
                    <div className="input-group">
                        <div className="form-outline searchEnquiry">
                            <input
                                type="search"
                                id="form1"
                                style={{border:"1px solid #000"}}
                                onChange={(e) => setFilterText(e.target.value)}
                                className="form-control"
                                placeholder="Search"
                            />
                        </div>
                    </div>
                    <DataTable
                        title="Blog Enquiry"
                        columns={columns}
                        data={data}
                        defaultSortFieldId={1}
                        highlightOnHover
                        defaultSortField="name"
                        sortIcon={sortIcon}
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                        onChangePage={(page) => setCurrentPage(page)}
                        onChangeRowsPerPage={handlePerRowsChange}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        progressPending={pending}
                    />
                </Card>
            </div>
        </React.Fragment>
    )
}

export default BlogEnquiryDatatable;
