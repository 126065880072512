import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import BasicSweetCallback from '../../SweetAlertCallback';

import { ContactService } from '../../services/contactservice';
import { checkUserPermission } from '../../checkUserPermission';




const contactservice = new ContactService();
const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
const ContactusDatatable = ({ refresh, pending, setRefresh, setFilterText, currentPage, handlePerRowsChange, totalRows, setCurrentPage, data, handleEdit }) => {


    const columns = [
        {
            name: "Sl.No",
            key: "index",
            cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10,
            // width: "150px",
        },

        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            reorder: true,
            // width: "150px",
        },

        {
            name: 'Email',
            selector: row => row?.email,
            cell: (row) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.email}>
                    {row.email}
                </div>,
            sortable: true,
            reorder: true,
            // width: "150px",
        },
        {
            name: 'Phone Number ',
            selector: row => row.phone_number,
            sortable: true,
            reorder: true,
            // width: "150px",
        },
        {
            name: 'Description ',
            cell: (row) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.description}>
                    {row.description}
                </div>,
            selector: row => row.description,
            sortable: true,
            reorder: true,
            // width: "150px",
        },
        checkUserPermission("Delete Contact Us") && {
            id: 'action',
            name: 'Actions',
            cell: (row) =>
                <div className='d-flex align-items-center'>
                    <BasicSweetCallback onDelete={contactservice.deleteContact} deleteId={row.id} title="Contactus" setRefresh={setRefresh} refresh={refresh} />
                </div>,
           
            // width: "150px",
        },
    ];

    const [selectedRows, setSelectedRows] = React.useState([]);
    const [toggleCleared, setToggleCleared] = React.useState(false);
    // const [data, setData] = React.useState([]);
    const handleChange = () => { setSelectedRows(selectedRows); };


    return (
        <div>
            <React.Fragment>
                <div className='mt-5'>
                    <Card style={{ height: '100%' }}>
                        <div className="input-group">
                            <div className="form-outline searchEnquiry">
                                <input
                                    type="search"
                                    id="form1"
                                    style={{ border: "1px solid #000" }}
                                    onChange={(e) => {
                                        setFilterText(e.target.value); setCurrentPage(1);
                                        setRefresh(!refresh);
                                    }}
                                    className="form-control"
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                        <DataTable
                            title="Contact Us"
                            columns={columns}
                            data={data?.results}
                            defaultSortFieldId={1}
                            // selectableRows={selectableRows}
                            highlightOnHover
                            defaultSortField="name"
                            // actions={actions}
                            // contextActions={contextActions(deleteAll)}
                            sortIcon={sortIcon}
                            selectableRowsComponent={Checkbox}
                            selectableRowsComponentProps={selectProps}
                            onSelectedRowsChange={handleChange}
                            clearSelectedRows={toggleCleared}
                            onChangePage={(page) => { setCurrentPage(page); setRefresh(!refresh) }}
                            onChangeRowsPerPage={handlePerRowsChange}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            progressPending={pending}
                        // expandableRows={expandableRows}
                        />
                    </Card>
                </div>
            </React.Fragment>

        </div>
    )
}

export default ContactusDatatable;
