// ** Third Party Components
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from 'react-router-dom'
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const MySwal = withReactContent(Swal)

const BasicSweetCallback = ({ onDelete, deleteId,setActive, title, setRefresh, formik = null }) => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Delete
        </Tooltip>
      );
    const handleConfirmCancel = () => {
        return MySwal.fire({
            title: `Are you sure you want to remove this ${title}?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ms-1'
            },
            buttonsStyling: false
        }).then(function (result) {
            if (result.value) {
                onDelete(deleteId).then((response) => {
                    if (response.status) {
                        setRefresh((prevRef) => !prevRef);
                        formik && formik.resetForm()
                        MySwal.fire({
                            icon: 'success',
                            title: 'Deleted',
                            text: response.message,
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                            .catch((response) => {
                                console.error(`Delete operation failed: ${response.message}`);
                            });
                    }
                })


            } else if (result.dismiss === MySwal.DismissReason.cancel) {
                MySwal.fire({
                    title: 'Cancelled',                    
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            }
        })
    }

    return (

        <Link className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" onClick={handleConfirmCancel}>
            <span className="svg-icon">
            <OverlayTrigger placement="top"  delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                    <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                    <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                </svg>
                </OverlayTrigger>
            </span>
        </Link>

    )
}

export default BasicSweetCallback
