// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import CreateCourse from './createCourse';
import CourseDatatable from './courseDatatable';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import { CourseService } from '../../services/CourseService';
import { CourseTypeService } from '../../services/CourseTypeService';
import { StreamService } from '../../services/StreamService';
import { checkUserPermission } from '../../checkUserPermission';

const courseService = new CourseService()


const Courses = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [pending, setPending] = useState(true);
    const [courseType, setCourseType] = useState([]);
    const [streams, setStreams] = useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = React.useState(0);

    async function getAllCourses() {
        setPending(true)
        const data = await courseService.getCourses(currentPage, perpage, filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setPending(false);
    }
    useEffect(() => {
        getAllCourses()
    }, [refresh])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])


    // const imageValidation = Yup
    //     .mixed()
    //     .required("Required (Max size. 2mb)")
    //     .test("size", "Image should be less than 2 MB", (value) => {
    //         return value && value.size <= 2000000;
    //     })
    //     .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
    //         return value && (
    //             value.type === "image/jpeg" ||
    //             value.type === "image/jpg" ||
    //             value.type === "image/png"
    //         );
    //     })

    const validationSchema = Yup.object().shape({
        course_name: Yup.string()
            .required('Required'),
            course_type: Yup.string()
            .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            course_name: "",
            course_type: "",         
            description: "",
            image: "",
            image_url: "",
            course_nature:""
        },
        validationSchema,
        validate: async (values) => {
            const errors = {};
            if (
              values.image !== "" &&
              values.image.type !== "image/png" &&
              values.image.type !== "image/jpg" &&
              values.image.type !== "image/jpeg"
            ) {
              errors.image = "Only jpg/jpeg and png files are allowed!";

            } else {
              const kb = values.image.size / 1024; // convert the file size to KB
              const mb = kb / 1024; // convert KB to MB
              if (mb > 2) {
                errors.image = "Images should be less than 2 MB";
              }
            }
          console.log("valoidation images are here",errors)
          console.log("valoidation images are here",errors.image)

            return errors;
          },

        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                
                formData.append("id", values.id ?? "");
            }
            formData.append("course_name", values.course_name);
            formData.append("course_type", values.course_type);
            formData.append("description", values.description);
            formData.append("image", values?.image);
            formData.append("course_nature",values?.course_nature);

            courseService.addOrUpdateCourse(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        course_name: response?.response?.data?.errors?.non_field_errors,
                        course_type: response?.response?.data?.errors?.course_type,
                        image: response?.response?.data?.errors?.image,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error(`${response?.response?.data?.errors?.non_field_errors}`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        console.log("itemmmmmmmm", item);
        setScroll(preVal => !preVal)
        // setImage(await dataURLtoFile(item.image))
        formik.setValues({
            id: item.id,
            course_name: item.course_name,
            image: await dataURLtoFile(item?.image),
            image_url: item?.image,
            course_type: item.course_type_id,
            description: item.description,
            course_nature:item.course_nature == "Part Time" ? 1 : item.course_nature == "Full Time" ? 2 :'' ,
        })

    }
    console.log("formik errors are here",formik.errors)
console.log(formik.errors);
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Course</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Course</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Course") && <React.Fragment>
                        <CreateCourse image={image} setCourseType={setCourseType} setStreams={setStreams} courseType={courseType} streams={streams} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <CourseDatatable totalRows={totalRows} pending={pending} setFilterText={setFilterText} currentPage={currentPage} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} setPerpage={setPerpage} setCurrentPage={setCurrentPage} formik={formik} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Courses;