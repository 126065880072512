import request from "../api/agent";

const API_ROUTE = "university/course_syllabus";



export class CourseSyllabusService {

    async getCourseSyllabus(perpage,currentPage) {
        const response = await request.get(`${API_ROUTE}/get-course-syllabus?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }


    async addOrUpdateCourseSyllabus(CourseSyllabus) {
        let action = `${API_ROUTE}/create-or-update-course-syllabus`;
        const response = await request.post(action, CourseSyllabus);
        return response;
    }

    async deleteCourseSyllabus(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-course-syllabus`, {data: { id: id}});
        return response;
    }
    async deleteCourseSyllabusPoints(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-course-syllabus-points`, {data: { id: [id] }});
        return response;
    }


}