import request from "../api/agent";

const API_ROUTE = "scholarships/scholarship";

export class ScholarshipCategoryService {

    async getScholarshipCategory(perpage, currentPage,search) {
        if(perpage){
            const serchValue = search??""
            const response = await request.get(`${API_ROUTE}/get-all-category?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
            return response.data;      
        }else{
            const response = await request.get(`${API_ROUTE}/get-all-category`);
            return response.data;
        }
    }

    async getSchoplarshipCategoryChoice() {
        const response = await request.get(`${API_ROUTE}/get-all-category-choice`);
        return response;
    }

    async addOrUpdateScholarshipCategory(scholarshipData) {
        let action = `${API_ROUTE}/create-or-update-category`;
        const response = await request.post(action, scholarshipData);
        return response;
    }

    async deleteScholarshipCategory(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-category`, { data: { ids: [id]  } });
        return response;
    }

    async setSchoplarshipCategoryStatus(id) {
        const response = await request.put(`${API_ROUTE}/category-status-change`, { id: id });
        return response;
    }

}