import request from "../api/agent";
const API_ROUTE = "webinar/webinar_register";

export class WebinarRegisterService {
   

    async getWebinarRegisters(page, limit,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/webinar-register-all-listing?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }
    


    async deleteWebinarRegister(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-webinar-register`, {
            data: { id: id }
        });
        return response;
    }
   
}