import request from "../api/agent";


export class PermissionService {
   

    async getAllPermissions() {
        const response = await request.get(`users/get-permissions`);
        return response.data;
    }


   
}