import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';

const Creategallery = ({ formik, setSlotValues, formRef, gallery, setGallery, deleteImageOne, deleteImageTwo, deleteImageThree, deleteImageFour, deleteImageFive }) => {


    const onChangeImage = (e, name, image_name) => {
        const selectedImages = e.target.files;
        const imageUrls = [];
        const slotValues = [];

        for (let i = 0; i < selectedImages.length; i++) {
            imageUrls.push(URL.createObjectURL(selectedImages[i]));
            slotValues.push(selectedImages[i]);
        }

        setSlotValues(slotValues);

        formik.setValues({
            ...formik.values,
            [name]: slotValues,
            [image_name]: imageUrls,
        });
    };





    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                                <Form.Label>Slot One</Form.Label>
                                <div className="file_wrapper">
                                    <Form.Control
                                        type="file"
                                        name="slot_one"
                                        accept="image/*"
                                        onChange={(e) => onChangeImage(e, 'slot_one', 'image_one')}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.slot_one && !formik.errors.slot_one}
                                        // isInvalid={formik.touched.slot_one && formik.errors.slot_one}
                                        multiple
                                    />

                                    <div className='view_image_wrapper'>
                                        {formik.values?.image_one && formik.values.image_one.map((url, index) => (
                                            <div key={index} className='img-wrapper'>
                                                <img src={url} alt={`img-${index}`} className='img-fluid mt-2' />
                                            </div>
                                        ))}

                                    </div>
                                    <div><p className='size'>(Dimensions:	240 * 250 px)</p></div>
                                    
                                    <div style={{ color: 'red' }}>{formik?.touched?.slot_one && formik?.errors?.slot_one && formik?.errors?.slot_one}</div>
                                    {/* <Form.Control.Feedback >
                                    
                                        
                                    </Form.Control.Feedback> */}

                                    {gallery?.results?.map((galleryimg, index) => (
                                        <div key={index} className='img-wrapper'>
                                            <div className="view_image_wrapper">
                                                {galleryimg?.slot_one_image.map((slot, slotIndex) => (
                                                    <div key={slotIndex} className='image-item'>
                                                        <img src={slot?.image} alt='' className='img-fluid mt-4' />
                                                        <MdClose className="close_icon" onClick={() => deleteImageOne([slot.id])} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                                <Form.Label>Slot Two</Form.Label>
                                <div className="file_wrapper">
                                    <Form.Control
                                        type="file"
                                        name="slot_two"
                                        accept="image/*"
                                        onChange={(e) => onChangeImage(e, 'slot_two', 'image_two')}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.slot_two && !formik.errors.slot_two}
                                        // isInvalid={formik.touched.slot_two && formik.errors.slot_two}
                                        multiple
                                    />
                                    <div className='view_image_wrapper'>
                                        {formik.values?.image_two && formik.values.image_two.map((url, index) => (
                                            <div key={index} className='img-wrapper'>
                                                <img src={url} alt={`img-${index}`} style={{ height: '50px' }} className='img-fluid mt-2' />
                                            </div>
                                        ))}
                                    </div>

                                    <div><p className='size'>(Dimensions:	175 * 250 px)</p></div>

                                    {/* <Form.Control.Feedback type="invalid">
                                        {formik.touched.slot_two && formik.errors.slot_two && formik.errors.slot_two}
                                    </Form.Control.Feedback> */}

                                    <div style={{ color: 'red' }}>{formik?.touched?.slot_two && formik?.errors?.slot_two && formik?.errors?.slot_two}</div>



                                    {gallery?.results?.map((galleryimg, index) => (
                                        <div key={index} className='img-wrapper'>
                                            <div className="view_image_wrapper">
                                                {galleryimg?.slot_two_image.map((slot, slotIndex) => (
                                                    <div key={slotIndex} className='image-item'>
                                                        <img src={slot?.image} alt='' className='img-fluid mt-4' />
                                                        <MdClose className="close_icon" onClick={() => deleteImageTwo([slot.id])} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                                <Form.Label>Slot Three</Form.Label>
                                <div className="file_wrapper">
                                    <Form.Control
                                        type="file"
                                        name="slot_three"
                                        accept="image/*"
                                        onChange={(e) => onChangeImage(e, 'slot_three', 'image_three')}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.slot_three && !formik.errors.slot_three}
                                        // isInvalid={formik.touched.slot_three && formik.errors.slot_three}
                                        multiple
                                    />
                                    <div className='view_image_wrapper'>
                                        {formik.values?.image_three && formik.values.image_three.map((url, index) => (
                                            <div key={index} className='img-wrapper'>
                                                <img src={url} alt={`img-${index}`} style={{ height: '50px' }} className='img-fluid mt-2' />
                                            </div>
                                        ))}
                                    </div>

                                    <div><p className='size'>(Dimensions:	310 * 250 px)</p></div>

                                    {/* <Form.Control.Feedback type="invalid">
                                        {formik.touched.slot_three && formik.errors.slot_three && formik.errors.slot_three}
                                    </Form.Control.Feedback> */}

                                    <div style={{ color: 'red' }}>{formik?.touched?.slot_three && formik?.errors?.slot_three && formik?.errors?.slot_three}</div>

                                    {gallery?.results?.map((galleryimg, index) => (
                                        <div key={index} className='img-wrapper'>
                                            <div className="view_image_wrapper">
                                                {galleryimg?.slot_three_image.map((slot, slotIndex) => (
                                                    <div key={slotIndex} className='image-item'>
                                                        <img src={slot?.image} alt='' className='img-fluid mt-4' />
                                                        <MdClose className="close_icon" onClick={() => deleteImageThree([slot.id])} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                                <Form.Label>Slot Four</Form.Label>
                                <div className="file_wrapper">
                                    <Form.Control
                                        type="file"
                                        name="slot_four"
                                        accept="image/*"
                                        onChange={(e) => onChangeImage(e, 'slot_four', 'image_four')}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.slot_four && !formik.errors.slot_four}
                                        // isInvalid={formik.touched.slot_four && formik.errors.slot_four}
                                        multiple
                                    />
                                    <div className='view_image_wrapper'>
                                        {formik.values?.image_four && formik.values.image_four.map((url, index) => (
                                            <div key={index} className='img-wrapper'>
                                                <img src={url} alt={`img-${index}`} style={{ height: '50px' }} className='img-fluid mt-2' />
                                            </div>
                                        ))}
                                    </div>

                                    <div><p className='size'>(Dimensions:	240 * 260 px)</p></div>

                                    {/* <Form.Control.Feedback type="invalid">
                                        {formik.touched.slot_four && formik.errors.slot_four && formik.errors.slot_four}
                                    </Form.Control.Feedback> */}

                                    <div style={{ color: 'red' }}>{formik?.touched?.slot_four && formik?.errors?.slot_four && formik?.errors?.slot_four}</div>

                                    {gallery?.results?.map((galleryimg, index) => (
                                        <div key={index} className='img-wrapper'>
                                            <div className="view_image_wrapper">
                                                {galleryimg?.slot_four_image.map((slot, slotIndex) => (
                                                    <div key={slotIndex} className='image-item'>
                                                        <img src={slot?.image} alt='' className='img-fluid mt-4' />
                                                        <MdClose className="close_icon" onClick={() => deleteImageFour([slot.id])} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                                <Form.Label>Slot Five</Form.Label>
                                <div className="file_wrapper">
                                    <Form.Control
                                        type="file"
                                        name="slot_five"
                                        accept="image/*"
                                        onChange={(e) => onChangeImage(e, 'slot_five', 'image_five')}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.slot_five && !formik.errors.slot_five}
                                        // isInvalid={formik.touched.slot_five && formik.errors.slot_five}
                                        multiple
                                    />
                                    <div className='view_image_wrapper'>
                                        {formik.values?.image_five && formik.values.image_five.map((url, index) => (
                                            <div key={index} className='img-wrapper'>
                                                <img src={url} alt={`img-${index}`} style={{ height: '50px' }} className='img-fluid mt-2' />
                                            </div>
                                        ))}
                                    </div>

                                    <div><p className='size'>(Dimensions:	560 * 350 px)</p></div>

                                    {/* <Form.Control.Feedback type="invalid">
                                        {formik.touched.slot_five && formik.errors.slot_five && formik.errors.slot_five}
                                    </Form.Control.Feedback> */}
                                    <div style={{ color: 'red' }}>{formik?.touched?.slot_five && formik?.errors?.slot_five && formik?.errors?.slot_five}</div>


                                    {gallery?.results?.map((galleryimg, index) => (
                                        <div key={index} className='img-wrapper'>
                                            <div className="view_image_wrapper">
                                                {galleryimg?.slot_five_image.map((slot, slotIndex) => (
                                                    <div key={slotIndex} className='image-item'>
                                                        <img src={slot?.image} alt='' className='img-fluid mt-4' />
                                                        <MdClose className="close_icon" onClick={() => deleteImageFive([slot.id])} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>
                        </Row>
                        <div className="group">
                            <button type="submit" className="save-btn me-4 btn btn-primary">Save</button>
                        </div>
                    </>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default Creategallery;