import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { UpcomingBatchService } from '../../services/UpcomingBatch';
import UpcomingBatchDatatable from './UpcomingBatchDatatable';
import UpcomingBatchCreate from './UpcomingBatchCreate';
import moment from 'moment';
import { checkUserPermission } from '../../checkUserPermission';
import { dataURLtoFile } from '../../functions';

const UpcomingBatch = () => {

    const upcomingBatchService = new UpcomingBatchService();

    const formRef = useRef(null);
    const [image, setImage] = useState();
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [filterText, setFilterText] = React.useState('');
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState(true);

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage);
        setRefresh(!refresh);
    };

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    async function getAllBatch() {
        setLoading(true)
        const data = await upcomingBatchService.getUpcomingBatch(perpage, currentPage, filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setLoading(false)
    }

    useEffect(() => {
        getAllBatch()

    }, [refresh])

    const imageValidation = Yup
        .mixed()
        .required("Required (Max size. 2mb)")
        .test("size", "Image should be less than 2 MB", (value) => {
            return value && value.size <= 2000000;
        })
        .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
            return value && (
                value.type === "image/jpeg" ||
                value.type === "image/jpg" ||
                value.type === "image/png"
            );
        })

    const validationSchema = Yup.object().shape({
        batch: Yup.string().required('Required'),
        time: Yup.string().required('Required'),
        start_date: Yup.string().required('Required'),
        duration: Yup.string().required('Required'),
        amount: Yup.number().required('Required'),
        taught_by: Yup.string().required('Required'),
        image: imageValidation
    });

    const formik = useFormik({
        initialValues: {
            instance_id: "",
            batch: "",
            time: "",
            start_date: "",
            duration: "",
            amount: "",
            taught_by: "",
            image: "",
            image_url: "",
            is_online: false,
        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            if (values?.image == "") {
                errors.image = "Required"
            }
            else if (values?.image !== "" && values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            } if (values?.image !== "" && values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            console.log("valz", values);
            setIsloader(true)
            let formData = new FormData();
            if (values.instance_id) {
                formData.append("instance_id", values.instance_id ?? "");
            }
            formData.append("batch", values.batch);
            formData.append("time", values.time);
            formData.append("start_date", values?.start_date);
            formData.append("duration", values.duration);
            formData.append("amount", values.amount);
            formData.append("taught_by", values?.taught_by);
            formData.append("image", values?.image);

            formData.append("is_online", values.is_online ? "true" : "false");
            upcomingBatchService.addOrUpdateUpcomingBatch(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        question: response?.response?.data?.errors?.title,
                        category: response?.response?.data?.errors?.category,
                        university_course: response?.response?.data?.errors?.university_course,
                        price: response?.response?.data?.errors?.price,
                        about: response?.response?.data?.errors?.about,
                        requirements: response?.response?.data?.errors?.requirements,
                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        const date = moment(item?.start_date).format('YYYY-MM-DD')

        formik.setValues({
            instance_id: item.id,
            batch: item.batch,
            time: item.time,
            start_date: date,
            duration: item.duration,
            amount: item.amount,
            taught_by: item.taught_by,
            image: item.image ? await dataURLtoFile(item.image) : '',
            image_url: item?.image,
            is_online: item.is_online,
        })
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Upcoming Batch</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Upcoming Batch</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Upcomming Batch") && <React.Fragment>
                        <UpcomingBatchCreate
                            formik={formik}
                            isloader={isloader}
                            toaster={toaster}
                            setToaster={setToaster}
                            formRef={formRef}
                            image={image}
                            setImage={setImage}
                        />
                    </React.Fragment>}
                    <React.Fragment>
                        <UpcomingBatchDatatable
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            totalRows={totalRows}
                            formik={formik}
                            loading={loading}
                            currentPage={currentPage}
                            setFilterText={setFilterText}

                        />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default UpcomingBatch;