import request from "../api/agent";
const API_ROUTE = "courses/Course_type";

export class CourseTypeService {
    async addOrUpdateCourseType(course) {
        let action = `${API_ROUTE}/create-or-update-course-type`;
        const response = await request.post(action, course);
        return response;
    }

    async getCourseTypePagination(page, limit,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-all-course-type?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }
    async getAllCourseType() {
        const response = await request.get(`${API_ROUTE}/get-all-course-type`);
        return response.data;
    }


    async deleteCourseType(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-course-type`, {
            data: { id: id }
        });
        return response;
    }
    async setStatus(id) {
        const response = await request.post(`${API_ROUTE}/course-type-status-change`, { id: id });
        return response;
    }

}