import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';





const CreateWebinarType = ({ formik, isloader, toaster, setToaster, setImage, image, formRef, imageError }) => {




    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={formik.values?.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.title && !formik.errors.title}
                                isInvalid={formik.touched.title && formik.errors.title}
                                placeholder="Title"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                style={{ height: '110px' }}
                                value={formik.values?.description}
                                onChange={(e) => {
                                            const inputValue=e.target.value;
                                            const charCount=inputValue.length;
                                            console.log(inputValue.split(/\s+/).length)
                                            // debugger
                                            if (inputValue.split(/\s+/).length<=1000) {
                                                formik.handleChange(e);
                                            } else {
                                                alert("Word Limit Exceeded")
                                            }

                                        }}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.description && !formik.errors.description}
                                isInvalid={formik.touched.description && formik.errors.description}
                                placeholder="Description"
                                // maxLength={1000}
                            />
                            {formik.errors.description? <Form.Control.Feedback type="invalid" >{formik.errors.description}</Form.Control.Feedback>:
                                        <div className="max-char">{formik.values.description.split(/\s+/).length+"/1000"}</div>

                                    }
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateWebinarType;