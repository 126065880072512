import { Formik, useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import loader from "../../assets/images/loader.gif";
import { ToastContainer, toast } from "react-toastify";
import { BsFillCCircleFill } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";
import logo from "../../assets/images/afresh-logo.png";
import design from "../../assets/images/design.png";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { makeStyles } from '@material-ui/core';

import { Button, Col, Form, Row } from "react-bootstrap";

import { AuthService } from '../services/AuthService';



const authService = new AuthService();
const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
  },
});

const Reset_Password = () => {
  const classes = useStyles();

  const [isloader, setIsloader] = useState(false);
  const { uid, token } = useParams();

  console.log(uid, token);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswor, setShowPasswor] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const togglePasswordVisibilit = () => {
    setShowPasswor(!showPasswor)
  }
  const navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    new_password: Yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters,1 Uppercase,1 Lowercase,1 Number and 1 Special Character"
    ),
    confirm_password: Yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters,1 Uppercase,1 Lowercase,1 Number and 1 Special Character"
    ),
    // new_password: Yup.string().required("Required"),
    // confirm_password: Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      //   user_instance:"",     
      new_password: "",
      confirm_password: "",
    },
    validationSchema,
    validate: async (values) => {
      const errors = {}

      if(values?.confirm_password == ""){
        errors.confirm_password = "Required"
      }
      else if (values?.new_password != values?.confirm_password) {
        errors.confirm_password = "Password Mismatch Please try again"
      }
      return errors
    },
    onSubmit: (values) => {
      console.log(values);

      setIsloader(true)
      let formData = new FormData();
      let user = JSON.parse(localStorage.getItem('user'))

      // formData.append("user",values.user);
      //   formData.append("old_password", values.old_password);
      formData.append("new_password", values.new_password);
      formData.append("confirm_password", values?.confirm_password);
      formData.append("uid", uid);
      formData.append("token", token);
      authService.reset(formData).then((response) => {
        if (response && response.status) {
          setIsloader(false);
          toast.success("Password Changed Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate('/login', { state: { change_password: true } })
          // return()

        } else {
          formik.setErrors({
            ...formik.errors,
            // old_password: response?.data?.data?.errors?.old_password && response?.data?.data?.errors?.old_password[0] ? response?.data?.data?.errors?.old_password[0] : '',
            new_password: response?.data?.data?.errors?.new_password && response?.data?.data?.errors?.new_password[0] ? response?.data?.data?.errors?.new_password[0] : '',
            confirm_password: response?.data?.data?.errors?.confirm_password && response?.data?.data?.errors?.confirm_password[0] ? response?.data?.data?.errors?.confirm_password[0] : '',
          })

          setIsloader(false);
          toast.error(
            response?.response?.data?.message
              ? response?.response?.data?.message
              : "Something went wrong",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      });
    },
  });
  console.log(formik);
  return (
    <div className='container-fluid'>
      <div className='row'>

        <div className="col-lg-6 col-md-6 col-sm-6 col-12 image">
          <img src={design} alt="Login image" className="img-fluid imgs" />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-6 col-12 login-forms'>
          <div className='login_left_wrapper'>
            <div className=''>
              <img src={logo} className='img-fluid logo-login ' />
            </div>
            <ToastContainer />

            <div className="reset_text">Reset Password</div>
           
            <Form onSubmit={formik.handleSubmit}>
              <Row className="mb-2">
                <Form.Group
                  as={Col}
                  md="8"
                  controlId="formFile"
                  className="mb-3"
                >
                  {/* <Form.Label>New Password</Form.Label> */}
                  <Form.Control
                    className={`usrname ${classes.root}`}
                    type={showPassword ? 'input' : 'password'}
                    name="new_password"
                    value={formik.values?.new_password}
                    onChange={formik.handleChange}
                    // isValid={
                    //   formik.touched.new_password && !formik.errors.new_password
                    // }
                    // isInvalid={!!formik.errors.new_password}
                    isValid={
                      formik.touched.new_password &&
                      !formik.errors.new_password
                    }
                    
                     isInvalid={formik.touched.new_password && formik.errors.new_password}
                    
                    placeholder="New Password"
                    maxLength={100}
                  />
                  <div className='icon_eyes'>
                    {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { togglePasswordVisibility() }} viewBox="0 0 16 16">
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />

                    </svg>
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { togglePasswordVisibility() }} className="bi bi-eye-slash" viewBox="0 0 16 16">
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                      </svg>}
                  </div>
                  <Form.Control.Feedback className='invalid-feedbacks' type="invalid">
                    {formik.errors.new_password}
                  </Form.Control.Feedback>{" "}
                </Form.Group>

              </Row>
              <Row className="mb-2" >
                <Form.Group as={Col} md="8 " controlId="validationCustom04">
                  {/* <Form.Label>Confirm Password</Form.Label> */}
                  <Form.Control
                    className={`usrname ${classes.root}`}
                    type={showPasswor ? 'input' : 'password'}
                    name="confirm_password"
                    value={formik.values?.confirm_password}

                    onChange={formik.handleChange}
                    isValid={
                      formik.touched.confirm_password &&
                      !formik.errors.confirm_password
                    }
                    
                     isInvalid={formik.touched.confirm_password && formik.errors.confirm_password}
                    
                    
                    placeholder="Confirm Password"
                    maxLength={100}
                  />
                  <div className='icon_eyes'>
                    {showPasswor ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { togglePasswordVisibilit() }} viewBox="0 0 16 16">
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />

                    </svg>
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { togglePasswordVisibilit() }} className="bi bi-eye-slash" viewBox="0 0 16 16">
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                      </svg>}
                  </div>
                  <Form.Control.Feedback className='invalid-feedbacks' type="invalid">
                    {formik.errors.confirm_password}
                  </Form.Control.Feedback>{" "}
                </Form.Group>
              </Row>
              <div className="login_btn_wrapper group">
                {isloader == true ? (
                  <div className="loader">
                    <img src={loader} alt="" />
                  </div>
                ) : (
                  <Button type="submit" className="btnss">
                    Reset
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset_Password;
