import request from "../api/agent";
const API_ROUTE = "courses/course_stream";

export class StreamService {
    async addOrUpdateStream(stream) {
        let action = `${API_ROUTE}/create-or-update-course-stream`;
        const response = await request.post(action, stream);
        return response;
    }

    async getStreams(page,limit,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-all-course-stream?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }
    async getAlStreams(values) {
        const searchValue = values ?? "";
        const response = await request.get(`${API_ROUTE}/v2/get-course-stream-dropdown-listing?search=${searchValue}`);
        return response.data;
    }
    async getAllStreams() {
        const response = await request.get(`${API_ROUTE}/get-all-course-stream`);
        return response.data;
    }


    async deleteStream(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-course-stream`, {
            data: { id: id }
        });
        return response;
      }
      async setStatus(id) {
        const response = await request.post(`${API_ROUTE}/course-stream-status-change`, { id: id });
        return response;
    }

}