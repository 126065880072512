import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { CourseTypeService } from '../../services/CourseTypeService';


const courseTypeService = new CourseTypeService();
const CreateCourseType = ({ formik, isloader, toaster, setToaster, setImage, image, formRef, imageError }) => {
    const [types, setTypes] = useState([]);
    const onChangeImage = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
            formik.setValues({ ...formik.values, image: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) })
        }
    }
    useEffect(() => {
        getAllCourseTypes()
    }, [])

    async function getAllCourseTypes() {
        const data = await courseTypeService.getAllCourseTypes();
        setTypes(data?.data?.results)
        console.log(data);
    }
    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="course_type_name"
                                value={formik.values?.course_type_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                             isValid={formik.touched.course_type_name && !formik.errors.course_type_name}
                           isInvalid={formik.touched.course_type_name && formik.errors.course_type_name}
                                placeholder="Eg:UG"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.course_type_name}</Form.Control.Feedback>
                        </Form.Group>                        
                    </Row>              
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateCourseType;