import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import BasicSweetCallback from '../../SweetAlertCallback';
import loader from '../../../assets/images/loader.gif';
import { UniversityCourseService } from '../../services/UniversityCourseService';
import { Tooltip, OverlayTrigger, Form, Button, Modal } from "react-bootstrap";
import { checkUserPermission } from '../../checkUserPermission';
const renderTooltip=(props) => (
    <Tooltip id="button-tooltip" {...props}>
        Edit
    </Tooltip>
);


const universityCourseService=new UniversityCourseService();
const sortIcon=<ArrowDownward />;
const selectProps={ indeterminate: isIndeterminate => isIndeterminate };
const actions=(
    <IconButton color="primary">
        <Add />
    </IconButton>
);
const contextActions=deleteHandler => (
    <IconButton color="secondary" onClick={deleteHandler}>
        <Delete />
    </IconButton>
);


const UniversityCourseDatatable=({ totalRows, setModalShow, modalShow, bulkRef, isBulkLoader, setCurrentPage, bulkformik, toggleCleared, pending, handlePerRowsChange, handleRowClicked, handleChange, data, handleEdit, refresh, currentPage, setRefresh, loading, setFilterText }) => {

    const handleChangefiles=(e, name, url) => {
        bulkformik.setValues({ ...bulkformik.values, [name]: e.target.files[0], [url]: e.target.files[0]? URL.createObjectURL(e.target.files[0]):"" })
    }

    const [open, setOpen]=React.useState(false);
    const handleOpen=() => setOpen(true);
    const handleClose=() => setOpen(false);


    const columns=[
        {
            name: "Sl.No",
            key: "index",
            cell: (row, index) => ((currentPage*10)+(index+1))-10,
            // width: "150px",
        },
        {
            name: 'University',
            selector: row => row?.university_name,
            cell: (row) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.university_name}>
                    {row.university_name}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
            // width: "150px",
        },
        {
            name: 'Course',
            selector: row => row?.course_name,
            cell: (row) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.course_name}>
                    {row.course_name}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
            // width: "200px",
        },
        {
            name: 'Tuition Fee/Year',
            selector: row => row?.total,
            sortable: true,
            grow: 2,
            reorder: true,
            width: "180px",
        },
        {
            name: 'Website',
            selector: row => row?.web_url,
            sortable: true,
            grow: 2,
            reorder: true,
            // width: "100px",
        },
        {
            name: 'Status',
            selector: row => <div class="form-check form-switch"  >
                <input onChange={() => {
                    universityCourseService.setStatus(row.id).then((response) => {
                        setRefresh(!refresh)
                    })
                }} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_active} />
                <label class="form-check-label" for="flexSwitchCheckDefault"></label>
            </div>,
            grow: 2,
            // width: "100px",
            center: true
        },
        {
            name: 'Popular',
            selector: row => <div class="form-check form-switch"  >
                <input onChange={() => {
                    universityCourseService.setPopular(row.id).then((response) => {
                        setRefresh(!refresh)
                    })
                }} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_popular} />
                <label class="form-check-label" for="flexSwitchCheckDefault"></label>
            </div>,
            grow: 2,
            // width: "100px",
            center: true
        },

        (checkUserPermission("Delete University Course")||checkUserPermission("Edit University Course"))&&{
            id: 'action',
            name: 'Actions',
            cell: (row) =>
                <div className='d-flex align-items-center'>
                    {checkUserPermission("Edit University Course")&&<Link onClick={() => handleEdit(row)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <span className="svg-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                    <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </OverlayTrigger>
                    </Link>}
                    {checkUserPermission("Delete University Course")&&<BasicSweetCallback onDelete={universityCourseService.deleteUniversityCourse} deleteId={row?.id} title="University Course" setRefresh={setRefresh} refresh={refresh} />}
                </div>,
            // width: "100px",
            center: true
        },
    ];

    return (

        <React.Fragment>
            <div className='mt-5'>
                <Card style={{ height: '100%' }}>
                    <Modal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        // {...props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >

                        <Modal.Body closeButton>
                            <div className='modal_content'>
                                <Form
                                    ref={bulkRef}
                                    onSubmit={bulkformik.handleSubmit}>
                                    <div className='bulk_upload'>Bulk Upload </div>
                                    <Form.Group md="6" controlId="validationCustom04">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                            style={{
                                                width: "100%",
                                                borderRadius: "0.25rem 0 0 0.25rem"
                                            }}
                                            name="country"
                                            value={bulkformik.values?.country}
                                            onChange={bulkformik.handleChange}
                                            onBlur={bulkformik.handleBlur}
                                            isValid={bulkformik.touched.country&&!bulkformik.errors.country}
                                            isInvalid={bulkformik.touched.country&&bulkformik.errors.country}

                                            maxLength={100}
                                        />
                                        <Form.Control.Feedback type="invalid">{bulkformik.errors.country}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Label>Bulk Upload</Form.Label>
                                    <Form.Group md="6" controlId="formFile" className="mb-3" style={{ display: "flex", width: "600px" }}>
                                        <div style={{ width: "250px" }}>
                                            <Form.Control
                                                type="file"
                                                name="file"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "0.25rem 0 0 0.25rem"
                                                }}
                                                accept=".zip"
                                                onChange={(e) => { handleChangefiles(e, 'file', 'file_url') }}
                                                onBlur={bulkformik.handleBlur}
                                                isValid={bulkformik.touched.file&&!bulkformik.errors.file}
                                                isInvalid={bulkformik.touched.file&&bulkformik.errors.file}
                                            />
                                            {bulkformik.errors.file? 
                                            <Form.Control.Feedback type="invalid">{bulkformik.errors.file}</Form.Control.Feedback>
                                                :
                                                <div style={{
                                                    color: "#093384",
                                                    fontWeight: "500",
                                                    fontSize: "12px",
                                                    marginTop: "0.25rem"
                                                }}>(Upload zip file)
                                                </div>}
                                        </div>
                                    </Form.Group>
                                    {isBulkLoader===true?
                                        <div className='loader'>
                                            <img src={loader} alt="" />
                                            <p style={{ fontSize: "14px", fontWeight: '600' }}>This may take few minutes, Please wait</p>
                                        </div>
                                        :<div>
                                            <Button type="submit" className='save-btn me-4'>Save</Button>
                                        </div>
                                    }

                                </Form>
                            </div>
                            {/* {console.log(bulkformik, ".....................................")} */}
                        </Modal.Body>
                    </Modal>
                    <div className='bulk_button'>

                        <Button className='save-btn me-4' onClick={() => setModalShow(true)}>
                            Bulk Upload
                        </Button>
                    </div>
                    <div className="input-group">
                        <div className="form-outline searchEnquiry">
                            <input
                                type="search"
                                style={{ border: "1px solid #000" }}
                                id="form1"
                                onChange={(e) => {
                                    setFilterText(e.target.value);
                                    setCurrentPage(1);
                                    setRefresh(!refresh);
                                }}
                                className="form-control"
                                placeholder="Search"
                            />
                        </div>
                    </div>
                    <DataTable
                        title="University Course"
                        columns={columns}
                        data={data?.results}
                        defaultSortFieldId={1}
                        // selectableRows={selectableRows}
                        highlightOnHover
                        defaultSortField="name"
                        // actions={actions}
                        // contextActions={contextActions(deleteAll)}
                        sortIcon={sortIcon}
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                        onSelectedRowsChange={handleChange}
                        clearSelectedRows={toggleCleared}
                        onRowClicked={handleRowClicked}
                        onChangePage={(page) => { setCurrentPage(page); setRefresh(!refresh); }}
                        onChangeRowsPerPage={handlePerRowsChange}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        progressPending={pending}
                    // expandableRows={expandableRows}
                    />
                </Card>
            </div>
        </React.Fragment>
    )
}

export default UniversityCourseDatatable;