import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { IntakeService } from '../../services/intakeService';
import React, { useEffect, useState } from 'react';


const intakeService = new IntakeService();
const CreateIntake = ({ formik, isloader, toaster, setToaster, setImage, image, formRef, imageError }) => {
    const [intake, setIntake] = useState([])


    const onChangeImage = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
            formik.setValues({ ...formik.values, image: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) })
        }
    }
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="full_name"
                                value={formik.values?.full_name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.full_name && !formik.errors.full_name}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.full_name && formik.errors.full_name}
                                placeholder="User Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.full_name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" >
                            <Form.Label>Image<span className='required ms-1'>*</span></Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        onChangeImage(e)
                                    }
                                    }
                                    isValid={formik.touched.image && !formik.errors.image}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.image && formik.errors.image}
                                />
                                {formik.values?.image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }

                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                                {/* { !formik.touched.image &&  !formik.values.image && <div style={{
                                        fontSize: "14px",
                                        color: "#198754",
                                        margin: "4px 0 0 4px",
                                        fontWeight:"500"
                                    }}>Max size. 2mb</div>} */}
                            </div>
                            <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 600 * 600 px)</p></div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Course</Form.Label>
                            <Form.Control
                                type="text"
                                name="course_data"
                                value={formik.values?.course_data}
                                onChange={formik.handleChange}
                                isValid={formik.touched.course_data && !formik.errors.course_data}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.course_data && formik.errors.course_data}
                                placeholder="Eg:Master of Biotechnology Oxford"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.course_data}</Form.Control.Feedback>
                        </Form.Group>

                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateIntake;