import request from "../api/agent";

const API_ROUTE ="/blogs/blog-enquiry";

export class BlogEnquiryService {

    async getblogEnquiry(limit,page,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/blog-enquiry-all-listing?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }

    async deleteEnquiry(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-blog-enquiry`, {
            data: { id: id }
        });
        return response;
    }
//     async enquiryStatus(data) {
//         let action = `${API_ROUTE}/is-contacted-or-not`;
//         const response = await request.post(action, data);
//         return response;
// }

}