import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';

const JobCreate = ({ formik, isloader, formRef, formView, setFormView,markAllFieldsAsTouched }) => {
    const [profileErrors, setProfileErrors] = useState("false");
    const [responsibilityErrors, setResponsibilityErrors] = useState("false");

    const deleteBtn = {
        padding: "0.49rem 0.75rem",
        margin: "0 0 0 -2px",
        borderRadius: "0.25rem"
    }
    const addBtn = {
        padding: "0.49rem 1.2rem",
        margin: "0 0 0 -2px",
        borderRadius: "0.25rem"
    }

    function addJobProfile() {
        let jobProfile = formik.values?.job_candidate_profile;
        jobProfile?.push({
            profile: ''
        });
        formik.setValues({ ...formik.values, job_candidate_profile: jobProfile })
    }

    function deleteJobProfile(index) {
        let jobProfile = formik.values?.job_candidate_profile;
        jobProfile?.splice(index, 1)
        formik.setValues({ ...formik.values, job_candidate_profile: jobProfile })
    }

    function addJobResponsibilities() {
        let jobResponsibilities = formik.values?.job_responsibilities;
        jobResponsibilities?.push({
            responsibility: ''
        });
        formik.setValues({ ...formik.values, job_responsibilities: jobResponsibilities })
    }
    function deleteJobResponsibilities(index) {
        let jobResponsibilities = formik.values?.job_responsibilities;
        jobResponsibilities?.splice(index, 1);
        formik.setValues({ ...formik.values, job_responsibilities: jobResponsibilities })
    }
    // formik.values?.job_responsibilities? setFormView('profile'):markAllFieldsAsTouched

    const onNextClick = () => {
        let errorflag = false;
        formik.values?.job_responsibilities?.length && formik.values?.job_responsibilities?.map((responsibility) => {
            if (responsibility?.responsibility == '') {
                // console.log("test data",errorflag)
                errorflag = true;
                // console.log("data test",errorflag)
            }
        })
        if(errorflag){   
            markAllFieldsAsTouched()
            // console.log("touched formiks are",formik.touched)
            formik.setTouched({             
                ...formik.touched,
                job_responsibilities: formik.values?.job_responsibilities?.map((responsibility)=>{return {responsibility:true}})
            })

        }
        else{
            setFormView('profile')
        }
       
    }


    console.log('====================================');
    console.log(formik.errors);
    console.log('====================================');







    console.log('====================================');
    console.log("gggggg",formik.touched);
    console.log('====================================');

    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    {formView === "jobName" &&
                        <>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Job Name </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="job_name"
                                        value={formik.values?.job_name}
                                         onChange={formik.handleChange}
                                        isValid={formik.touched.job_name && !formik.errors.job_name}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.job_name && formik.errors.job_name}
                                        placeholder="Job Name"
                                        maxLength={100}
                                    />
                                    {formik.touched.job_name && formik.errors.job_name &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.job_name}</Form.Control.Feedback>
                                    }

                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Job Type </Form.Label>
                            <Form.Select
                                name="job_type"
                                        value={formik.values?.job_type}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.job_type && !formik.errors.job_type}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.job_type && formik.errors.job_type}
                                        placeholder="Job Type"
                            >
                                <option value='' >Select a Job Type</option>
                                <option value={"Full_time"}>Full Time</option>
                                <option value={"Part_time"}>Part Time</option>
                                <option value={"Hybrid"}>Hybrid</option>
                                <option value={"work_from_home"}>Work From Home</option>
                                <option value={"Freelance"}>Freelance</option>
                                <option value={"Contract"}>Contract</option>
                                <option value={"Affiliate"}>Affiliate</option>




                            </Form.Select>
                            {/* <Form.Control.Feedback type="invalid">{formik.errors.course_nature}</Form.Control.Feedback>
                        </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Job Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="job_type"
                                        value={formik.values?.job_type}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.job_type && !formik.errors.job_type}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.job_type && formik.errors.job_type}
                                        placeholder="Job Type"
                                        maxLength={100}
                                    /> */}
                                    {formik.touched.job_type && formik.errors.job_type &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.job_type}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Job Location </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="job_location"
                                        value={formik.values?.job_location}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.job_location && !formik.errors.job_location}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.job_location && formik.errors.job_location}
                                        placeholder="Job Location"
                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.job_location}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Years of Experience </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="job_experience"
                                        value={formik.values?.job_experience}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.job_experience && !formik.errors.job_experience}
                                        isInvalid={formik.touched.job_experience && formik.errors.job_experience}
                                        placeholder="Years of Experience"
                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.job_experience}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <div className="group">
                                <Button type="button" onClick={() => {
                                    formik.values.job_name && formik.values.job_experience && formik.values.job_type&& formik.values.job_location ?setFormView('jobResponsibilities'):markAllFieldsAsTouched()
                                }} className='save-btn me-4'>Next</Button>
                            </div>
                        </>}

                    {formView === 'jobResponsibilities' &&
                        <>
                            <Row>
                                {formik.values.job_responsibilities.map((job_responsibility, index) => {
                                    if (job_responsibility === undefined) {
                                        setResponsibilityErrors(true)
                                        return;
                                    }
                            //  const touchedIntake = formik.touched?.intake && formik.touched?.intake[index] && formik.touched?.intake[index].intake; */}
                                    const touched = formik.touched?.job_responsibilities && formik.touched?.job_responsibilities[index] && formik.touched?.job_responsibilities[index].responsibility;
                                    return <>
                                        <Form.Label>{index === 0 && "Job Responsibilities"} </Form.Label>
                                        <Form.Group as={Col} md="6" controlId="validationCustom07" style={{ display: "flex", width: "600px" }} className='pt-10'> 
                                            <div style={{ width: "250px" }}>
                                                <Form.Control
                                                    type="text"
                                                    name={`job_responsibilities[${index}].responsibility`}
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "0.25rem 0 0 0.25rem"
                                                    }}
                                                    value={job_responsibility?.responsibility}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    // isValid={false}
                                                    isValid={touched && !(formik.errors.job_responsibilities && formik.errors.job_responsibilities[index] && formik.errors.job_responsibilities[index]?.responsibility && !!formik.errors.job_responsibilities[index]?.responsibility)}
                                                    isInvalid={touched && formik.errors.job_responsibilities && formik.errors.job_responsibilities[index] && formik.errors.job_responsibilities[index]?.responsibility && !!formik.errors.job_responsibilities[index]?.responsibility}
                                                    // isInvalid={true}
                                                    placeholder="Job Responsibilities"
                                                    maxLength={100}
                                                />
                                                {/* <span style={{ color: '#000', fontSize: "15px" }}>
                                                        
                                                </span>  
                                                {formik.touched?.job_responsibilities && formik.touched?.job_responsibilities[index] && formik.touched?.job_responsibilities[index]?.responsibility ?
                                                    (<span style={{ color: '#000', fontSize: "15px" }}>
                                                        {formik.touched?.job_responsibilities[index]?.responsibility}
                                                    </span>)
                                                    : null}     */}

                                                         
                                                {/* <Form.Control.Feedback type="invalid">{ touched && formik.errors.job_responsibilities && formik.errors.job_responsibilities[index] && formik.errors.job_responsibilities[index]?.responsibility && formik.errors.job_responsibilities[index]?.responsibility}</Form.Control.Feedback> */}
                                                <Form.Control.Feedback type="invalid">{ touched && formik.errors?.job_responsibilities && formik.errors.job_responsibilities[index] && formik.errors.job_responsibilities[index]?.responsibility ? formik.errors.job_responsibilities[index]?.responsibility : ''}</Form.Control.Feedback>
                                            </div>
                                            <div className='d-flex'>
                                                {index == formik.values?.job_responsibilities?.length - 1 &&
                                                    <div>
                                                        <Button type="button" onClick={() => addJobResponsibilities()} className='save-btn me-1' style={addBtn} >Add</Button>
                                                    </div>}
                                                <div>
                                                    {formik.values?.job_responsibilities?.length > 1 && <Button type="button" onClick={() => deleteJobResponsibilities(index)} className='save-btn' style={deleteBtn}>Delete</Button>}
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </>
                                })}
                            </Row>
                            <div className="group mt-3">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('jobName')}>Back</Button>
                                <Button type="button" onClick={() =>onNextClick()} className='save-btn me-4'>Next</Button>
                            </div>
                        </>

                    }
                    {formView === 'profile' &&
                        <>
                            <Row>
                                {formik.values.job_candidate_profile?.length && formik.values.job_candidate_profile?.map((job_candidate_profile, index) => {
                                    if (job_candidate_profile === undefined) {
                                        setProfileErrors(true)
                                        return;
                                    }
                                    const touched = formik.touched?.job_candidate_profile && formik.touched?.job_candidate_profile[index];
                                    return <>
                                        <Form.Label>{index === 0 && "Job Profile"} </Form.Label>
                                        <Form.Group as={Col} md="6" controlId="validationCustom07" style={{ display: "flex", width: "600px" }} className='pt-10'>
                                            <div style={{ width: "250px" }}>
                                                <Form.Control
                                                    type="text"
                                                    name={`job_candidate_profile[${index}].profile`}
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "0.25rem 0 0 0.25rem"
                                                    }}
                                                    value={job_candidate_profile?.profile}
                                                    onChange={formik.handleChange}
                                                    isValid={touched && !(formik.errors.job_candidate_profile && formik.errors.job_candidate_profile[index] && formik.errors.job_candidate_profile[index]?.profile && !!formik.errors.job_candidate_profile[index]?.profile)}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={touched && formik.errors.job_candidate_profile && formik.errors.job_candidate_profile[index] && formik.errors.job_candidate_profile[index]?.profile && !!formik.errors.job_candidate_profile[index]?.profile}
                                                    placeholder="Job Profile"
                                                    maxLength={100}
                                                />
                                                <Form.Control.Feedback type="invalid">{touched && formik.errors.job_candidate_profile && formik.errors.job_candidate_profile[index] && formik.errors.job_candidate_profile[index]?.profile && formik.errors.job_candidate_profile[index]?.profile}</Form.Control.Feedback>
                                            </div>
                                            <div className='d-flex'>
                                                {index == formik.values?.job_candidate_profile?.length - 1 &&
                                                    <div>
                                                        <Button type="button" onClick={() => addJobProfile()} className='save-btn me-1 ' style={addBtn}>Add</Button>
                                                    </div>}
                                                <div>
                                                    {formik.values?.job_candidate_profile?.length > 1 && <Button type="button" onClick={() => deleteJobProfile(index)} className='save-btn' style={deleteBtn}>Delete</Button>}
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </>
                                })}
                            </Row>
                            <div className="group">
                                {isloader === true ?
                                    <div className='loader'>
                                        <img src={loader} alt="" />
                                    </div>
                                    :
                                    <>
                                        <Button type="button" className='save-btn me-4' onClick={() => setFormView('jobResponsibilities')}>Back</Button>
                                        <Button type="submit" className='save-btn me-4' >Save</Button>
                                    </>
                                }
                            </div>
                        </>}
                </Form>
            </div>
        </React.Fragment>
    )
}

export default JobCreate;