import request from "../api/agent";

const API_ROUTE ="/faq/Faq";

export class FaqService {

    async getFaq(perpage,currentPage,search,filterId) {
        const serchValue = search??""
        let base_url='/get-all-faq?'
        if(perpage){
            base_url=base_url+'limit='+perpage+'&'
        }
        if(currentPage){
            base_url=base_url+'page='+currentPage+'&'

        }
        if(serchValue){
            base_url=base_url+'search='+serchValue+'&'
        }
        if(filterId){
            base_url=base_url+'type='+filterId+'&'
        }

        
            const response = await request.get(API_ROUTE+base_url);
            return response.data;
        
    }


    async addOrUpdateFaq(faq) {
        let action = `${API_ROUTE}/create-or-update-faq`;
        const response = await request.post(action, faq);
        return response;
    }

    async deleteFaq(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-faq`, {data: { id: id }});
        return response;
    }

    async setFaqStatus(id){
        const response = await request.put(`${API_ROUTE}/faq-status-change`,{id:id})
        return response;
    }
}