import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { FaqService } from '../../services/FaqService';
import { UniversityService } from '../../services/universityService';
import { CountryService } from '../../services/CountryServices';


const SpeakersCreate = ({ formik, isloader, formRef }) => {
    const countryService = new CountryService();
    const universityService = new UniversityService();
    const [universities, setUniversities] = React.useState([]);
    const [countries, setCountries] = React.useState([]);
    useEffect(() => {
        universityService.getUniversity().then((response) => {
            setUniversities(response?.data?.results)
        })
        countryService.getAllCountry().then((response) => {
            setCountries(response?.data?.results)
        })
    }, [])

    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="speaker_name"
                                value={formik.values?.speaker_name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.speaker_name && !formik.errors.speaker_name}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.speaker_name && formik.errors.speaker_name}
                                placeholder="Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.speaker_name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Designation</Form.Label>
                            <Form.Control
                                type="text"
                                name="designation"
                                value={formik.values?.designation}
                                onChange={formik.handleChange}
                                isValid={formik.touched.designation && !formik.errors.designation}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.designation && formik.errors.designation}
                                placeholder="Designation"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.designation}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label>University</Form.Label>
                            <Form.Control
                                type="text"
                                name="university"
                                value={formik.values?.university}
                                onChange={formik.handleChange}
                                isValid={formik.touched.university && !formik.errors.university}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.university && formik.errors.university}
                                placeholder="University"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.university}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                type="text"
                                name="country"
                                value={formik.values?.country}
                                onChange={formik.handleChange}
                                isValid={formik.touched.country && !formik.errors.country}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.country && formik.errors.country}
                                placeholder="country"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.country}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        {/* <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>University </Form.Label>
                            <Form.Select
                                placeholder="Select an Option"
                                className="select-dropdown"
                                classnameprefix="select"
                                name="university"
                                value={formik.values?.university}
                                isValid={formik.touched.university && !formik.errors.university}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.university && formik.errors.university}
                                onChange={formik.handleChange}
                            >
                                <option value='' >Select University</option>
                                {universities?.map((university, index) =>
                                    <option value={university?.id} key={index}>{university?.university_name}</option>
                                )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.university}</Form.Control.Feedback>
                        </Form.Group>
                        <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Country</Form.Label>
                            <Form.Select
                                placeholder="Select an Option"
                                className="select-dropdown"
                                classnameprefix="select"
                                name="country"
                                value={formik.values?.country}
                                isValid={formik.touched.country && !formik.errors.country}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.country && formik.errors.country}
                                onChange={formik.handleChange}
                            >
                                <option value=''>Select Country</option>
                                {countries?.map((country, index) =>
                                    <option value={country?.id} key={index}>{country?.country_name}</option>
                                )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.faq_type}</Form.Control.Feedback>
                        </Form.Group>
                    </Row> */}

                        <Form.Group
                            as={Col}
                            md="6"
                            controlId="formFile"
                            className="mb-3"
                        >
                            <Form.Label> Image<span className='required ms-1'>*</span></Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="speaker_image"
                                    accept="image/*"
                                    onChange={(e) => {
                                        formik.setValues({
                                            ...formik.values,
                                            speaker_image: e.target.files[0],
                                            speaker_image_url: e.target.files[0] !== undefined && URL.createObjectURL(e.target.files[0]),
                                        });
                                    }}
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.speaker_image && !formik.errors.speaker_image}
                                    isInvalid={formik.touched.speaker_image && formik.errors.speaker_image}
                                />
                                {formik.values?.speaker_image_url && (
                                    <div className="img-wrapper ms-2">
                                        <img
                                            src={formik.values.speaker_image_url}
                                            alt="speaker_image"
                                            style={{ height: "100px", width: "150px" }}
                                            className=" mt-2"
                                        />
                                    </div>
                                )}
                                {/* {!formik.touched.speaker_image && !formik.values.speaker_image && <div style={{
                                    fontSize: "14px",
                                    color: "#198754",
                                    margin: "4px 0 0 4px",
                                    fontWeight: "500"
                                }}>Max size. 2mb</div>} */}
                                <Form.Control.Feedback type="invalid">{formik.errors.speaker_image} </Form.Control.Feedback>
                            </div>
                            <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 600 * 600 px)</p></div>
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader === true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default SpeakersCreate;