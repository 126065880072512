import request from "../api/agent";

const API_ROUTE = "counsellors/counsellor_profile";

export class CounsellorProfileService {
    async getCounsellorProfile(perpage, currentPage,search) {
        const serchValue = search??""
        if (perpage) {
            const response = await request.get(`${API_ROUTE}/counsellor-profile-all-listing?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
            return response.data;
        } else {
            const response = await request.get(`${API_ROUTE}/counsellor-profile-all-listing`);
            return response.data;
        }
    }

    async addOrUpdateCounsellorProfile(counsellor) {
        let action = `${API_ROUTE}/create-or-update-counsellor-profile`;
        const response = await request.post(action, counsellor);
        return response;
    }

    async deleteCounsellorProfile(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-counsellor-profile`, { data: { id: id } });
        return response;
    }

}