import request from "../api/agent";
const API_ROUTE = "seo/seo";

export class SeoService {
    async addOrUpdateSeo(Seo) {
        let action = `${API_ROUTE}/create-or-update-seo`;
        const response = await request.post(action, Seo);
        return response;
    }

    async getAllSeo(perpage, currentPage, search) {
        const serchValue = search ?? ""
        if (perpage) {
            const response = await request.get(`${API_ROUTE}/get-all-seo?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
            return response.data;
        } else {
            const response = await request.get(`${API_ROUTE}/get-all-seo`)
            return response.data;
        }
    }
    async getStatus(id) {
        const response = await request.put(`${API_ROUTE}/active-inactive-status`, { id: id });
        return response;
    }

    // async getCountry(perpage, currentPage, search) {
    //     const serchValue = search ?? ""
    //     const response = await request.get(`${API_ROUTE}/my-profile?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
    //     return response.data;
    // }

    async deleteSeo(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-seo`, {
            data: { id: id }
        });
        return response;
    }

    // async getStatus(id) {
    //     const response = await request.post(`${API_ROUTE}/country-status`, { country_id: id })
    //     return response;
    // }

    // async setCountryFilter(filterId) {
    //     const response = await request.post(`${API_ROUTE}/country-listing-dropdown?category=${filterId}`)
    //     return response;
    // }

}