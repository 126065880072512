import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { CourseService } from '../../services/CourseService';
import { SpecializationService } from '../../services/SpecializationService';
import AsyncSelect from 'react-select/async';

const courseService = new CourseService();
const specializationService = new SpecializationService();

const CourseSpecCreate = ({ formik, isloader, formRef }) => {

    const [courseDefault, setCourseDefault] = useState([]);
    const [specializatonDefault, setSpecializatonDefault] = useState([]);




    let requestTime;
    const requestOptions = async (values) => {
        clearTimeout(requestTime);
        return new Promise((resolve, reject) => {
          requestTime = setTimeout(async () => {
            if (values.length >= 3) {
              try {
                const data = await courseService.getAllCoursess(values);
                    if (data && data.status) {
                      setCourseDefault(data?.data);
                      resolve(data?.data);
                    }
                
              } catch (error) {
                console.error("Error fetching courses:", error);
                resolve([]);
              }
            } else {
              resolve([]);
            }
          }, 500);
        });
      };

     let requestSpecialization;
     const  requestSpecializationOptions = async(values) =>{
        clearTimeout(requestSpecialization);
        return new Promise((resolve,reject) =>{
            requestSpecialization = setTimeout(async()=>{
                if(values.length >= 3){
                    try{
                        const datas = await specializationService.getSpecializations(values);
                        if(datas && datas.status){
                            setSpecializatonDefault(datas?.data)
                            resolve(datas?.data)
                        }
                    }catch(error){
                        console.error("Error fetching courses:", error);
                        resolve([]);
                    }
                }
                else{
                    resolve([])
                }
            },500)
        })
     }

     const handleCourseChange = (selectedOption) => {
        formik.setFieldValue('course', selectedOption.value);
        formik.setFieldValue('selectedCourse', selectedOption);
      };

    const handleSpecializationChange = (selectedOption) => {
        formik.setFieldValue('specialization', selectedOption.value);
        formik.setFieldValue('selectedSpecilization', selectedOption);
    }

    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Course</Form.Label>
                            <AsyncSelect 
                                placeholder="Search for Course"
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="course"
                                value={formik.values?.selectedCourse}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.course && !formik.errors.course}
                                isInvalid={formik.touched.course && formik.errors.course}
                                onChange={handleCourseChange}
                                loadOptions={requestOptions}
                                
                            />
                           {formik.touched.course&&<div className="feedback_errors">
                                {formik.errors.course}
                            </div>}
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Specialization</Form.Label>
                            <AsyncSelect
                                placeholder="Select a Specialization"
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="specialization"
                                value={formik.values?.selectedSpecilization}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.specialization && !formik.errors.specialization}
                                isInvalid={formik.touched.specialization && formik.errors.specialization}
                                onChange={handleSpecializationChange}
                                loadOptions={requestSpecializationOptions}

                            />
                           {formik.touched.specialization&&<div className="feedback_errors">
                                {formik.errors.specialization}
                            </div>}
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CourseSpecCreate;