import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import loader from "../../../assets/images/loader.gif";
import { SpecializationService } from "../../services/SpecializationService";
import { StreamService } from "../../services/StreamService";
import AsyncSelect from "react-select/async";

const streamService = new StreamService();

const specializationService = new SpecializationService();

const CreateSpecialization = ({
  formik,
  isloader,
  toaster,
  setToaster,
  setImage,
  image,
  formRef,
  imageError,
}) => {


  let requestTime;
    const requestOptions = async (values) => {
        clearTimeout(requestTime);
        return new Promise((resolve, reject) => {
          requestTime = setTimeout(async () => {
            if (values.length >= 3) {
              try {
                const data = await streamService.getAlStreams(values);
                console.log(data)
                    if (data && data.status) {
                      resolve(data?.data);
                    }
                
              } catch (error) {
                console.error("Error fetching courses:", error);
                resolve([]);
              }
            } else {
              resolve([]);
            }
          }, 500);
        });
      };

      const handleStreamChange = (selectedOption) => {
        formik.setFieldValue('course_stream', selectedOption?.value);
        formik.setFieldValue('selectedCourse', selectedOption);
      };



  return (
    <React.Fragment>
      <div className="form_sec">
        <Form ref={formRef} onSubmit={formik.handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustom03">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values?.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={formik.touched.name && formik.errors.name}
                placeholder="Name"
                maxLength={100}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Form.Group>


            <Form.Group as={Col} md="6" controlId="validationCustom03">
              <Form.Label>Course Stream</Form.Label>
              <AsyncSelect
                placeholder="Search an Option"
                className="select-dropdown"
                classNamePrefix="select"
                name="course_stream"
                value={formik.values?.selectedCourse}
                onChange={handleStreamChange}
                onBlur={formik.handleBlur}
                isValid={formik.touched.course_stream && !formik.errors.course_stream}
                isInvalid={formik.touched.course_stream && formik.errors.course_stream}
                loadOptions={requestOptions}
                />
              {formik.touched.course_stream&&<div className="feedback_errors">
                    {formik.errors.course_stream}
                  </div>}
            </Form.Group>











            {/* <Form.Select
                    placeholder="Course"
                    className="select-dropdown"
                    classNamePrefix="select"
                    name="course_stream"
                    value={formik.values?.course_stream}
                    isValid={formik.touched.course_stream && !formik.errors.course_stream}
                    isInvalid={!!formik.errors?.course_stream}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select an Option</option>
                    {streams?.map((type) => (
                      <option value={type?.id}>{type?.course_stream}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.course_stream}
                  </Form.Control.Feedback> */}

            {/* <Select
                classNamePrefix="select"
                isMulti
                styles={style}
                value={formik.values?.stream}
                name="
                
                
                "
                options={streams}
                onChange={(e) => {
                  handleOnSelectChange({
                     name: "course_stream_name", 
                     value: e });
                }}
                placeholder="Select Stream..."
              /> */}
            {/* <div className="errorMessage">
              {formik.errors.stream}</div>  */}







            {/* </Form.Group> */}
          </Row>
          <div className="group">
            {isloader == true ? (
              <div className="loader">
                <img src={loader} alt="" />
              </div>
            ) : (
              <Button type="submit" className="save-btn me-4">
                Save
              </Button>
            )}
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default CreateSpecialization;
