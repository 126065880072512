import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { UserService } from '../../services/UserService';
import UserCreate from './UserCreate';
import UserDatatble from './UserDatatable';
import { checkUserPermission } from '../../checkUserPermission';


const User = () => {

    const userService = new UserService();

    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [groupId, setGroupId] = useState([]);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [passField, setPassField] = useState(true)
    const [pending, setpending] = useState(true);
    const [filterText, setFilterText] = React.useState('');

    const handlePerRowsChange = async (newPerPage) => {
        setRefresh(!refresh);
        setPerpage(newPerPage)
    };
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])



    async function getAllUser() {
        setpending(true)
        const data = await userService.getUsers(perpage, currentPage, filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setpending(false)
    }

    useEffect(() => {
        getAllUser()
    }, [refresh])

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required('Required'),

        showpass: Yup.boolean(),
        email: Yup.string().email('Invalid email format').required('Required'),

        groups: Yup.array().min(1)
            .required('Required'),
        phone_number: Yup.string()
            .min(10, "Please enter a valid number")
            .max(10, "Please enter a valid number")
            .required("This field is required")
    });



    const formik = useFormik({
        initialValues: {
            user: "",
            username: "",
            email: "",
            phone_number: "",
            showpass: true,
            password: "",
            is_active: true,
            is_admin: true,
            groups: ""
        },
        validationSchema,
        // validate: async (values) => {
        //     let errors = {}
        //     if (passField) {
        //         if (values.password ===/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/) {
        //             // errors.password = "Required"
        //             errors.password ='Must contain 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character'

        //         } 

        //     }

        //     return errors
        // },
        validate: async (values) => {
            let errors = {}
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
            if (passField) {
            if (values.password) {
                console.log(passwordRegex.test(values.password))
              if (!passwordRegex.test(values.password)) {
                errors.password = 'Must contain 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character'
              }
            } else {
              errors.password = 'Required'
            }
        }
            return errors
          },
          

        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = {};
            if (values.user) {
                formData.user = values.user;
            }
            formData.username = values.username;
            formData.email = values.email;
            formData.phone_number = values.phone_number;
            if (passField) {
                formData.password = values.password;
            }
            formData.is_active = values.is_active;
            formData.is_admin = values.is_admin;
            formData.groups = groupId;

            userService.addOrUpdateUser(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                    setPassField(true)
                }
                else {
                    formik.setErrors({
                        username: response?.response?.data?.errors?.username,
                        email: response?.response?.data?.errors?.email,
                        phone_number: response?.response?.data?.errors?.phone_number,
                        password: response?.response?.data?.errors?.password,
                        groups: response?.response?.data?.errors?.groups,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error(response?.response?.data?.errors?.email ? response?.response?.data?.errors?.email[0] : "Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });


    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        console.log("item")
        console.log(item)
        setGroupId(item.groups.map(item => item.pk))
        formik.setValues({
            user: item.pk,
            username: item.username,
            phone_number: item.phone_number,
            email: item.email,
            groups: item.groups.map(item => ({ value: item.pk, label: item.name })),
            showpass: false
        })
        setPassField(false)
    }

console.log("formik errors",formik.errors)
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Users</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Users</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Users") && <React.Fragment>
                        <UserCreate setGroupId={setGroupId} image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} passField={passField} />
                    </React.Fragment>}
                    <React.Fragment>
                        <UserDatatble setFilterText={setFilterText} currentPage={currentPage} setPassField={setPassField} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} totalRows={totalRows} formik={formik} pending={pending} />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default User