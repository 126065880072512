import request from "../api/agent";
const API_ROUTE = "/about/team";



export class AboutTeamService {
    async addOrUpdateTeam(team) {
        let action = `${API_ROUTE}/create-or-update-team`;
        const response = await request.post(action, team);
        return response;
    }

    async getAllTeam(perpage,currentPage) {
        const response = await request.get(`${API_ROUTE}/team-all-listing?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }


  async deleteTeam(id) {
    console.log(id)
        const response = await request.delete(`${API_ROUTE}/destroy-team`, {
            data: { id: id}
          
        } );
        
        return response;
      }
    //   async setEnrollStatus(id){
    //     const response = await request.put(`${API_ROUTE}/endroll-status-change`,{id:id})
    //     return response;
    // }
}