import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { PermissionService } from '../../services/PermissionService';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const RolesCreate = ({ formik, isloader, formRef }) => {
    const permissionService = new PermissionService();
    const [nodes, setNodes] = useState(
        []);

    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        getAllPermissions()
    }, [])

    async function getAllPermissions() {
        const data = await permissionService.getAllPermissions();
        setNodes(data?.data?.permissions)
    }


    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formik.values?.name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.name && !formik.errors.name}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.name && formik.errors.name}
                                placeholder="Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Permissions</Form.Label>
                            <CheckboxTree
                                nodes={nodes}
                                checked={formik.values.permissions}
                                expanded={expanded}
                                onCheck={checked => {
                                    console.log("ajsdfajfgsgh")
                                    console.log(checked)
                                    formik.setValues({ ...formik.values, permissions: checked })}}
                                onExpand={expanded => setExpanded(expanded)}
                                icons={{
                                    check: <i className="bi bi-check2-square" />,
                                    uncheck: <span className="bi bi-square" />,
                                    halfCheck: <span className="bi bi-dash-square" />,
                                    expandClose: <span className="bi bi-chevron-right" />,
                                    expandOpen: <span className="bi bi-chevron-down" />,
                                    expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                    collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                    parentClose: <span className="bi bi-folder" />,
                                    parentOpen: <span className="bi-folder2-open" />,
                                    leaf: <span className="bi bi-file-earmark" />,
                                }}
                            />
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader === true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default RolesCreate;