import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link, json } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { checkUserPermission } from '../../checkUserPermission';
import CourseSpecCreate from './CourseSpecCreate';
import { CourseSpecializationService } from '../../services/courseSpecialization';
import CourseSpecDatatable from './CourseSpecDatatable';

const CourseSpec = () => {

    const courseSpecializationService = new CourseSpecializationService();

    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = useState(true);
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])


    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage);
        setRefresh(!refresh);
    };

    async function getCourseSpecialization() {
        setPending(true)
        const data = await courseSpecializationService.getCourseSpecialization(perpage, currentPage, filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setPending(false)
    }

    useEffect(() => {
        getCourseSpecialization()
    }, [refresh])

    const validationSchema = Yup.object().shape({
        specialization: Yup.number().required('Required'),
        course: Yup.number().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            instance_id: "",
            course: "",
            selectedCourse:"",
            specialization: "",
            selectedSpecilization:""
        },
        validationSchema,
        validate: async (values) => {
            const errors = {};
            if (!values.course) {
                errors.course = "Course is required";
            }
            if (!values.specialization) {
                errors.specialization = "University is required";
            }
            return errors;
        },
        onSubmit: (values) => {
            setIsloader(true)
            let formData = {};
            if (values.instance_id) {
                formData.instance_id = values.instance_id ?? "";
            }
            formData.course = values.course;
            formData.specialization = values.specialization;

            courseSpecializationService.addOrUpdateCourseSpecialization(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        question: response?.response?.data?.errors?.title,
                        category: response?.response?.data?.errors?.category,
                        university_course: response?.response?.data?.errors?.university_course,
                        price: response?.response?.data?.errors?.price,
                        about: response?.response?.data?.errors?.about,
                        requirements: response?.response?.data?.errors?.requirements,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        console.log(item)
        formik.setValues({
            instance_id: item.id,
            course: item.course,
            selectedCourse:{label:item.course_name,value:item.course},
            specialization: item.specialization,
            selectedSpecilization:{label:item.specialization_name,value:item.specialization}
        })
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Course Specialization</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Course Specialization</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Course Specialization") && <React.Fragment>
                        <CourseSpecCreate
                            formik={formik}
                            isloader={isloader}
                            toaster={toaster}
                            setToaster={setToaster}
                            formRef={formRef}
                        />
                    </React.Fragment>}
                    <React.Fragment>
                        <CourseSpecDatatable
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            totalRows={totalRows}
                            formik={formik}
                            pending={pending}
                            currentPage={currentPage}
                            setFilterText={setFilterText}
                        />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default CourseSpec