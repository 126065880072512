// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { WebinarTypeService } from '../../services/WebinarTypeService';
import CreateWebinar from './createWebinar';
import WebinarDatatable from './webinarDatatable';
import { WebinarService } from '../../services/WebinarService';
import moment from 'moment';
import { checkUserPermission } from '../../checkUserPermission';
import { dataURLtoFile } from '../../functions';


const webinarService = new WebinarService();

const Webinar = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [pending, setPending] = useState(true);
    const [scroll, setScroll] = useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = React.useState(0);
    const [active, setActive] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])



    async function getAllWebinars() {
        setPending(true)
        const data = await webinarService.getWebinars(currentPage, perpage, filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setPending(false)
    }
    useEffect(() => {
        getAllWebinars()

    }, [refresh])
    const imageValidation = Yup
        .mixed()
        .required("Required (Max size. 2mb)")
        .test("size", "Image should be less than 2 MB", (value) => {
            return value && value.size <= 2000000;
        })
        .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
            return value && (
                value.type === "image/jpeg" ||
                value.type === "image/jpg" ||
                value.type === "image/png"
            );
        })
    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Required'),
        description: Yup.string()
            .required('Required (max.250 characters)'),
        speaker: Yup.string()
            .required('Required'),
        designation: Yup.string()
            .required('Required'),
        type: Yup.string()
            .required('Required'),

        time: Yup.string()
            .required('Required'),
        end_time: Yup.string()
            .required('Required'),
        image: imageValidation,
        website: Yup.string()
            // .matches(
            //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            //     'Enter correct url!'
            // )
            .required('Please enter a valid url'),
        webinar_event_type: Yup.string()
            .required('Required'),
        start_date: Yup.date()
            .required('Start date is required'),
        end_date: Yup.date()
            .required('End date is required')
            .min(Yup.ref('start_date'), 'End date should be greater than or equal to start date')
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            title: "",
            description: "",
            speaker: "",
            designation: "",
            start_date: "",
            end_date: "",
            website: "",
            webinar_event_type: "",
            country: "",
            type: "",
            time: "",
            end_time: "",


            image: "",
            image_url: "",
        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            if (values?.image == "") {
                errors.image = "Required"
            }
            else if (values?.image !== "" && values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            } if (values?.image !== "" && values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("webinar_id", values.id ?? "");
            }
            formData.append("title", values.title);
            formData.append("description", values.description);
            formData.append("speaker", values.speaker);
            formData.append("designation", values.designation);
            formData.append("start_date", values.start_date);
            formData.append("end_date", values.end_date);
            formData.append("website", values.website);
            formData.append("webinar_event_type", values.webinar_event_type);
            formData.append("country", values.country);
            formData.append("time", values.time);
            formData.append("end_time", values.end_time);
            formData.append("is_webinar", values.type);
            formData.append("image", values?.image);



            webinarService.addOrUpdateWebinar(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        course_type_name: response?.response?.data?.errors?.course_type_name,

                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        console.log(moment(item.start_date).format('YYYY-MM-DD'));
        formik.setValues({
            id: item.id,
            title: item.title,
            description: item.description,
            speaker: item.speaker,
            designation: item.designation,
            start_date: moment(item.start_date).format('YYYY-MM-DD'),
            end_date: moment(item.end_date).format('YYYY-MM-DD'),
            website: item.website,
            time: item.time,
            end_time: item.end_time,
            webinar_event_type: item.webinar_event_type,
            country: item.country,
            type: item.is_webinar,
            image: await dataURLtoFile(item?.image),
            image_url: item?.image,

        })

    }
    console.log("formik.errors")
    console.log(formik.values)
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Webinar</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Webinar</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Webinar") && <React.Fragment>
                        <CreateWebinar image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <WebinarDatatable totalRows={totalRows}
                            setFilterText={setFilterText}

                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            formik={formik}
                            setCurrentPage={setCurrentPage}
                            setPerpage={setPerpage}
                            currentPage={currentPage}
                            pending={pending} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Webinar;