import request from "../api/agent";
const API_ROUTE = "location/country";

export class CountryService {
    async addOrUpdateCountry(country) {
        let action = `${API_ROUTE}/create-or-update-country`;
        const response = await request.post(action, country);
        return response;
    }

    async getAllCountry(search,perpage, currentPage ) {
        const serchValue = search ?? ""
        if (perpage) {
            const response = await request.get(`${API_ROUTE}/my-profile?search=${serchValue}&limit=${perpage}&page=${currentPage}`);
            return response.data;
        } else {
            const response = await request.get(`${API_ROUTE}/my-profile?`)
            return response.data;
        }
    }
    async getCountries(value) {
        // console.log(value,"{}||}|}")
        const searchvalue = value ?? ""
        const response = await request.get(`${API_ROUTE}/v2/get-country-dropdown-listing?search=${searchvalue}`);
        return response.data;
    }



    async getCountry(perpage, currentPage, search) {
        const serchValue = search ?? ""
        const response = await request.get(`${API_ROUTE}/my-profile?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }
    async getFilterCountry( search) {
        const serchValue = search ?? ""
        const response = await request.get(`${API_ROUTE}/my-profile?&search=${serchValue}`);
        return response.data;
    }

    async deleteCountry(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-country`, {
            data: { country_id: id }
        });
        return response;
    }

    async getStatus(id) {
        const response = await request.post(`${API_ROUTE}/country-status`, { country_id: id })
        return response;
    }

    async setCountryFilter(filterId) {
        const response = await request.post(`${API_ROUTE}/country-listing-dropdown?category=${filterId}`)
        return response;
    }

}