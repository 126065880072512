// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import WebinarRegisterDatatable from './webinarRegisterDatatable';
import { WebinarRegisterService } from '../../services/WebinarRegisterService';

const webinarRegisterService = new WebinarRegisterService();

const WebinarRegister = () => {
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = useState(true);
    const [totalRows, setTotalRows] = React.useState(0);
    const [active, setActive] = useState(true);

    async function getAllCourses() {
         setPending(true)
        const data = await webinarRegisterService.getWebinarRegisters(currentPage,perpage,filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setPending(false)
    }
    useEffect(() => {
        getAllCourses()
        setActive(false)
    }, [refresh])

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Webinar Register</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Webinar Register</li>
                            </ol>
                        </nav>
                    </div>

                    <React.Fragment>
                        <WebinarRegisterDatatable setTotalRows={setTotalRows} totalRows={totalRows} setFilterText={setFilterText} currentPage={currentPage} data={data} refresh={refresh} setRefresh={setRefresh} setCurrentPage={setCurrentPage} setPerpage={setPerpage} pending={pending} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default WebinarRegister;