import request from "../api/agent";
const API_ROUTE = "university/university_type";

export class UniversityTypeService {
    async addOrUpdateUniversityType(data) {
        let action = `${API_ROUTE}/create-or-update-university-type`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllUniversityType(limit, page, search) {
        if (page) {
            const serchValue = search ?? ""
            const response = await request.get(`${API_ROUTE}/get-all-university-type?page=${page}&limit=${limit}&search=${serchValue}`);
            return response.data;
        } else {
            const response = await request.get(`${API_ROUTE}/get-all-university-type`);
            return response.data;
        }
    }

    async getUniversityTypes(value) {
        const searchField = value ?? "";
        const response = await request.get(`${API_ROUTE}/v2/get-university-type-dropdown-listing?search=${searchField}`);
        return response;
    }

    async deleteUniversityType(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-university-type`, {
            data: { ids: [id] }
        });
        return response;
    }
    async setStatus(id) {
        const response = await request.put(`${API_ROUTE}/university-type-status-change`, { id });
        return response;
    }

}