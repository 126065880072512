import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import RecentIntakeDatable from './RecentIntakeDatatable';
import RecentIntakeCreate from './RecentIntakeCreate';
import { RecentIntakeService } from '../../services/RecentIntakeService';
import { checkUserPermission } from '../../checkUserPermission';
import { dataURLtoFile } from '../../functions';

const RecentIntake = () => {

    const recentIntakeService = new RecentIntakeService();

    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [image, setImage] = useState();
    const [totalRows, setTotalRows] = useState(0);
    const [tableloader, setTableloader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [pending, setPending] = useState(true);
    const [scroll, setScroll] = useState(false);
    const [active, setActive] = useState(true);
     
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [scroll])

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };

    async function getAllExpertise() {
        setPending(true)
        const data = await recentIntakeService.getIntakes(perpage, currentPage);
        setData(data.data)
        setTotalRows(data.data.count)
         setPending(false)
    }

    useEffect(() => {
        getAllExpertise()
       
    }, [refresh, perpage, currentPage])
    // const imageValidation = Yup
    // .mixed()
    // .required("Required (Max size. 2mb)")
    // .test("size", "Image should be less than 2 MB", (value) => {
    //     return value && value.size <= 2000000;
    // })
    // .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
    //     return value && (
    //         value.type === "image/jpeg" ||
    //         value.type === "image/jpg" ||
    //         value.type === "image/png"
    //     );
    // })
    const validationSchema = Yup.object().shape({
        full_name: Yup.string().required('Required'),
        course_data: Yup.string().required('Required'),
        university_data: Yup.string().required('Required'),
        exam: Yup.string().required('Required'),
        score: Yup.string().required('Required'),
        country: Yup.number().required('Required'),
        // image:imageValidation,
        // expert_in: Yup.array().min().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            instance_id: "",
            full_name: "",
            course_data: "",
            university_data: "",
            exam: "",
            score: "",
            country: "",
            image: "",
            image_url: ""

        },
        validationSchema,
        validate: async (values) => {
            const errors ={}
        if(values?.image == ""){
            errors.image = "Required"
        }
        else if (values?.image !== "" &&values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
            errors.image = "Only jpg/jpeg and png files are allowed!"
        }
        else {
            let kb = values.image.size / 1024; // convert the file size into byte to kb
            let mb = kb / 1024; // convert kb to mb
            if (mb > 2) {
                errors.image = "images should be less than 2 MB"
            }
        } if (values?.image !== "" &&values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            console.log("valuessssssssssssssssss",values)
            setIsloader(true)
            let formData = new FormData();
            if (values.instance_id) {
                formData.append("instance_id", values.instance_id ?? "");
            }
            formData.append("full_name", values.full_name);
            formData.append("course_data", values.course_data);
            formData.append("university_data", values.university_data);
            formData.append("exam", values.exam);
            formData.append("score", values.score);
            formData.append("country", values.country);
            formData.append("image", values?.image);


            recentIntakeService.addOrUpdateIntake(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        question: response?.response?.data?.errors?.title,
                        category: response?.response?.data?.errors?.category,
                        university_course: response?.response?.data?.errors?.university_course,
                        price: response?.response?.data?.errors?.price,
                        about: response?.response?.data?.errors?.about,
                        requirements: response?.response?.data?.errors?.requirements,
                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    const handleEdit = async (item) => {
        console.log("itemmmmmmmmms",item)
        setScroll(preVal=>!preVal)
        formik.setValues({
            instance_id: item.id,
            full_name: item.full_name,
            course_data: item.course_data,
            university_data: item.university_data,
            exam: item.exam,
            score: item.score,
            country: item.country.id,
            image: await dataURLtoFile(item?.image),
            image_url: item?.image,
        })
    }
    console.log(formik)
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Recent Intake</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Recent Intake</li>
                            </ol>
                        </nav>
                    </div>
                    { checkUserPermission("Create Recent Intake") && <React.Fragment>
                        <RecentIntakeCreate
                            formik={formik}
                            isloader={isloader}
                            toaster={toaster}
                            setToaster={setToaster}
                            formRef={formRef}
                            image={image}
                            setImage={setImage}
                        />
                    </React.Fragment>}
                    <React.Fragment>
                        <RecentIntakeDatable
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            totalRows={totalRows}
                            formik={formik}
                            pending={pending}
                            currentPage={currentPage}
                        />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default RecentIntake;