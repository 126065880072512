import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import BasicSweetCallback from '../../SweetAlertCallback';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { BrochureServices } from '../../services/brochureServices';

const brochureservices = new BrochureServices();
const BrochureCreate = ({ data, handleEdit, refresh, setRefresh, loading, formik, isloader, toaster, setToaster, setImage, flag, formRef, imageError }) => {
  const handleClick = () => {
    window.open(formik.values.brochure_url, '_blank').focus();
  };
  const handleChangeFile = (e, name, url) => {
    formik.setValues({ ...formik.values, [name]: e.target.files[0], [url]: URL.createObjectURL(e.target.files[0]) })
  }
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );

  const addBtn = {
    padding: "0.49rem 1.2rem",
    margin: "0 0 0 -2px",
    borderRadius: "0.25rem"
  }

  return (

    <React.Fragment>
      <div className='form_sec'>
        <Form ref={formRef} onSubmit={formik.handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} md="5" controlId="validationCustom04">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formik.values?.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={formik.touched.title && !formik.errors.title}
                isInvalid={formik.touched.title && formik.errors.title}
                placeholder="title"
                maxLength={100}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
            </Form.Group>
            <div style={{ width: "50%" }}>
              <Form.Label>Brochure pdf</Form.Label>
              <Form.Group as={Col} md="5" controlId="formFile" className="mb-3" style={{ display: "flex", width: "400px" }}>
                <div >
                  <Form.Control
                    type="file"
                    name="brochure_pdf"
                    accept="application/pdf"
                    className='brochure_download_input'
                    style={{
                      width: "100%",
                      borderRadius: "0.25rem 0 0 0.25rem"
                    }}
                    onChange={(e) => { handleChangeFile(e, 'brochure_pdf', 'brochure_url') }}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.brochure_pdf && !formik.errors.brochure_pdf}
                    isInvalid={formik.touched.brochure_pdf && formik.errors.brochure_pdf}
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.brochure_pdf}</Form.Control.Feedback>
                </div>
                <div>
                  {
                    formik.values.brochure_url &&
                    <div>
                      <Button className='save-btn me-1 brochure_download_btn' style={addBtn} onClick={handleClick}>View</Button>
                    </div>
                  }
                </div>
              </Form.Group>
              <div><p className='size'>(Only pdf files are allowed)</p></div>
            </div>

          </Row>
          <div className="group">
            {isloader == true ?
              <div className='loader'>
                <img src={loader} alt="" />
              </div>
              :
              <Button type="submit" className='save-btn me-4'>Save</Button>

            }
            {/* <div className='d-flex align-items-center'>
                    <Link onClick={() => handleEdit} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                    <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            > 
                
                        <span className="svg-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                            </svg>
                        </span>
                        </OverlayTrigger>
                    </Link>
                    <BasicSweetCallback onDelete={brochureservices.deleteBrochure}  title="Brochure" setRefresh={setRefresh} refresh={refresh} formik={formik} />
                </div> */}
          </div>
        </Form>
      </div>
    </React.Fragment>
  )
}

export default BrochureCreate;