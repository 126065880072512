import request from "../api/agent";

const API_ROUTE = "/users/user-management";

export class UserService {

    async getUsers(perpage, currentPage,search) {
        const serchValue = search??""
        if(perpage){
            const response = await request.get(`${API_ROUTE}/get-users?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
            return response.data;
        }else{
            const response = await request.get(`${API_ROUTE}/get-users`);
            return response.data;
        }  
    }

    async addOrUpdateUser(user) {
        let action = `${API_ROUTE}/create-or-update-user`;
        const response = await request.post(action, user);
        return response;
    }

    async getActivatedUser(id) {
        const response = await request.put(`${API_ROUTE}/activate-or-deactivate-users`,  { "user":id } );
        return response;
    }


}