import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import Select from "react-select";
import BasicSweetCallback from '../../SweetAlertCallback';
import { ScholarshipService } from '../../services/ScholarshipService';
import { Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import { checkUserPermission } from '../../checkUserPermission';
const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Edit
    </Tooltip>
);



const scholarshipService = new ScholarshipService();

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
const actions = (
    <IconButton color="primary">
        <Add />
    </IconButton>
);
const contextActions = deleteHandler => (
    <IconButton color="secondary" onClick={deleteHandler}>
        <Delete />
    </IconButton>
);


const ScholarshipDatatable = ({ setCountrySearch, countries, setCountryFilterId, countryFilterId, pending, filterId, scholarType, setFilterId, data, setFilterText, handleEdit, currentPage, refresh, setRefresh, handlePerRowsChange, setCurrentPage, totalRows, formik }) => {

    const [country, setCountry] = useState("");

    const columns = [
        {
            name: "Sl.No",
            key: "index",
            cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10,
            center: true,
        },
        {
            name: 'Title',
            // selector: row => row.title,
            cell: (row) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.title}>
                    {row.title}
                </div>,
            sortable: true,
            reorder: true,
            center: true,
        },
        {
            name: 'Scholarship Type',
            selector: row => row.scholarship_type_name,
            sortable: true,
            grow: 2,
            reorder: true,
            center: true,
        },
        {
            name: 'Scholarships Awarded',
            selector: row => row.scholarships_awarded,
            sortable: true,
            grow: 3,
            reorder: true,
            center: true,
        },
        {
            name: 'University',
            // selector: row => row.university_course_name,
            cell: (row) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.university_course_name}>
                    {row.university_course_name}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
            center: true,
        },
        {
            name: 'Status',
            selector: row => <div class="form-check form-switch"  >
                <input onChange={() => {
                    scholarshipService.setSchoplarshipStatus(row.id).then((response) => {
                        setRefresh(!refresh)
                    })
                }} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_active} />
                <label class="form-check-label" for="flexSwitchCheckDefault"></label>
            </div>
            ,
            sortable: true,
            reorder: true,
            center: true,
            grow: 2,
        },
        {
            name: 'Is popular',
            selector: row => <div class="form-check form-switch"  >
                <input onChange={() => {
                    scholarshipService.setIspopular(row.id).then((response) => {
                        setRefresh(!refresh)
                    })
                }} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_popular} />
                <label class="form-check-label" for="flexSwitchCheckDefault"></label>
            </div>
            ,
            sortable: true,
            reorder: true,
            center: true,
            grow: 2,
        },
        (checkUserPermission("Delete Sholarship") || checkUserPermission("Edit Sholarship")) && {
            id: 'action',
            name: 'Actions',
            cell: (row) =>
                <div className='d-flex align-items-center'>
                    {checkUserPermission("Edit Sholarship") && <Link onClick={() => handleEdit(row)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <span className="svg-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                    <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </OverlayTrigger>
                    </Link>}
                    {checkUserPermission("Delete Sholarship") && <BasicSweetCallback onDelete={scholarshipService.deleteScholarship} deleteId={row.id} title="Scholarship" setRefresh={setRefresh} refresh={refresh} formik={formik} />}
                </div>,
            sortable: true,
            center: true,
            grow: 2,
        },
    ];

    return (
        <React.Fragment>
            <div className='mt-5'>
                <Card style={{ height: '100%' }}>
                    <div className="filter-main">
                        <div className="form-outline searchEnquiry">
                            <input
                                type="search"
                                id="form1"
                                style={{ border: "1px solid #000" }}
                                onChange={(e) => {
                                    setFilterText(e.target.value); setCurrentPage(1);
                                    setRefresh(!refresh);
                                }}
                                className="form-control"
                                placeholder="Search"
                            />
                        </div>
                        <div className='filterEnquiry'>
                            <Form.Select
                                placeholder="Search"
                                className="select-dropdown"
                                classNamePrefix="select"
                                style={{
                                    border: "1px solid #000",
                                    zIndex: 1
                                }}
                                name="country"
                                value={filterId}
                                onChange={(e) => {
                                    setFilterId(e.target.value)
                                    setCurrentPage(1)
                                    setRefresh(!refresh)
                                }}
                            >
                                <option value="">Select scholarship type</option>
                                {scholarType?.map((item) => (
                                    <option value={item.value}>{item.label}</option>
                                ))}
                            </Form.Select>
                        </div>
                        {/* 
                        <div className='countryFilter'>
                            <Form.Select
                                placeholder="Search"
                                className="select-dropdown"
                                classNamePrefix="select"
                                style={{
                                    border: "1px solid #000",
                                    zIndex: 1
                                }}
                                name="country"
                                value={countryFilterId}
                                onChange={(e) => {
                                    setCountryFilterId(e.target.value);
                                    setCurrentPage(1);
                                    setRefresh(!refresh);
                                }}
                            >
                                <option value="">Select Country</option>
                                {countries?.map((item) => (
                                    <option value={item.id}>{item.country_name}</option>
                                ))}
                            </Form.Select>
                        </div> */}
                        <div className='countryFilter'>
                            <Select
                                value={country}
                                options={countries}
                                isClearable
                                isSearchable
                                onInputChange={(e) => {
                                    setCountrySearch(e)
                                    setRefresh(!refresh);
                                }}
                                onChange={(e) => {
                                    if(e){
                                        setCountry(e);
                                        setCountryFilterId(e?.value);
                                        console.log(e)
                                        setCurrentPage(1);
                                        setRefresh(!refresh);
                                    }else{
                                        setCountry(e);
                                        setCountryFilterId("");
                                        console.log(e)
                                        setCurrentPage(1);
                                        setRefresh(!refresh);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <DataTable
                        title="Scholarship"
                        columns={columns}
                        data={data?.results}
                        defaultSortFieldId={1}
                        highlightOnHover
                        defaultSortField="name"
                        sortIcon={sortIcon}
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                        onChangePage={(page) => { setCurrentPage(page); setRefresh(!refresh); }}
                        onChangeRowsPerPage={handlePerRowsChange}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        formik={formik}
                        progressPending={pending}
                    />
                </Card>
            </div>
        </React.Fragment>
    )
}

export default ScholarshipDatatable;
