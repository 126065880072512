import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { GroupService } from '../../services/GroupService';
import Select from 'react-select';

let options = [];

const UserCreate = ({ setGroupId, formik, isloader, formRef, passField }) => {
    const groupService = new GroupService();
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        async function getAllGroup() {
            const data = await groupService.getGroups();
            options = []
            data.data?.results.forEach((item) => {
                options.push({ value: item.pk, label: item.name })
            })
            setUpdate(!update)
        }
        getAllGroup()
    }, [])
    const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
}

    const onchangePhone = (e) => {
        if (e.target.value !== '') {
            if (e.target.value.length <= 10 && e.target.value.length > 0) {
              formik.handleChange(e)
            }
          } else {
            formik.handleChange(e)
          }       
    }

    const handleOnSelectChange = ({ name, value }) => {
        formik.setValues({ ...formik.values, [name]: value })
        setGroupId(value.map((val) => val.value))
    }

    const style = {
        control: (styles) => ({
            ...styles,
            borderColor: formik.errors.groups ? "#dc3545" : "white",
            fontSize: "14px",
            height: "10px",

            "&:focus": {
                boxShadow: "#f5b7bd 0px 0px 0px 3px",
                borderColor: "#dc3545",
            },
        }),
    };


    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                value={formik.values?.username}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.username && !formik.errors.username}
                                isInvalid={formik.touched.username && formik.errors.username}
                                placeholder="User Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.username}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formik.values?.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.email && !formik.errors.email}
                                isInvalid={formik.touched.email && formik.errors.email}
                                placeholder="Email"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Groups</Form.Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isMulti
                                value={formik.values.groups}
                                // styles={style}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.groups && !formik.errors.groups}
                                isInvalid={formik.touched.groups && formik.errors.groups}
                                name="groups"
                                options={options}
                                onChange={(e) => { handleOnSelectChange({ name: 'groups', value: e }) }}
                                placeholder="Select Group Type..."
                            />
                        <div className="errorMessage text-danger mt-1">
                            {formik.errors.groups}
                        </div>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="number"
                                name="phone_number"
                                value={formik.values?.phone_number}
                                onChange={(e) => {
                                    onchangePhone(e)
                                }}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.phone_number && !formik.errors.phone_number}
                                isInvalid={formik.touched.phone_number && formik.errors.phone_number}
                                placeholder="Phone Number"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.phone_number}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    {passField && <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type={showPassword ? 'input' : 'password'}
                                name="password"
                                value={formik.values?.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.password && !formik.errors.password}
                                isInvalid={formik.touched.password && formik.errors.password}
                                placeholder="Password"
                                maxLength={100}
                            />
                             <div className=' icon-eye-two'> 
                                {showPassword ?  <svg xmlns="http://www.w3.org/2000/svg"   className=' icon-eye' width="18" height="18" fill="currentColor" onClick={() => { togglePasswordVisibility() }} viewBox="0 0 16 16">
                                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                                
                                                            </svg>
                                                            :
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { togglePasswordVisibility() }} className="bi bi-eye-slash" viewBox="0 0 16 16">
                                                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                                            </svg>}
                                 </div>
                            <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
                        </Form.Group>

                    </Row>}

                    <div className="group">
                        {isloader === true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default UserCreate;