import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { Link } from 'react-router-dom';
import BasicSweetCallback from '../../SweetAlertCallback';
import { EnquiryService } from '../../services/enquiryService';
import { Form } from 'react-bootstrap'
import { checkUserPermission } from '../../checkUserPermission';


const enquiryService = new EnquiryService();
const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

const EnquiryDatatable = ({ data,pending, filterId, setFilterText, setFilterId, currentPage, refresh, setRefresh, handlePerRowsChange, setCurrentPage, totalRows, formik }) => {


    const options = [
        {
            key: 1,
            name: 'Free Session'
        },
        {
            key: 2,
            name: 'Education Couselling'
        },
        {
            key: 3,
            name: 'Student Visa'
        },
        {
            key: 4,
            name: 'IELTS'
        },
        {
            key: 5,
            name: 'Education Loan'
        },
        {
            key: 6,
            name: 'Scholarship'
        },
        {
            key: 7,
            name: 'Post-Landing'
        },
        {
            key: 8,
            name: 'Blog'
        },
    ]


    const columns = [
        {
            name: "Sl.No",
            key: "index",
            cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10,
            grow: 2,
        },       
        {
            name: 'Full Name',
            selector: row => row.full_name,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            name: 'Mobile Number',
            selector: row => row.mobile_number,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        checkUserPermission("Edit Enquiry") && {
            id: 'action',
            name: 'Actions',
            cell: (row) =>
                <div className='d-flex align-items-center'>
                    <BasicSweetCallback onDelete={enquiryService.deleteEnquiry} deleteId={row.id} title="Enquiry" setRefresh={setRefresh} refresh={refresh} formik={formik} />
                </div>,
            
            grow: 2,
        },
    ];

    return (
        <React.Fragment>
            <div className='mt-5'>
                <Card style={{ height: '100%' }}>
                    <div className="input-group">
                        <div className="form-outline searchEnquiry">
                            <input
                                style={{
                                    border: "1px solid #000",
                                }}
                                type="search"
                                id="form1"
                                onChange={(e) => {
                                    setFilterText(e.target.value); setCurrentPage(1);
                                    setRefresh(!refresh);
                                }}
                                className="form-control"
                                placeholder="Search"
                            />
                        </div>
                        <div className='filterEnquiry'>
                            <Form.Select
                                placeholder="Search"
                                className="select-dropdown "
                                classNamePrefix="select"
                                style={{
                                    border: "1px solid #000",
                                    zIndex: 1
                                }}
                                name="country"
                                value={filterId}
                                onChange={(e) => {
                                    setFilterId(e.target.value)
                                }}
                            >
                                <option value="">Select Category</option>
                                {options?.map((item) => (
                                    <option value={item.key}>{item.name}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                    <DataTable
                        title="Enquiry"
                        columns={columns}
                        data={data?.results}
                        defaultSortFieldId={1}
                        highlightOnHover
                        defaultSortField="name"
                        sortIcon={sortIcon}
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                        onChangePage={(page) => {setCurrentPage(page);setRefresh(!refresh);}}
                        onChangeRowsPerPage={handlePerRowsChange}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        formik={formik}
                        progressPending={pending}
                    />
                </Card>
            </div>
        </React.Fragment>
    )
}

export default EnquiryDatatable;
