import React, { useRef, useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';

// import * as Yup from 'yup';
// import { newsCreateOrUpdate } from '../../../Redux/News/action';
// import { useFormik } from 'formik';
// import { dataURLtoFile } from '../../functions';
 import { toast, ToastContainer } from 'react-toastify';
import ContactusDatatable from './ContactusDatatable';
import { ContactService } from '../../services/contactservice';


const contactservice = new ContactService();


const ContactUs = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState('');
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [perpage, setPerpage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = React.useState('');
    const [active, setActive] = useState(true);
    const [pending, setPending] = useState(true);

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)

    };
    async function getallContacts() {
        active &&  setPending(true)
        const data = await contactservice.getContact(perpage, currentPage,filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        active &&  setPending(false)
    }
        useEffect(() => {
            getallContacts()
            setActive(false)
        }, [refresh])


    // const validationSchema = Yup.object().shape({
    //     title: Yup.string()
    //         .required('Required'),
    //     description: Yup.string()
    //         .required('Required'),
    //     // image: Yup.string()
    //     //     .required('Required'),
    // });

    // const formik = useFormik({
    //     initialValues: {
    //         title: "",
    //         description: "",
    //         image: "",
    //     },
    //     validationSchema,
    //     validate: async (values) => {
    //         const errors = {}
    //         // let image = values.image


    //         // values.id ? image = await dataURLtoFile(values.image) : image = values.image
    //         if (values.image == "") {
    //             errors.image = "Required"
    //         }
    //         else if (values.image.type != "image/png" && values.image.type != "image/jpg" && values.image.type != "image/jpeg") {
    //             errors.image = "Only jpg/jpeg and png files are allowed!"
    //         }
    //         console.log(values.image);
    //         return errors
    //     },
    //     onSubmit: (values) => {
    //         console.log(values);
    //         setIsloader(true)
    //         let formData = new FormData();
    //         if (values.id) {
    //             formData.append("id", values.id ?? "");
    //         }
    //         formData.append("title", values.title);
    //         formData.append("description", values.description);
    //         formData.append("image", image);
    //         newsCreateOrUpdate({ formData })((response) => {
    //             console.log(response);
    //             if (response && response.status) {
    //                 setIsloader(false)
    //                 console.log(response?.data?.data)
    //                 toast.success("Success", {
    //                     position: toast.POSITION.TOP_RIGHT
    //                 });
    //                 setRefresh(true)
    //                 formik.resetForm()
    //                 formRef.current.reset();
    //             }
    //             else {
    //                 formik.setErrors({
    //                     title: response?.response?.data?.errors?.title,
    //                     description: response?.response?.data?.errors?.description,
    //                     image: response?.response?.data?.errors?.image,
    //                 });
    //                 console.log(response?.response?.data?.errors);
    //                 setIsloader(false)
    //                 toast.error("Something went wrong", {
    //                     position: toast.POSITION.TOP_RIGHT
    //                 });
    //             }
    //         });
    //     },
    // });
    // const handleEdit = async (item) => {
    //     setImage(await dataURLtoFile(item.image))
    //     formik.setValues({
    //         id: item.id,
    //         title: item.title,
    //         image: await dataURLtoFile(item.image),
    //         description: item.description
    //     })
    //     console.log(item);
    // }
    

    // console.log(formik.values, '::::::::<<<<<<<<<<<<<>>>>>>>>>>');
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Contact Us</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                    {/* <React.Fragment> */}
                        {/* <NewsCreate image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} /> */}
                    {/* </React.Fragment> */}
                    <React.Fragment>
                        <ContactusDatatable pending={pending} currentPage={currentPage} setFilterText={setFilterText} setCurrentPage={setCurrentPage}  refresh={refresh} setRefresh={setRefresh} handlePerRowsChange={handlePerRowsChange} data={data} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )

    }

export default ContactUs;