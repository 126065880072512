import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { CourseTypeService } from '../../services/CourseTypeService';
import { StreamService } from '../../services/StreamService';

const courseTypeService=new CourseTypeService();
const courseStreamService=new StreamService();
const CreateCourse=({
    courseType,
    streams,
    setCourseType,
    setStreams,
    formik,
    isloader,
    setImage,
    formRef }) => {

    useEffect(() => {
        courseTypeService.getAllCourseType().then((response) => {
            setCourseType(response?.data?.results)
        })
        courseStreamService.getAllStreams().then((response) => {
            setStreams(response?.data?.results)
        })

    }, [])

    const onChangeImage=(e) => {
        setImage(e.target.files[0])
        formik.setValues({
            ...formik.values,
            image: e.target.files[0]? e.target.files[0]:"",
            image_url: e.target.files[0]? URL.createObjectURL(e.target.files[0]):"",
        })
    }
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="course_name"
                                value={formik.values?.course_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.course_name&&!formik.errors.course_name}
                                isInvalid={formik.touched.course_name&&formik.errors.course_name}
                                placeholder="Eg:Master of Philosophy "
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.course_name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Image<span className='required ms-1'>*</span></Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        onChangeImage(e)
                                    }
                                    }
                                    onBlur={formik.handleBlur}
                                    // isValid={formik.touched.image && !formik.errors.image}
                                    isInvalid={formik.touched.image&&formik.errors.image}
                                />
                                {formik.values?.image_url&&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }
                                {console.log("ppp", formik.errors.image)}
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.image}
                                </Form.Control.Feedback>

                            </div>
                            <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 80 * 80 px)</p></div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Course Type</Form.Label>
                            <Form.Select
                                // placeholder=""
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="course_type"
                                value={formik.values?.course_type}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.course_type&&!formik.errors.course_type}
                                isInvalid={formik.touched.course_type&&formik.errors.course_type}
                                onChange={formik.handleChange}
                            >
                                <option value='' >Select a Course Type</option>
                                {courseType?.map(type =>
                                    <option value={type?.id}>{type?.course_type_name}</option>
                                )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.course_type}</Form.Control.Feedback>
                        </Form.Group>

                        {/* <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Course Stream</Form.Label>
                            <Form.Select
                                placeholder="Select an Option"
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="course_stream"
                                value={formik.values?.course_stream}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.course_stream && !formik.errors.course_stream}
                                isInvalid={formik.touched.course_stream && formik.errors.course_stream}
                                onChange={formik.handleChange}
                            >
                                <option value='' >Select an Option</option>
                                {streams?.map(stream =>
                                    <option value={stream?.id}>{stream?.course_stream_name}</option>
                                )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.course_stream}</Form.Control.Feedback>
                        </Form.Group> */}
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Course mode</Form.Label>
                            <Form.Select
                                placeholder="Course mode"
                                // className="select-dropdown"
                                // classNamePrefix="select"
                                // name="course_nature"
                                // value={formik.values?.course_nature}
                                // // isInvalid={!!formik.errors?.errors?.course_nature}
                                // onChange={formik.handleChange}
                                name="course_nature"
                                value={formik.values?.course_nature}
                                onChange={formik.handleChange}
                                isValid={formik.touched.course_nature&&!formik.errors.course_nature}
                                onBlur={formik.handleBlur}
                            // isInvalid={formik.touched.job_type && formik.errors.job_type}
                            // placeholder="Job Type"
                            >
                                <option value='' >Select a Course mode</option>
                                <option value={"2"}>Full Time</option>
                                <option value={"1"}>Part Time</option>



                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.course_nature}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={formik.values?.description}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const charCount = inputValue.length;
                                    console.log(inputValue.split(/\s+/).length)
                                    // debugger
                                    if(inputValue.split(/\s+/).length <=1000){
                                    formik.handleChange(e);
                                    }else{
                                        alert("Word Limit Exceeded")
                                    }
                                   
                                }}
                                onBlur={formik.handleBlur}
                                style={{ height: '110px' }}
                                // isValid={formik.touched.description && !formik.errors.description}
                                // isInvalid={formik.touched.description && formik.errors.description}
                                placeholder="Description"
                            // maxLength={1000}
                            />
                            {formik.errors.description? <Form.Control.Feedback type="invalid" >{formik.errors.description}</Form.Control.Feedback>:
                                <div className="max-char">{formik.values.description.split(/\s+/).length+"/1000"}</div>

                            }
                        </Form.Group>
                        {/* <Form.Control.Feedback type="invalid">{formik.errors.description}</Form.Control.Feedback>
                        </Form.Group> */}
                    </Row>

                    <div className="group">
                        {isloader==true?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateCourse;