import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import loader from "../../../assets/images/loader.gif";
import { CountryService } from "../../services/CountryServices";
import { BlogService } from "../../services/BlogService";
import { UniversityService } from "../../services/universityService";
import { CourseService } from "../../services/CourseService";
import { BlogCategoryService } from "../../services/BlogcategoryService";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



const countryService = new CountryService();

const blogService = new BlogService();
const universityService = new UniversityService();

const courseService = new CourseService();
const categoriesService = new BlogCategoryService();
const BlogCreate = ({
  formik,
  isloader,
  toaster,
  setToaster,
  setImage,
  flag,
  data,
  formRef,
  imageError,
}) => {
  const [countries, setCountries] = useState([]);
  const [universitys, setUniversitys] = useState([]);
  const [courses, setCourses] = useState([]);
  const [catagories, setCatagories] = useState([]);
  const [formView, setFormView] = useState("details");
  async function getAllCatagories() {
    const data = await blogService.getAllBlogdropdown();
    setCatagories(data?.data?.results);
    console.log("blog categories data", data);
  }


  useEffect(() => {
    getAllCatagories();
  }, []);
  const inputHandler = (event, editor) => {
    formik.setFieldValue("description", editor.getData());
  };
  const onChangeOgImage = (e) => {
    setImage(e.target.files[0])
    formik.setValues({ ...formik.values, og_image: e.target.files[0], og_image_url: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "" })
  }
  const onChangeImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      formik.setValues({
        ...formik.values,
        image: e.target.files[0],
        image_url: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  return (
    <React.Fragment>
      <div className="form_sec">
        <Form ref={formRef} onSubmit={formik.handleSubmit}>
          {formView == "details" && (
            <Fragment>
              <Row className="mb-6">
                <Form.Group as={Col} md="6" controlId="validationCustom04" className='mb-2'>
                  <Form.Label>Blog Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="blog_title"
                    value={formik.values?.blog_title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.blog_title && !formik.errors.blog_title}
                    isInvalid={formik.touched.blog_title && formik.errors.blog_title}
                    placeholder="Blog Title"
                    maxLength={100}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.blog_title}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03" className='mb-2'>
                  <Form.Label>Blog Category</Form.Label>
                  <Form.Select
                    placeholder="Blog Category"
                    className="select-dropdown"
                    classNamePrefix="select"
                    name="blog_category"
                    value={formik.values?.blog_category}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.blog_category && !formik.errors.blog_category}
                    isInvalid={formik.touched.blog_category && formik.errors.blog_category}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select an Option</option>
                    {catagories?.map(category =>
                      <option value={category?.id}>{category?.category_name}</option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{formik.errors.blog_category}</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-6" >
                <Form.Group as={Col} md="6" controlId="validationCustom04" className='mb-2'>
                  <Form.Label> Type</Form.Label>
                  <Form.Select
                    placeholder="type"
                    className="select-dropdown"
                    classNamePrefix="select"
                    name="category"
                    value={formik.values?.category}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.category && !formik.errors.category}
                    isInvalid={formik.touched.category && formik.errors.category}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select an Option</option>

                    <option value='blog'>blog</option>
                    <option value='article'>article</option>

                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{formik.errors.category}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="formFile"
                  className="mb-2 "

                >
                  <Form.Label>Blog Image<span className='required ms-1'>*</span></Form.Label>
                  <div className="file_wrapper" >
                    <Form.Control
                      type="file"
                      name="blog_image"
                      accept="image/*"
                      values={formik.values.blog_image}
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                        formik.setValues({
                          ...formik.values,
                          blog_image: e.target.files[0],
                          image_url: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "",
                        });
                      }}
                      onBlur={formik.handleBlur}
                      isValid={formik.touched.blog_image && !formik.errors.blog_image}
                      isInvalid={formik.touched.blog_image && formik.errors.blog_image}
                    />
                    {formik.values?.image_url && (
                      <div className="img-wrapper">
                        <img
                          src={formik.values?.image_url}
                          alt="course"
                          style={{ height: "100px" }}
                          className="img-fluid mt-2"
                        />
                      </div>
                    )}
                    {/* {!formik.touched.blog_image && !formik.values.blog_image && <div style={{
                      fontSize: "14px",
                      color: "#198754",
                      margin: "4px 0 0 4px",
                      fontWeight: "500"
                    }}>Max size. 2mb</div>} */}
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.blog_image}
                    </Form.Control.Feedback>
                  </div>
                  <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 800 * 600 px)</p></div>
                </Form.Group>

              </Row>

              <Row className="mb-2">

                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Description</Form.Label>

                  <CKEditor
                    name="description"
                    id="ck_description"
                    editor={ClassicEditor}
                    height={500}
                    // style={{height:'110px'}}
                    config={{
                      toolbar: {
                        items: [
                          '|',
                          'heading',
                          'bold',
                          'italic',
                          'underline',
                          'strikethrough',
                          'subscript',
                          'superscript',
                          'link',
                          'blockQuote',
                          'insertTable',
                          'undo',
                          'redo',
                          'alignment',
                          'fontSize',
                          'fontColor',
                          'backgroundColor',
                          'list',
                          'bulletedList',
                          'indent',
                          'outdent',
                          'code',
                          'codeBlock',
                        ]
                      },
                      language: 'en',
                    }}
                    data={formik.values?.description}
                    // onChange={(e, editor) => { console.log(e, editor); formik.setValues({ ...formik.values, description: editor.getData() }); }}
                    // data="<p>Hello from CKEditor 5!</p>"
                    // data=""
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    // onChange={ ( event, editor ) => {
                    //     const data = editor.getData();
                    //     console.log( { event, editor, data } );
                    // } }
                    onChange={inputHandler}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  />

                  <Form.Control.Feedback type="invalid">{formik.errors.description}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Meta description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="meta_description"
                    value={formik.values?.meta_description}
                    onChange={formik.handleChange}
                    isValid={formik.touched.meta_description && !formik.errors.meta_description}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.meta_description && formik.errors.meta_description}
                    placeholder="meta description"
                    style={{ height: '138px' }}
                    maxLength={250}
                  />
                  {/* {formik.errors.meta_description ?  <Form.Control.Feedback type="invalid">{formik.errors.meta_description}</Form.Control.Feedback> :
                  <div className="max-char">{formik.values.description.length + "/250"}</div>} */}
                  {formik.errors.meta_description ? <Form.Control.Feedback type="invalid">{formik.errors.meta_description}</Form.Control.Feedback> : <div className="max-char">{formik.values.meta_description.length + "/250"}</div>}

                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                  <Form.Label>Og image<span className='required ms-1'>*</span></Form.Label>
                  <div className="file_wrapper">
                    <Form.Control
                      type="file"
                      name="og_image"
                      accept="image/*"
                      values={formik.values.og_image}
                      onChange={(e) => {
                        onChangeOgImage(e)
                      }
                      }
                      isValid={formik.touched.og_image && !formik.errors.og_image}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.og_image && formik.errors.og_image}
                    />
                    {formik.values?.og_image_url &&
                      <div className='img-wrapper'>
                        <img src={formik.values?.og_image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                      </div>
                    }
                    {/* {!formik.touched.og_image && !formik.values.og_image && <div style={{
                      fontSize: "14px",
                      color: "#198754",
                      margin: "4px 0 0 4px",
                      fontWeight: "500"
                    }}>Max size. 2mb</div>} */}
                    <Form.Control.Feedback type="invalid">{formik.errors.og_image}</Form.Control.Feedback>

                  </div>
                  <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 1200 * 630 px)</p></div>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Meta image title</Form.Label>
                  <Form.Control
                    type="text"
                    name="meta_image_title"
                    value={formik.values?.meta_image_title}
                    onChange={formik.handleChange}
                    isValid={formik.touched.meta_image_title && !formik.errors.meta_image_title}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.meta_image_title && formik.errors.meta_image_title}
                    placeholder="meta image title"

                    maxLength={100}
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.meta_image_title}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Meta title</Form.Label>
                  <Form.Control
                    type="text"
                    name="meta_title"
                    value={formik.values?.meta_title}
                    onChange={formik.handleChange}
                    isValid={formik.touched.meta_title && !formik.errors.meta_title}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.meta_title && formik.errors.meta_title}
                    placeholder="meta title"

                    maxLength={100}
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.meta_title}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Meta keyword (seperated by commas)</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="meta_keyword"
                    value={formik.values?.meta_keyword}
                    onChange={formik.handleChange}
                    isValid={formik.touched.meta_keyword && !formik.errors.meta_keyword}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.meta_keyword && formik.errors.meta_keyword}
                    placeholder="meta keyword"

                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.meta_keyword}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Posted By</Form.Label>
                  <Form.Control
                    type="text"
                    name="posted_by"
                    value={formik.values?.posted_by}
                    onChange={formik.handleChange}
                    isValid={formik.touched.posted_by && !formik.errors.posted_by}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.posted_by && formik.errors.posted_by}
                    placeholder="Posted By"

                    maxLength={100}
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.posted_by}</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="group">
                {isloader == true ?
                  <div className='loader'>
                    <img src={loader} alt="" />
                  </div>
                  :
                  <Button type="submit" className='save-btn me-4'>Save</Button>
                }
              </div>
            </Fragment>
          )}
        </Form>
      </div>
    </React.Fragment>
  );
};

export default BlogCreate;
