import request from "../api/agent";

const API_ROUTE = "scholarships/scholarships";

export class ScholarshipWinnerService{
    async getWinners(perpage, currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-scholarships-winners?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }

    async addOrUpdateWinners(scholarshipData) {
        let action = `${API_ROUTE}/scholarships-winners-create-or-update`;
        const response = await request.post(action, scholarshipData);
        return response;
    }

    async deleteWinners(id) {
        const response = await request.delete(`${API_ROUTE}/scholarships-winners-delete`, { data: { instance_id: [id] } });
        return response;
    }

    async setWinnersStatus(id) {
        const response = await request.post(`${API_ROUTE}/scholarships-winners-status`, { instance_id: id });
        return response;
    }
}