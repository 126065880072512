import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import { BlogEnquiryService } from '../../services/blogEnquiryservice';
import BlogEnquiryDatatable from './blogenquiryDatatable';

const BlogEnquiry = () => {
    const blogenquiryService = new BlogEnquiryService();

    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
   // const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = React.useState(true)

    const handlePerRowsChange = async (newPerPage) => {
      setPerpage(newPerPage)
    };
  
    async function getAllEnquires() {
      setPending(true)
      const data = await blogenquiryService.getblogEnquiry(perpage, currentPage,filterText);
      setPending(false)
      setData(data.data.results)
      // console.log("data",data);

    console.log("dattaaa",data.data);
      setTotalRows(data.data.count)
    }


  
    useEffect(() => {
      getAllEnquires()
    }, [refresh, currentPage, perpage,filterText])
console.log(data)
  return (
    <React.Fragment>
      <ToastContainer />
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Blog Enquiry</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active"> Blog  Enquiry</li>
              </ol>
            </nav>
          </div>
          <React.Fragment>
            <BlogEnquiryDatatable currentPage={currentPage} pending={pending} filterText={filterText} setFilterText={setFilterText}  data={data}  refresh={refresh} setRefresh={setRefresh} handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} totalRows={totalRows} />
          </React.Fragment>
        </main>
      </>
    </React.Fragment>
  )
}

export default BlogEnquiry;