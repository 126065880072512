
import React, { useEffect, useRef, useState } from 'react'


import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { TestimonialVideoService } from '../../services/testimonialVideoService';
import TestimonialVideoDatatable from './TestimonialVideoDatatable';
import { dataURLtoFile } from '../../functions';
import CreateTestimonial from './CreateTestimonial';
import { checkUserPermission } from '../../checkUserPermission';


const Testimonial = () => {
  const testimonialVideoService = new TestimonialVideoService();


  const formRef = useRef(null);
  const [isloader, setIsloader] = useState(false);
  const [toaster, setToaster] = useState("");
  const [thumbnail, setThumbnail] = useState();
  const [data, setData] = React.useState([]);
  const [refresh, setRefresh] = useState(false);
  const [image, setImage] = useState();
  const [pending, setPending] = React.useState(true)
  const [filterText, setFilterText] = React.useState('');
  const [totalRows, setTotalRows] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [active, setActive] = useState(true);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [scroll])

  const handlePerRowsChange = async (newPerPage) => {
    setPerpage(newPerPage);
    setRefresh(!refresh);
  };

  async function getTestimonialVideos() {
      setPending(true)
    const data = await testimonialVideoService.getVideo(perpage, currentPage, filterText);
    setData(data.data)
    setTotalRows(data.data.count)
      setPending(false)
  }

  useEffect(() => {
    getTestimonialVideos()
  }, [refresh])


  const imageValidation = Yup
    .mixed()
    .required("Required (Max size. 2mb)")
    .test("size", "Image must be less than 2mb", (value) => {
      return value && value.size <= 2000000;
    })
    .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
      return value && (
        value.type === "image/jpeg" ||
        value.type === "image/jpg" ||
        value.type === "image/png"
      );
    })

  const videoUrl = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/

  const validationSchema = Yup.object().shape({
    video: Yup.string().matches(videoUrl, "Enter valid url!").required("Required"),
    // image: imageValidation
  });


  const formik = useFormik({
    initialValues: {
      instance_id: "",
      video: "",
      image: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("valuesss",values);
      setIsloader(true)
      let formData = new FormData();
      if (values.instance_id) {
        formData.append("instance_id", values.instance_id ?? "");
      }
      formData.append("video", values.video);
      formData.append("thumbnail", values.image);



      testimonialVideoService.addOrUpdateVideo(formData).then((response) => {
        if (response && response.status) {
          setIsloader(false)
          console.log(response?.data?.data)
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT
          });
          setRefresh(!refresh)
          formik.resetForm()
          formRef.current.reset();
        }
        else {
          formik.setErrors({
            instance_id: response?.response?.data?.errors?.instance_id,
            video: response?.response?.data?.errors?.video,
            image: response?.response?.data?.errors?.thumbnail,

          });
          console.log(response?.response?.data?.errors);
          setIsloader(false)
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    },
  });
  const handleEdit = async (item) => {
    setScroll(preVal => !preVal)
    formik.setValues({
      instance_id: item.id,
      video: item.video,
      image: await dataURLtoFile(item.thumbnail),
      image_url: item?.thumbnail

    })
  }

  console.log("ppppppppp",formik.errors)
  return (
    <div><React.Fragment>
      <ToastContainer />
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Testimonial Video</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Testimonial Video</li>
              </ol>
            </nav>
          </div>
          {checkUserPermission("Create Testimonial Video") && <React.Fragment>
            <CreateTestimonial thumbnail={thumbnail} setImage={setImage} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setThumbnail={setThumbnail} formRef={formRef} />
          </React.Fragment>}
          <React.Fragment>
            <TestimonialVideoDatatable
              data={data}
              handleEdit={handleEdit}
              pending={pending}
              refresh={refresh}
              setRefresh={setRefresh}
              handlePerRowsChange={handlePerRowsChange}
              setCurrentPage={setCurrentPage}
              totalRows={totalRows}
              formik = {formik}
              currentPage={currentPage}
              setFilterText={setFilterText}
            />
          </React.Fragment>
        </main>
      </>
    </React.Fragment></div>
  )
}

export default Testimonial