import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { BlogCategoryService } from '../../services/BlogcategoryService'
import BlogcategoryDatatable from './blogcategoryDatatable';
import BlogcategoryCreate from './createBlogcategory';
import { checkUserPermission } from '../../checkUserPermission';

const Blogcategory = () => {

    const blogcategoryservice = new BlogCategoryService();

    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [pending, setPending] = React.useState(true)
    const [filterText, setFilterText] = React.useState('');
    const [scroll, setScroll] = useState(false);
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState(true);


    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage);
        setRefresh(!refresh);
    };

    async function getAllBlogcategory() {
      setPending(true)
        const data = await blogcategoryservice.getBlogcategory(perpage, currentPage,filterText);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
        // setLoading(false)
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])


    useEffect(() => {
        getAllBlogcategory()
        
    }, [refresh])

    const validationSchema = Yup.object().shape({
        category_name: Yup.string()
            .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            category: "",
            category_name: "",

        },
        validationSchema,
        onSubmit: (values) => {
            console.log("val", values);
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.category) {
                formData.append("category", values.category ?? "");
            }
            formData.append("category_name", values.category_name);

            blogcategoryservice.addOrUpdateBlogcategory(formData).then((response) => {
                // })
                // CreateOrUpdateCourse({ formData })((response) => {
                console.log("response", response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        category_name: response?.response?.data?.errors?.title,
                    });
                    console.log("res", response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },

    });

    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        formik.setValues({
            category: item.id,
            category_name: item.category_name,
        })
        console.log("here is edit", item
        );
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Blog Category</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Blog Category</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Blog Category") && <React.Fragment>
                        <BlogcategoryCreate
                            formik={formik}
                            isloader={isloader}
                            toaster={toaster}
                            setToaster={setToaster}
                            formRef={formRef}
                        />
                    </React.Fragment>}
                    <React.Fragment>
                        <BlogcategoryDatatable
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            totalRows={totalRows}
                            formik={formik}
                            pending={pending}
                            filterText={filterText} 
                            setFilterText={setFilterText}                      
                            loading={loading}
                        />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default Blogcategory;