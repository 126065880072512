import request from "../api/agent";
const API_ROUTE = "webinar/speaker";

export class SpeakerService {
    async addOrUpdateSpeaker(data) {
        let action = `${API_ROUTE}/create-or-update-speaker`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllSpeaker(perpage, currentPage, search) {
        const serchValue = search ?? ""
        if (perpage) {
            const response = await request.get(`${API_ROUTE}/speaker-all-listing?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
            return response.data;
        } else {
            const response = await request.get(`${API_ROUTE}/speaker-all-listing`);
            return response.data;
        }
    }

    async deleteSpeaker(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-speaker`, {
            data: { id }
        });
        return response;
    }

    async setActiveSpeaker(id) {
        const response = await request.put(`${API_ROUTE}/upcomingbatch-status`, { id });
        return response;
    }
}