import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { UniversityCourseService } from '../../services/UniversityCourseService';
import Select from 'react-select';
import { ScholarshipCategoryService } from '../../services/scholarshipCategoryService';
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";



const ScholarshipCreate = ({ formik, isloader, formRef, formView, setFormView, scholarType, universityData, setUniversityData, markAllFieldsAsTouched }) => {

    const universityCourseService = new UniversityCourseService();
    const [universitycourses, setUniversityCourses] = useState([]);
    const [universityTypes, setUniversityTypes] = useState([]);

    async function getAllUniversityTypes() {
        const data = await universityCourseService.getUniversityCourses(10, 1, '');
        let defaultData = []
        data?.data?.data?.results?.map((type) => {
            defaultData.push({ value: type?.id, label: type?.course_name + ' | ' + type?.university_name })
        })
        setUniversityTypes(defaultData)
        console.log("university course dataaa", data?.data?.data?.results);
    }
    const animatedComponents = makeAnimated();
    const onChangeFilerForm = ({ name, value, label }) => {
        formik.setValues({ ...formik.values, [name]: value });
        setUniversityData([{ value: value, label: label }]
        );
    };

    useEffect(() => {
        // getAllUniversityCourses()
        getAllUniversityTypes()
    }, [])
    const classLoadOptions = async (inputValue) => {
        // setTimeout(() => {
        //     callback(filterClasses(inputValue));
        // }, 1000);
        let defaultData = []
        const data = await universityCourseService.getUniversityCourses({ search: inputValue, perpage: 10, currentPage: 1 });
        data?.data?.results?.map((type) => {
            defaultData.push({ value: type?.id, label: type?.course_name + ' | ' + type?.university_name })
        })
        return defaultData
    };

    const handleOnSelectChange = ({ name, value }) => {
        formik.setValues({ ...formik.values, [name]: value })
    }

    const style = {
        control: (styles) => ({
            ...styles,
            height: "30px",
            borderColor: formik.errors.groups ? "#dc3545" : "white",
            "&:focus": {
                boxShadow: "#f5b7bd 0px 0px 0px 3px",
                borderColor: "#dc3545",
            },
        }),

    };

    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    {formView === "page1" &&
                        <>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        value={formik.values?.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.title && !formik.errors.title}
                                        isInvalid={formik.touched.title && formik.errors.title}
                                        placeholder="Title"
                                        maxLength={100}
                                    />
                                    {formik.touched.title && formik.errors.title &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom010">
                                    <Form.Label>Scholarship Type</Form.Label>
                                    <Form.Select
                                        placeholder="Scholarship Type"
                                        className="select-dropdown"
                                        classNamePrefix="select"
                                        name="scholarship_type"
                                        value={formik.values?.scholarship_type}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.scholarship_type && !formik.errors.scholarship_type}
                                        // isInvalid={formik.touched.scholarship_type && formik.errors.scholarship_type}
                                        onChange={formik.handleChange}
                                    >
                                        <option value="">Select scholarship type</option>
                                        {scholarType?.map((scholar) => (
                                            <option value={scholar?.value}>{scholar?.label}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{formik.errors.scholarship_type}</Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom05">
                                    <Form.Label> University Course</Form.Label>
                                    <AsyncSelect
                                        cacheOptions
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        loadOptions={classLoadOptions}
                                        defaultOptions={universityTypes}
                                        value={formik.values.scholarship}
                                        onChange={(option) => {
                                            onChangeFilerForm({ name: 'scholarship', value: option?.value, label: option?.label });
                                        }}
                                        isSearchable={true}
                                        isClearable={true}
                                        debounceInterval={100}
                                        classNamePrefix="select"
                                        placeholder="Enter the University Course name"
                                        name="scholarship"
                                        value={universityData}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom012">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="price"
                                        value={formik.values?.price}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.price && !formik.errors.price}
                                        // isInvalid={formik.touched.price && formik.errors.price}
                                        placeholder="price"
                                        maxLength={100}
                                    />
                                    {formik.touched.price && formik.errors.price &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.price}</Form.Control.Feedback>
                                    }
                                </Form.Group>


                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="validationCustom08">
                                    <Form.Label>Number of Scholarship Awarded</Form.Label>
                                    <Form.Control
                                        type="string"
                                        name="scholarships_awarded"
                                        value={formik.values?.scholarships_awarded}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.scholarships_awarded && !formik.errors.scholarships_awarded}
                                        // isInvalid={formik.touched.scholarships_awarded && formik.errors.scholarships_awarded}
                                        placeholder="Number of Scholarship Awarded"
                                        maxLength={200}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.scholarships_awarded}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>About Scholarship</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="about_scholarship"
                                        value={formik.values?.about_scholarship}
                                        onChange={(e) => {
                                            const inputValue=e.target.value;
                                            const charCount=inputValue.length;
                                            console.log(inputValue.split(/\s+/).length)
                                            // debugger
                                            if (inputValue.split(/\s+/).length<=1000) {
                                                formik.handleChange(e);
                                            } else {
                                                alert("Word Limit Exceeded")
                                            }

                                        }}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.about_scholarship && !formik.errors.about_scholarship}
                                        isInvalid={formik.touched.about_scholarship && formik.errors.about_scholarship}
                                        placeholder="About Scholarship"
                                        style={{ height: '110px' }}
                                        // maxLength={1000}
                                    />
                                    {/* {formik.errors.about_scholarship ? <Form.Control.Feedback type="invalid" >{formik.errors.about_scholarship}</Form.Control.Feedback> :
                                        <div className="max-char">{formik.values.about_scholarship.length + "/1000"}</div>} */}
                                        {formik.errors.about_scholarship? <Form.Control.Feedback type="invalid" >{formik.errors.about_scholarship}</Form.Control.Feedback>:
                                        <div className="max-char">{formik.values.about_scholarship.split(/\s+/).length+"/1000"}</div>

                                    }
                                </Form.Group>
                                {/* {formik.errors.about_scholarship ? <Form.Control.Feedback type="invalid">{formik.errors.about_scholarship}</Form.Control.Feedback>
                                <div className="max-char">{formik.values.about_scholarship.length + "/250"}</div>}
                            </Form.Group> */}
                            </Row>
                            <div className="group">
                                <Button type="button" onClick={() => {
                                    formik.values.title && formik.values.about_scholarship ? setFormView('page2') : markAllFieldsAsTouched()
                                }} className='save-btn me-4'>Next</Button>
                            </div>
                        </>}
                    {formView === 'page2' &&
                        <>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="validationCustom09">
                                    <Form.Label>Announcement Award On</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="announcement_award_on"
                                        value={formik.values?.announcement_award_on}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.announcement_award_on && !formik.errors.announcement_award_on}
                                        // isInvalid={formik.touched.announcement_award_on && formik.errors.announcement_award_on}
                                        placeholder="Announcement Award On"
                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.announcement_award_on}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="validationCustom08">
                                    <Form.Label>Application Deadline</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="application_deadline"
                                        value={formik.values?.application_deadline}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.application_deadline && !formik.errors.application_deadline}
                                        // isInvalid={formik.touched.application_deadline && formik.errors.application_deadline}
                                        placeholder="Application Deadline"
                                        maxLength={200}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.application_deadline}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="validationCustom010">
                                    <Form.Label>Renewability</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="renewability"
                                        value={formik.values?.renewability}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.renewability && !formik.errors.renewability}
                                        // isInvalid={formik.touched.renewability && formik.errors.renewability}
                                        placeholder="Eg:Annual Renewabile"
                                        maxLength={50}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.renewability}</Form.Control.Feedback>
                                </Form.Group>


                                <Form.Group as={Col} md="6" controlId="validationCustom010">
                                    <Form.Label>Scholarship Amount Details</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="scholarship_amount_details"
                                        value={formik.values?.scholarship_amount_details}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.scholarship_amount_details && !formik.errors.scholarship_amount_details}
                                        // isInvalid={formik.touched.scholarship_amount_details && formik.errors.scholarship_amount_details}
                                        placeholder="Scholarship Amount Details"
                                        maxLength={50}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.scholarship_amount_details}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="validationCustom06">
                                    <Form.Label>Eligibility</Form.Label>
                                    <Form.Control

                                        as="textarea"
                                        name="eligibility"
                                        value={formik.values?.eligibility}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.eligibility && !formik.errors.eligibility}
                                        // isInvalid={formik.touched.eligibility && formik.errors.eligibility}
                                        placeholder="Eligibility"
                                        maxLength={1000}
                                        style={{ height: '110px' }}
                                    />
                                    {formik.touched.eligibility && formik.errors.eligibility &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.eligibility}</Form.Control.Feedback>
                                    }
                                    {formik.errors.eligibility ? <Form.Control.Feedback type="invalid" ></Form.Control.Feedback> :
                                        <div className="max-char">{formik.values.eligibility.length + "/1000"}</div>}
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom010">
                                    <Form.Label>Application Process</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="application_process"
                                        value={formik.values?.application_process}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.application_process && !formik.errors.application_process}
                                        // isInvalid={formik.touched.application_process && formik.errors.application_process}
                                        placeholder="Application Process"
                                        maxLength={1000}
                                        style={{ height: '110px' }}
                                    />

                                    <Form.Control.Feedback type="invalid">{formik.errors.application_process}</Form.Control.Feedback>
                                    {formik.errors.application_process ? <Form.Control.Feedback type="invalid" ></Form.Control.Feedback> :
                                        <div className="max-char">{formik.values.application_process.length + "/1000"}</div>}
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                            </Row>
                            <div className="group">
                                {isloader === true ?
                                    <div className='loader'>
                                        <img src={loader} alt="" />
                                    </div>
                                    :
                                    <>
                                        <Button type="button" className='save-btn me-4' onClick={() => setFormView('page1')}>Back</Button>
                                        <Button type="submit" className='save-btn me-4'>Save</Button>
                                    </>
                                }
                            </div>
                        </>}
                </Form>
            </div>
        </React.Fragment>
    )
}

export default ScholarshipCreate;