import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { GroupService } from '../../services/GroupService';
import Select from 'react-select';
import { UniversityService } from '../../services/universityService';
import AsyncSelect from 'react-select/async';


const UniversityNewsCreate=({ setGroupId, formik, isloader, formRef, passField }) => {

    const [universities, setUniversities]=useState([]);
    const universityService=new UniversityService();

    useEffect(() => {
        getCountries()
    }, [])

    async function getCountries() {
        const universityData=await universityService.getUniversities();
        setUniversities(universityData.data.results)
    }

    const handleChangeFile=(e, name, url) => {
        formik.setValues({
            ...formik.values,
            [name]: e.target.files[0],
            [url]: e.target.files[0]? URL.createObjectURL(e.target.files[0]):""
        });
    };

    let requestUniversity;
    const loadUniversityOption = async (value) =>{
        clearTimeout(requestUniversity);
        return new Promise((resolve,reject)=>{
            requestUniversity = setTimeout(async()=>{
                if(value.length>=3){
                    try{
                        const data = await universityService.getUniversities(value)
                        if(data && data.status){
                            resolve(data?.data)
                        }
                    } catch(err){
                        console.error("Error fetching courses")
                    }
                }else{
                    resolve([])
                }
            },500)
        })
    }

    const handleUniversityChange = (selectedOption)=>{
        formik.setFieldValue('selectedUniversity',selectedOption)
        formik.setFieldValue('university', selectedOption?.value)
    }


    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Author</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formik.values?.name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.name&&!formik.errors.name}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.name&&formik.errors.name}
                                placeholder="Author"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom010">
                            <Form.Label>University</Form.Label>
                            <AsyncSelect 
                                placeholder='Search for University'
                                className='select-dropdown'
                                classNamePrefix='select'
                                name="university"
                                value={formik.values?.selectedUniversity}
                                onChange={handleUniversityChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.university&&!formik.errors.university}
                                isInvalid={formik.touched.university&&formik.errors.university}
                                loadOptions={loadUniversityOption}
                            />
                            {formik.touched.university&&<div className="feedback_errors">
                                {formik.errors.university}
                            </div>}
                        </Form.Group>


                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={formik.values?.description}
                                onChange={(e) => {
                                    const inputValue=e.target.value;
                                    const charCount=inputValue.length;
                                    console.log(inputValue.split(/\s+/).length)
                                    // debugger
                                    if (inputValue.split(/\s+/).length<=1000) {
                                        formik.handleChange(e);
                                    } else {
                                        alert("Word Limit Exceeded")
                                    }

                                }}
                                isValid={formik.touched.description&&!formik.errors.description}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.description&&formik.errors.description}
                                placeholder="Description"
                                // maxLength={1000}
                                style={{ height: '110px' }}
                            />
                            {formik.errors.description? <Form.Control.Feedback type="invalid" >{formik.errors.description}</Form.Control.Feedback>:
                                <div className="max-char">{formik.values.description.split(/\s+/).length+"/1000"}</div>

                            }
                        </Form.Group>
                        {/* <Form.Control.Feedback type="invalid">{formik.errors.description}</Form.Control.Feedback>
                        </Form.Group> */}

                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="date"
                                value={formik.values?.date}
                                onChange={formik.handleChange}
                                isValid={formik.touched.date&&!formik.errors.date}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.date&&formik.errors.date}
                                placeholder="Date"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.date}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group
                            as={Col}
                            md="6"
                            controlId="formFile"
                            className="mb-3"
                        >
                            <Form.Label>Cover Image<span className='required ms-1'>*</span></Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    onChange={(e) => {
                                        handleChangeFile(e, "image", "image_url");
                                    }}
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.image&&!formik.errors.image}
                                    isInvalid={formik.touched.image&&formik.errors.image}
                                />
                                {formik.values?.image_url&&(
                                    <div className="img-wrapper ms-2">
                                        <img
                                            src={formik.values.image_url}
                                            alt="news"
                                            style={{ height: "100px", width: "150px" }}
                                            className=" mt-2"
                                        />
                                    </div>
                                )}
                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                                {/* { !formik.errors.image &&  !formik.values.image && <div style={{
                                        fontSize: "14px",
                                        color: "#198754",
                                        margin: "4px 0 0 4px",
                                        fontWeight:"500"
                                    }}>Max size. 2mb</div>} */}
                            </div>
                            <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 800 * 600 px)</p></div>
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader===true?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default UniversityNewsCreate;