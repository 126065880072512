import request from "../api/agent";

const API_ROUTE = "intakes/recent-intake";

export class RecentIntakeService {
    async getIntakes(perpage, currentPage) {
        const response = await request.get(`${API_ROUTE}/recent-intake-listing?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }

    async addOrUpdateIntake(scholarshipData) {
        let action = `${API_ROUTE}/create-or-update-recent-intake`;
        const response = await request.post(action, scholarshipData);
        return response;
    }

    async deleteIntake(id) {
        const response = await request.delete(`${API_ROUTE}/intake-delete`, { data: { instance_id: [id] } });
        return response;
    }

    async setIntakeStatus(id) {
        const response = await request.post(`${API_ROUTE}/recent-intake-status`, { instance_id: id  });
        return response;
    }
}