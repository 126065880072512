import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';

import { ToastContainer, toast } from 'react-toastify';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import { Articleservice } from '../../services/articleservice';
import CreateArticle from './createArticle';
import ArticleDatatable from './articleDatatable';
import { checkUserPermission } from '../../checkUserPermission';

const articleservice = new Articleservice();

const Article = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [pending, setpending] = useState(true);
    const [filterText, setFilterText] = React.useState('');


    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)

    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])


    async function getArticles() {
        setpending(true)
        const data = await articleservice.getAllarticle(perpage, currentPage,filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setpending(false)
    }
    useEffect(() => {
        getArticles()
    }, [refresh, perpage, currentPage,filterText])

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Required'),
        description: Yup.string()
            .required('Required'),
        meta_image_title: Yup.string()
            .required('Required'),
        meta_title: Yup.string()
            .required('Required'),
        meta_description: Yup.string()
            .required('Required'),
        meta_keyword: Yup.string()
            .required('Required'),


    });

    const formik = useFormik({
        initialValues: {
            instance_id: "",
            title: "",
            description: "",
            image: "",
            og_image: "",
            og_image_url: "",
            image_url: "",
            meta_image_title: "",
            meta_title: "",
            meta_description: "",
            meta_keyword: "",
        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            if (values.image == "") {
                errors.image = "Required"
            }
            else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                  errors.image ="images should be less than 2 MB"
                }
              }

            console.log(values.og_image);
            if (values.og_image == "") {
                errors.og_image = "Required"
            }
            else if (values?.og_image?.type !== "image/png" && values?.og_image?.type !== "image/jpg" && values?.og_image?.type !== "image/jpeg") {
                errors.og_image = "Only jpg/jpeg and png files are allowed!"
            }else {
                let kb = values.og_image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                  errors.og_image ="images should be less than 2 MB"
                }
              }
            return errors
        },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.instance_id) {
                console.log("val",values);
                formData.append("instance_id", values.instance_id ?? "");
            }
            formData.append("title", values.title);
            formData.append("description", values.description);
            formData.append("image", values.image);
            formData.append("og_image", values.og_image);
            formData.append("meta_title", values.meta_title);
            formData.append("meta_description", values.meta_description);
            formData.append("meta_keyword", values.meta_keyword);
            formData.append("meta_image_title",values.meta_image_title);

            articleservice.addOrUpdatearticle(formData).then((response) => {
                // })
                // CountryCreateOrUpdate({ formData })((response) => {
                console.log(response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        title: response?.response?.data?.errors?.title,
                        description: response?.response?.data?.errors?.description,
                        image: response?.response?.data?.errors?.image,
                        og_image: response?.response?.data?.errors?.og_image,
                        meta_title: response?.response?.data?.errors?.meta_title,
                        meta_description: response?.response?.data?.errors?.meta_description,
                        meta_keyword: response?.response?.data?.errors?.meta_keyword,
                        meta_image_title:response?.response?.data?.errors?.meta_image_title,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        console.log("item")
        console.log(item)
        setScroll(preVal => !preVal)
        // setImage(await dataURLtoFile(item.image))
        formik.setValues({
            instance_id:item.id,
            title: item.title,
            description: item.description,
            image: await dataURLtoFile(item.image),
            image_url: item?.image,
            og_image: await dataURLtoFile(item.og_image),
            og_image_url: item?.og_image,
            meta_title: item.meta_title,
            meta_description: item.meta_description,
            meta_keyword: item.meta_keyword,
            meta_image_title:item.meta_image_title,
        })
        
    }

    console.log(formik.errors)

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Article</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Article</li>
                            </ol>
                        </nav>
                    </div>
                    { checkUserPermission("Create Article") && <React.Fragment>
                        <CreateArticle image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <ArticleDatatable pending={pending} totalRows={totalRows} filterText={filterText} setFilterText={setFilterText} setCurrentPage={setCurrentPage} setPerpage={setPerpage} currentPage={currentPage} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik}  />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default Article;