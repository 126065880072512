import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';


import { checkUserPermission } from '../../checkUserPermission';
import SeoDatatable from './DatatableSeo';
import CreateSeo from './CreateSeo';
import { SeoService } from '../../services/seoServices';

const seoservice = new SeoService();

const Seo = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [toggleCleared, setToggleCleared] = React.useState(false);
    const [pending, setPending] = React.useState(true)
    const [scroll, setScroll] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [active, setActive] = useState(true);

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)

    };
    const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {

        console.log(`${row.name} was clicked!`);
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])




    async function getSeo() {
         setPending(true)
        const data = await seoservice.getAllSeo(perpage,currentPage,filterText);
       setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        getSeo()
        
    }, [refresh])

    // const imageValidation = Yup
    // .mixed()
    // .required("Required (Max size. 2mb)")
    // .test("size", "Image should be less than 2 MB", (value) => {
    //     return value && value.size <= 2000000;
    // })
    // .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
    //     return value && (
    //         value.type === "image/jpeg" ||
    //         value.type === "image/jpg" ||
    //         value.type === "image/png"
    //     );
    // })

    const validationSchema = Yup.object().shape({
        meta_title: Yup.string()
            .required('Required'),
            meta_description: Yup.string()
            .required('Required'),
            meta_keyword: Yup.string()
            .required('Required'),
            meta_image_title: Yup.string()
            .required('Required'),
            // og_image: imageValidation,
    });

    const formik = useFormik({
        initialValues: {
            seo_id: "",
            meta_title: "",
            meta_description: "",
            meta_keyword	:"",
            meta_image_title:"",
            og_image: "",
            image_url: ""
        },
        validationSchema,
        validate: async (values) => {
            const errors ={}
        if(values?.og_image ==""){
            errors.og_image = "Required"
        }
        else if (values?.og_image !== "" &&values?.og_image?.type !== "image/png" && values?.og_image?.type !== "image/jpg" && values?.og_image?.type !== "image/jpeg") {
            errors.og_image = "Only jpg/jpeg and png files are allowed!"
        }
        else {
            let kb = values.og_image.size / 1024; // convert the file size into byte to kb
            let mb = kb / 1024; // convert kb to mb
            if (mb > 2) {
                errors.og_image = "images should be less than 2 MB"
            }
        } if (values?.og_image !== "" &&values?.og_image?.type !== "image/png" && values?.og_image?.type !== "image/jpg" && values?.og_image?.type !== "image/jpeg") {
                errors.og_image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.og_image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.og_image = "images should be less than 2 MB"
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            console.log("val", values);
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.seo_id) {
                formData.append("seo_id", values.seo_id ?? "");
            }
            formData.append("meta_title", values.meta_title);
            formData.append("meta_description", values.meta_description);
            formData.append("meta_keyword", values.meta_keyword);
            formData.append("meta_image_title", values.meta_image_title);

            formData.append("og_image", values?.og_image);
           // formData.append("og_image", values?.og_image);

           seoservice.addOrUpdateSeo(formData).then((response) => {
                // })
                // CreateOrUpdateCourse({ formData })((response) => {
                console.log("response", response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        meta_title: response?.response?.data?.errors?.meta_title,
                        meta_description: response?.response?.data?.errors?.meta_description,
                        og_image: response?.response?.data?.errors?.og_image,
                        meta_keyword: response?.response?.data?.errors?.meta_keyword,
                        meta_image_title: response?.response?.data?.errors?.meta_image_title,
                        //image: response?.response?.data?.errors?.image,
                    });
                    console.log("res", response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        // setImage(await dataURLtoFile(item.image))
        formik.setValues({
            seo_id: item.id,
            meta_title: item.meta_title,
            og_image: item.og_image ? await dataURLtoFile(item.og_image) : '',
            image_url: item?.og_image,
            meta_description: item.meta_description,
            meta_keyword:item.meta_keyword,
            meta_image_title:item.meta_image_title,

        })
        console.log("item", item);
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>SEO Management</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">SEO Management</li>
                            </ol>
                        </nav>
                    </div>
                    { checkUserPermission("Create Country") && <React.Fragment>
                        <CreateSeo image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <SeoDatatable pending={pending} totalRows={totalRows} setFilterText={setFilterText} setCurrentPage={setCurrentPage} currentPage={currentPage} handleRowClicked={handleRowClicked} handleChange={handleChange} handlePerRowsChange={handlePerRowsChange} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Seo;