import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import forgot_icon from '../../assets/images/forgot.png';
import { Link, useNavigate } from 'react-router-dom';
import loader from '../../assets/images/loader.gif';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { AuthService } from '../services/AuthService';
import logo from "../../assets/images/afresh-logo.png";
import design from "../../assets/images/designs.png";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import IconButton from '@material-ui/core/IconButton';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { makeStyles } from '@material-ui/core';

const authService = new AuthService();
const useStyles = makeStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
    },
});


const Login = () => {
    const classes = useStyles();

    const [isloader, setIsloader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }
    const validationSchema = Yup.object().shape({
        // email: Yup.string()
        //     .required('Required'),
        email: Yup.string().email('Invalid email format').required('Required'),
        password: Yup
            .string()
            .required('Please Enter your password'),
    });
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema,
        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            formData.append("email", values.email);
            formData.append("password", values.password);

            authService.userLogin(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data);
                    localStorage.setItem('is_logged', true)
                    localStorage.setItem('token', response?.data?.data?.token)
                    localStorage.setItem('refresh', response?.data?.data?.refresh)
                    localStorage.setItem('user', JSON.stringify(response?.data?.data?.user))
                    localStorage.setItem('user_permissions', JSON.stringify(response?.data?.data?.user.permissions))
                    console.log(response?.data?.data?.user.permissions)
                    navigate('/')
                }
                else {
                    setIsloader(false)
                    toast.error(response?.response?.data?.message ? response?.response?.data?.message : "Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    console.log(formik.errors)
    return (
        <div className="Login_page">
            <div className='container'>
                <ToastContainer />
                <div className='content-wrapper'>
                    <div className='row'>
                        <div className='col-lg-7 col-md-6  col-12 image d-none d-md-block' >
                            <div className="img_wrapper">
                                <img src={design} alt="Login image" className="img-fluid imgs" />
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6  col-12 login-forms'>
                            <div className='login_left_wrapper'>
                                <div>
                                    <img src={logo} className='img-fluid logo-login  ' />
                                </div>
                                <div >
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Row className="mb-2">
                                            <div className='input-login'>
                                                <Form.Group as={Col} md="12" controlId="validationCustom04">
                                                    {/* <Form.Label>Email Address</Form.Label> */}
                                                    <Form.Control
                                                        variant="outlined"
                                                        type="email"
                                                        name="email"
                                                        value={formik.values?.email}
                                                        className="usrname"
                                                        onChange={formik.handleChange}
                                                        isValid={formik.touched.email && !formik.errors.email}
                                                        isInvalid={formik.touched.email && formik.errors.email}
                                                        onBlur={formik.handleBlur}

                                                        // isValid={formik.touched.email && !formik.errors.email}
                                                        // isInvalid={!!formik.errors.email}
                                                        placeholder="Email address"
                                                        maxLength={100}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Row>
                                        <Row className="mb-2">

                                            <div className='input-login'>
                                                <Form.Group as={Col} md="12" controlId="validationCustom04">

                                                    {/* <Form.Label></Form.Label> */}
                                                    <Form.Control
                                                        variant="outlined"
                                                        type={showPassword ? 'input' : 'password'}
                                                        name="password"
                                                        value={formik.values?.password}
                                                        className={`usrnam ${classes.root}`}
                                                        onChange={formik.handleChange}
                                                        isValid={formik.touched.password && !formik.errors.password}
                                                        onBlur={formik.handleBlur}
                                                        isInvalid={formik.touched.password && formik.errors.password}
                                                        placeholder="Password"
                                                        maxLength={100}
                                                    />
                                                    <div className='eye_button'>
                                                        {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { togglePasswordVisibility() }} viewBox="0 0 16 16">
                                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />

                                                        </svg>
                                                            :
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { togglePasswordVisibility() }} className="bi bi-eye-slash" viewBox="0 0 16 16">
                                                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                                            </svg>}
                                                    </div>
                                                    <Form.Control.Feedback className='invalid-feedbacks' type="invalid">{formik.errors.password}</Form.Control.Feedback>
                                                </Form.Group></div>
                                        </Row>
                                        <div className='forgot-login'>
                                            <Link to='/forget-password'>
                                                <div className='forgot-text' role='button'>Forgot Password ?</div>
                                            </Link>
                                        </div>
                                        <div className="login_btn_wrapper group">
                                            {isloader == true ?
                                                <div className='loader'>
                                                    <img src={loader} alt="" />
                                                </div>
                                                :
                                                <Button type="submit" className='login_btn'>LOGIN</Button>
                                            }
                                        </div>

                                    </Form>

                                </div>

                            </div>

                            {/* </React.Fragment> */}
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}

export default Login;