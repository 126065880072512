import React, { Fragment, Suspense, useEffect } from 'react'
import { Route, Routes as Switch, useNavigate } from "react-router-dom";
import Dashboard from '../dashboard/dashboard';
import Courses from '../courses/courses';
import CourseType from '../courseType/courseType';
import Stream from '../courseStream/stream';
import Roles from '../roles/Roles';
import Group from '../groups/Group';
import Testimonial from '../TestimonialVideo/Testimonial';
import ContactUs from '../ContactUs/ContactUs';
import User from '../users/User';
import Countries from '../countries/country';
import Blog from '../blog/blog';
import University from '../university/university';
import AboutTeam from '../aboutTeam/AboutTeam';
import WebinarType from '../webinarType/webinarType';
import Webinar from '../webinar/webinar';
import WebinarRegister from '../webinarRegister/webinarRegister';
import Scholarship from '../scholarships/Scholarship';
import Winners from '../scholarshipWinners/Winners';
import Batch from '../batch/Batch';
import UpcomingBatch from '../upcomingBatch/UpcomingBatch';
import Article from '../article/article';
import CourseSyllabus from '../course Syllabus/course Syllabus';
import Specialization from '../Specialization/specialization';
import Intake from '../intake/intake';
import UniversityCourse from '../universityCourse/universityCourse';
import CounsellorExpertise from '../counsellorExpertise/CounsellorExpertise';
import CounsellorProfile from '../counsellorProfile/CounsellorProfile';
import Faq from '../faq/Faq';
import HappyCoustumers from '../happyCoustumers/happyCoustumers';
import Blogcategory from '../blogcateg/blogCategory';
import BlogEnquiry from '../blogEnquiry/blogenquiry';
import Job from '../job/Job';
import Benefits from '../benefits/Benefits';

import EmployeeTestimonialVideo from '../employeTestimonialVideo/employeTestimonialVideo';
import InformativeVideo from '../informativeVideo/InformativeVideo';
import Reset from '../../changepassword/changePassword';
import RecentIntake from '../recentIntake/RecentIntake';
import Enquiry from '../enquiry/Enquiry';
import UniversityNews from '../universityNews/UniversityNews';
import BrochureSettings from '../settings/settings';
import UniversityType from '../universityType/UniversityType';
import JobList from '../jobList/jobList';
import ScholarshipCategory from '../scholarshipCategory/ScholarshipCategory';
import Speakers from '../speakers/Speakers';
import Seo from '../SeoManagement/Seo';
import Sidebar from '../../main/sidebar';
import Reset_Password from '../../forgot/resetpassword';
import Gallery from '../gallery/gallery';
import CourseSpec from '../courseSpecialization/CourseSpec';





const AdminPanel = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const logged = localStorage.getItem('is_logged');
        if (!logged) {
            navigate('/login')
        }
    }, [])

    return (
        <Suspense
            fallback={
                <div style={{ height: '100vh' }}>
                    Loading

                </div>
            }
        >
            <Switch>
                <Route index exact path='/resett' element={<Reset_Password />} />
                <Route index exact path='/' element={<Dashboard />} />
                <Route index exact path='/sidebar' element={<Sidebar />} />
                <Route index exact path='/courses' element={<Courses />} />
                <Route index exact path='/course-type' element={<CourseType />} />
                <Route index exact path='/course-stream' element={<Stream />} />
                <Route index exact path='/roles' element={<Roles />} />
                <Route index exact path='/group' element={<Group />} />
                <Route index exact path='/user' element={<User />} />
                <Route index exact path='/scholarship' element={<Scholarship />} />
                <Route index exact path='/scholarship-category' element={<ScholarshipCategory />} />
                <Route index exact path='/scholarship-winners' element={<Winners />} />
                <Route index exact path='/batch' element={<Batch />} />
                <Route index exact path='/upcoming-batch' element={<UpcomingBatch />} />
                <Route index exact path='/faq' element={<Faq />} />
                <Route index exact path='/recent-intake' element={<RecentIntake />} />
                <Route index exact path='/counsellor-expertise' element={<CounsellorExpertise />} />
                <Route index exact path='/counsellor-profile' element={<CounsellorProfile />} />
                <Route index exact path='/roles' element={<Roles />} />
                <Route index exact path='/group' element={<Group />} />
                <Route index exact path='/user' element={<User />} />
                <Route index exact path='/speakers' element={<Speakers />} />
                <Route index exact path='/enquiry' element={<Enquiry />} />
                <Route index exact path='/testimonialvideo' element={<Testimonial />} />
                <Route index exact path='/contactus' element={<ContactUs />} />
                <Route index exact path='/job' element={<Job />} />
                <Route index exact path='/gallery' element={<Gallery />} />
                <Route index exact path='/benefits' element={<Benefits />} />
                <Route exact path='/resetpassword' element={<Reset />} />
                <Route index exact path='/country' element={<Countries />} />
                <Route index exact path='/blog' element={<Blog />} />
                <Route index exact path='/blogcategory' element={<Blogcategory />} />
                <Route index exact path='/university' element={<University />} />
                <Route index exact path='/specialization' element={<Specialization />} />
                <Route index exact path='/aboutteam' element={<AboutTeam />} />
                <Route index exact path='/contactus' element={<ContactUs />} />
                <Route index exact path='/job' element={<Job />} />
                <Route index exact path='/benefits' element={<Benefits />} />
                <Route index exact path='/university-news' element={<UniversityNews />} />
                <Route index exact path='/university-type' element={<UniversityType />} />
                <Route index exact path='/country' element={<Countries />} />
                <Route index exact path='/blog' element={<Blog />} />
                <Route index exact path='/blogcategory' element={<Blogcategory />} />
                <Route index exact path='/university' element={<University />} />
                <Route index exact path='/specialization' element={<Specialization />} />
                <Route index exact path='/aboutteam' element={<AboutTeam />} />
                <Route index exact path='/webinar-type' element={<WebinarType />} />
                <Route index exact path='/webinar' element={<Webinar />} />
                <Route index exact path='/webinar-register' element={<WebinarRegister />} />
                {/* <Route index exact path='/article' element={<Article />} /> */}
                <Route index exact path='/coursesyllabus' element={<CourseSyllabus />} />
                <Route index exact path='/intake' element={<Intake />} />
                <Route index exact path='/universitycourse' element={<UniversityCourse />} />
                <Route index exact path='/happy-customers' element={<HappyCoustumers />} />
                {/* <Route index exact path='/ourservices' element={<Ourservice />} /> */}
                <Route index exact path='/blog-enquiry' element={<BlogEnquiry />} />
                <Route index exact path='/employetestimonialvideo' element={<EmployeeTestimonialVideo />} />
                <Route index exact path='/informativevideo' element={<InformativeVideo />} />
                {/* <Route index exact path='/brochure' element={<Brochure />} /> */}
                <Route index exact path='/brochuresettings' element={<BrochureSettings />} />
                <Route index exact path='/jobapplications' element={<JobList />} />
                <Route index exact path='/seo' element={<Seo />} />
                <Route index exact path='/course-specialization' element={<CourseSpec />} />
            </Switch>
        </Suspense>
    )
}

export default AdminPanel