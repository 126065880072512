import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';

import CreateInformativeVideo from './CreateInformativeVideo';
import InformativeDatatable from './InformativeDataTable';
import { InformativeService } from '../../services/informativeVideoService';
import { checkUserPermission } from '../../checkUserPermission';



const InformativeVideo = () => {

  const informativeservice = new InformativeService();


  const formRef = useRef(null);
  const [isloader, setIsloader] = useState(false);
  const [toaster, setToaster] = useState("");
  const [thumbnail, setThumbnail] = useState();
  const [data, setData] = React.useState([]);
  const [refresh, setRefresh] = useState(false);
  const [tableloader, setTableloader] = useState(true);
  const [pending, setPending] = React.useState(true)
  const [filterText, setFilterText] = React.useState('');
  const [totalRows, setTotalRows] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [scroll, setScroll] = useState(false);
  const [active, setActive] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [scroll])

  const handlePerRowsChange = async (newPerPage) => {
    setPerpage(newPerPage);
    setRefresh(!refresh);
  };

  async function getInformativeVideos() {
    setPending(true)
    const data = await informativeservice.getInformativeVideo(perpage, currentPage,filterText);
    setPending(false)
    setData(data.data)
    setTotalRows(data.data.count)
  }

  useEffect(() => {
    getInformativeVideos()
    
  }, [refresh])


  const video = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
  const validationSchema = Yup.object().shape({
    video: Yup.string().matches(video, "Enter valid url!").required("Required"),
    title: Yup.string().required('Required'),
  });



  const formik = useFormik({
    initialValues: {
      instance_id:"",
      title: "",
      video: "",
      is_active: true
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("values",values);
      setIsloader(true)
      let formData = new FormData();
      if (values.instance_id) {
        formData.append("instance_id", values.instance_id ?? "");
      }
      formData.append("title", values.title);
      formData.append("video", values.video);


      informativeservice.addOrUpdateInformativeVideo(formData).then((response) => {
        if (response && response.status) {
          setIsloader(false)
          // console.log(response?.data?.data)
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT
          });
          setRefresh(!refresh)
          formik.resetForm()
          formRef.current.reset();
        }
        else {
          formik.setErrors({
            video: response?.response?.data?.errors?.video,
            title: response?.response?.data?.errors?.title,
          });
          console.log(response?.response?.data?.errors);
          setIsloader(false)
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    },
  });
  const handleEdit = async (item) => {
    setScroll(preVal => !preVal)
    console.log("item",item)
    formik.setValues({
      instance_id: item.id,
      title: item.title,
      video: item.video,
    })
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Informative Video</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Informative Video</li>
              </ol>
            </nav>
          </div>
          {checkUserPermission("Create Informative Video") && <React.Fragment>
            <CreateInformativeVideo thumbnail={thumbnail} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setThumbnail={setThumbnail} formRef={formRef} />
          </React.Fragment>}
          <React.Fragment>
            <InformativeDatatable
              tableloader={tableloader}
              data={data}
              handleEdit={handleEdit}
              refresh={refresh}
              formik={formik}
              setRefresh={setRefresh}
              handlePerRowsChange={handlePerRowsChange}
              setCurrentPage={setCurrentPage}
              totalRows={totalRows}
              pending={pending}
              setFilterText={setFilterText}
              currentPage={currentPage}
            />
          </React.Fragment>
        </main>
      </>
    </React.Fragment>
  )
}

export default InformativeVideo;