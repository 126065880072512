import React, { Fragment, useState, useEffect } from 'react';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { BsCart, BsCurrencyDollar, BsPeople } from 'react-icons/bs'
import { Link } from 'react-router-dom';
import { MdWork } from 'react-icons/md'
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { BsBookHalf, BsChevronDown, BsCircle, BsFillDoorClosedFill, BsGrid, BsFillSignIntersectionTFill, BsBookmarkPlusFill, AiFillSetting, BsMicrosoftTeams } from 'react-icons/bs';
const Dashboard = () => {
    const [dashData, setDashData] = useState([]);
    const [view, setView] = useState("true");

    useEffect(() => {
        fetch('https://api.afreshlearn.com/api/dashboard/home-page-dashboard-count/dashboard-datas')
            .then(response => response.json())
            .then(jsonData => setDashData(jsonData))
            .catch(error => console.log(error));

    }, []);
    console.log(dashData.data?.webinar_data)
    const speakerElements = dashData?.data?.webinar_data?.map(data => (
        <tr key={data.id} >
            {/* console.log("id",data.id); */}
            {/* <th scope="row"><a href="#">{data.title}</a></th> */}
            <th className="tilt" scope="row">{data.title}</th>
            <td>{data.speaker}</td>
        </tr>
    ))

    const jobElements = dashData?.data?.career_data?.map(data => (
        <tr key={data.id} >
            {/* <th scope="row"><a href="#">{data.job_name}</a></th> */}
            <th scope="row">{data.job_name}</th>
            <td>{data.job_experience}</td>
        </tr>
    ))

    return (
        <Fragment>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Dashboard</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </nav>
                </div>
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="row">
                                <div className="col-xxl-4 col-md-4">
                                    <div className="card info-card sales-card">


                                        <div className="card-body">
                                            <h5 className="card-title">Career
                                                {/* <span>| Today</span> */}
                                            </h5>
                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <i class="bi bi-mortarboard-fill"></i>
                                                </div>
                                                <div className="ps-3">
                                                    <h6>{dashData.career_count}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-md-4">
                                    <div className="card info-card sales-card">



                                        <div className="card-body">

                                            <h5 className="card-title">Total Webinar
                                            </h5>
                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <BsBookHalf />
                                                </div>
                                                <div className="ps-3">
                                                    <h6>{dashData.webinar_count}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-md-4">
                                    <div className="card info-card sales-card">



                                        <div className="card-body">

                                            <h5 className="card-title">Total Job Application
                                            </h5>
                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <BsBookHalf />
                                                </div>
                                                <div className="ps-3">
                                                    <h6>{dashData.job_application}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* 
                                <div className="col-12">
                                    <div className="card info-card sales-card">

                                      
                                        <div className="card-body">
                                        
                                            <h5 className="card-title">Total Job Application
                                                {/* <span>| This Month</span> */}
                                {/* </h5>

                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <MdWork />
                                                </div>
                                                <div className="ps-3">
                                                    <h6>{dashData.job_application}</h6> */}
                                {/* <span className="text-success small pt-1 fw-bold">8%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}

                                {/* </div>
                                            </div>
                                        </div> */}

                                {/* </div>
                                </div> */}


                                <div className="col-12">
                                    <div className="card recent-sales overflow-auto">
                                        <div className="filter">
                                            <a className="icon" href="#" data-bs-toggle="dropdown"><HiOutlineSwitchHorizontal /></a>
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>
                                                <li><a className="dropdown-item" onClick={() => setView(true)}>Webinar</a></li>
                                                <li><a className="dropdown-item" onClick={() => setView(false)}>Jobs</a></li>
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">{view ? " Latest Webinar" : "Latest Career"}<span></span></h5>
                                            <table className="table table-borderless datatable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{view ? "Title" : "Job"}</th>
                                                        <th scope="col">{view ? "Speaker" : " Experience"}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* {speakerElements} */}
                                                    {view ? speakerElements : jobElements}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    )
}

export default Dashboard