import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { UniversityNewsService } from '../../services/universityNewsService';
import UniversityNewsCreate from './UniversityNewsCreate';
import UniversityNewsDatatble from './UniversityNewsDatatable';
import { dataURLtoFile } from '../../functions';
import moment from 'moment';
import { checkUserPermission } from '../../checkUserPermission';

const UniversityNews = () => {

    const universityNewsService = new UniversityNewsService();

    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [groupId, setGroupId] = useState([]);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [passField, setPassField] = useState(true)
    const [filterText, setFilterText] = React.useState('');
    const [active, setActive] = useState(true);
    const [pending, setpending] = useState(false);

    const [loading, setLoading] = useState(true);
    const [scroll, setScroll] = useState(false);

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage);
        setRefresh(!refresh);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    async function getAllUser() {
         setpending(true)
        const data = await universityNewsService.getAllUniversityNews(perpage, currentPage, filterText);
        setData(data.data)
        setTotalRows(data.data.count)
         setpending(false)
    }

    useEffect(() => {
        getAllUser()
       
    }, [refresh])

    // const imageValidation = Yup
    //     .mixed()
    //     .required("Required (Max size. 2mb)")
    //     .test("size", "The file is too large", (value) => {
    //         return value && value.size <= 2000000;
    //     })
    //     .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
    //         return value && (
    //             value.type === "image/jpeg" ||
    //             value.type === "image/jpg" ||
    //             value.type === "image/png"
    //         );
    //     })

    const validationSchema = Yup.object().shape({
        university: Yup.number()
            .required('Required'),
        description: Yup.string()
            .required('Required'),
        name: Yup.string()
            .required('Required'),
        date: Yup.string()
            .required('Required'),
        // image: imageValidation,
    });

    const formik = useFormik({
        initialValues: {
            news_id: "",
            university: "",
            selectedUniversity: "",
            description: "",
            name: "",
            date: "",
            image: "",
        },
        validationSchema,
        validate: async (values) => {
            const errors ={}
        if(values?.image == ""){
            errors.image = "Required"
        }
        else if (values?.image !== "" &&values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
            errors.image = "Only jpg/jpeg and png files are allowed!"
        }
        else {
            let kb = values.image.size / 1024; // convert the file size into byte to kb
            let mb = kb / 1024; // convert kb to mb
            if (mb > 2) {
                errors.image = "images should be less than 2 MB"
            }
        } if (values?.image !== "" &&values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            }
                if (!values.university) {
                    errors.university = "University is required";
                }
            return errors;
        },
        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            if (values.news_id) {
                formData.append("news_id", values.news_id);
            }
            formData.append("university", values.university)
            formData.append("description", values.description)
            formData.append("name", values.name)
            formData.append("date", values.date)
            formData.append("image", values.image)

            universityNewsService.addOrUpdateUniversityNews(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                    setPassField(true)
                }
                else {
                    formik.setErrors({
                        question: response?.response?.data?.errors?.title,
                        answer: response?.response?.data?.errors?.description,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        const date = moment(item?.date).format('YYYY-MM-DD')
        formik.setValues({
            news_id: item.id,
            university: item.university,
            description: item.description,
            name: item.name,
            date: date,
            image: await dataURLtoFile(item?.image),
            image_url: item.image
        })
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>University News</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">University News</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create University News") && <React.Fragment>
                        <UniversityNewsCreate setGroupId={setGroupId} image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} passField={passField} />
                    </React.Fragment>}
                    <React.Fragment>
                        <UniversityNewsDatatble setFilterText={setFilterText} setPassField={setPassField} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} totalRows={totalRows} formik={formik} currentPage={currentPage} pending={pending} />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default UniversityNews