import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import { BlogService } from '../../services/BlogService';
import BlogDatatable from '../blog/blogDatatable';
import CreateBlog from '../blog/createBlog';
import { checkUserPermission } from '../../checkUserPermission';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const blogService = new BlogService();

const Blog = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [toggleCleared, setToggleCleared] = React.useState(false);
    const [scroll, setScroll] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [active, setActive] = useState(true);


    // const [data, setData] = React.useState([]);
    const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {
        console.log(`${row.name} was clicked!`);
    };


    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage);
        setRefresh(!refresh);
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])


    async function getblog() {
        setPending(true)
        const data = await blogService.getAllBlog(perpage, currentPage, filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setPending(false)
    }

    useEffect(() => {
        getblog()

    }, [refresh])

    // const imageValidation = Yup
    // .mixed()
    // .required("Required (Max size. 2mb)")
    // .test("size", "Image should be less than 2 MB", (value) => {
    //     return value && value.size <= 2000000;
    // })
    // .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
    //     return value && (
    //         value.type === "image/jpeg" ||
    //         value.type === "image/jpg" ||
    //         value.type === "image/png"
    //     );
    // })


    const validationSchema = Yup.object().shape({
        blog_title: Yup.string()
            .required('Required'),
        posted_by: Yup.string()
            .required('Required'),
        meta_image_title: Yup.string()
            .required('Required'),
        meta_title: Yup.string()
            .required('Required'),
        meta_description: Yup.string()
            .required('Required'),
        // og_image:imageValidation,
        // blog_image:imageValidation,
        meta_keyword: Yup.string()
            .required('Required'),
        category: Yup.string()
            .required('Required'),
        blog_category: Yup.string()
            .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            blog_id: "",
            blog_title: "",
            blog_category: "",
            description: '',
            blog_image: "",
            image_url: "",
            og_image: "",
            og_image_url: "",
            posted_by: "",
            meta_image_title: "",
            meta_title: "",
            meta_description: "",
            meta_keyword: "",
            category: "",
        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            if (values.blog_image == "") {
                errors.blog_image = "Required"
            }
            else if (values?.blog_image?.type !== "image/png" && values?.blog_image?.type !== "image/jpg" && values?.blog_image?.type !== "image/jpeg") {
                errors.blog_image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.blog_image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.blog_image = "images should be less than 2 MB"
                }

            }

            if (values.og_image == "") {
                errors.og_image = "Required"
            }
            else if (values?.og_image?.type !== "image/png" && values?.og_image?.type !== "image/jpg" && values?.og_image?.type !== "image/jpeg") {
                errors.og_image = "Only jpg/jpeg and png files are allowed!"
            } else {
                let kb = values.og_image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.og_image = "images should be less than 2 MB"
                }
            }
            return errors
        },
        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            if (values.blog_id) {
                formData.append("blog_id", values.blog_id ?? "");
            }
            formData.append("blog_title", values.blog_title);
            formData.append("blog_category", values?.blog_category);
            formData.append("blog_image", values?.blog_image);
            formData.append("posted_by", values.posted_by)
            formData.append("description", values.description);
            formData.append("og_image", values.og_image);
            formData.append("meta_title", values.meta_title);
            formData.append("meta_description", values.meta_description);
            formData.append("meta_keyword", values.meta_keyword);
            formData.append("meta_image_title", values.meta_image_title);
            formData.append("category", values.category);



            blogService.addOrUpdateBlog(formData).then((response) => {
                // })
                // CreateOrUpdateCourse({ formData })((response) => {
                console.log("response", response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                    const ck_description = ClassicEditor.instances.ck_description;
                    ck_description.setData('');
                    console.log(ck_description)


                }
                else {
                    formik.setErrors({
                        blog_title: response?.response?.data?.errors?.title,
                        description: response?.response?.data?.errors?.description,
                        blog_image: response?.response?.data?.errors?.blog_image,
                        og_image: response?.response?.data?.errors?.og_image,
                        meta_title: response?.response?.data?.errors?.meta_title,
                        meta_description: response?.response?.data?.errors?.meta_description,
                        meta_keyword: response?.response?.data?.errors?.meta_keyword,
                        category: response?.response?.data?.errors?.category,
                        blog_category: response?.response?.data?.errors?.blog_category,

                        meta_image_title: response?.response?.data?.errors?.meta_image_title,
                    });
                    console.log("res", response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    console.log("formik", formik);
    console.log(formik.errors)
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        formik.setValues({
            blog_id: item.id,
            blog_title: item.blog_title,
            blog_image: item.blog_image ? await dataURLtoFile(item.blog_image) : '',
            image_url: item?.blog_image,
            blog_category: item?.blog_category_id,
            description: item.description,
            og_image: await dataURLtoFile(item.og_image),
            og_image_url: item?.og_image,
            meta_title: item.meta_title,
            meta_description: item.meta_description,
            meta_keyword: item.meta_keyword,
            meta_image_title: item.meta_image_title,
            category: item.category,
            posted_by: item.posted_by,

        })
        console.log(item);
    }
    console.log(formik.errors)

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Blog</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Blog</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Blog") && <React.Fragment>
                        <CreateBlog image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <BlogDatatable totalRows={totalRows} pending={pending} setFilterText={setFilterText} setCurrentPage={setCurrentPage} currentPage={currentPage} handleRowClicked={handleRowClicked} handlePerRowsChange={handlePerRowsChange} handleChange={handleChange} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} loading={loading} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Blog;

