import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';



const WinnersCreate = ({ formik, isloader, formRef, setImage }) => {
    const onChangeImage = (e) => {
        setImage(e.target.files[0])
        formik.setValues({
            ...formik.values,
            image: e.target.files[0],
            image_url: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "",
        })
    }


    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom01" className='mb-2'>
                            <Form.Label>User Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="user"
                                value={formik.values?.user}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.user && !formik.errors.user}
                                isInvalid={formik.touched.user && formik.errors.user}
                                placeholder="User name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.user}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02" className='mb-2'>
                            <Form.Label>University location</Form.Label>
                            <Form.Control
                                type="text"
                                name="place"
                                value={formik.values?.place}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.place && !formik.errors.place}
                                isInvalid={formik.touched.place && formik.errors.place}
                                placeholder="university location"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.place}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Image<span className='required ms-1'>*</span></Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        onChangeImage(e)
                                    }
                                    }
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.image && !formik.errors.image}
                                    isInvalid={formik.touched.image && formik.errors.image}
                                />
                                {formik.values?.image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }

                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                                {/* { !formik.touched.image &&  !formik.values.image && <div style={{
                                        fontSize: "14px",
                                        color: "#198754",
                                        margin: "4px 0 0 4px",
                                        fontWeight:"500"
                                    }}>Max size. 2mb</div>} */}
                            </div>
                            <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 600 * 600 px)</p></div>
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default WinnersCreate;