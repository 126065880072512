import React, { useState } from 'react';
import { BsBookHalf, BsChevronDown, BsCircle, BsFillDoorClosedFill, BsGrid, BsFillSignIntersectionTFill, BsBookmarkPlusFill, AiFillSetting, BsMicrosoftTeams, BsFillImageFill } from 'react-icons/bs';
import { FaPlane, FaQuestion, FaSearch, FaStream, FaUniversity, FaUserFriends, FaUsers, FaServicestack } from 'react-icons/fa';
import { SiGooglescholar, SiXstate, SiHelpscout, SiSemanticscholar } from 'react-icons/si';

import { checkUserPermission } from '../checkUserPermission';
import { Link, useLocation } from 'react-router-dom';


import { MDBIcon, cog } from 'mdb-react-ui-kit';
import { FiSettings, FiVideo } from 'react-icons/fi'
import { RiContactsFill, RiTeamLine } from 'react-icons/ri'
import { GiGraduateCap } from 'react-icons/gi'
import { MdWork, MdOutlineArticle, MdOutlineWorkspaces, MdLocationOn, } from 'react-icons/md'
import { ImBlog } from 'react-icons/im'
import { TbSeo } from 'react-icons/tb'
// import { MdsSharpSpeechToText } from 'react-icons/md'


const Sidebar = () => {
    const location = useLocation();
    const [isBatchNavOpen, setIsBatchNavOpen] = useState(false);

    const toggleBatchNav = () => {
        setIsBatchNavOpen(!isBatchNavOpen);
    };
    return (
        <React.Fragment>
            <>
                <aside id="sidebar" className="sidebar">
                    <ul className="sidebar-nav" id="sidebar-nav">
                        <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                            <Link className="nav-link " to="/">
                                <BsGrid />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        {checkUserPermission("View Country") && <li className={`nav-item ${location.pathname === '/country' ? 'active' : ''}`}>
                            <Link className="nav-link " to="/country">
                                <MdLocationOn />
                                <span>Country</span>
                            </Link>
                        </li>}
                        {(checkUserPermission("View Course") || checkUserPermission("View Course Syllabus") || checkUserPermission("View Specialization") || checkUserPermission("View Course Stream") || checkUserPermission("View Course Specialization"))  && <li className="nav-item">
                            <div className="nav-link collapsed" data-bs-target="#services-nav" data-bs-toggle="collapse">
                                <BsBookHalf /> <span>Courses</span><BsChevronDown className='chevron-down ms-auto' />
                            </div>
                            <ul id="services-nav" className={`nav-content collapse${location.pathname === '/course-type' || location.pathname === '/courses' || location.pathname === '/course-stream' || location.pathname === '/specialization' ? 'collapse show' : ''} `} data-bs-parent="#sidebar-nav">

                                {checkUserPermission("View Course Type") && <li className={`nav-item ${location.pathname === '/course-type' ? 'active' : ''}`}>
                                    <Link to="/course-type">
                                        <BsCircle /><span>Course Type</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Course") && <li className={`nav-item ${location.pathname === '/courses' ? 'active' : ''}`}>
                                    <Link to="/courses">
                                        <BsCircle /><span>Courses</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Course Stream") && <li className={`nav-item ${location.pathname === '/course-stream' ? 'active' : ''}`}>
                                    <Link to="/course-stream">
                                        <BsCircle /><span>Stream</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Specialization") && <li className={`nav-item ${location.pathname === '/specialization' ? 'active' : ''}`}>
                                    <Link to="/specialization">
                                        <BsCircle />
                                        <span>Specialization </span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Course Specialization") && <li className={`nav-item ${location.pathname === '/course-specialization' ? 'active' : ''}`}>
                                    <Link to="/course-specialization">
                                        <BsCircle />
                                        <span>Course Specialization</span>
                                    </Link>
                                </li>}

                                {/* {checkUserPermission("View Course Syllabus") && <li>
                                    <Link to="/coursesyllabus">
                                        <BsCircle />
                                        <span>Course Syllabus</span>
                                    </Link>
                                </li>} */}
                            </ul>
                        </li>}
                        {(checkUserPermission("View University") || checkUserPermission("View University News") || checkUserPermission("View University Course")) && <li className="nav-item">
                            <div className="nav-link collapsed" data-bs-target="#university-nav" data-bs-toggle="collapse">
                                <FaUniversity /> <span>University</span><BsChevronDown className='chevron-down ms-auto' />
                            </div>
                            <ul id="university-nav"
                                className={`nav-content collapse ${location.pathname === '/university-type' || location.pathname === '/university' || location.pathname === '/universitycourse' || location.pathname === '/university-news' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">

                                {checkUserPermission("View University News") && <li className={`nav-item ${location.pathname === '/university-type' ? 'active' : ''}`}>
                                    <Link to="/university-type">
                                        <BsCircle /><span>University Type</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Universities") && <li className={`nav-item ${location.pathname === '/university' ? 'active' : ''}`}>
                                    <Link to="/university">
                                        <BsCircle /><span>University</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View University Course") && <li className={`nav-item ${location.pathname === '/universitycourse' ? 'active' : ''}`}>
                                    <Link to="/universitycourse">
                                        <BsCircle /><span>University Course</span>
                                    </Link>
                                </li>}

                                {checkUserPermission("View University News") && <li className={`nav-item ${location.pathname === '/university-news' ? 'active' : ''}`} >
                                    <Link to="/university-news">
                                        <BsCircle /><span>University News</span>
                                    </Link>
                                </li>}
                            </ul>
                        </li>}
                        {(checkUserPermission("View Sholarship") || checkUserPermission("View Scholarship Winners") || checkUserPermission("View Scholarship Category")) && <li className="nav-item">
                            <div className="nav-link collapsed" data-bs-target="#scholarship-nav" data-bs-toggle="collapse">
                                <GiGraduateCap /> <span>Scholarship</span><BsChevronDown className='chevron-down ms-auto' />
                            </div>
                            <ul id="scholarship-nav"
                                className={`nav-content collapse ${location.pathname === '/scholarship-category' || location.pathname === '/scholarship' || location.pathname === '/scholarship-winners' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">

                                {checkUserPermission("View Scholarship Category") && <li className={`nav-item ${location.pathname === '/scholarship-category' ? 'active' : ''}`} >
                                    <Link to="/scholarship-category">
                                        <BsCircle /><span>Scholarship Category</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Sholarship") && <li className={`nav-item ${location.pathname === '/scholarship' ? 'active' : ''}`} >
                                    <Link to="/scholarship">
                                        <BsCircle /><span>Scholarship</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Scholarship Winners") && <li className={`nav-item ${location.pathname === '/scholarship-winners' ? 'active' : ''}`}>
                                    <Link to="/scholarship-winners">
                                        <BsCircle />  <span>Scholarship Winners</span>
                                    </Link>
                                </li>}


                            </ul>
                        </li>}
                        {(checkUserPermission("View Home") || checkUserPermission("View Recent Intake") || checkUserPermission("View Informative Video") || checkUserPermission("View Employee Testimonial Video")) && <li className="nav-item">
                            <div className="nav-link collapsed" data-bs-target="#home-nav" data-bs-toggle="collapse">
                                <SiSemanticscholar /> <span> Home </span><BsChevronDown className='chevron-down ms-auto' />
                            </div>
                            <ul id="home-nav" className={`nav-content collapse ${location.pathname === '/intake' || location.pathname === '/recent-intake' || location.pathname === '/testimonialvideo' || location.pathname === '/informativevideo' ? 'collapse show' : ''}`} data-bs-parent="#sidebar-nav">
                                {checkUserPermission("View Intake ") && <li className={`nav-item ${location.pathname === '/intake' ? 'active' : ''}`}> <Link to="/intake">
                                    <BsCircle />
                                    <span>Banner Intake </span>
                                </Link>
                                </li>
                                }

                                {checkUserPermission("View Recent Intake") && <li className={`nav-item ${location.pathname === '/recent-intake' ? 'active' : ''}`}>
                                    <Link to="/recent-intake">
                                        <BsCircle />
                                        <span>Recent Intake</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Informative Video") && <li className={`nav-item ${location.pathname === '/informativevideo' ? 'active' : ''}`}><Link to="/informativevideo">
                                    <BsCircle />
                                    <span>Informative Videos</span>
                                </Link>
                                </li>}
                                {checkUserPermission("View Employee Testimonial Video") && <li className={`nav-item ${location.pathname === '/testimonialvideo' ? 'active' : ''}`}>
                                    <Link to="/testimonialvideo">
                                        <BsCircle />
                                        <span>Testimonial Video</span>
                                    </Link>
                                </li>}
                            </ul>
                        </li>}
                        {(checkUserPermission("View Webinar") || checkUserPermission("View Webinar Event Type") || checkUserPermission("View Speakers")) && <li className="nav-item">
                            <div className="nav-link collapsed" data-bs-target="#webinar-nav" data-bs-toggle="collapse">
                                <BsBookHalf /> <span>Webinar and Event</span><BsChevronDown className='chevron-down ms-auto' />
                            </div>
                            <ul id="webinar-nav"
                                className={`nav-content collapse ${location.pathname === '/webinar-type' || location.pathname === '/webinar' || location.pathname === '/speakers' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">
                                {checkUserPermission("View Webinar Event Type") && <li className={`nav-item ${location.pathname === '/webinar-type' ? 'active' : ''}`} >
                                    <Link to="/webinar-type">
                                        <BsCircle /><span>Webinar / Event Type</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Webinar") && <li className={`nav-item ${location.pathname === '/webinar' ? 'active' : ''}`}>
                                    <Link to="/webinar">
                                        <BsCircle /><span>Webinar</span>
                                    </Link>
                                </li>}

                                {/* {checkUserPermission("Edit Webinar Register") && <li>
                                    <Link to="/webinar-register">
                                        <BsCircle /><span>Webinar Register</span>
                                    </Link>
                                </li>} */}
                                {checkUserPermission("View Speakers") && <li className={`nav-item ${location.pathname === '/speakers' ? 'active' : ''}`} >
                                    <Link to="/speakers">
                                        <BsCircle />
                                        <span>Speakers </span>
                                    </Link>
                                </li>}

                            </ul>

                        </li>}
                        {(checkUserPermission("View Blog") || checkUserPermission("View Blog Category")) && <li className="nav-item">
                            <div className="nav-link collapsed" data-bs-target="#blog-nav" data-bs-toggle="collapse">
                                <ImBlog /> <span>Blog</span><BsChevronDown className='chevron-down ms-auto' />
                            </div>

                            <ul id="blog-nav"
                                className={`nav-content collapse ${location.pathname === '/blogcategory' || location.pathname === '/blog' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">

                                {checkUserPermission("View Blog Category") && <li className={`nav-item ${location.pathname === '/blogcategory' ? 'active' : ''}`} >
                                    <Link to="/blogcategory">
                                        <BsCircle /><span>Blog Category</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Blog") && <li className={`nav-item ${location.pathname === '/blog' ? 'active' : ''}`}>
                                    <Link to="/blog">
                                        <BsCircle /><span>Blog</span>
                                    </Link>
                                </li>}

                            </ul>

                        </li>}
                        {(checkUserPermission("View Upcoming Batch") || checkUserPermission("View Batch") || checkUserPermission("View Happy Customers")) && <li className="nav-item">
                            <div className="nav-link collapsed" data-bs-target="#batch-nav" data-bs-toggle="collapse">
                                <SiGooglescholar /> <span>Batches</span><BsChevronDown className='chevron-down ms-auto' />
                            </div>
                            <ul id="batch-nav"
                                className={`nav-content collapse ${location.pathname === '/batch' || location.pathname === '/upcoming-batch' || location.pathname === '/happy-customers' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">
                                {checkUserPermission("View Batch") && <li className={`nav-item ${location.pathname === '/batch' ? 'active' : ''}`}>
                                    <Link to="/batch">
                                        <BsCircle /><span>Batch</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Upcomming Batch") && <li className={`nav-item ${location.pathname === '/upcoming-batch' ? 'active' : ''}`}>
                                    <Link to="/upcoming-batch">
                                        <BsCircle /><span>Upcoming Batch</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Happy Customers") && <li className={`nav-item ${location.pathname === '/happy-customers' ? 'active' : ''}`}>
                                    <Link to="/happy-customers">
                                        <BsCircle />
                                        <span>Happy Customers</span>
                                    </Link>
                                </li>}
                            </ul>
                        </li>}
                        {checkUserPermission("View About Team") && <li className={`nav-item ${location.pathname === '/aboutteam' ? 'active' : ''}`}>
                            <Link className="nav-link " to="/aboutteam">
                                <BsMicrosoftTeams />
                                <span>About Team </span>

                            </Link>
                        </li>}
                        {checkUserPermission("View Gallery") && <li className={`nav-item ${location.pathname === '/gallery' ? 'active' : ''}`}>
                            <Link className="nav-link " to="/gallery">
                                <BsFillImageFill />
                                <span>Gallery </span>

                            </Link>
                        </li>}
                        {(checkUserPermission("View Counsellor Profile") || checkUserPermission("View Counsellor Expertise")) && <li className="nav-item">
                            <div className="nav-link collapsed"
                                data-bs-target="#counsellor-nav"
                                data-bs-toggle="collapse">
                                <SiSemanticscholar /> <span>Counsellor</span><BsChevronDown className='chevron-down ms-auto' />
                            </div>
                            <ul id="counsellor-nav"
                                className={`nav-content collapse ${location.pathname === '/counsellor-expertise' || location.pathname === '/counsellor-profile' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">
                                {checkUserPermission("View Counsellor Expertise") && <li className={`nav-item ${location.pathname === '/counsellor-expertise' ? 'active' : ''}`}>
                                    <Link to="/counsellor-expertise">
                                        <BsCircle /><span>Counsellor Expertise</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Counsellor Profile") && <li className={`nav-item ${location.pathname === '/counsellor-profile' ? 'active' : ''}`}>
                                    <Link to="/counsellor-profile">
                                        <BsCircle /><span>Counsellor Profile</span>
                                    </Link>
                                </li>}
                            </ul>
                        </li>}


                        {/* {checkUserPermission("View Enquiry") && <li className={`nav-item ${location.pathname === '/enquiry' ? 'active' : ''}`}>
                            <Link className="nav-link " to="/enquiry">
                                <FaQuestion />
                                <span>Enquiry</span>
                            </Link>
                        </li>} */}



                        {/* {checkUserPermission("View Blog Enquiry") && <li className="nav-item">
                                <Link className="nav-link collapsed" to="/blog-enquiry">
                                    <FaQuestion />
                                    <span>Blog Enquiry</span>
                                </Link>
                            </li>} */}
                        {(checkUserPermission("View Job") || checkUserPermission("View Benefits") || checkUserPermission("View Job Applications") || checkUserPermission("View Employee Testimonial Video")) && <li className="nav-item">
                            <div className="nav-link collapsed" data-bs-target="#job-nav" data-bs-toggle="collapse">
                                <MdWork /> <span>Careers</span><BsChevronDown className='chevron-down ms-auto' />
                            </div>
                            <ul id="job-nav"
                                className={`nav-content collapse ${location.pathname === '/job' || location.pathname === '/benefits' || location.pathname === '/jobapplications' || location.pathname === '/employetestimonialvideo' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">
                                {checkUserPermission("View Job") && <li className={`nav-item ${location.pathname === '/job' ? 'active' : ''}`}>
                                    <Link to="/job">
                                        <BsCircle />
                                        <span>Job</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Benefits") && <li className={`nav-item ${location.pathname === '/benefits' ? 'active' : ''}`}>
                                    <Link to="/benefits">
                                        <BsCircle />
                                        <span>Why work with Afresh</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Job Applications") && <li className={`nav-item ${location.pathname === '/jobapplications' ? 'active' : ''}`}>
                                    <Link to="/jobapplications">
                                        <BsCircle />
                                        <span>Job Applications</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Employee Testimonial Video") && <li className={`nav-item ${location.pathname === '/employetestimonialvideo' ? 'active' : ''}`}>
                                    <Link to="/employetestimonialvideo">
                                        <BsCircle />
                                        <span>Employee Testimonial</span>
                                    </Link>
                                </li>}
                                {/* {checkUserPermission("View Employee Testimonial Video") && <li className={`nav-item ${location.pathname === '/employetestimonialvideo' ? 'active' : ''}`}> 
                                    <Link to="/employetestimonialvideo">
                                        <BsCircle />
                                        <span>Employee Testimonial </span>
                                    </Link>
                                    </li>
                                    } */}
                            </ul>
                        </li>}
                        {/* {checkUserPermission("View Our Services") && <Link className="nav-link " to="/ourservices">
                                    <FaServicestack />
                                    <span>Our Services</span>
                                </Link>} */}

                        {(checkUserPermission("View Group") || checkUserPermission("View User") || checkUserPermission("View Roles")) && <li className="nav-item">
                            <div className="nav-link collapsed"
                                data-bs-target="#users-nav"
                                data-bs-toggle="collapse">
                                <FaUserFriends /> <span>User Management</span><BsChevronDown className='chevron-down ms-auto' />
                            </div>
                            <ul id="users-nav"
                                className={`nav-content collapse ${location.pathname === '/roles' || location.pathname === '/group' || location.pathname === '/user' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">
                                {checkUserPermission("View Roles") && <li className={`nav-item ${location.pathname === '/roles' ? 'active' : ''}`}>
                                    <Link to="/roles">
                                        <BsCircle /><span> Roles </span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Group") && <li className={`nav-item ${location.pathname === '/group' ? 'active' : ''}`}>
                                    <Link to="/group">
                                        <BsCircle /><span>Group</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Users") && <li className={`nav-item ${location.pathname === '/user' ? 'active' : ''}`}>
                                    <Link to="/user">
                                        <BsCircle /><span>User</span>
                                    </Link>
                                </li>}
                            </ul>
                        </li>}

                        {checkUserPermission("View SEO") && <li className={`nav-item ${location.pathname === '/seo' ? 'active' : ''}`}>
                            <Link className="nav-link " to="/seo">
                                <TbSeo />
                                <span>SEO Management</span>
                            </Link>
                        </li>}
                        {checkUserPermission("View Settings") && <li className={`nav-item ${location.pathname === '/brochuresettings' ? 'active' : ''}`}>
                            <Link className="nav-link " to="/brochuresettings">
                                <FiSettings />
                                <span>Brochure</span>
                            </Link>
                        </li>}
                        {checkUserPermission("View Contact Us") && <li className={`nav-item ${location.pathname === '/contactus' ? 'active' : ''}`}>
                            <Link className="nav-link " to="/contactus">
                                <RiContactsFill />
                                <span>Contact Us </span>
                            </Link>

                        </li>}
                        {checkUserPermission("View FAQ") && <li className={`nav-item ${location.pathname === '/faq' ? 'active' : ''}`}>
                            <Link className="nav-link " to="/faq">
                                <FaQuestion />
                                <span>FAQ</span>
                            </Link>
                        </li>}


                        {/* {checkUserPermission("View Article") && <li className="nav-item">
                            <Link className="nav-link " to="/article">
                                <MdOutlineArticle />
                                <span>Article</span>
                            </Link>
                        </li>} */}
                        <li className="nav-item">
                            {/* {checkUserPermission("View Course Syllabus ") && <Link className="nav-link " to="/intake">
                                <BsBookHalf />
                                <span>Coursesyllabus</span>
                            </Link>} */}



                            {/* {checkUserPermission("View Brochure") && <li className="nav-item">
                                <Link className="nav-link collapsed" to="/brochure">
                                    <FaQuestion />
                                    <span>Brochure</span>
                                </Link>
                            </li>} */}



                        </li>
                    </ul>

                </aside>
            </>
        </React.Fragment>
    )
}

export default Sidebar;