import request from "../api/agent";

const API_ROUTE = "counsellors/counsellor_expertise";

export class CounsellorExpertiseService {
    async getCounsellorExpertise(perpage, currentPage,search) {
        const serchValue = search??""
        if (perpage) {
            const response = await request.get(`${API_ROUTE}/counsellor-expertise-all-listing?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
            return response.data;
        } else {
            const response = await request.get(`${API_ROUTE}/counsellor-expertise-all-listing`);
            return response.data;
        }
    }

    async addOrUpdateCounsellorExpertise(counsellor) {
        let action = `${API_ROUTE}/create-or-update-counsellor-expertise`;
        const response = await request.post(action, counsellor);
        return response;
    }

    async deleteCounsellorExpertise(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-counsellor-expertise`, { data: { id: id } });
        return response;
    }

}