import request from "../api/agent";
const API_ROUTE = "career";

export class GalleryService {
    async createGalleryImages(country) {
        let action = `${API_ROUTE}/galleryimages`;
        const response = await request.post(action, country);
        return response;
    }

    async getGalleries() {
        const response = await request.get(`${API_ROUTE}/galleryget-image`);
        return response.data;
    }

    async deleteSlotOne(id) {
        const response = await request.delete(`${API_ROUTE}/gallerydelete-image-slot-one`, {
            data: { instance_id: id }
        });
        return response;
    }

    async deleteSlotTwo(id) {
        const response = await request.delete(`${API_ROUTE}/gallerydelete-image-slot-two`, {
            data: { instance_id: id }
        });
        return response;
    }

    async deleteSlotThree(id) {
        const response = await request.delete(`${API_ROUTE}/gallerydelete-image-slot-three`, {
            data: { instance_id: id }
        });
        return response;
    }

    async deleteSlotFour(id) {
        const response = await request.delete(`${API_ROUTE}/gallerydelete-image-slot-four`, {
            data: { instance_id: id }
        });
        return response;
    }

    async deleteSlotFive(id) {
        const response = await request.delete(`${API_ROUTE}/gallerydelete-image-slot-five`, {
            data: { instance_id: id }
        });
        return response;
    }
}