import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import CreateHappyCoustmers from './createHappyCoustmers';
import DatatableHappycoustomers from './datatableHappycoustomers';
import { HappyCostumerService } from '../../services/happyCostumerService';
import Sidebar from '../../main/sidebar';
import { checkUserPermission } from '../../checkUserPermission';

const happyCostumerservice = new HappyCostumerService();

const HappyCoustumers = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = React.useState(0);
    const [pending, setPending] = React.useState(true)

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage);
        setRefresh(!refresh);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    async function getHappyCostumer() {
        setPending(true)
        const data = await happyCostumerservice.getAllHappyCostumer(perpage, currentPage, filterText);
        setData(data.data)
        setPending(false)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        getHappyCostumer()
    }, [refresh])

    // const imageValidation = Yup
    //     .mixed()
    //     .required("Required (Max size. 2mb)")
    //     .test("size", "Image should be less than 2 MB", (value) => {
    //         return value && value.size <= 2000000;
    //     })
    //     .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
    //         return value && (
    //             value.type === "image/jpeg" ||
    //             value.type === "image/jpg" ||
    //             value.type === "image/png"
    //         );
    //     })


    const validationSchema = Yup.object().shape({
        batch: Yup.string()
            .required('Required'),
        comments: Yup.string()
            .required('Required'),
        customer_name: Yup.string()
            .required('Required'),
        band_score: Yup.string()
            .required('Required'),
        // image: imageValidation

    });

    const formik = useFormik({
        initialValues: {
            happy_customer_id: "",
            batch: "",
            comments: "",
            customer_name: "",
            band_score: "",
            image: "",
            image_url: ""
        },
        validationSchema,
        validate: async (values) => {
            const errors ={}
        if(values?.image==""){
            errors.image = "Required"
        }
        else if (values?.image !== "" &&values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
            errors.image = "Only jpg/jpeg and png files are allowed!"
        }
        else {
            let kb = values.image.size / 1024; // convert the file size into byte to kb
            let mb = kb / 1024; // convert kb to mb
            if (mb > 2) {
                errors.image = "images should be less than 2 MB"
            }
        } if (values?.image !== "" &&values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.happy_customer_id) {
                formData.append("happy_customer_id", values.happy_customer_id ?? "");
            }
            formData.append("batch", values.batch);
            formData.append("customer_name", values.customer_name);
            formData.append("comments", values.comments);
            formData.append("image", values?.image);
            formData.append("band_score", values.band_score);


            happyCostumerservice.addOrUpdateHappyCostumer(formData).then((response) => {
                // })
                // CreateOrUpdateCourse({ formData })((response) => {
                console.log(response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        batch: response?.response?.data?.errors?.batch,
                        customer_name: response?.response?.data?.errors?.customer_name,
                        comments: response?.response?.data?.errors?.comments,
                        band_score: response?.response?.data?.errors?.band_score,
                        image: response?.response?.data?.errors?.image,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        console.log("itemmmmmmmmmmmmmmsssssssssssssss", item);
        setScroll(preVal => !preVal)
        // setImage(await dataURLtoFile(item.image))
        formik.setValues({
            happy_customer_id: item.id,
            batch: item.batch,
            customer_name: item.customer_name,
            comments: item.comments,
            band_score: item.band_score,
            image: await dataURLtoFile(item?.image),
            image_url: item?.image,

        })
        console.log("item", item);
    }
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Happy Customers</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Happy Customers</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Happy Customers") && <React.Fragment>
                        <CreateHappyCoustmers image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <DatatableHappycoustomers
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            handlePerRowsChange={handlePerRowsChange}
                            setPerpage={setPerpage}
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            formik={formik}
                            loading={loading}
                            setFilterText={setFilterText}
                            totalRows={totalRows}
                            pending={pending}
                        />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default HappyCoustumers;