import request from "../api/agent";
const API_ROUTE = "courses/course-specialization";

export class CourseSpecializationService {
    async addOrUpdateCourseSpecialization(courseSpecialization) {
        let action = `${API_ROUTE}/course-specialization-creation`;
        const response = await request.post(action, courseSpecialization);
        return response;
    }

    async getCourseSpecialization(limit, page,search) {
        console.log(page,limit,search,">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/course-specialization-listing?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }
    // async getCourseSpecialization() {
    //     const response = await request.get(`${API_ROUTE}/course-specialization-listing`);
    //     return response.data;
    // }


    async deleteCourseSpecialization(id) {
        const response = await request.delete(`${API_ROUTE}/course-specialization-delete`, {
            data: { instance_id: [id] }
        });
        return response;
    }
    async setStatus(id) {
        const response = await request.post(`${API_ROUTE}/specialization-status`, { instance_id: id });
        return response;
    }

}