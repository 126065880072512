// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import CreateSpecialization from './createSpecialization';
import SpecializationDatatable from './specializationDatatable';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import { SpecializationService } from '../../services/SpecializationService';
import { checkUserPermission } from '../../checkUserPermission';

const specializationService = new SpecializationService();

const Specialization = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [pending, setPending] = React.useState(true)
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [totalRows, setTotalRows] = React.useState(0);
    const [active, setActive] = useState(true);

    const [loading, setLoading] = useState(true);
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [scroll])


    async function getAllSpecialization() {
        setPending(true)
        const data = await specializationService.getAllSpecializations(filterText,perpage,currentPage);
         setPending(false)
        setTotalRows(data.data.count)
        setData(data.data)
        // setLoading(false)
    }
    useEffect(() => {
        getAllSpecialization()
    }, [refresh])

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),
        course_stream: Yup.number()
            .required("Required")
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            course_stream: "",
            selectedCourse: "",
            // image: "",
            // image_url: ""
        },
        validationSchema,
        validate: async (values) => {
            const errors = {};
            if (!values.course_stream) {
                errors.course_stream = "Course Stream is required";
            }
            // if (!values.university_id) {
            //     errors.university_id = "University is required";
            // }
            return errors;
        },
        // validate: async (values) => {
        //     const errors = {}
        //     // let image = values.image


        //     // values.id ? image = await dataURLtoFile(values.image) : image = values.image
        //     if (values.image == "") {
        //         errors.image = "Required"
        //     }
        //     else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
        //         errors.image = "Only jpg/jpeg and png files are allowed!"
        //     }
        //     console.log(values.image);
        //     return errors
        // },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("instance_id", values.id ?? "");
            }
            formData.append("name", values.name);
            formData.append("course_stream", values.course_stream);
            // formData.append("image", values?.image);

            specializationService.addOrUpdateSpecialization(formData).then((response) => {
                // })
                // CreateOrUpdateCourse({ formData })((response) => {
                console.log("response", response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    setIsloader(false)
                    toast.error(response?.response?.data?.data?.errors?.non_field_errors[0], {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    console.log(formik);
    const handleEdit = async (item) => {
        setScroll(preVal=>!preVal)
        formik.setValues({
            id: item.id,
            name: item.name,
            selectedCourse: {value:item.course_stream,label:item.course_stream_name},
        })

        console.log("handle edit", item);
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Specialization</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Specialization</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Specialization") &&  <React.Fragment>
                        <CreateSpecialization image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <SpecializationDatatable
                        totalRows={totalRows}
                         setCurrentPage={setCurrentPage} 
                         setPerpage={setPerpage}
                         currentPage={currentPage}  pending={pending} setFilterText={setFilterText} filterText={filterText} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Specialization;