import request from "../api/agent";

const API_ROUTE ="/contact-us/contact-us";

export class ContactService {

    async getContact(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/contact-us-all-listing?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }
     
    
    // async addOrUpdateVideo(video) {
    //     let action = `${API_ROUTE}/create-contact-us`;
    //     const response = await request.post(action, video);
    //     return response;
    // }

    async deleteContact(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-contact-us-request`, {data: { contact_id: id }});
        return response;
    }

    // async setVideoStatus(id){
    //     const response = await request.post(`${API_ROUTE}/testimonial-video-status`,{instance_id:id})
    //     return response;
    // }
}