import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';



const BenefitsCreate = ({ formik, isloader, formRef }) => {

    const [pointsErrors, setPointsErrors] = useState(false);

    const deleteBtn = {
        padding: "0.49rem 0.75rem",
        margin: "0 0 0 -2px",
        borderRadius: "0.25rem"
    }
    const addBtn = {
        padding: "0.49rem 1.2rem",
        margin: "0 0 0 -2px",
        borderRadius: "0.25rem"
    }


    function addPoints() {
        let points = formik.values?.points;
        points?.push({
            points: ''
        });
        formik.setValues({ ...formik.values, points: points })
    }

    function deletePoints(index) {
        let points = formik.values?.points;
        points?.splice(index, 1);
        formik.setValues({ ...formik.values, points: points })
    }
    console.log(formik)
    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Title </Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={formik.values?.title}
                                onChange={formik.handleChange}
                                isValid={formik.touched.title && !formik.errors.title}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.title && formik.errors.title}
                                placeholder="Title"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        {formik.values?.points.map((p, index) => {
                            if (p === undefined) {
                                setPointsErrors(true)
                                return;
                            }
                            const touched = formik.touched?.points && formik.touched?.points[index]  && formik.touched?.points[index].points;
                            return <>
                                <Form.Label>{index == 0 && "Benefits"} </Form.Label>
                                <Form.Group as={Col} md="6" controlId="validationCustom07" style={{ display: "flex", width: "600px" }}>
                                    <div style={{ width: "250px" }}>
                                        <Form.Control
                                            type="text"
                                            name={`points[${index}].points`}
                                            value={p?.points}
                                            onChange={formik.handleChange}
                                            isValid={touched && !(formik.errors.points && formik.errors.points[index] && formik.errors.points[index]?.points && !!formik.errors.points[index]?.points)}
                                            style={{
                                                width: "100%",
                                                borderRadius: "0.25rem 0 0 0.25rem"
                                            }}
                                            onBlur={formik.handleBlur}
                                            isInvalid={touched && formik.errors.points && formik.errors.points[index] && formik.errors.points[index]?.points && !!formik.errors.points[index]?.points}
                                            placeholder="Benefits"
                                            maxLength={100}
                                        />
                                        <Form.Control.Feedback type="invalid">{touched && formik.errors.points && formik.errors.points[index] && formik.errors.points[index]?.points && formik.errors.points[index]?.points}</Form.Control.Feedback>
                                    </div>
                                    <div className='d-flex'>
                                        {index == formik.values?.points?.length - 1 &&
                                            <div>
                                                <Button type="button" onClick={() => addPoints()} className='save-btn me-1' style={addBtn}>Add</Button>
                                            </div>}
                                        <div>
                                            {formik.values?.points?.length > 1 && <Button type="button" onClick={() => deletePoints(index)} className='save-btn ' style={deleteBtn}>Delete</Button>}
                                        </div>
                                    </div>
                                </Form.Group>
                            </>
                        })}
                    </Row>
                    <div className="group">
                        {isloader === true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default BenefitsCreate;