import request from "../api/agent";
const API_ROUTE = "about/brochure";

export class BrochureServices {
    async addOrUpdateBrochure(brochure) {
        let action = `${API_ROUTE}/create-or-update-brochure`;
        const response = await request.post(action, brochure);
        return response;
    }

    

    async getallBrochure() {
            const response = await request.get(`${API_ROUTE}/get-all-brochure`);
            return response.data;
    }



    async deleteBrochure(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-brochure`, {
            data: { id: id }
        });
        return response;
      }
      async getBrochure(id){
        const response = await request.put(`${API_ROUTE}/brochure-status-change`,{id:id})
        return response;
    }
    
}