import React, { useEffect, useRef, useState } from 'react';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { Link } from 'react-router-dom';
import Creategallery from './create-gallery';
import { GalleryService } from '../../services/galleryService';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { async } from 'q';


const galleryService = new GalleryService();
const Gallery = () => {
    
    const [isToastOpen, setToastOpen]=useState(true);
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [slotvalues, setSlotValues] = useState();
    const [gallery, setGallery] = useState();
    const [instanceId, setInstanceId] = useState('');



    const formik = useFormik({
        initialValues: {
            instance_id: "",
            slot_one: [],
            slot_two: [],
            slot_three: [],
            slot_four: [],
            slot_five: [],
        },

        validate: async (values) => {
            const errors = {}


            values.slot_one.forEach((file, index) => {
                if (
                    file.type !== "image/png" &&
                    file.type !== "image/jpg" &&
                    file.type !== "image/jpeg"
                ) {
                    errors.slot_one = errors.slot_one || [];
                    errors.slot_one[index] = "Only jpg/jpeg and png files are allowed!";
                } else {
                    let kb = file.size / 1024;
                    let mb = kb / 1024;
                    if (mb > 2) {
                        errors.slot_one = errors.slot_one || [];
                        errors.slot_one[index] = "Images should be less than 2 MB";
                    }
                }
            });




            values.slot_two.forEach((file, index) => {
                if (
                    file.type !== "image/png" &&
                    file.type !== "image/jpg" &&
                    file.type !== "image/jpeg"
                ) {
                    errors.slot_two = errors.slot_two || [];
                    errors.slot_two[index] = "Only jpg/jpeg and png files are allowed!";
                } else {
                    let kb = file.size / 1024;
                    let mb = kb / 1024;
                    if (mb > 2) {
                        errors.slot_two = errors.slot_two || [];
                        errors.slot_two[index] = "Images should be less than 2 MB";
                    }
                }
            });


            values.slot_three.forEach((file, index) => {
                if (
                    file.type !== "image/png" &&
                    file.type !== "image/jpg" &&
                    file.type !== "image/jpeg"
                ) {
                    errors.slot_three = errors.slot_three || [];
                    errors.slot_three[index] = "Only jpg/jpeg and png files are allowed!";
                } else {
                    let kb = file.size / 1024;
                    let mb = kb / 1024;
                    if (mb > 2) {
                        errors.slot_three = errors.slot_three || [];
                        errors.slot_three[index] = "Images should be less than 2 MB";
                    }
                }
            });


            values.slot_four.forEach((file, index) => {
                if (
                    file.type !== "image/png" &&
                    file.type !== "image/jpg" &&
                    file.type !== "image/jpeg"
                ) {
                    errors.slot_four = errors.slot_four || [];
                    errors.slot_four[index] = "Only jpg/jpeg and png files are allowed!";
                } else {
                    let kb = file.size / 1024;
                    let mb = kb / 1024;
                    if (mb > 2) {
                        errors.slot_four = errors.slot_four || [];
                        errors.slot_four[index] = "Images should be less than 2 MB";
                    }
                }
            });


            values.slot_five.forEach((file, index) => {
                if (
                    file.type !== "image/png" &&
                    file.type !== "image/jpg" &&
                    file.type !== "image/jpeg"
                ) {
                    errors.slot_five = errors.slot_five || [];
                    errors.slot_five[index] = "Only jpg/jpeg and png files are allowed!";
                } else {
                    let kb = file.size / 1024;
                    let mb = kb / 1024;
                    if (mb > 2) {
                        errors.slot_five = errors.slot_five || [];
                        errors.slot_five[index] = "Images should be less than 2 MB";
                    }
                }
            });

            return errors;
        },


        onSubmit: (values) => {
            console.log("Hello i am here");
            setIsloader(true)
            let formData = new FormData();
            let hasUploadedImages = false;
            if (instanceId) {
                formData.append("instance_id", instanceId);
            }

            const slots = ['slot_one', 'slot_two', 'slot_three', 'slot_four', 'slot_five'];
            slots.forEach((slot) => {
                if (values[slot]) {
                    for (let i = 0; i < values[slot].length; i++) {
                        const file = values[slot][i];
                        formData.append(slot, file);
                        hasUploadedImages = true;
                    }
                }
            });

            // formData.append("slot_one", values?.slot_one);
            // formData.append("slot_two", values?.slot_two);
            // formData.append("slot_three", values?.slot_three);
            // formData.append("slot_four", values?.slot_four);
            // formData.append("slot_five", values?.slot_five);
            if (hasUploadedImages) {
            galleryService.createGalleryImages(formData).then((response) => {

                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        toastId: "sucess1",
                        onOpen: () => {
                            setToastOpen(false)
                        },
                        onClose:() => {
                            setToastOpen(true)
                        },
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }

                else {
                    formik.setErrors({

                        slot_one: response?.response?.data?.errors?.slot_one,
                    });
                    setIsloader(false)
                    toast.error(response?.response?.data?.errors?.country_name ? response?.response?.data?.errors?.country_name[0] : "Something went wrong",
                        {
                            toastId: "sucess1",
                            onOpen: () => {
                                setToastOpen(false)
                            },
                            onClose:() => {
                                setToastOpen(true)
                            },
                            position: toast.POSITION.TOP_RIGHT
                        });
                }


            });
        }
        else {
            // No images uploaded, show a warning message
            toast.warning("Please upload at least one image", {
                toastId: "warning1",
                position: toast.POSITION.TOP_RIGHT
            });
            setIsloader(false);
        }
        },
    });


    async function getGallery() {
        const data = await galleryService.getGalleries();
        setGallery(data.data)
        console.log(data?.data?.results);
        if (data?.data?.results.length > 0) {
            setInstanceId(data?.data?.results[0].id);
        }
    }

    useEffect(() => {
        getGallery()
    }, [refresh])


    const deleteImageOne = async (id) => {
        const slotone = await galleryService.deleteSlotOne(id);
        setRefresh(!refresh)
    }

    const deleteImageTwo = async (id) => {
        const slottwo = await galleryService.deleteSlotTwo(id);
        setRefresh(!refresh)
    }

    const deleteImageThree = async (id) => {
        const slotthree = await galleryService.deleteSlotThree(id);
        setRefresh(!refresh)
    }

    const deleteImageFour = async (id) => {
        const slothfour = await galleryService.deleteSlotFour(id);
        setRefresh(!refresh)
    }

    const deleteImageFive = async (id) => {
        const slotfive = await galleryService.deleteSlotFive(id);
        setRefresh(!refresh)
    }

    console.log(formik.values, 'errors');

    return (
        <React.Fragment>
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Gallery</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Gallery</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                    <ToastContainer/>
                        <Creategallery
                            formik={formik}
                            isloader={isloader}
                            setSlotValues={setSlotValues}
                            formRef={formRef}
                            gallery={gallery}
                            deleteImageOne={deleteImageOne}
                            deleteImageTwo={deleteImageTwo}
                            deleteImageThree={deleteImageThree}
                            deleteImageFour={deleteImageFour}
                            deleteImageFive={deleteImageFive}
                        />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Gallery;