import request from "../api/agent";

const API_ROUTE ="/testimonials/testimonial";

export class TestimonialVideoService {

    async getVideo(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-testimonial-videos?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }
     
    
    async addOrUpdateVideo(video) {
        let action = `${API_ROUTE}/testimonial-video-create-or-update`;
        const response = await request.post(action, video);
        return response;
    }

    async deleteVideo(id) {
        const response = await request.delete(`${API_ROUTE}/testimonial-video-delete`, {data: { instance_id: [id] }});
        return response;
    }

    async setVideoStatus(id){
        const response = await request.post(`${API_ROUTE}/testimonial-video-status`,{instance_id:id})
        return response;
    }
}