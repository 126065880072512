// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
// import { CourseService } from "../../../services/CourseService";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import CreateUniversity from '../university/createUniversity';
import UniversityDatatable from '../university/universityDatatable';
import { UniversityService } from '../../services/universityService';
import { checkUserPermission } from '../../checkUserPermission';
import { CountryService } from '../../services/CountryServices';

const universityService = new UniversityService();
const countryService = new CountryService;

const University = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [pending, setpending] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [filterId, setFilterId] = useState("");
    const [countries, setCountries] = useState([]);
    const [formView, setFormView] = useState("page1");
    const [active, setActive] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])



    async function getAllUniversity() {
         setpending(true)
        const data = await universityService.getAllUniversity(perpage, currentPage, filterText, filterId);
        setData(data.data)
        setTotalRows(data.data?.count)
        setpending(false)
    }

   

    useEffect(() => {
        getAllUniversity()
    }, [refresh])

    // const imageValidation = Yup
    //     .mixed()
    //     .test( "size", "",(value) => {
    //         return value === "";
    //     })
    //     .test("size", "The file is too large", (value) => {
    //         return value && value.size <= 2000000;
    //     })
    //     .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
    //         return value && (
    //             value.type === "image/jpeg" ||
    //             value.type === "image/jpg" ||
    //             value.type === "image/png"
    //         );
    //     })

    const validationSchema = Yup.object().shape({
        university_name: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        // website: Yup.string().required('Required'),
        // website: Yup.string()
        // .matches(
        //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        //     'Enter correct url!'
        // )
        // .required('Please enter website'),
        // established_on: Yup.number().required('Required'),
        // university_video_url: Yup.string().required('Required'),
        // university_video_details: Yup.string().required('Required'),
        // cover_image: imageValidation,
        // logo: imageValidation,
        // university_images: Yup.array()
        //     .of(
        //         Yup.object().shape({
        //             image: imageValidation,
        //         }).required("Required")
        //     )
        //     .required('Required'),
        // type: Yup.number().required('Required')
    });
    const markAllFieldsAsTouched = () => {
        const fieldKeys = Object.keys(formik.values);
        const touchedFields = fieldKeys.reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {});
        formik.setTouched(touchedFields);
      };
    const initialValues =  {
        id: "",
        university_name: "",
        address: "",
        country: "",
        selectedCountry:'',
        website: "",
        established_on: "",
        university_ranking: "",
        // university_sector: "",
        university_video_url: "",
        university_video_details: "",
        about:"",
        cover_image: '',
        cover_image_url: '',
        logo: '',
        logo_url: '',
        specialization: '',
        specializationValue:'',
        // university_images: [{
        //     image: '',
        //     image_url: ''
        // }],
        type: "",
        universityType:"",
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validate: async (values) => {
            console.log(values,"VALUES{}{}{}{}{}{}|}|}|}|}|")
            const errors = {}
            const videoUrl = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

            if (values?.university_video_url !== "" && !videoUrl.test(values?.university_video_url)) {
              errors.university_video_url = "Please enter a correct video URL";
            }
            // if(values?.specialization == ""){
        //     errors.specialization ="Required"
        // }
            if (values?.logo !== "" &&values?.logo?.type !== "image/png" && values?.logo?.type !== "image/jpg" && values?.logo?.type !== "image/jpeg") {
                errors.logo = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.logo.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.logo = "images should be less than 2 MB"
                }
            }
            // if (values?.university_images !== "" &&values?.university_images?.type !== "image/png" && values?.university_images?.type !== "image/jpg" && values?.university_images?.type !== "image/jpeg") {
            //     errors.university_images = "Only jpg/jpeg and png files are allowed!"
            // }
            // else {
            //     let kb = values.university_images.size / 1024; // convert the file size into byte to kb
            //     let mb = kb / 1024; // convert kb to mb
            //     if (mb > 2) {
            //         errors.university_images = "images should be less than 2 MB"
            //     }
            // }
            if (values?.cover_image !== "" &&values?.cover_image?.type !== "image/png" && values?.cover_image?.type !== "image/jpg" && values?.cover_image?.type !== "image/jpeg") {
                errors.cover_image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.cover_image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.cover_image = "images should be less than 2 MB"
                }
            }
                if (!values.country) {
                    errors.country = "Country is required";
                }
                if (!values.type) {
                    errors.type = "University Type is required";
                }
                if(!values.specialization){
                    errors.specialization = "Specialization is required";
                }
            return errors;
        },
        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("university_id", values.id ?? "");
            }
            formData.append("university_name", values.university_name);
            formData.append("address", values.address);
            formData.append("country", values?.country);
            formData.append("website", values?.website);
            formData.append("university_ranking", values?.university_ranking);
            formData.append("established_on", values?.established_on);
            formData.append("about", values?.about);
            formData.append("university_video_url", values?.university_video_url);
            formData.append("university_video_details", values?.university_video_details);
            formData.append("cover_image", values?.cover_image ? values?.cover_image :"" );
            // values?.university_images?.map(image => {
            //     formData.append("university_images", image?.image ? image?.image : "");
            // })
            formData.append("logo", values?.logo);
            // values?.specialization?.map(specialization => {
            //     formData.append("specialization", specialization?.value);
            // })
            formData.append("specialization", values?.specialization);
            formData.append("type", values?.type);
            universityService.addOrUpdateUniversity(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    setFormView("page1")
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm(initialValues)
                    formRef.current.reset();
                }
            });
        },
    });

    const handleEdit = async (item) => {
        console.log(item,"{}{}{}{}{}{}")
        setScroll(preVal => !preVal)
        setFormView("page1")
        let universityImages = []
        // item.university_image && item.university_image.forEach(async item => {
        //     universityImages.push({
        //         image: await dataURLtoFile(item.image),
        //         image_url: item.image
        //     })
        // })

        formik.setValues({
            id: item.id,
            university_name: item?.university_name,
            address: item?.address,
            selectedCountry: {value:item?.country,label:item?.country_name},
            country: item?.country,
            website: item?.website,
            established_on: item?.established_on,
            university_ranking: item?.university_ranking,
            about: item?.about,
            university_video_url: item?.university_video_url,
            university_video_details: item?.university_video_details,
            cover_image: item?.university_cover_images && item?.university_cover_images != "" ? await dataURLtoFile(item?.university_cover_images) : '',
            cover_image_url: item?.university_cover_images,
            logo: item?.logo ? await dataURLtoFile(item?.logo) : '',
            logo_url: item?.logo,
            // university_images: item?.university_image.length ? universityImages : [{
            //     image: '',
            //     image_url: ''
            // }],
            // university_images_url: item?.university_images,
            specializationValue:item?.specialization.map(spec => ({ value: spec.id, label: spec.name })),
            specialization:item?.specialization.map(spec => spec.id),
            universityType: {value:item.type_id,label:item.type},
            type: item.type_id
        })
    }
    // console.log("formik values are",countries)
    // console.log(formik.errors)
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>University</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">University</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Universities") && <React.Fragment>
                        <CreateUniversity markAllFieldsAsTouched={markAllFieldsAsTouched} formView={formView} setFormView={setFormView} setCountries={setCountries} countries={countries} image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <UniversityDatatable countries={countries} setFilterId={setFilterId} setFilterText={setFilterText} currentPage={currentPage} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} setCurrentPage={setCurrentPage} setPerpage={setPerpage} totalRows={totalRows} pending={pending} />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default University;