import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const CountryCreate = ({ formik, isloader, toaster, setToaster, setImage, markAllFieldsAsTouched, flag, formRef, imageError, setFormView, formView }) => {
    const onChangeImage = (e) => {
        setImage(e.target.files[0])
        formik.setValues({
            ...formik.values,
            image: e.target.files[0] ? e.target.files[0] : "",
            image_url: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "",
        })
    }
    const onChangeOgImage = (e) => {
        setImage(e.target.files[0])
        formik.setValues({ ...formik.values, og_image: e.target.files[0], og_image_url: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "" })
    }
    // const nextclick=()=>{
    //     if(!formik.values?.country_name  ){
    //         markAllFieldsAsTouched()
    //     }

    //     else if(!formik.values?.image && formik.values?.country_id ){
    //         markAllFieldsAsTouched()

    //     }
    //     else{
    //         setFormView("whythiscountry")
    //     }
    // }



    const otherbenifitsinputHandler = (event, editor) => {
        formik.setFieldValue("other_benefits", editor.getData());
    };

    const deleteBtn = {
        padding: "0.49rem 1.75rem",
        margin: "0 0 0 -2px",
        borderRadius: "0.25rem"
    }
    const addBtn = {
        padding: "0.49rem 1.2rem",
        margin: "0 0 0 -2px",
        borderRadius: "0.25rem"
    }
    function countrybenefits() {
        let countrybenefits = formik.values?.country_benefits;
        countrybenefits?.push({
            title: '',
            description: '',

        });
        formik.setValues({ ...formik.values, country_benefits: countrybenefits })
    }

    function whythiscountry() {
        let whythiscountry = formik.values?.why_this_country;
        whythiscountry?.push({
            title: '',
            description: '',

        });
        formik.setValues({ ...formik.values, why_this_country: whythiscountry })
    }
    function deletewhythiscountry(index) {
        let whythiscountry = formik.values?.why_this_country;
        whythiscountry?.splice(index, 1);
        formik.setValues({ ...formik.values, why_this_country: whythiscountry })
    }
    function deletecountrybenefits(index) {
        let countrybenefits = formik.values?.country_benefits;
        countrybenefits?.splice(index, 1);
        formik.setValues({ ...formik.values, country_benefits: countrybenefits })
    }
    function topindustries() {
        let topindustries = formik.values?.top_industries;
        topindustries?.push({
            title: '',
            description: '',

        });
        formik.setValues({ ...formik.values, top_industries: topindustries })
    }
    function deletetopindustries(index) {
        let deletetopindustries = formik.values?.top_industries;
        deletetopindustries?.splice(index, 1);
        formik.setValues({ ...formik.values, top_industries: deletetopindustries })
    }
    function visaprocess() {
        let visaprocess = formik.values?.visa_process;
        visaprocess?.push({
            title: '',
            description: '',

        });
        formik.setValues({ ...formik.values, visa_process: visaprocess })
    }
    function deletevisaprocess(index) {
        let deletevisaprocess = formik.values?.visa_process;
        deletevisaprocess?.splice(index, 1);
        formik.setValues({ ...formik.values, visa_process: deletevisaprocess })
    }
    const onClickNextwhy = () => {
        let errorflag = false
        formik.values?.why_this_country?.length && formik.values?.why_this_country.map((why) => {
            if (why?.title == '') {
                errorflag = true;
            }
            else if (why?.description == '') {
                errorflag = true;
            }
        })
        if (errorflag) {
            markAllFieldsAsTouched()
            formik.setTouched({
                ...formik.touched,
                why_this_country: formik.values?.why_this_country?.map((country) => { return { title: true, description: true } })
            })
        }
        else {
            setFormView('countrybenefits')
        }

    }
    const onClickcountrybenefits = () => {
        let errorflag = false
        formik.values?.country_benefits?.length && formik.values?.country_benefits.map((why) => {
            if (why?.title == '') {
                errorflag = true;
            }
            else if (why?.description == '') {
                errorflag = true;
            }
        })
        if (errorflag) {
            markAllFieldsAsTouched()
            formik.setTouched({
                ...formik.touched,
                country_benefits: formik.values?.country_benefits?.map((country) => { return { title: true, description: true } })
                // title: formik.values?.country_benefits?.map((title)=>{return {title:true}}),
                // description: formik.values?.country_benefits?.map((description)=>{return {description:true}})
            })
        }
        else {
            setFormView('topindustries')
        }

    }
    const onClicktopindustries = () => {
        let errorflag = false
        formik.values?.top_industries?.length && formik.values?.top_industries.map((why) => {
            if (why?.title == '') {
                errorflag = true;
            }
            else if (why?.description == '') {
                errorflag = true;
            }
        })
        if (errorflag) {
            markAllFieldsAsTouched()
            formik.setTouched({
                ...formik.touched,
                top_industries: formik.values?.top_industries?.map((country) => { return { title: true, description: true } })

            })
        }
        else {
            setFormView('visaprocess')
        }

    }
    const onClickvisaprocess = () => {
        let errorflag = false
        formik.values?.visa_process?.length && formik.values?.visa_process.map((why) => {
            if (why?.title == '') {
                errorflag = true;
            }
            else if (why?.description == '') {
                errorflag = true;
            }
        })
        if (errorflag) {
            markAllFieldsAsTouched()
            formik.setTouched({
                ...formik.touched,
                visa_process: formik.values?.visa_process?.map((country) => { return { title: true, description: true } })

            })
        }
        else {
            setFormView('otherbenifits')
        }

    }


    // console.log(formik.values)
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    {formView === "country" &&
                        <>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="country_name"
                                        value={formik.values?.country_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.country_name && !formik.errors.country_name}
                                        isInvalid={formik.touched.country_name && formik.errors.country_name}
                                        placeholder="Name"
                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.touched.country_name && formik.errors.country_name && formik.errors.country_name}</Form.Control.Feedback>
                                </Form.Group>
                                {/* <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Flag</Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={image}
                                    onChange={(e) => {
                                        setImage(e.target.files[0])
                                        formik.setValues({ ...formik.values, image: e.target.files[0] })
                                    }
                                    }
                                    isValid={formik.touched.image && !formik.errors.image}
                                    isInvalid={!!formik.errors.image}
                                />
                                {formik.values?.image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }
                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                            </div>
                        </Form.Group> */}
                                <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                                    <Form.Label>Flag<span className='required ms-1'>*</span></Form.Label>
                                    <div className="file_wrapper">
                                        <Form.Control
                                            type="file"
                                            name="image"
                                            accept="image/*"
                                            values={formik.values.image}
                                            onChange={(e) => {
                                                setImage(e.target.files[0]);
                                                formik.setValues({
                                                    ...formik.values,
                                                    image: e.target.files[0],
                                                    image_url: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "",
                                                });
                                            }}
                                            onBlur={formik.handleBlur}
                                            isValid={formik.touched.image && !formik.errors.image}
                                            isInvalid={formik.touched.image && formik.errors.image}


                                        />
                                        {formik.values?.image_url &&
                                            <div className='img-wrapper'>
                                                <img src={formik.values?.image_url} alt='course' style={{ height: '50px' }} className='img-fluid mt-2' />
                                            </div>
                                        }
                                        <Form.Control.Feedback type="invalid">{formik.touched.image && formik.errors.image && formik.errors.image}</Form.Control.Feedback>
                                    </div>
                                    <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 218 * 122 px)</p></div>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom03" className="mb-3">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="country_code"
                                        value={formik.values?.country_code}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        // isValid={formik.touched.country_code && !formik.errors.country_code}
                                        // isInvalid={formik.touched.country_code && formik.errors.country_code}
                                        placeholder="Eg:+91"
                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.country_code}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Currency Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="currency_code"
                                        value={formik.values?.currency_code}
                                        onChange={formik.handleChange}
                                        // isValid={formik.touched.currency_code && !formik.errors.currency_code}
                                        // isInvalid={!!formik.errors.currency_code}
                                        placeholder="Eg:INR "
                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.currency_code}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label> Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="description"
                                        value={formik.values?.description}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const charCount = inputValue.length;
                                            console.log(inputValue.split(/\s+/).length)
                                            // debugger
                                            if (inputValue.split(/\s+/).length <= 1000) {
                                                formik.handleChange(e);
                                            } else {
                                                alert("Word Limit Exceeded")
                                            }

                                        }}
                                        // isValid={formik.touched.description && !formik.errors.description}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.description && formik.errors.description}
                                        placeholder="Description"
                                        style={{ height: '138px' }}
                                    // maxLength={1000}
                                    />
                                    {/* {formik.errors.description ? <Form.Control.Feedback type="invalid">{formik.errors.description}</Form.Control.Feedback> :
                                        <div className="max-char">{formik?.values?.description?.length + "/1000"}</div>} */}
                                    {formik.errors.description ? <Form.Control.Feedback type="invalid" >{formik.errors.description}</Form.Control.Feedback> :
                                        <div className="max-char">{formik.values.description.split(/\s+/).length + "/1000"}</div>

                                    }
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Meta description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="meta_description"
                                        value={formik.values?.meta_description}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.meta_description && !formik.errors.meta_description}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.meta_description && formik.errors.meta_description}
                                        placeholder="Meta description"
                                        style={{ height: '138px' }}
                                        maxLength={250}
                                    />
                                    {/* {formik.errors.meta_description ?  <Form.Control.Feedback type="invalid">{formik.errors.meta_description}</Form.Control.Feedback> :
                  <div className="max-char">{formik.values.description.length + "/250"}</div>} */}
                                    {formik.errors.meta_description ? <Form.Control.Feedback type="invalid">{formik.errors.meta_description}</Form.Control.Feedback> : <div className="max-char">{formik.values.meta_description.length + "/250"}</div>}

                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                {/* <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                                    <Form.Label>Og image</Form.Label>
                                    <div className="file_wrapper">
                                        <Form.Control
                                            type="file"
                                            name="og_image"
                                            accept="image/*"
                                            values={formik.values.og_image}
                                            onChange={(e) => {
                                                onChangeOgImage(e)
                                            }
                                            }
                                            isValid={formik.touched.og_image && !formik.errors.og_image}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.og_image && formik.errors.og_image}
                                        />
                                        {formik.values?.og_image_url &&
                                            <div className='img-wrapper'>
                                                <img src={formik.values?.og_image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                            </div>
                                        }
                                    
                                        <Form.Control.Feedback type="invalid">{formik.errors.og_image}</Form.Control.Feedback>

                                    </div>
                                    <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 1200 * 630 px)</p></div>
                                </Form.Group> */}
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>Meta image title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="meta_image_title"
                                        value={formik.values?.meta_image_title}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.meta_image_title && !formik.errors.meta_image_title}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.meta_image_title && formik.errors.meta_image_title}
                                        placeholder="Meta image title"

                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.meta_image_title}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>Meta title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="meta_title"
                                        value={formik.values?.meta_title}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.meta_title && !formik.errors.meta_title}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.meta_title && formik.errors.meta_title}
                                        placeholder="Meta title"

                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.meta_title}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Meta keyword (seperated by commas)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        type="text"
                                        name="meta_keyword"
                                        value={formik.values?.meta_keyword}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.meta_keyword && !formik.errors.meta_keyword}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.meta_keyword && formik.errors.meta_keyword}
                                        placeholder="Meta keyword"

                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.meta_keyword}</Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <div className="group">

                                <Button type="button" onClick={() => formik.values?.image && formik.values?.country_name ? setFormView("whythiscountry") : markAllFieldsAsTouched()} className='save-btn me-4'>Next</Button>

                            </div>
                        </>}
                    {formView === 'whythiscountry' &&
                        <>
                            <Row>
                                <h6 className='country_title mb-3'>Why Study In This Country</h6>
                                {formik.values.why_this_country.map((why_this_country, index) => {
                                    if (why_this_country === undefined) {
                                        // setResponsibilityErrors(true)
                                        return;
                                    }
                                    console.log(why_this_country)
                                    const touched = formik.touched?.why_this_country && formik.touched?.why_this_country[index]?.title;
                                    const toucheddescription = formik.touched?.why_this_country && formik.touched?.why_this_country[index]?.description;

                                    return <>
                                        <div className='row'>
                                            <Form.Group as={Col} md="6" controlId="validationCustom07" >
                                                <Form.Label>{index == 0 && "Tittle"} </Form.Label>

                                                <div >
                                                    <Form.Control
                                                        type="text"
                                                        name={`why_this_country[${index}].title`}
                                                        style={{

                                                            borderRadius: "0.25rem 0 0 0.25rem"
                                                        }}
                                                        value={why_this_country?.title}
                                                        onChange={formik.handleChange}
                                                        isValid={touched && !(formik.errors.why_this_country && formik.errors.why_this_country[index] && formik.errors.why_this_country[index]?.title && !!formik.errors.why_this_country[index]?.title)}
                                                        onBlur={formik.handleBlur}
                                                        isInvalid={touched && formik.errors.why_this_country && formik.errors.why_this_country[index] && formik.errors.why_this_country[index]?.title && !!formik.errors.why_this_country[index]?.title}
                                                        placeholder="Tittle"
                                                        maxLength={100}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{touched && formik.errors.why_this_country && formik.errors.why_this_country[index] && formik.errors.why_this_country[index]?.title && formik.errors.why_this_country[index]?.title}</Form.Control.Feedback>
                                                </div>

                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom07" >
                                                <Form.Label>{index == 0 && " Description"} </Form.Label>

                                                <div >
                                                    <Form.Control
                                                        as="textarea"
                                                        name={`why_this_country[${index}].description`}
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "0.25rem 0 0 0.25rem",
                                                            height: '138px'
                                                        }}
                                                        value={why_this_country?.description}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            const charCount = inputValue.length;
                                                            console.log(inputValue.split(/\s+/).length)
                                                            // debugger
                                                            if (inputValue.split(/\s+/).length <= 1000) {
                                                                formik.handleChange(e);
                                                            } else {
                                                                alert("Word Limit Exceeded")
                                                            }

                                                        }}
                                                        isValid={toucheddescription && !(formik.errors.why_this_country && formik.errors.why_this_country[index] && formik.errors.why_this_country[index]?.description && !!formik.errors.why_this_country[index]?.description)}
                                                        onBlur={formik.handleBlur}
                                                        isInvalid={toucheddescription && formik.errors.why_this_country && formik.errors.why_this_country[index] && formik.errors.why_this_country[index]?.description && !!formik.errors.why_this_country[index]?.description}
                                                        placeholder=" Description"
                                                    // maxLength={1000}
                                                    />

                                                    {formik.errors.description ? <Form.Control.Feedback type="invalid" >{formik.errors.description}</Form.Control.Feedback> :
                                                        <div className="max-char">{formik.values.description.split(/\s+/).length + "/1000"}</div>

                                                    }

                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    {index == formik.values?.why_this_country?.length - 1 &&
                                                        <div>
                                                            <Button type="button" onClick={() => whythiscountry()} className='save-btn me-1' style={deleteBtn} >Add</Button>
                                                        </div>}
                                                    <div>
                                                        {formik.values?.why_this_country?.length > 1 && <Button type="button" onClick={() => deletewhythiscountry(index)} className='save-btn ' style={deleteBtn}>Delete</Button>}
                                                    </div>



                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div class="hr-divider-country"></div>
                                    </>

                                }
                                )
                                }
                            </Row>

                            <div className="group mt-3">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('country')}>Back</Button>
                                <Button type="button" onClick={() => onClickNextwhy()} className='save-btn me-4'>Next</Button>
                            </div>
                        </>


                    }
                    {formView === 'countrybenefits' &&
                        <>
                            <Row>
                                <h6 className='country_title mb-3'>Benefits Of Studying In This Country</h6>
                                {formik.values.country_benefits.map((country_benefits, index) => {
                                    if (country_benefits === undefined) {
                                        // setResponsibilityErrors(true)
                                        return;
                                    }
                                    console.log(country_benefits)
                                    const touched = formik.touched?.country_benefits && formik.touched?.country_benefits[index]?.title;
                                    const toucheddescription = formik.touched?.country_benefits && formik.touched?.country_benefits[index]?.description;
                                    return <>
                                        <div className='row'>

                                            <Form.Group as={Col} md="6" controlId="validationCustom07" >
                                                <Form.Label>{index == 0 && "Title"} </Form.Label>

                                                <div >
                                                    <Form.Control
                                                        type="text"
                                                        name={`country_benefits[${index}].title`}
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "0.25rem 0 0 0.25rem"
                                                        }}
                                                        value={country_benefits?.title}
                                                        onChange={formik.handleChange}
                                                        isValid={touched && !(formik.errors.country_benefits && formik.errors.country_benefits[index] && formik.errors.country_benefits[index]?.title && !!formik.errors.country_benefits[index]?.title)}
                                                        onBlur={formik.handleBlur}
                                                        isInvalid={touched && formik.errors.country_benefits && formik.errors.country_benefits[index] && formik.errors.country_benefits[index]?.title && !!formik.errors.country_benefits[index]?.title}
                                                        placeholder="Tittle"
                                                        maxLength={100}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{touched && formik.errors.country_benefits && formik.errors.country_benefits[index] && formik.errors.country_benefits[index]?.title && formik.errors.country_benefits[index]?.title}</Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom07" >
                                                <Form.Label>{index == 0 && " Description"} </Form.Label>

                                                <div >
                                                    <Form.Control
                                                        as="textarea"
                                                        name={`country_benefits[${index}].description`}
                                                        style={{

                                                            borderRadius: "0.25rem 0 0 0.25rem",
                                                            height: '138px'
                                                        }}
                                                        value={country_benefits?.description}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            const charCount = inputValue.length;
                                                            console.log(inputValue.split(/\s+/).length)
                                                            // debugger
                                                            if (inputValue.split(/\s+/).length <= 1000) {
                                                                formik.handleChange(e);
                                                            } else {
                                                                alert("Word Limit Exceeded")
                                                            }

                                                        }}
                                                        isValid={toucheddescription && !(formik.errors.country_benefits && formik.errors.country_benefits[index] && formik.errors.country_benefits[index]?.description && !!formik.errors.country_benefits[index]?.description)}
                                                        onBlur={formik.handleBlur}
                                                        isInvalid={toucheddescription && formik.errors.country_benefits && formik.errors.country_benefits[index] && formik.errors.country_benefits[index]?.description && !!formik.errors.country_benefits[index]?.description}
                                                        placeholder=" Description"
                                                    // maxLength={1000}
                                                    />
                                                    {formik.errors.description ? <Form.Control.Feedback type="invalid" >{formik.errors.description}</Form.Control.Feedback> :
                                                        <div className="max-char">{formik.values.description.split(/\s+/).length + "/1000"}</div>

                                                    }

                                                    {/* <Form.Control.Feedback type="invalid">{ touched && formik.errors.country_benefits && formik.errors.country_benefits[index] && formik.errors.country_benefits[index]?.description && formik.errors.country_benefits[index]?.description}</Form.Control.Feedback> */}
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    {index == formik.values?.country_benefits?.length - 1 &&
                                                        <div>
                                                            <Button type="button" onClick={() => countrybenefits()} className='save-btn me-1' style={deleteBtn} >Add</Button>
                                                        </div>}
                                                    <div>
                                                        {formik.values?.country_benefits?.length > 1 && <Button type="button" onClick={() => deletecountrybenefits(index)} className='save-btn' style={deleteBtn}>Delete</Button>}
                                                    </div>


                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div class="hr-divider-country"></div>

                                    </>


                                }
                                )
                                }
                            </Row>

                            <div className="group mt-3">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('whythiscountry')}>Back</Button>
                                <Button type="button" onClick={() => onClickcountrybenefits()} className='save-btn me-4'>Next</Button>
                            </div>
                        </>


                    }
                    {
                        formView === 'topindustries' &&
                        <>
                            <Row>
                                <h6 className='country_title mb-3'>Top Industries In This Country</h6>
                                {formik.values.top_industries.map((top_industries, index) => {
                                    if (top_industries === undefined) {
                                        // setResponsibilityErrors(true)
                                        return;
                                    }
                                    console.log(top_industries)
                                    const touched = formik.touched?.top_industries && formik.touched?.top_industries[index]?.title;
                                    const toucheddescription = formik.touched?.top_industries && formik.touched?.top_industries[index]?.description;

                                    return <>
                                        <div className='row'>
                                            <Form.Group as={Col} md="6" controlId="validationCustom07" >

                                                <Form.Label>{index == 0 && "Title"} </Form.Label>

                                                <div >
                                                    <Form.Control
                                                        type="text"
                                                        name={`top_industries[${index}].title`}
                                                        style={{

                                                            borderRadius: "0.25rem 0 0 0.25rem"
                                                        }}
                                                        value={top_industries?.title}
                                                        onChange={formik.handleChange}
                                                        isValid={touched && !(formik.errors.top_industries && formik.errors.top_industries[index] && formik.errors.top_industries[index]?.title && !!formik.errors.top_industries[index]?.title)}
                                                        onBlur={formik.handleBlur}
                                                        isInvalid={touched && formik.errors.top_industries && formik.errors.top_industries[index] && formik.errors.top_industries[index]?.title && !!formik.errors.top_industries[index]?.title}
                                                        placeholder="Title"
                                                        maxLength={250}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{touched && formik.errors.top_industries && formik.errors.top_industries[index] && formik.errors.top_industries[index]?.title && formik.errors.top_industries[index]?.title}</Form.Control.Feedback>
                                                </div>

                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom07" >
                                                {/* <Form.Label>{index == 0 && " Description"} </Form.Label> */}

                                                <div >
                                                    <Form.Label>{index == 0 && " Description"} </Form.Label>

                                                    <div >
                                                        <Form.Control
                                                            as="textarea"
                                                            name={`top_industries[${index}].description`}
                                                            style={{

                                                                borderRadius: "0.25rem 0 0 0.25rem",
                                                                height: '138px'
                                                            }}
                                                            value={top_industries?.description}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                const charCount = inputValue.length;
                                                                console.log(inputValue.split(/\s+/).length)
                                                                // debugger
                                                                if (inputValue.split(/\s+/).length <= 1000) {
                                                                    formik.handleChange(e);
                                                                } else {
                                                                    alert("Word Limit Exceeded")
                                                                }

                                                            }}
                                                            isValid={toucheddescription && !(formik.errors.top_industries && formik.errors.top_industries[index] && formik.errors.top_industries[index]?.description && !!formik.errors.top_industries[index]?.description)}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={toucheddescription && formik.errors.top_industries && formik.errors.top_industries[index] && formik.errors.top_industries[index]?.description && !!formik.errors.top_industries[index]?.description}
                                                            placeholder=" Description"
                                                        // maxLength={1000}
                                                        />
                                                        {formik.errors.description ? <Form.Control.Feedback type="invalid" >{formik.errors.description}</Form.Control.Feedback> :
                                                            <div className="max-char">{formik.values.description.split(/\s+/).length + "/1000"}</div>

                                                        }


                                                    </div>

                                                    <Form.Control.Feedback type="invalid">{toucheddescription && formik.errors.top_industries && formik.errors.top_industries[index] && formik.errors.top_industries[index]?.description && formik.errors.top_industries[index]?.description}</Form.Control.Feedback>
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    {index == formik.values?.top_industries?.length - 1 &&
                                                        <div>
                                                            <Button type="button" onClick={() => topindustries()} className='save-btn me-1' style={deleteBtn} >Add</Button>
                                                        </div>}
                                                    <div>
                                                        {formik.values?.top_industries?.length > 1 && <Button type="button" onClick={() => deletetopindustries(index)} className='save-btn' style={deleteBtn}>Delete</Button>}
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div class="hr-divider-country"></div>

                                    </>

                                }
                                )
                                }
                            </Row>

                            <div className="group mt-3">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('countrybenefits')}>Back</Button>
                                <Button type="button" onClick={() => onClicktopindustries()} className='save-btn me-4'>Next</Button>
                            </div>
                        </>
                    }
                    {
                        formView === 'visaprocess' &&
                        <>
                            <Row>
                                <h6 className='country_title mb-3'>Visa Process</h6>
                                {formik.values.visa_process.map((visa_process, index) => {
                                    if (visa_process === undefined) {
                                        // setResponsibilityErrors(true)
                                        return;
                                    }
                                    console.log(visa_process)
                                    const touched = formik.touched?.visa_process && formik.touched?.visa_process[index]?.title;
                                    const toucheddescription = formik.touched?.visa_process && formik.touched?.visa_process[index]?.description;

                                    return <>
                                        <div className='row'>
                                            <Form.Group as={Col} md="6" controlId="validationCustom07" >

                                                <Form.Label>{index == 0 && "Title"} </Form.Label>

                                                <div >
                                                    <Form.Control
                                                        type="text"
                                                        name={`visa_process[${index}].title`}
                                                        style={{

                                                            borderRadius: "0.25rem 0 0 0.25rem"
                                                        }}
                                                        value={visa_process?.title}
                                                        onChange={formik.handleChange}
                                                        isValid={touched && !(formik.errors.visa_process && formik.errors.visa_process[index] && formik.errors.visa_process[index]?.title && !!formik.errors.visa_process[index]?.title)}
                                                        onBlur={formik.handleBlur}
                                                        isInvalid={touched && formik.errors.visa_process && formik.errors.visa_process[index] && formik.errors.visa_process[index]?.title && !!formik.errors.visa_process[index]?.title}
                                                        placeholder="Title"
                                                        maxLength={250}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{touched && formik.errors.visa_process && formik.errors.visa_process[index] && formik.errors.visa_process[index]?.title && formik.errors.visa_process[index]?.title}</Form.Control.Feedback>
                                                </div>

                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom07" >
                                                {/* <Form.Label>{index == 0 && " Description"} </Form.Label> */}

                                                <div >
                                                    <Form.Label>{index == 0 && " Description"} </Form.Label>

                                                    <div >
                                                        <Form.Control
                                                            as="textarea"
                                                            name={`visa_process[${index}].description`}
                                                            style={{

                                                                borderRadius: "0.25rem 0 0 0.25rem",
                                                                height: '138px'
                                                            }}
                                                            value={visa_process?.description}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                const charCount = inputValue.length;
                                                                console.log(inputValue.split(/\s+/).length)
                                                                // debugger
                                                                if (inputValue.split(/\s+/).length <= 1000) {
                                                                    formik.handleChange(e);
                                                                } else {
                                                                    alert("Word Limit Exceeded")
                                                                }

                                                            }}
                                                            isValid={toucheddescription && !(formik.errors.visa_process && formik.errors.visa_process[index] && formik.errors.visa_process[index]?.description && !!formik.errors.visa_process[index]?.description)}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={toucheddescription && formik.errors.visa_process && formik.errors.visa_process[index] && formik.errors.visa_process[index]?.description && !!formik.errors.visa_process[index]?.description}
                                                            placeholder=" Description"
                                                        // maxLength={1000}

                                                        />
                                                        {formik.errors.description ? <Form.Control.Feedback type="invalid" >{formik.errors.description}</Form.Control.Feedback> :
                                                            <div className="max-char">{formik.values.description.split(/\s+/).length + "/1000"}</div>

                                                        }


                                                    </div>

                                                    <Form.Control.Feedback type="invalid">{toucheddescription && formik.errors.visa_process && formik.errors.visa_process[index] && formik.errors.visa_process[index]?.description && formik.errors.visa_process[index]?.description}</Form.Control.Feedback>
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    {index == formik.values?.visa_process?.length - 1 &&
                                                        <div>
                                                            <Button type="button" onClick={() => visaprocess()} className='save-btn me-1' style={deleteBtn} >Add</Button>
                                                        </div>}
                                                    <div>
                                                        {formik.values?.visa_process?.length > 1 && <Button type="button" onClick={() => deletevisaprocess(index)} className='save-btn' style={deleteBtn}>Delete</Button>}
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div class="hr-divider-country"></div>

                                    </>

                                }
                                )
                                }
                            </Row>

                            <div className="group mt-3">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('topindustries')}>Back</Button>
                                <Button type="button" onClick={() => onClickvisaprocess()} className='save-btn me-4'>Next</Button>
                            </div>
                        </>
                    }
                    {
                        formView === 'otherbenifits' &&
                        <>
                            <Row>
                                <Form.Group as={Col} md="12" controlId="validationCustom03">
                                    <Form.Label>Other Benefits</Form.Label>
                                    <div className={`${formik.touched.other_benefits && formik.errors.other_benefits ? 'red-border' : ''}`}>
                                        <CKEditor
                                            name="other_benefits"
                                            id="ck_description"
                                            editor={ClassicEditor}
                                            height={300}
                                            className={`${formik.touched.other_benefits && formik.errors.other_benefits ? 'red-border' : ''


                                                }`}
                                            // style={{height:'110px'}}
                                            config={{
                                                toolbar: {

                                                    items: [
                                                        '|',
                                                        'heading',
                                                        'bold',
                                                        'italic',
                                                        'underline',
                                                        'strikethrough',
                                                        'subscript',
                                                        'superscript',
                                                        'link',
                                                        'blockQuote',
                                                        'insertTable',
                                                        'undo',
                                                        'redo',
                                                        'alignment',
                                                        'fontSize',
                                                        'fontColor',
                                                        'backgroundColor',
                                                        'list',
                                                        'bulletedList',
                                                        'indent',
                                                        'outdent',
                                                        'code',
                                                        'codeBlock',
                                                    ]
                                                },
                                                language: 'en',
                                            }}
                                            isInvalid={formik.touched.other_benefits && formik.errors.other_benefits}
                                            data={formik.values?.other_benefits}

                                            onReady={editor => {


                                            }}

                                            onChange={otherbenifitsinputHandler}
                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}
                                        />
                                    </div>
                                    {formik.touched.other_benefits && formik.errors.other_benefits && (
                                        <div className="feedback_errors">{formik.errors.other_benefits}</div>
                                    )}

                                    <Form.Control.Feedback type="invalid"> {formik.touched.other_benefits && formik.errors.other_benefits}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>


                            <div className="group">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('topindustries')}>Back</Button>
                                {isloader === true ?
                                    <div className='loader'>
                                        <img src={loader} alt="" />
                                    </div>
                                    :
                                    <Button type="submit" className='save-btn me-4'>Save</Button>
                                }
                            </div>

                        </>


                    }

                </Form>
            </div>
        </React.Fragment>
    )

}

export default CountryCreate;