import request from "../api/agent";
import moment from 'moment';



export class JobListService {

    async getJobList(limit,page,filterId,Search) {
        // const serchValue = search??""
        let base_url='career/apply-for-jobjob-application-all-listing?'
        if(page){
            base_url=base_url+'page='+page+'&'
        }
        if(limit){
            base_url=base_url+'limit='+limit+'&'
        }
        if(Search){
            base_url=base_url+'search='+Search+'&'
        }
        if(filterId){
            
            base_url=base_url+'start_date='+moment(filterId[0]).format("YYYY-MM-DD")+'&'
        }
        if(filterId){
            base_url=base_url+'end_date='+moment(filterId[1]).format("YYYY-MM-DD")+'&'
        }
        
       
        const response = await request.get(base_url);
        return response.data;
    }

    async deleteJobList(id) {
        const type = typeof id;
        console.log(type);
        const response = await request.delete(`career/apply-for-jobdestroy-job-application`, {
            data: { job_application_id: type== 'number' ? [id] : id }
        });
        return response;
    }
}