import request from "../api/agent";
const API_ROUTE = "university/university_news";

export class UniversityNewsService {
    async addOrUpdateUniversityNews(data) {
        let action = `${API_ROUTE}/create-or-update-university-news`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllUniversityNews(perpage,currentPage,search) {
        const serchValue = search??""
        if(perpage){
            const response = await request.get(`${API_ROUTE}/get-university-news?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
            return response.data;
        }else{
            const response = await request.get(`${API_ROUTE}/get-university-news`);
            return response.data;
        }
    }
    
    async getUniversityNews() {
        const response = await request.get(`${API_ROUTE}/get-university`);
        return response.data;
    }

    async deleteUniversityNews(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-university-news`, {
            data: { id: id }
        });
        return response;
    }
}