import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';

import { BatchService } from '../../services/Batch';


const CreateHappyCoustmers=({ formik, isloader, toaster, setToaster, setImage, image, formRef, imageError }) => {
    // const [types, setTypes] = useState([]);
    const [batch, setbatch]=useState([]);
    const batchservice=new BatchService();

    async function getAllbatch() {
        const data=await batchservice.getBatch();
        setbatch(data?.data?.results)
        console.log(data);
    }

    useEffect(() => {
        getAllbatch();
    }, [])
    const onChangeImage=(e) => {
        setImage(e.target.files[0])
        formik.setValues({ ...formik.values, image: e.target.files[0], image_url: e.target.files[0]? URL.createObjectURL(e.target.files[0]):"" })
    }

    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03" className='mb-2'>
                            <Form.Label>Batch </Form.Label>
                            <Form.Select
                                placeholder="Select an Option"
                                className="select-dropdown"
                                classnameprefix="select"
                                name="batch"
                                value={formik.values?.batch}
                                isInvalid={formik.touched.batch&&formik.errors.batch}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.batch&&!formik.errors.batch}
                                onChange={formik.handleChange}
                            >
                                <option value='' >Select Batch</option>
                                {batch?.map(batch =>
                                    <option value={batch?.id}>{batch?.name}</option>
                                )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.batch}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04" className='mb-2'>
                            <Form.Label>Testimonial </Form.Label>
                            <Form.Control
                                as="textarea"
                                type="text"
                                name="comments"
                                value={formik.values?.comments}
                                onChange={(e) => {
                                    const inputValue=e.target.value;
                                    const charCount=inputValue.length;
                                    console.log(inputValue.split(/\s+/).length)
                                    // debugger
                                    if (inputValue.split(/\s+/).length<=1000) {
                                        formik.handleChange(e);
                                    } else {
                                        alert("Word Limit Exceeded")
                                    }

                                }}
                                isValid={formik.touched.comments&&!formik.errors.comments}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.comments&&formik.errors.comments}
                                placeholder="Testimonial"
                                // maxLength={1000}
                                style={{ height: '110px' }}
                            />
                            {formik.errors.comments? <Form.Control.Feedback type="invalid" >{formik.errors.comments}</Form.Control.Feedback>:
                                <div className="max-char">{formik.values.comments.split(/\s+/).length+"/1000"}</div>

                            }
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Customer Name </Form.Label>
                            <Form.Control
                                type="text"
                                name="customer_name"
                                value={formik.values?.customer_name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.customer_name&&!formik.errors.customer_name}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.customer_name&&formik.errors.customer_name}
                                placeholder="Customer Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.customer_name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label> Customer Image<span className='required ms-1'>*</span> </Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        onChangeImage(e)
                                    }
                                    }
                                    isValid={formik.touched.image&&!formik.errors.image}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.image&&formik.errors.image}
                                />
                                {formik.values?.image_url&&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }
                                {/* {!formik.touched.image && !formik.values.image && <div style={{
                                    fontSize: "14px",
                                    color: "#198754",
                                    margin: "4px 0 0 4px",
                                    fontWeight: "500"
                                }}>Max size. 2mb</div>} */}
                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                            </div>
                            <div><p className='size'>(Only jpg/jpeg and png files are allowed, Dimensions: 225 * 225 px)</p></div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Band Score </Form.Label>
                            <Form.Control
                                type="text"
                                name="band_score"
                                value={formik.values?.band_score}
                                onChange={formik.handleChange}
                                isValid={formik.touched.band_score&&!formik.errors.band_score}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.band_score&&formik.errors.band_score}
                                placeholder="Band Score"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.band_score}</Form.Control.Feedback>
                        </Form.Group>

                    </Row>
                    <div className="group">
                        {isloader==true?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateHappyCoustmers;