import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link, json } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ScholarshipWinnerService } from '../../services/ScholarshipWinnerService';
import WinnersDatatable from './WinnersDatatable';
import WinnersCreate from './WinnersCreate';
import { checkUserPermission } from '../../checkUserPermission';
import { dataURLtoFile } from '../../functions';

const Winners = () => {

    const scholarshipWinnersService = new ScholarshipWinnerService();

    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = useState(true);
    const [scroll, setScroll] = useState(false);
    const [image, setImage] = useState();

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [scroll])
  
  
    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage);
        setRefresh(!refresh);
    };

    async function getAllWinners() {
        setPending(true)
        const data = await scholarshipWinnersService.getWinners(perpage, currentPage,filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setPending(false)
    }

    useEffect(() => {
        getAllWinners()
    }, [refresh])
    // const imageValidation = Yup
    // .mixed()
    // .required("Required (Max size. 2mb)")
    // .test("size", "Image should be less than 2 MB", (value) => {
    //     return value && value.size <= 2000000;
    // })
    // .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
    //     return value && (
    //         value.type === "image/jpeg" ||
    //         value.type === "image/jpg" ||
    //         value.type === "image/png"
    //     );
    // })
    const validationSchema = Yup.object().shape({
        user: Yup.string().required('Required'),
        place: Yup.string().required('Required'),
        // image:imageValidation,
    });

    const formik = useFormik({
        initialValues: {
            instance_id: "",
            user: "",
            place: "",
             image: "",
            image_url: "",
        },
        validationSchema,
        validate: async (values) => {
            const errors ={}
        if(values?.image == ""){
            errors.image = "Required"
        }
        else if (values?.image !== "" &&values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
            errors.image = "Only jpg/jpeg and png files are allowed!"
        }
        else {
            let kb = values.image.size / 1024; // convert the file size into byte to kb
            let mb = kb / 1024; // convert kb to mb
            if (mb > 2) {
                errors.image = "images should be less than 2 MB"
            }
        } if (values?.image !== "" &&values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.instance_id) {
                formData.append("instance_id", values.instance_id ?? "");
                // formData.instance_id = values.instance_id ?? "";
            }
            // formData.user = values.user;
            // formData.place = values.place;
            // formData.image = values.image;
            // formData.image_url = values.image_url;
            formData.append("user", values.user);
            formData.append("place", values.place);
            formData.append("image", values?.image);
            formData.append("image_url",values?.image_url);

            scholarshipWinnersService.addOrUpdateWinners(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        question: response?.response?.data?.errors?.title,
                        category: response?.response?.data?.errors?.category,
                        university_course: response?.response?.data?.errors?.university_course,
                        price: response?.response?.data?.errors?.price,
                        about: response?.response?.data?.errors?.about,
                        requirements: response?.response?.data?.errors?.requirements,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    const handleEdit = async (item) => {
        setScroll(preVal=>!preVal)
        console.log(item)
        formik.setValues({
            instance_id: item.id,
            user: item.user,
            place: item.place,
            image: await dataURLtoFile(item?.image),
            image_url: item?.image,
        })
    }
console.log(formik.errors)
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Scholarship Winners</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Scholarship Winners</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Scholarship Winners") && <React.Fragment>
                        <WinnersCreate
                            formik={formik}
                            isloader={isloader}
                            toaster={toaster}
                            setToaster={setToaster}
                            formRef={formRef}
                            setImage={setImage}
                        />
                    </React.Fragment>}
                    <React.Fragment>
                        <WinnersDatatable
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            totalRows={totalRows}
                            formik={formik}
                            pending={pending}
                            currentPage={currentPage}
                            setFilterText={setFilterText}
                        />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default Winners