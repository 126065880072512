import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';




const CreateCoursesyllabus = ({ formik, isloader, formRef, formView, setFormView }) => {
    console.log(formik.errors)
    //const [universityTypes, setUniversityTypes] = useState([]);
    //const [categoryType, setCategoryType] = useState([]);
    const [errors, setErrors] = useState("false");
    const [errorState, setErrorState] = useState();

   // const universityCourseService = new UniversityCourseService();
    //onst scholarshipCategoryService = new ScholarshipCategoryService();

    // async function getAllUniversityTypes() {
    //     const data = await universityCourseService.getAllUniversityCourses();
    //     setUniversityTypes(data?.data?.results)
    //     console.log(data);
    // }

    // async function getAllCategories() {
    //     const data = await scholarshipCategoryService.getCategories();
    //     setCategoryType(data.data.results)
    // }

    // useEffect(() => {
    //     getAllUniversityTypes();
    //     getAllCategories()
    // }, [])

    function addPoints() {
        let points = formik.values?.course_syllabus_points;
        points?.push({
            points: ''
        });
        formik.setValues({ ...formik.values, course_syllabus_points: points })
    }

    console.log(typeof formik.errors)
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    {formView === "title" &&
                        <>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        value={formik.values?.title}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.title && !formik.errors.title}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.title && formik.errors.title}
                                        placeholder="Title"
                                        maxLength={100}
                                    />
                                    {formik.touched.title && formik.errors.title &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                                    }

                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>course details id</Form.Label>
                                    <Form.Select
                                        placeholder="Select an Option"
                                        className="select-dropdown"
                                        classNamePrefix="select"
                                        name="course_details_id"
                                        value={formik.values?.course_details_id}
                                        isInvalid={formik.touched.course_details_id && formik.errors.course_details_id}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.course_details_id && !formik.errors.course_details_id}
                                        onChange={formik.handleChange}
                                    >
                                        <option value='' >Select an Option</option>
                                        {/* {course_details_id?.map(course_details_id =>
                                            <option value={course_details_id?.id}>{category?.course_details_id}</option>
                                        )} */}
                                    </Form.Select>
                                    {formik.touched.course_details_id && formik.errors.course_details_id &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.course_details_id}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                            <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Days</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="days"
                                        value={formik.values?.days}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.days && !formik.errors.days}
                                        isInvalid={formik.touched.days && formik.errors.days}
                                        placeholder="days"
                                        maxLength={100}
                                    />
                                    {formik.touched.days && formik.errors.days &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.days}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                               
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Number assignments</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="number_assignments"
                                        value={formik.values?.number_assignments}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.number_assignments && !formik.errors.number_assignments}
                                        isInvalid={formik.touched.number_assignments && formik.errors.number_assignments}
                                        placeholder="number assignments"
                                        maxLength={100}
                                    />
                                    {formik.touched.number_assignments && formik.errors.number_assignments &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.number_assignments}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                               
                                
                            </Row>
                            
                            <div className="group">
                                <Button type="button" onClick={() => {
                                  !formik.errors.title && /*!formik.errors.course_details_id &&*/ !formik.errors.days && !formik.errors.number_assignments && setFormView('course_syllabus_points')
                                    }} className='save-btn me-4'>Next</Button>
                            </div>
                        </>}
                    {formView === 'course_syllabus_points' &&
                        <>
                            <Row>
                                {formik.values.course_syllabus_points.map((points, index) => {
                                    console.log(points)
                                    if (points === undefined) {
                                        setErrors(true)
                                        return;
                                    }
                                    const touched = formik.touched?.course_syllabus_points && formik.touched?.course_syllabus_points[index];
                                    return <>
                                        <Form.Group as={Col} md="6" controlId="validationCustom07">
                                            <Form.Label>{index == 0 && "Points"}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={`course_syllabus_points[${index}].points`}
                                                value={formik.course_syllabus_points?.points}
                                                onChange={formik.handleChange}
                                                isValid={ touched && errors}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.course_syllabus_points && formik.errors.course_syllabus_points && formik.errors.course_syllabus_points[index] && formik.errors.course_syllabus_points[index]?.points && !!formik.errors.course_syllabus_points[index]?.points}
                                                placeholder="course syllabus points"
                                                maxLength={100}
                                            />
                                            <Form.Control.Feedback type="invalid">{formik.errors.course_syllabus_points && formik.errors.course_syllabus_points[index] && formik.errors.course_syllabus_points[index]?.points && formik.errors.course_syllabus_points[index]?.points}</Form.Control.Feedback>
                                        </Form.Group>
                                        {index == formik.values?.course_syllabus_points?.length - 1 &&
                                            <div>
                                                <Button type="button" onClick={() => addPoints()} className='save-btn me-4 ms-2 mb-4'>Add</Button>
                                            </div>}
                                    </>
                                })}
                            </Row>
                            <div className="group">
                                {isloader === true ?
                                    <div className='loader'>
                                        <img src={loader} alt="" />
                                    </div>
                                    :
                                    <>
                                    <Button type="submit" className='save-btn me-4'>Save</Button>
                                    <Button type="button" className='save-btn me-4' onClick={()=>setFormView('title')}>Back</Button>
                                    </>
                                }
                            </div>
                        </>}
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateCoursesyllabus;