import request from "../api/agent";
const API_ROUTE = "intakes/intake";

export class IntakeService {
    async addOrUpdateIntake(intake) {
        let action = `${API_ROUTE}/create-or-update-intake`;
        const response = await request.post(action, intake);
        return response;
    }

    async getAllIntake(perpage,currentPage) {
        const response = await request.get(`${API_ROUTE}/intake-listing?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }


    async deleteIntake(id) {
        const response = await request.delete(`${API_ROUTE}/intake-delete`, {
            data: { instance_id: [id] }
        });
        return response;
      }
      async setStatus(id) {
        const response = await request.post(`${API_ROUTE}/intake-status`, { instance_id: id });
        return response;
    }

}