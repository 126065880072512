import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';




const CreateTestimonial = ({ formik, isloader, formRef, image, setImage }) => {
    console.log(formik.errors)
    const onChangeImage = (e) => {
            setImage(e.target.files[0])
            formik.setValues({ ...formik.values, image: e.target.files[0], image_url:e.target.files[0]  ? URL.createObjectURL(e.target.files[0]) : "" })
    }
    
    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        {/* <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Thumbnail</Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        onChangeImage(e)
                                    }
                                    }
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.image && !formik.errors.image}
                                    isInvalid={formik.touched.image && formik.errors.image}
                                />
                                 <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                                 { !formik.touched.image &&  !formik.values.image && <div style={{
                                        fontSize: "14px",
                                        color: "#198754",
                                        margin: "4px 0 0 4px",
                                        fontWeight:"500"
                                    }}>Max size. 2mb</div>}
                                {formik.values?.image_url && 
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }
                            </div>
                        </Form.Group> */}
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                                <Form.Label>Video URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="video"
                                    value={formik.values?.video}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.video && !formik.errors.video}
                                    isInvalid={formik.touched.video && formik.errors.video}
                                    placeholder="Video"
                                    maxLength={100}
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.video}</Form.Control.Feedback>
                            </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateTestimonial;
