import request from "../api/agent";
const API_ROUTE = "university/university_course";

export class UniversityCourseService {
    async addOrUpdateUniversityCourse(data) {
        let action = `${API_ROUTE}/create-or-update-university-course`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllUniversityCourses(perpage,currentPage,search) {
        console.log(perpage)
        const serchValue = search ?? ""
        if(perpage){
        const response = await request.get(`${API_ROUTE}/get-university-course?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
        }else{
            const response = await request.get(`${API_ROUTE}/get-university-course`)
            return response.data;
        }
    }
    async getUniversityCourses() {
        const response = await request.get(`${API_ROUTE}/get-university-course-dropdown`);
        return response;
    }
    async deleteUniversityCourse(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-university-course`, {
            data: { id: id }
        });
        return response;
    }
    async setStatus(id) {
        const response = await request.put(`${API_ROUTE}/university-course-status-change-admin`, { id	: id });
        return response;
    }
    async setPopular(id) {
        const response = await request.put(`${API_ROUTE}/university-course-popular`, { id	: id });
        return response;
    }
    async addCsv(data){
        let action = `${API_ROUTE}/course-finder/ai/import-csv`;
        const response = await request.post(action, data);
        return response;
    }

}