import request from "../api/agent";
const API_ROUTE = "webinar/webinar_event_type";

export class WebinarTypeService {
    async addOrUpdateWebinarType(course) {
        let action = `${API_ROUTE}/create-or-update-webinar-event-type`;
        const response = await request.post(action, course);
        return response;
    }

    async getWebinarTypes(page, limit,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/webinar-event-type-all-listing?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }
    async getAllWebinarTypes() {
        const response = await request.get(`/webinar/webinar/webniar-type-dropdown`);
        return response.data;
    }


    async deleteWebinarType(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-webinar-event-type`, {
            data: { id: id }
        });
        return response;
    }
   
}