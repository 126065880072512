import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link, json } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { JobService } from '../../services/JobService';
import JobCreate from './CreateJob';
import JobDatatable from './DatatableJob';
// import moment from 'moment';
import { checkUserPermission } from '../../checkUserPermission';

const Job = () => {

    const jobService = new JobService();
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [formView, setFormView] = useState("jobName");
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [pending, setPending] = useState(true);
    // const [active, setActive] = useState(true);
    const [scroll, setScroll] = useState(false);
    const [filterText, setFilterText] = React.useState('');

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage);
        setRefresh(!refresh);
    };
   
    const markAllFieldsAsTouched = () => {
        const fieldKeys = Object.keys(formik.values);
        const touchedFields = fieldKeys.reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {});
        formik.setTouched(touchedFields);
      };
    async function getJobDetails() {
        setPending(true)
        const data = await jobService.getJob(perpage, currentPage, filterText);
        setData(data.data)
        setTotalRows(data.data.count)
        setPending(false)
    }

    useEffect(() => {
        getJobDetails()
        // setActive(false)
    }, [refresh])

    const validationSchema = Yup.object().shape({
        job_name: Yup.string()
            .required('Required'),
        job_type: Yup.string()
            .required('Required'),
        job_location: Yup.string()
            .required('Required'),
        job_experience: Yup.string()
            .required('Required'),
        job_responsibilities: Yup.array().of(
            Yup.object().shape({
                responsibility: Yup.string().required("Required"),
            }).required("Required")
        ),
        job_candidate_profile: Yup.array().of(
            Yup.object().shape({
                profile: Yup.string().required("Required"),
            }).required("Required")
        )
    });

    const formik = useFormik({
        initialValues: {
            job_id: "",
            job_name: "",
            job_type: "",
            job_location: "",
            job_experience: "",
            job_responsibilities: [{
                responsibility: ""
            }],
            job_candidate_profile: [{
                profile: ""
            }]
        },

        validationSchema,
        onSubmit: (values) => {
            console.log("values", values);
            setIsloader(true)
            let formData = new FormData();
            if (values.job_id) {
                formData.append("job_id", values.job_id ?? "");
            }
            formData.append("job_name", values.job_name);
            formData.append("job_type", values.job_type);
            formData.append("job_location", values.job_location);
            formData.append("job_experience", values.job_experience);
            formData.append("job_responsibilities", JSON.stringify(values.job_responsibilities));
            formData.append("job_profile", JSON.stringify(values.job_candidate_profile));
            jobService.addOrUpdateJobVacancy(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    setFormView('jobName')
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        question: response?.response?.data?.errors?.title,
                        category: response?.response?.data?.errors?.category,
                        university_course: response?.response?.data?.errors?.university_course,
                        price: response?.response?.data?.errors?.price,
                        about: response?.response?.data?.errors?.about,
                        requirements: response?.response?.data?.errors?.requirements,

                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });


    const handleEdit = (item) => {
        console.log('handleEdit', item);
        setScroll(preVal => !preVal)
        formik.setValues({
            job_id: item.id,
            job_name: item.job_name,
            job_type: item.job_type,
            job_location: item.job_location,
            job_experience: item.job_experience,
            job_candidate_profile: item?.job_candidate_profile?.length ? item?.job_candidate_profile?.map(document => { return { profile: document?.profile_description } }) : [{ profile: "" }],
            job_responsibilities: item?.job_responsibilities?.length ? item?.job_responsibilities?.map(document => { return { responsibility: document?.responsibilities_description } }) : [{ responsibility: '' }],

            // job_responsibilities: item.job_responsibilities.map(item => item.responsibilities_description),
            // job_candidate_profile: item.job_candidate_profile.map(item => item.profile_description)
        })
        console.log("job_responsibilities", item.job_responsibilities);
        console.log("job_profile", item.job_candidate_profile)
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Job</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Job</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create Job") && <React.Fragment>
                        <JobCreate
                            formik={formik}
                            isloader={isloader}
                            toaster={toaster}
                            setToaster={setToaster}
                            formRef={formRef}
                            formView={formView}
                            setFormView={setFormView}
                            markAllFieldsAsTouched={markAllFieldsAsTouched}
                        />
                    </React.Fragment>}
                    <React.Fragment>
                        <JobDatatable
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            totalRows={totalRows}
                            formik={formik}
                            currentPage={currentPage}
                            pending={pending}
                            setFilterText={setFilterText}
                        />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default Job