// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile, dataURLtoPDF } from '../../functions';
// import CreateUniversityCourse from './CreateuniversityCourse';
import UniversityCourseDatatable from './universityCourseDatatable';
import { UniversityCourseService } from "../../services/UniversityCourseService"
import moment from 'moment';
import CreateUniversityCourse from './CreateuniversityCourse';
import { checkUserPermission } from '../../checkUserPermission';
import { CsvService } from '../../services/csvService';
// import { checkUserPermission } from '../../checkPermission';
const universityCourseService=new UniversityCourseService();
const csvService=new CsvService();
const UniversityCourse=() => {
    const formRef=useRef(null);
    const bulkRef=useRef(null);
    const [isloader, setIsloader]=useState(false);
    const [isBulkLoader, setIsBulkLoader]=useState(false);
    const [toaster, setToaster]=useState("");
    const [image, setImage]=useState();
    const [data, setData]=React.useState([]);
    const [refresh, setRefresh]=useState(false);
    const [imageError, setImageError]=useState(false);
    const [tableloader, setTableloader]=useState(true);
    const [filterText, setFilterText]=React.useState('');
    const [active, setActive]=useState(true);
    // const [intakeValue] = useState(intakes)
    const [formView, setFormView]=useState('details')
    const [currentPage, setCurrentPage]=useState(1);
    const [perpage, setPerpage]=useState(10);
    const [totalRows, setTotalRows]=React.useState(0);
    const [pending, setPending]=React.useState(true)
    const [scroll, setScroll]=useState(false);
    const [selectedRows, setSelectedRows]=React.useState([]);
    const [toggleCleared, setToggleCleared]=React.useState(false);
    const [modalShow, setModalShow]=React.useState(false);
    // const [data, setData] = React.useState([]);
    const handleChange=() => { setSelectedRows(selectedRows); };


    const handleRowClicked=row => {

        console.log(`${row.name} was clicked!`);
    };

    const handlePerRowsChange=async (newPerPage) => {
        setPerpage(newPerPage);
        setRefresh(!refresh);
    };

    const markAllFieldsAsTouched=() => {
        const fieldKeys=Object.keys(formik.values);
        const touchedFields=fieldKeys.reduce((acc, key) => {
            acc[key]=true;
            return acc;
        }, {});
        formik.setTouched(touchedFields);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    async function getAllCourses() {
        setPending(true)
        const data=await universityCourseService.getAllUniversityCourses(perpage, currentPage, filterText);
        setPending(false)
        setData(data.data)
        setTotalRows(data?.data?.count)


    }
    useEffect(() => {
        getAllCourses()
    }, [refresh])

    const imageValidation=Yup
        .mixed()
        // .required("Required (Max size. 2mb)")
        // .test("size", "The file is too large", (value) => {
        //     return value&&value.size<=2000000;
        // })
        .test("type", "Only the following formats are accepted: .zip", (value) => {
            return value&&(
                value.type===".zip",
                value.type==="application/x-zip-compressed"
            );
        })
    const validation=Yup.object().shape({
        file: imageValidation
    });

    const bulkformik=useFormik({
        initialValues: {
            country: "",
            file: "",
            file_url: "",
        },
        validationSchema: validation,
        // validate:() => {
        //     const errors = {};

        //     errors.file = "required"
        //     return errors;

        // },
        onSubmit: (values) => {
            setIsBulkLoader(true)
            console.log(")))))))))", values);

            let formData=new FormData();
            formData.append("country", values?.country);
            formData.append("file", values?.file);
            csvService.addCsv(formData).then((response) => {
                setIsBulkLoader(false)

                if (response&&response.status) {
                    bulkRef.current.reset();
                    bulkformik.resetForm();
                    setModalShow(false)
                    // toast.success("Success", {
                    //     position: toast.POSITION.TOP_RIGHT
                    // });
                    setRefresh(!refresh)
                }
                else {
                    setModalShow(false)
                    // bulkformik.setErrors({
                    //     country: response?.response?.data?.errors?.country,
                    //     file: response?.response?.data?.errors?.file,
                    //     file_url: response?.response?.data?.errors?.file_url,
                    // });

                    // toast.error("Something went wrong", {
                    //     position: toast.POSITION.TOP_RIGHT
                    // });
                }
            });

        }
    })

    // bulkformik.setValues({
    //     country: item?.country,
    //     file: item?.pdf_file ? await dataURLtoPDF(item.pdf_file) : "",
    //     file_url: item.pdf_file ? item.pdf_file : "",
    // })
    const validationSchema=Yup.object().shape({
        university_id: Yup.number().required('Required'),
        course_id: Yup.number().required('Required'),
        // intake: Yup.array().min(1).required('Required'),
        // syllabus: Yup.array().min(1).required('Required'),
        // total: Yup.string().required('Required'),
        // duration: Yup.string().required('Required'),
        intake: Yup.array().of(
            Yup.object().shape({
                intake: Yup.string().required("Required")
            })
        ),
        required_documents: Yup.array().of(
            Yup.object().shape({
                required_details: Yup.string().required("Required")
            }).required("Required")
        ),
        // over_view: Yup.array().of(
        //     Yup.object().shape({
        //         required_details: Yup.string().required("Required")
        //     }).required("Required"),
        // ),
        // syllabus: Yup.array().of(
        //     Yup.object().shape({
        //         title: Yup.string().required("Required"),
        //         days: Yup.string().required("Required"),
        //         number_assignments: Yup.string().required("Required"),
        //         syllabus_details: Yup.array().of(
        //             Yup.object().shape({
        //                 details: Yup.string().required("Required"),
        //             }).required("Required"),
        //         ).required("Required"),
        //     }).required("Required"),
        // ),
        // fee_details: Yup.array().of(
        //     Yup.object().shape({
        //         term: Yup.string().required("Required"),
        //         fees_type: Yup.string().required("Required"),
        //         amount: Yup.string().required("Required"),
        //     }).required("Required"),
        // ),
    });

    const formik=useFormik({
        initialValues: {
            id: "",
            course_id: "",
            course:"",
            university_id: "",
            university:"",
            application_fee: "",
            duration: "",
            total: "",
            is_scholarship: true,
            fee_details: [{
                term: '',
                fees_type: '',
                amount: '',
            }],
            intake: [{
                intake: '',
                is_open: false,
                deadline: ""
            }],
            over_view: [{
                required_details: '',
            }],
            syllabus: [{
                title: '',
                days: '',
                number_assignments: '',
                syllabus_details: [{
                    details: ''
                }],
            }],
            required_documents: [{
                required_details: ''
            }],
            syllabus_pdf: "",
            syllabus_pdf_url: "",
            ielts_score: "",
            ielts_no_band_less: "",
            toefl_score: "",
            toefl_no_band_less: "",
            pte_score: "",
            pte_no_band_less: "",
            sat_score: "",
            gre_score: "",
            mat_score: "",
            act_score: "",
            backlog_range: "",
            web_url: ""
        },
        validationSchema,
        validate: async (values) => {
            const errors = {};
            if (!values.course_id) {
                errors.course_id = "Course is required";
            }
            if (!values.university_id) {
                errors.university_id = "University is required";
            }
            return errors;
        },
        onSubmit: (values) => {
            let formData=new FormData();
            console.log(values);
            setIsloader(true)
            if (values.id) {
                formData.append("university_course_id", values.id??"");
            }
            formData.append("course_id", values.course_id);
            formData.append("university_id", values.university_id);
            formData.append("duration", values?.duration);
            formData.append("application_fee", values?.application_fee);
            formData.append("syllabus_pdf", values?.syllabus_pdf);
            formData.append("total", values?.total);
            formData.append("web_url", values?.web_url);
            values.fee_details.map(detail => {
                if (detail.term===""||detail.fees_type===""||detail.amount==="") {
                    formData.append("fee_details", "");
                } else {
                    formData.append("fee_details", JSON.stringify(values?.fee_details));
                }
            })

            formData.append("intake", JSON.stringify(values?.intake));
            formData.append("over_view", JSON.stringify(values?.over_view));
               const isValid =  values?.syllabus[0]?.title || values?.syllabus[0]?.days || values?.syllabus[0]?.number_assignments ||  values?.syllabus[0]?.syllabus_details[0]?.details
               isValid ?  formData.append("syllabus", JSON.stringify(values?.syllabus)) : formData.append("syllabus", []);
            formData.append("required_documents", JSON.stringify(values?.required_documents));
            formData.append("score_listing", JSON.stringify([
                { ielts_score: formik.values.ielts_score },
                { ielts_no_band_less: formik.values.ielts_no_band_less },
                { toefl_score: formik.values.toefl_score },
                { toefl_no_band_less: formik.values.toefl_no_band_less },
                { pte_score: formik.values.pte_score },
                { pte_no_band_less: formik.values.pte_no_band_less },
                { sat_score: formik.values.sat_score },
                { gre_score: formik.values.gre_score },
                { mat_score: formik.values.mat_score },
                { act_score: formik.values.act_score },
                { backlog_range: formik.values.backlog_range }
            ]));
            universityCourseService.addOrUpdateUniversityCourse(formData).then((response) => {
                if (response&&response.status) {
                    setIsloader(false)
                    setFormView("details")
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        university_id: response?.response?.data?.errors?.title,
                        fee_details: response?.response?.data?.errors?.fee_details,
                        required_documents: response?.response?.data?.errors?.image,
                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    const handleEdit=async (item) => {
        setScroll(preVal => !preVal)
        let fees_details=[];
        let syllabus=[];
        let intake=[];

        item?.fees_details&&item?.fees_details.map(detail => {
            console.log("ppp", item.fees_details);
            fees_details.push({
                term: detail?.term,
                fees_type: detail?.fees_type,
                amount: detail?.amount,
            })
        })
        item?.syllabus&&item?.syllabus.map(data => {
            syllabus.push({
                title: data?.title,
                days: data?.days,
                number_assignments: data?.number_assignments,
                syllabus_details: data?.syllabus_details?.map(detail => { return { details: detail?.details } }),
            })
        })
        formik.setValues({
            id: item.id,
            course_id: Number(item?.course_id),
            university_id: Number(item?.university_id),
            duration: item?.duration,
            web_url: item?.web_url,
            application_fee: item?.application_fee,
            total: item?.total,
            fee_details: fees_details.length? fees_details:[{
                term: '',
                fees_type: '',
                amount: null,
            }],
            // intake: item?.intake.length ? item?.intake?.map(intake => { return { intake: intake?.month } }) : [{
            //     intake: '',
            // }],
            intake: item?.intake.length
                ? item?.intake.map((intake) => {
                    return {
                        intake: intake?.month,
                        is_open: intake?.is_open||false,
                        deadline: intake?.application_deadline
                    };
                })
                :[
                    {
                        intake: '',
                        is_open: false,
                        deadline: ''
                    },
                ],
            over_view: item?.over_view.length? item?.over_view?.map(over_view => { return { required_details: over_view?.details } }):[{
                required_details: '',
            }],
            syllabus: item?.syllabus.length? item.syllabus.map(item => {
                if (item.syllabus_details.length) {
                    return {
                        title: item.title,
                        days: item.days,
                        number_assignments: item.number_assignments,
                        syllabus_details: item?.syllabus_details.length? item?.syllabus_details?.map(syllabus_details => { return { details: syllabus_details?.points } }):[{
                            details: '',
                        }],
                    }
                } else {
                    return syllabus;
                }
            }):[{
                title: '',
                days: '',
                number_assignments: '',
                syllabus_details: [{
                    details: ''
                }],
            }],
            required_documents: item?.required_documents.length? item?.required_documents?.map(document => { return { required_details: document?.details } }):[{
                required_details: ''
            }],
            syllabus_pdf: item.pdf_file? await dataURLtoPDF(item.pdf_file):"",
            syllabus_pdf_url: item.pdf_file? item.pdf_file:"",
            ...item?.score_listing[0]
        })
        setFormView("details")
    }

    console.log("formikerrors", formik);
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>University Course</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">University Course</li>
                            </ol>
                        </nav>
                    </div>
                    {checkUserPermission("Create University Course")&&<React.Fragment>
                        <CreateUniversityCourse markAllFieldsAsTouched={markAllFieldsAsTouched} image={image} formView={formView} setFormView={setFormView} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />
                    </React.Fragment>}
                    <React.Fragment>
                        <UniversityCourseDatatable modalShow={modalShow} setModalShow={setModalShow} bulkRef={bulkRef} isBulkLoader={isBulkLoader} bulkformik={bulkformik} totalRows={totalRows} toggleCleared={toggleCleared} handlePerRowsChange={handlePerRowsChange} handleRowClicked={handleRowClicked} handleChange={handleChange} setFilterText={setFilterText} pending={pending} currentPage={currentPage} setPerpage={setPerpage} setCurrentPage={setCurrentPage} setActive={setActive} tableloader={tableloader} filterText={filterText} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default UniversityCourse;