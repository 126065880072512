import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Select from 'react-select';
import { GroupService } from '../../services/GroupService';


const groupService = new GroupService();
let options = [];

const GroupCreate = ({ formik, isloader, formRef, setRoles }) => {

    const [update, setUpdate] = useState(false)

    useEffect(() => {
        getAllRoles()
    }, [])

    async function getAllRoles() {
        const data = await groupService.getGroupRoles();
        options = []
        data.data?.roles.forEach((item) => {
            options.push({ value: item.value, label: item.label })
        })
        setUpdate(!update)
    }

    const handleOnSelectChange = ({ name, value }) => {
        console.log("value.value")
        console.log(value)
        formik.setValues({ ...formik.values, [name]: value })
        setRoles(value.map((val) => val.value))
    }

    const style = {
        control: (styles) => ({
          ...styles,
          borderColor: formik.errors.roles ? "#dc3545" : "white",
          fontSize: "14px",
          height: "10px",
    
          "&:focus": {
            boxShadow: "#f5b7bd 0px 0px 0px 3px",
            borderColor: "#dc3545",
          },
        }),
      };


    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Name </Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formik.values?.name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.name && !formik.errors.name}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.name && formik.errors.name}
                                placeholder="Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Roles</Form.Label>
                            <Select
                                // className="basic-single"
                                classNamePrefix="select"
                                isMulti
                                value={formik.values.roles}
                                // styles={style}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.roles && !formik.errors.roles}
                                isInvalid={formik.touched.roles && formik.errors.roles}
                                name="roles"
                                options={options}
                                onChange={(e) => { handleOnSelectChange({ name: 'roles', value: e }) }}
                                placeholder="Select Role Type..."
                            />
                            <div className="errorMessage text-danger mt-1">
                                {formik.errors.roles}
                            </div>
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader === true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default GroupCreate;
